/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input, Select, Spin } from 'antd';

import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';
import ModalServerError from './ModalServerError';

const { TextArea } = Input;
export default class ModalBlacklist extends Component {

    constructor(props) {
        super(props);


        this.state = {
            BLK_PER: undefined,
            Destinationlist: [],
            errors: [],
            pagesizeDes: 20,
            modalVisible: false,
        };

        this.GetDropDownListDestination = this.GetDropDownListDestination.bind(this);
        this.handleSetDestination = this.handleSetDestination.bind(this);
        this.handleCheckBlacklist = this.handleCheckBlacklist.bind(this);
        this.handleVaildate = this.handleVaildate.bind(this);
        this.handleAutoSearch = this.handleAutoSearch.bind(this);

    }

    componentDidMount() {
        this.GetDropDownListDestination(null, "get");

    }

    componentDidUpdate(prevProps) {
        if (this.props.BLK_PER !== prevProps.BLK_PER) {
            this.setState({ BLK_PER: this.props.BLK_PER });
            this.GetDropDownListDestination(null, "get");
        }
        if (this.props.BLK_DES !== prevProps.BLK_DES) {
            this.setState({ BLK_DES: this.props.BLK_DES });
        }
        if (this.props.BLK_DESC !== prevProps.BLK_DESC) {
            this.setState({ BLK_DESC: this.props.BLK_DESC });
        }
        if (this.props.errors !== prevProps.errors) {
            this.setState({ errors: this.props.errors });
        }
    }

    GetDropDownListDestination(value, get) {
        if (!CheckTokenExp()) {
            let searchLength = value == null ? 0 : value.length;

            if (searchLength > 5 || get == "get") {
                this.setState({ Destinationlist: [], loading: true })

                let req = JSON.stringify({
                    "SEARCH": value == null ? "" : value,
                    "PAGESIZE": this.state.pagesizeDes
                });

                apiClient.post('api/Blacklist/DROPDOWNLISTS_DESTINATION', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        if (data.status === 200 && data.data) {
                            this.setState({ Destinationlist: data.data });
                        } else {
                            this.setState({ modalVisible: true });
                            this.setState({ Destinationlist: [] });
                        }
                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ modalVisible: true });
                        this.setState({ Destinationlist: [] });
                        this.setState({ loading: false });
                    });
            }
        } else {
            window.location.href = "logout";
        }

    }

    handleSetDestination(value) {
        let BLK_DES = value.props.name;
        this.setState({ BLK_DES: BLK_DES });
        this.props.setStateByProps("BLK_DES", BLK_DES)
    }

    handleCheckBlacklist() {
        if (!CheckTokenExp()) {
            let req = JSON.stringify({
                "BLK_DES": this.state.BLK_DES,
            });
            apiClient.post('api/Blacklist/CHECK_DESTINATION', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.handleVaildate(json);
                    this.setState({ loading: false });
                })
                .catch(req => {

                    this.setState({ loading: false });

                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleVaildate(value) {
        if (value == "") {
            this.props.warningModal(true, "Do you want to add this person to blacklist?", "Added blacklist successfully.")
            this.props.setStateByProps("action", "add")
        } else {
            let error = value.replace(/"/g, "");
            let errors = {};
            errors["BLK_DES"] = error
            this.props.setStateByProps("msg_errors", errors);
            this.props.warningModal(true, "Do you want to add this person to blacklist?", "Added blacklist successfully.")
            this.props.setStateByProps("action", "error")
        }
        
    }

    handleAutoSearch(value) {
        let searchLength = value == null ? 0 : value.length;
        if (searchLength == 0) {
            this.GetDropDownListDestination(value, "get")
        } else {
            this.GetDropDownListDestination(value)
        }
    }

    render() {
        const { visible, setStateByProps, loading } = this.props;
        const { modalVisible } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        return (
            <>
                <ModalServerError modalVisible={modalVisible}/>
                <Modal
                    width={700}
                    title={<div>Backlist <span className="fa fa-times-circle-o card-close" onClick={this.props.handleOk}></span></div>}
                    visible={visible}
                    onOk={this.props.handleOk}
                    onCancel={false}
                    footer={[
                        <Row gutter={16} type="flex" justify="end" style={styleMarginBottom} style={{ textAlign: 'right', marginRight: 31 }} >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Button type="primary" style={{ marginRight: 8 }} loading={loading}
                                    disabled={this.state.BLK_DES && this.state.BLK_DESC ? false : true}
                                    onClick={this.handleCheckBlacklist}>
                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                    Add
                            </Button>
                            </Col>
                        </Row>
                    ]}
                >

                    <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Name</label>
                            <Select value={this.state.BLK_PER}
                                showSearch
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                onSearch={function (value) { this.handleAutoSearch(value) }.bind(this)}
                                notFoundContent={this.state.loading ? <Spin size="small" /> : null}
                                onChange={function (value, name) { this.setState({ BLK_PER: value }, this.props.setStateByProps("BLK_PER", value), this.handleSetDestination(name)) }.bind(this)}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                placeholder="Select person">
                                {
                                    this.state.Destinationlist.map((c, index) => (
                                        <Option key={index} name={c.DES_VALUE} value={c.VALUE} >{c.TEXT}</Option>))
                                }
                            </Select>

                        </Col>
                    </Row>

                    <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Destination</label>
                            <label className="red">*</label>
                            <Input style={{ maxWidth: '100%' }} value={this.state.BLK_DES} name="BLK_DES" placeholder="Enter mobile number or e-mail here"
                                onChange={(e) => {
                                    this.props.setStateByProps("BLK_DES", e.currentTarget.value)
                                }} />
                            {
                                <div className="errorMsg" style={{ color: '#ff0000' }}>{this.state.errors.BLK_DES}</div>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Description</label>
                            <label className="red">*</label>
                            <TextArea
                                placeholder="Enter blacklist description or reason here"
                                autosize={{ minRows: 2, maxRows: 3 }}
                                value={this.state.BLK_DESC}
                                name="BLK_DESC"
                                onChange={(e) => {
                                    this.props.setStateByProps("BLK_DESC", e.currentTarget.value)
                                }}
                            />
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}


