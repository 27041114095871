import React, { Component } from 'react';
import { Container, Card, CardBody, CardFooter, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Button, Spin, Row, Col, Icon, Radio, notification } from 'antd';
import { CheckUserLogedin, getAccId, token, getId, set_cookie, getExpiredDate, packagelistPage } from '../../../../config';
import queryString from 'query-string';
import OmiseCreditCard from '../../../../components/Omise/OmiseCreditCard';
import OmiseInternetBanking from '../../../../components/Omise/OmiseInternetBanking';
import OmiseTrueMoney from '../../../../components/Omise/OmiseTrueMoney';
import Omiseprompay from '../../../../components/Omise/Omiseprompay';
import { apiClient, urlClientInfo } from '../../../../api';

class OrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errormessage: "",
            order_id: null,
            order_invoice_id: null,
            packagename: null,
            packagecost: null,
            packagevat: null,
            packagecostwithvat: null,
            packagedetail: null,
            packageid: null,
            btnLoginloading: false,
            omiseSource: null,
            PaymentType: null,
            /*isallowtopay: false,*/
            loading: true,
            statusmessage: "Preparing your order...",
            statusdescription: null,
            statusred: false,

        }

        this.handleChange = this.handleChange.bind(this);
        this.getOrderDetail = this.getOrderDetail.bind(this);
        this.CreateCharge = this.CreateCharge.bind(this);
        this.ProcessCreditcardPayment = this.ProcessCreditcardPayment.bind(this);
        /*this.setAllowToPayStatus = this.setAllowToPayStatus.bind(this);*/
        this.redirecttopackagelist = this.redirecttopackagelist.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.getClientInfo = this.getClientInfo.bind(this);
        this.handleErrorMessage = this.handleErrorMessage.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    getOrderPendingDetail() {
        let req = JSON.stringify({
            "pid": queryString.parse(this.props.location.search).pid
        });

        apiClient.post('api/ecom/createorderwithpackage', req,
            { headers: { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                if (response.data.status == 999) {
                    this.redirecttopackagelist();
                } else {
                    this.setState({
                        order_id: response.data.order.id,
                        order_invoice_id: response.data.order.invoice_no,
                        packagename: response.data.order.package.PKG_NAME,
                        packagecost: response.data.order.package.PKG_COST_INFO.PKGC_PRICE,
                        packagedetail: response.data.order.package.PKG_DETAIL,
                        packageid: response.data.order.package.PKG_ID,
                        packagevat: response.data.order.package.PKG_VAT,
                        packagecostwithvat: response.data.order.package.PKG_COST_INFO.PKGC_NET_PRICE,
                        loading: false
                    });
                }
            })
            .catch(error => {
                /*
                notification.open({
                    message: error.message,
                    description: error.description,
                    top: 60,
                    className: "rti_form r",
                    duration: 0,
                    onClose: this.redirecttopackagelist
                });*/
                this.setState({
                    statusmessage: error.response.data.message,
                    statusdescription: error.response.data.description,
                    statusred: true
                });
            })
    }

    getOrderDetail() {
        const self = this;
        let req = JSON.stringify({
            "oid": queryString.parse(this.props.location.search).oid
        });

        apiClient.post('api/ecom/getorderdetail', req,
            { headers: { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                if (response.data.status == 999) {
                    self.redirecttopackagelist();
                } else {
                    self.setState({
                        order_id: response.data.order.id,
                        order_invoice_id: response.data.order.invoice_no,
                        packagename: response.data.order.package.PKG_NAME,
                        packagecost: response.data.order.package.PKG_COST_INFO.PKGC_PRICE,
                        packagedetail: response.data.order.package.PKG_DETAIL,
                        packageid: response.data.order.package.PKG_ID,
                        packagevat: response.data.order.package.PKG_VAT,
                        packagecostwithvat: response.data.order.package.PKG_COST_INFO.PKGC_NET_PRICE,
                        loading: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    statusmessage: error.response.data.message,
                    statusdescription: error.response.data.description,
                    statusred: true
                });
            })
    }

    redirecttopackagelist() {
        window.location.href = packagelistPage;
    }

    CreateCharge(source, type) {
        let req = JSON.stringify({
            "oid": this.state.order_id,
            "source": source,
            "types": type
        });

        apiClient.post('api/ecom/createchargefromsource', req,
            { headers: { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                if (response.data.status != 300) {
                    notification.open({
                        message: String(response.data.message).replace("_", " "),
                        description: response.data.description,
                        top: 60,
                        className: "rti_form r",
                        duration: 10,
                        onClose: () => {
                        }
                    });
                    this.getOrderDetail();
                } else if (response.data.status == 300) {
                    window.location = response.data.redirect;
                }
            })
            .catch(error => {
                /*
                notification.open({
                    message: 'Error',
                    description: error.message,
                    top: 60,
                    className: "rti_form r",
                    duration: 0,
                    onClose: () => { location.reload(); }
                });*/
                this.setState({
                    statusmessage: error.response.data.message,
                    statusdescription: error.response.data.description
                });
            });
    }

    getClientInfo() {
        urlClientInfo.get(''
            , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                this.setState({
                    clientInfo: {
                        ipString: response.data.ipString,
                        userAgent: response.data.userAgent
                    }
                });
            })
            .catch(error => {
                this.setState({ clientInfo: "" });
            });
    }

    ProcessCreditcardPayment(chargetoken) {

        let req = JSON.stringify({
            "oid": this.state.order_id,
            "token": chargetoken,
            "client": this.state.clientInfo
        });

        apiClient.post('api/ecom/processcreditcardpayment', req,
            { headers: { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                console.table(response.data.order);

                if (response.data.status != 300) {
                    if (response.data.status == 802 || response.data.status == 803 || response.data.status == 804 || response.data.status == 805) {
                        this.setState({
                            statusmessage: response.data.message,
                            statusdescription: response.data.description
                        });
                        this.setLoading(false);
                    } else {
                        notification.open({
                            message: String(response.data.message).replace("_", " "),
                            description: response.data.description,
                            top: 60,
                            className: "rti_form r",
                            duration: 10,
                            onClose: () => {
                            }
                        });
                        this.getOrderDetail();
                    }
                } else if (response.data.status == 300) {
                    this.props.history.push("/OrderSummary?rid=" + response.data.rid);
                }
            })
            .catch(error => {
                this.setState({
                    statusmessage: error.message + ". " + "If your card have been charged please contact support with order invoice id : " + this.state.order_invoice_id,
                    statusdescription: error.description,
                    statusred: true
                });
            });
    }

    componentDidMount() {
        if (!CheckUserLogedin()) {
            this.props.history.push("/login" + this.props.location.search);
        } else {
            if (queryString.parse(this.props.location.search).oid === undefined) {
                this.redirecttopackagelist();
            } else {
                this.getClientInfo();
                this.getOrderDetail();
            }
        }
    }
    /*
    setAllowToPayStatus(status) {
        this.setState({ isallowtopay: status });
    }*/

    setLoading(loading) {
        this.setState({ loading: loading });
    }

    setMessage(message, description = null, isred = false) {
        this.setState({
            statusmessage: message,
            statusdescription: description,
            statusred: isred
        });
    }

    setUrl(url) {
        this.props.history.push(url);
    }

    handleErrorMessage(message) {
        this.setState({ errormessage: message });
    }

    render() {
        let { packagename, packagecost, packagedetail, packagevat, packagecostwithvat, loading,
            order_invoice_id, statusmessage, statusdescription, statusred } = this.state;

        return (
            <div className="app flex-row align-items-center orderdetailbg">
                <Col xs={0} lg={8} xl={12}>&nbsp;</Col>
                <Col xs={24} lg={16} xl={12}>
                    <Card className="mx-4">
                        {loading ?
                            <CardBody className="p-4">
                                <Row className="">
                                    <Col className={statusred ? "justify-content-center order-status-error" : "d-flex justify-content-center"}>
                                        <Row className="text-center"><Col>{statusmessage}</Col></Row>
                                        {(statusdescription != "" && statusdescription != null) ?
                                            <Row><Col className="text-center">{statusdescription}</Col></Row>
                                            : null
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <Spin size="large" />
                                    </Col>
                                </Row>
                            </CardBody>
                            :
                            <CardBody className="p-4">
                                <h2>ORDER DETAIL : {order_invoice_id}</h2>
                                <table class="table orderdetail">
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <th>#</th>
                                            <th>Item</th>
                                            <th>Unit</th>
                                            <th>Cost(Bath)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <td>1</td>
                                            <td>{packagename} - {packagedetail}</td>
                                            <td>1 unit</td>
                                            <td style={{ textAlign: "right" }}>{packagecost}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}>Total</td>
                                            <td style={{ textAlign: "right" }}>{packagecost}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}>Tax(7%)</td>
                                            <td style={{ textAlign: "right" }}>{packagevat}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}>Net</td>
                                            <td style={{ textAlign: "right" }}>{packagecostwithvat}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        }

                    </Card>
                </Col>
            </div >
        );
    }
}

export default OrderDetail;
