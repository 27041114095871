import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Modal, Tooltip } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, getUrlVars, validateEmail, CheckTokenExp } from '../../../config';

export default class TablePersonList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'PER_NAME',
                    key: 'PER_NAME',
                    width: 300,
                    render: text => (text ? <div className="table-title" title={text}>{text}</div> : "No name")
                },
                {
                    title: 'Destination',
                    dataIndex: 'DES_VALUE_LIST',
                    width: 300,
                    render: (text, item) => (
                        <div>
                            {
                                item.DES_PRIMARY ? item.DES_PRIMARY : ""
                            }
                            {
                                item.DES_VALUE_LIST.length > 0 ?
                                    <Tooltip placement="bottom"
                                        title={
                                            item.DES_VALUE_LIST.map((value) => {
                                                return (
                                                    <p style={{ marginBottom: '1px' }}>{value}</p>
                                                )
                                            })
                                        }>
                                        <label style={{ color: '#36b4a1' }}>{"+" + item.DES_COUNT + " more"}</label>
                                    </Tooltip>
                                    : null
                            }

                        </div>
                    )
                },
                {
                    title: 'Tag',
                    dataIndex: 'PRG_VALUE_LIST',
                    width: 300,
                    render: (value, item) => (
                        <div className="table-content" title={value.map((text, i) => text)}>
                            {
                                value.map((text, i) =>
                                    <span>
                                        {text} {value.length == i + 1 ? "" : " , "}
                                    </span>
                                )
                            }
                        </div>
                    )
                },
                {
                    title: 'Channel',
                    dataIndex: 'channel',
                    key: 'channel',
                    width: 100,
                    align: 'center',
                    render: (text, item) => {
                        { var phone = false; var email = false; }
                        return (
                            <div>
                                {
                                    item.CHANNEL_PRIMARY == "2" ? email = true : phone = true
                                }
                                {
                                    item.CHANNEL_LIST.map((value, index) => {
                                        if (value == "2") {
                                            if (!email) {
                                                email = true;
                                            }
                                        } else {
                                            if (!phone) {
                                                phone = true
                                            }
                                        }
                                    }
                                    )
                                }
                                {
                                    phone ?
                                        <span className="fa fa-mobile" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                }
                                {
                                    email ?
                                        <span className="fa fa-envelope-o" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                }
                            </div>
                        )
                    }
                },
            ],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            maxpage: 10,
            enddata: 10,
            totalpage: 0,
        };

        this.onChangePage = this.onChangePage.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
        if (this.props.loading !== prevProps.loading) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    "START_DATE": null,
                    "END_DATE": null,
                    "PRG_ID": getUrlVars()["id"],
                    "SEARCH": search ? search.name : "",
                    "DEST": search ? search.dest : "",
                    "MINPAGE": state.minpage,
                    "PAGESIZE": state.maxpage
                });

                apiClient.post('api/Tag/PERSON_LIST', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        this.setState(state => {
                            var json = data.data;
                            return {
                                data: json ? json.person_list : [],
                                totalpage: json ? json.totalpage : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var currentpage = this.state.pagesize == pageSize ? currentPage : 1;
        var maxpage = currentpage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState({
            loading: true,
            currentpage: currentpage,
            pagesize: pageSize,
            minpage: minpage,
            maxpage: maxpage
        });
        this.getData();
    }

    render() {
        const { columns, data, totalpage, loading } = this.state;
        const { search } = this.props;
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.setStateByProps("Insertlist", selectedRows)
            },
            getCheckboxProps: record => ({
                key: record.key,
            }),
        };
        return (
            <div>
                <Table
                    loading={loading}
                    rowKey={key => key.ID}
                    pagination={{
                        defaultPageSize: "10",
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }}
                    rowSelection={rowSelection}
                    dataSource={data}
                    columns={columns}
                    className="table-ants"
                />

            </div>

        );
    }
}


