import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal} from 'antd';


export default class ModalSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
           
        };

    }

    render() {
        const { loading, successModalVisible, successModal, messageSuccess} = this.props;
        return (
            <div>
                <style>{`
               
                 `}</style>

                <Modal
                    zIndex={1021}
                    wrapClassName="popup-success"
                    closeIcon={false}
                    visible={successModalVisible}
                    onOk={() => successModal(false)}
                    onCancel={() => successModal(false)}
                    footer={null}
                    width={360}
            >
                <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                            <img src={require("../../../img/vendors/icon/popup-Confirm.png")} width="65px" />
                    </Col>
                </Row>

                <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <h4>SUCCESS.</h4>
                            <label>{messageSuccess}</label>
                    </Col>
                </Row>


                </Modal>
                </div>
        );
    }
}


