/* eslint-disable */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Drawer, Upload, Modal, Collapse, Spin, Alert } from 'antd';
import { MentionsInput, Mention } from 'react-mentions';
import MentionStyle_preview from '../../../components/Ants/Mentions/Style/MentionStyle_preview';
import { token, getLinkbacklist } from '../../../config';
import Card2_Extra from '../Card/Card2_Extra';
import Card2 from '../Card/Card2';
import TableAnalyzeRecipients from '../Table/TableAnalyzeRecipients';
import TableCredit from '../Table/TableCredit';
import TableAnalyze from '../Table/TableAnalyze';
import ModalSuccess from '../Modal/ModalSuccess';
import ModalWarning from '../Modal/ModalWarning';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const { Panel } = Collapse;

export default class ButtonGroupBroadcast extends Component {

    constructor(props) {
        super(props);

        this.state = {
            variable: ["firstName", "lastName", "address",
                "city", "country", "gender", "birthDate"],
            content: "Test message",
            schedule_commu_date: "01/01/2020 16:00",
            sender: "Verify",
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            isError:false,
            creditcost: 0,
            Linkbacklist: getLinkbacklist()
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalResult = this.modalResult.bind(this);

    }

    componentDidMount() {
        // setTimeout(() => {
        //     let modalcontent = document.querySelectorAll('.ant-modal-content-hide');
        //     for (var i = 0; i < modalcontent.length; i++) {
        //         modalcontent[i].className = "ant-modal-content";
        //     }
        //     }, 30);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });

        //let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        //for (var i = 0; i < modalcontent.length; i++) {
        //    modalcontent[i].className = "ant-modal-content";
        //}
    }

    successModal(value) {
        if (this.state.action == "draft") {
            this.props.onDraft();
            this.setState({ action: "" });
        }

        if (this.state.action == "discard") {
            value = false;
            this.props.onDiscard;
            this.setState({ action: "" });
            window.location = '/communication';
        }

        if (this.state.action == "analyze") {
            value = false;
            this.props.analyze_showDrawer();
            this.setState({ action: "" });
        }

        if (this.state.action == "send") {
            if (this.props.total_recipients[0].recipients > 0) {
                this.props.analyze_onSend();
                this.setState({ action: "" });
            } else {
                value = false;
                Modal.error({
                    className: 'popup-error',
                    title: 'error.',
                    zIndex: 1202,
                    onOk: () => Modal.destroyAll(),
                    content: (
                        <div>
                            <p>No recipients. Please check.</p>
                        </div>
                    ),
                });
            }
        }

        if (this.state.action == "sendnow") {
            this.props.analyze_onSend();
            this.setState({ action: "" });
        }

      

        //let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        //for (var i = 0; i < modalcontent.length; i++) {
        //    modalcontent[i].className = "ant-modal-content";
        //}
    }

    modalResult(result,message) {
        if(result){
            this.setState({ successModalVisible: true });
            let secondsToGo = 2;
            this.setState(state => {
                if (state.successModalVisible) {
                    setTimeout(() => {
                        this.setState({ successModalVisible: false });
                        window.location = '/communication';
                    }, secondsToGo * 1000);
                }
            });
        }
    else{
        Modal.error({
            className: 'popup-error',
            title: 'Error',
            onOk: () => Modal.destroyAll(),
            content: message,
            icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
            zIndex: 1300
        });
    }
    }


    render() {
        const { analyze_showDrawer, analyze_onClose, analyze_onSend, analyze_visible, onClickPreview,
            preview, onDiscard, sender, content, schedule_commu_date, schedule_commu_time, mode, AnalyzeFunc,
            total_credit, sum_credit, SUM_RECIPTENTS, total_recipients, analyzeTable, disabled,disabledPreview, btnsend_loading, errorMessage } = this.props;
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, creditcost,isError, Linkbacklist } = this.state;

        return (
            <div style={{ marginTop: 16, whiteSpace: window.innerWidth > 600 ? "nowrap" : "inherit" }}>

                <Drawer
                    placement="right"
                    zIndex="1020"
                    closable={false}
                    onClose={analyze_onClose}
                    visible={analyze_visible}
                    width={380}
                >
                    <div>
                        <Spin spinning={btnsend_loading}>
                            {
                                errorMessage ?
                                    <Alert message={errorMessage} type="warning" banner style={{ marginBottom: 8 }} />
                                    : null
                            }

                            <Card2_Extra img={require('../../../img/vendors/icon/Total-Recipients.png')} title={SUM_RECIPTENTS} detail={'Total Recipients'} data={total_recipients} Table={TableAnalyzeRecipients} />

                            <Card2_Extra img={require('../../../img/vendors/icon/Total-Credit.png')} title={sum_credit} detail={sum_credit > 1 ? 'Total Credits' : 'Total Credit'} data={total_credit} Table={TableCredit} />
                            {Linkbacklist == "True" ?
                            <label>Using unsubscription service will cost 1 additional cost per receiver</label> : null
                            }
                            <TableAnalyze data={analyzeTable} />
                        </Spin>
                        <br />
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            zIndex: 1
                        }}
                    >
                        <Button icon="close-circle"
                            style={{
                                marginRight: 8,
                            }}
                            onClick={analyze_onClose}
                        >
                            Cancel
                        </Button>

                        <Button icon="check-circle" type="primary"
                            loading={btnsend_loading}
                            disabled={disabled || errorMessage || SUM_RECIPTENTS == 0}
                            onClick={() => { this.warningModal(true, "Do you want to send messages?", "Send messages successfully."), this.setState({ action: "send" }) }} >
                            Confirm
                                 </Button>

                    </div>

                </Drawer>




                <Modal visible={preview} style={{ top: 60 }}
                    footer={false} onCancel={() => onClickPreview(false)}
                >
                    <div className="phone-preview-container" style={{ marginLeft: '200px' }}>
                        <div className="phone-content">
                            <div className="phone-icon">
                                <img src={require("../../../img/logo-symbol.png")} alt="icon" style={{ width: 20 }} />
                            </div>
                            <p className="sender">
                                {sender}
                            </p>
                            <p className="msg-time">
                                Text message
                                <br />
                                {schedule_commu_date ? moment(moment(moment(schedule_commu_date).format("DD/MM/YYYY") + " " + moment(schedule_commu_time).format("HH:mm"), "DD/MM/YYYY HH:mm")).format('llll')
                                    : moment().format('llll')}
                            </p>
                            <p className="msg-container">
                                <span className="msg-content">
                                    <MentionsInput value={content.trim()} style={MentionStyle_preview}>
                                        <Mention
                                            markup="{__id__}"
                                            data={this.state.variable}
                                            style={{ backgroundColor: '#cee4e5' }}
                                        />
                                    </MentionsInput>
                                </span>
                            </p>
                        </div>
                    </div>
                </Modal>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    message={message}
                    messageSuccess={messageSuccess}
                    modalcountDown={()=>{}}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <Button key="submit" style={{ marginRight: 15, float: 'left' }}
                            onClick={() => {
                                this.warningModal(true, "Do you want to discard change?", "Discarded change successfully."),
                                    this.setState({ action: "discard" })
                            }}>
                            <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                            Discard
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                        {
                            mode == "qs" ? "" :
                                <Button key="submit1" style={{ marginRight: 15 }} loading={this.state.btndraft_loading} disabled={disabled}
                                    onClick={() => { this.warningModal(true, "Do you want to draft this campaign?", "Draft campaign successfully."), this.setState({ action: "draft" }) }}>
                                    <span className="fa fa-file-o" ></span> &nbsp;&nbsp;
                           Draft
                        </Button>
                        }
                        <Button key="submit2" type="primary" style={{ marginRight: 15 }} disabled={disabled}
                            onClick={() => { this.warningModal(true, "If the upload file exceed more than 100,000 records, this might take several minutes. Do you want to proceed?", ""), this.setState({ action: "analyze" }) }} >
                            <span className="fa fa-tasks"></span> &nbsp;&nbsp;
                             Analyze
                        </Button>

                        <Button key="submit3" disabled={disabledPreview} onClick={() => onClickPreview(true)}>
                            <span className="fa fa-eye"></span> &nbsp;&nbsp;
                       Preview
                        </Button>



                    </Col>
                </Row>
            </div>

        );
    }
}

