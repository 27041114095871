import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Progress,
} from 'reactstrap';
import { Avatar, Popover, Button } from 'antd';

import Aside from '../Aside/Aside';
import { apiClient } from '../../api';
import { token } from '../../config';

const propTypes = {
    accnt: PropTypes.bool,
    username: PropTypes.string,
    company: PropTypes.string,
    admin: PropTypes.bool,
    createuser: PropTypes.bool,
    createaccount: PropTypes.bool,
};
const defaultProps = {
    accnt: false,
    username: "",
    company: "",
    type: 0,
    admin: false,
    createuser: false,
    createaccount: false,
};

class HeaderDropdown extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.setVisible = this.setVisible.bind(this);
        this.state = {
            dropdownOpen: false,
            balance: 0,
            type: 0,
            visible: false,
            visiblePopover: false
        };

        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.changePopover = this.changePopover.bind(this);
    }

    componentDidMount() {
        console.log('this.props.ecom', this.props.ecom)
        //token ? this.getcredit() : null;
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            visible: !this.state.visible
        });
    }

    setVisible(value) {
        this.setState({
            dropdownOpen: value,
            visible: value
        });
    }

    changePopover(){
        this.setState({ visiblePopover: false });
    }

    handleVisibleChange(visible){
        this.setState({ visiblePopover: visible });
    };

    dropAccnt(username, company, admin, createuser, createaccount, userGenToken) {

        let profile = {
            name: username,
            comp: company,
            //type: this.state.type,
            admin: admin,
            //credits: this.state.balance,
            createuser: createuser,
            createaccount: createaccount,
            userGenToken: userGenToken
        };

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}  >
                <Popover
                    visible={this.state.visiblePopover}
                    placement="bottomRight"
                    content={<Aside {...this.props} profile={profile} visiblePopover={this.changePopover} ecom={this.props.ecom}/>}
                    trigger="click"
                    overlayClassName="profile"
                    onVisibleChange={this.handleVisibleChange}
                >
                    <DropdownToggle nav>
                        <Avatar className="avatar-ants" size="large" onClick={this.props.checkCreditBalance}>
                            <b>{profile.name ? profile.name.substring(0, 2) : ""}</b>
                        </Avatar>
                    </DropdownToggle>
                </Popover>
            </Dropdown>
        );
    }


    render() {
        const { accnt, username, company, admin, createuser, createaccount, userGenToken } = this.props;
        return (
            accnt ? this.dropAccnt(username, company, admin, createuser, createaccount, userGenToken) : null
        );
    }
}

HeaderDropdown.propTypes = propTypes;
HeaderDropdown.defaultProps = defaultProps;

export default HeaderDropdown;
