import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu } from 'antd';
import { token, CheckTokenExp, colorBlue } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableSender extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Sender Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'create_date',
                    key: 'create_date',
                    width: 200,
                    render: text => (text ? moment(text).format(dateFormat) : "")
                },
                {
                    title: 'Last Update',
                    dataIndex: 'update_date',
                    key: 'update_date',
                    width: 200,
                    render: text => (text ? moment(text).format(dateFormat) : "")
                },
                {
                    title: 'Allow List',
                    dataIndex: 'allowlist_status',
                    key: 'allowlist_status',
                    width: 130,
                    render: text => {
                        if (text === "4") {
                            return <span style={{ color: '#097FF5', fontWeight: 'bold' }}>ACTIVE</span>;
                        } else if (text === "2") {
                            return <span style={{ color: '#F2C94C', fontWeight: 'bold'  }}>REVIEWING</span>;
                        } else if (text === "5") {
                            return <span style={{ color: '#EB5757', fontWeight: 'bold'  }}>REJECTED</span>;
                        } else {
                            return <span style={{ color: '#D0D2D3', fontWeight: 'bold'  }}>Inactive</span>;
                        }
                    }
                },
                {
                    title: 'White List',
                    dataIndex: 'whitelist_status',
                    key: 'whitelist_status',
                    width: 130,
                    render: (text, record) => {
                        if (text === "4") {
                            return <span style={{ color: '#097FF5', fontWeight: 'bold'  }}>ACTIVE</span>;
                        } else if (text === "2") {
                            return <span style={{ color: '#F2C94C', fontWeight: 'bold'  }}>REVIEWING</span>;
                        } else if (text === "5") {
                            return <span style={{ color: '#EB5757', fontWeight: 'bold'  }}>REJECTED</span>;
                        } else if (text === "1") {
                            return <span style={{ color: '#D0D2D3', fontWeight: 'bold'  }}>Inactive</span>;
                        } else if (record.allowlist_status === "4" && record.whitelist_status === "0") {
                            return (
                                <Button type="primary" onClick={() => this.handleButtonClick(record.id)}>
                                    Request Whitelist
                                </Button>
                            )
                        } else {
                            return (
                                <Button type="primary" disabled>
                                    Request Whitelist
                                </Button>
                            )
                        }
                   }
                },
                // {
                //     title: 'Status',
                //     dataIndex: 'status',
                //     key: 'status',
                //     width: 130,
                //     render: text => text == true ? "Inactive" : "Active"
                // },
            ],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            enddata: 10,
            totalpage: 0,
            clickId: null,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    handleButtonClick = (key) => {
        apiClient
        .get(`api/sender/request_whitelist/${key}`, {
            headers: {
            "Content-type": "application/json; charset=UTF-8",
            },
        })
        .then((response) => {
            console.log("response: ", response.data);
            if (response.data.statusCode === "200") {
            // openNotification("success", response.data.statusDesciption);
            // history.push("/communication/sender/list");
            } else if (response.data.statusCode === "901") {
            // openNotification("warning", response.data.statusDesciption);
            } else {
            // openNotification("error", response.data.statusDesciption);
            }
            // setLoading(false);
        })
        .catch((error) => {
            // openNotification("error", error);
            // setLoading(false);
        });
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {
                const { search } = this.props;

                // let req = JSON.stringify({
                //     "search": search.name,
                //     "startdata": state.minpage,
                //     "enddata": state.pagesize
                // });

                let req = JSON.stringify({
                    "account": 1150,
                    "sender_name": search.name,
                    "limit": state.pagesize,
                    "offset": state.minpage
                });

                apiClient.post('api/sender/Getsenderlist', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                data: json.data,
                                totalpage: json ? json.total : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {

        var maxpage = currentPage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize ? currentPage : 1,
                pagesize: pageSize,
                minpage: currentPage,
            };
        });
        this.getData();

    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem } = this.props;
        return (
            <Table
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                    total: totalpage,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                    onShowSizeChange: this.onChangePage,
                    onChange: this.onChangePage,
                    current: this.state.currentpage
                }
                }
                columns={columns}
                dataSource={data}
                className="table-ants"
                tableLayout="fixed"
            />
        );
    }
}


