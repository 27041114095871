/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card,
    Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin, Radio
} from 'antd';
import ButtonGroupStatusAcc from '../../../components/Ants/Button/ButtonGroupStatusAcc';
import ButtonGroupSubAcc from '../../../components/Ants/Button/ButtonGroupSubAcc';

import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, validateEmail, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import ModalError from "../../../components/Ants/Modal/ModalError";

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            showPassword: false,
            screenwidth: 0,
            creactsubeaccount: false,
            userGenToken: false,
            user2Fa: false,
            admin: false,
            createuser: false,
            owner: false,
            email: "",
            password: "",
            repassword: "",
            emailError: false,
            errorModalVisible : false,
            messageError : ""
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onChangeSubAcc = this.onChangeSubAcc.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.passwordFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,}$/
        this.errorModal = this.errorModal.bind(this);
    }

    componentDidMount() {
        this.getProfile();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "accountcreate") {
            this.setState({ action: "" });
            this.UpdateProfile();
        } else {
            if (this.state.action == "discard") {
                this.setState({ action: "" });
                value = false;
                this.props.history.push('/dashboard')
            }

            this.setState({ successModalVisible: value });
        }


        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    getProfile() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            this.setState(state => {
                let req = JSON.stringify({


                });


                apiClient.post('api/profile/GetProfile', req

                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data[0];
                        this.props.setStateByProps("paytype", json.pay_type)
                        if (json) {
                            this.setState(state => {
                                return {
                                    accname: json.name,
                                    email: json.email,
                                    statusacc: json.status,
                                    username: json.username,
                                    paytype: json.pay_type,
                                    emailError: validateEmail(json.email),
                                    creactsubeaccount: json.creactsubeaccount,
                                    userGenToken: json.userGenToken,
                                    user2Fa: json.user2Fa,
                                };
                            });
                        }


                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }

    }

    UpdateProfile() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            let req = JSON.stringify({
                accName: this.state.accname,
                email: this.state.email,
                password: this.state.password,
            });

            apiClient.post('api/profile/UpdateProfile', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    if (data.data) {
                        this.setState({ successModalVisible: value });
                    } else {
                        this.setState({
                            errorModalVisible: true,
                            messageError: "Save Account unsuccessful!",
                        });
                    }

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "addusername" || parameters == "username" || value === "") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
    }

    onChangeSubAcc(e) {
        this.setState({
            subacc: e.target.value,
        });
    };

    onChangeStatus(e) {
        this.setState({
            statusactive: e.target.value,
        });
    };

    errorModal(value) {
        this.setState({ errorModalVisible: value });
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem,
            action, dateRange, screenwidth, screenwidth2, accname, email, statusacc, username, paytype, MadalAddUservisible,
            id, addusername, addpassword, repassword, password, addstatusacc, tocreate, creactsubeaccount, userGenToken, user2Fa, createuser,
            owner, addrepassword, uemail, emailError, uemailError, errorModalVisible, messageError } = this.state;

        const { admin } = this.props;

        const pay_type = paytype == 1 ? "Prepaid" : "Postpaid";

        var styleinputlable = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200,
            fontWeight: 700
        };
        var styleinput = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var stylelabel = {
            width: (screenwidth / 3),
            marginRight: 8,
            minWidth: 200,
            fontWeight: 600
        };
        var styleradio = {
            width: (screenwidth / 3),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput2 = {
            width: ((screenwidth / 3) - 216),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_name = {
            width: (screenwidth2 / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth2 / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };

        return (
            <div className="content" id="main">
                <Spin spinning={loading}>
                    <Row gutter={8} type="flex" ustify="start" >
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8} >
                            <label className=" control-label require" style={stylelabel}> Account name </label>
                            <label className=" control-label ant-fontsize-acc" style={styleinputlable}>{accname}</label>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}  >
                            <label className=" control-label require" style={stylelabel}> Username </label>
                            <label className=" control-label ant-fontsize-acc" style={styleinputlable}>{username}</label>
                        </Col>

                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}  >
                            <label className=" control-label require" style={stylelabel}> Payment type </label>
                            <label className=" control-label ant-fontsize-acc" style={styleinputlable}>{pay_type}</label>
                        </Col>

                    </Row>
                    <Row gutter={8} type="flex" ustify="start" >
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8} >
                            <Row>
                                <label className=" control-label require" > Email </label>
                                <label className="red" style={{ fontWeight: 600 }}>&nbsp;*&nbsp;{!emailError && email != "" ? "Invalid email format" : ""}</label>
                            </Row>
                            <Row>
                                <Input name="email" placeholder="Email" value={email} style={styleinput}
                                    disabled={!admin}
                                    onChange={(e) => {
                                        admin ? this.setStateByProps("email", e.currentTarget.value) : null
                                    }} />
                            </Row>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}  >
                            <Row>
                                <label className=" control-label require" style={stylelabel}> Password</label>
                            </Row>
                            <Row>
                                <Input.Password id="AccPassword" name="AccPassword" placeholder="Password" value={password} style={styleinput}
                                    disabled={!admin}
                                    onChange={(e) => {
                                        admin ? this.setStateByProps("password", e.currentTarget.value) : null
                                    }} />
                            </Row>
                        </Col>

                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}  >
                            <Row>
                                <label className=" control-label require" style={{ fontWeight: 700 }}> Re-type password</label>
                                {/*<label className="red" style={{ fontWeight: 700, marginLeft: 8, marginBottom: -10 }}>{*/}
                                {/*    password ?*/}
                                {/*        password.length > 5 ?*/}
                                {/*            password === repassword ? ""*/}
                                {/*                : "Passwords do not match"*/}
                                {/*            : "The password must be at least 6 characters long."*/}
                                {/*        : ""*/}
                                {/*}</label>*/}
                            </Row>
                            <Row>
                                <Input.Password disabled={!admin} placeholder="Re-type Password" style={styleinput} value={repassword} onChange={(e) => { admin ? this.setStateByProps("repassword", e.target.value) : null }} />
                            </Row>
                        </Col>

                    </Row>

                    <Row gutter={8} type="flex" ustify="start">
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={4} className="row-nowrap">
                            <Row>
                                <label className=" control-label require" style={stylelabel}> Create Subaccount</label>
                            </Row>
                            <Row>
                                <ButtonGroupSubAcc radio={creactsubeaccount ? 1 : 0} setStateByProps={this.setStateByProps} style={styleradio} disabled={true} />
                            </Row>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={4} className="row-nowrap">
                            <Row>
                                <label className=" control-label require" style={stylelabel}> Status</label>
                            </Row>
                            <Row>
                                <ButtonGroupStatusAcc radio={statusacc ? 1 : 0} setStateByProps={this.setStateByProps} style={styleradio} disabled={true} />
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16} className="row-nowrap">

                        </Col>
                    </Row>
                    <Row gutter={8} type="flex" ustify="start">
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={4} className="row-nowrap">
                            <Row>
                                <label className=" control-label require" style={stylelabel}> Generate Token</label>
                            </Row>
                            <Row>
                                <ButtonGroupSubAcc radio={userGenToken ? 1 : 0} setStateByProps={this.setStateByProps} style={styleradio} disabled={true} />
                            </Row>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={4} className="row-nowrap">
                            <Row>
                                <label className=" control-label require" style={stylelabel}> Two-Factor</label>
                            </Row>
                            <Row>
                                <ButtonGroupStatusAcc radio={user2Fa ? 1 : 0} setStateByProps={this.setStateByProps} style={styleradio} disabled={true} />
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16} className="row-nowrap">

                        </Col>
                    </Row>
                    <Row gutter={8} type="flex" ustify="end">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="row-nowrap">
                            <span style={{ float: 'right', marginTop: 80 }}>
                                {
                                    admin ?
                                        <>
                                            <div>
                                                {this.state.password && !this.passwordFilter.test(this.state.password) ?
                                                    <label className="red" style={{ fontWeight: 700, marginLeft: 8, marginBottom: -10 }}>
                                                        - The password must be at least 6 characters long <br />
                                                        - Uppercase and Lowercase is required <br />
                                                        - Number is required <br />
                                                        - Special character is required
                                                    </label>
                                                    : password && password !== repassword &&
                                                    <label className="red" style={{ fontWeight: 700, marginLeft: 8, marginBottom: -10 }}>{
                                                        `- Password do not match`
                                                    }</label>
                                                }
                                            </div>
                                            <Button type="primary" style={{ float: 'right' }}
                                                disabled={(password === repassword) && accname && email && username && emailError && this.passwordFilter.test(this.state.password) ? false : true}
                                                onClick={() => { this.warningModal(true, "Do you want to save this account?", "Save account successfully."), this.setStateByProps('action', 'accountcreate') }}>
                                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                                Save
                                            </Button>
                                        </>
                                        : null
                                }
                            </span>
                        </Col>
                    </Row>
                </Spin>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalError
                    loading={loading}
                    errorModalVisible={errorModalVisible}
                    errorModal={this.errorModal}
                    messageError={messageError}
                />
            </div>

        );
    }
}


