/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Pagination } from 'antd';

import { token, CheckTokenExp,ReplaceUrlTaga } from '../../../config';

import CardTag from '../../../components/Ants/Card/CardTag';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import TableTag from '../../../components/Ants/Table/TableTag';
import ModalTag from '../../../components/Ants/Modal/ModalTag';
import { apiClient } from '../../../api';
import{root} from '../../../config';

export default class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            member: "",
            PRG_ID: 0,
            data: [],
            Create_Tag: false,
            Tagdeletelist: [],
            Disabled: true,
            loading: false,
            errors: [],
            SEARCH: "",
            minpage: 1,
            pagesize: 10,
            totalpage: 0,
            current: 1,
            currentpage: null,
            action: "",
            search: {
                value: "",
            },
            visible: false,
            screenwidth: 0,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.RequestInfoTags = this.RequestInfoTags.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.handleOnSaveTag = this.handleOnSaveTag.bind(this);
        this.handleOnDeleteAllTag = this.handleOnDeleteAllTag.bind(this);
        this.handleOnSelectRowDelete = this.handleOnSelectRowDelete.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.showModal = this.showModal.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.updateWindowDimensions();
        //this.RequestInfoTags(1, 10, "");
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        ReplaceUrlTaga();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 116 : e.offsetWidth - 116 })
                }
            }
        }, 500);
    }

    RequestInfoTags(minpage, pagesize, value, currentpage) {
        if (!CheckTokenExp()) {
            this.state.data.length > 0 ? "" : this.setState({ loading: true });
            let req = JSON.stringify({
                "SEARCH_TAG": value,
                "MINPAGE": minpage,
                "PAGESIZE": pagesize
            });

            apiClient.post('api/Tag/TAG_LIST', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ totalpage: json ? json.totalpage : 0, current: currentpage == null ? this.state.current : currentpage });
                    this.setState({
                        data: json.tag_list,
                        Tagdeletelist: []
                    });


                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ totalpage:  0, current:  this.state.current});
                    this.setState({
                        data: [],
                        Tagdeletelist: []
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    warningModal(value, message, messageSuccess) {
        this.state.Tagdeletelist ?
            this.setState({ warningModalVisible: value, message, messageSuccess }) : ""
    }

    successModal(value) {
        if (this.state.action == "add") {
            this.handleOnSaveTag();
            this.setState({ action: "" });
        }

        if (this.state.action == "delete") {
            this.handleOnDeleteAllTag();
            this.setState({ action: "" });
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);
    }

    onChangePage(currentPage, pageSize) {
        var maxpage = currentPage * pageSize;
        var minpage = maxpage - pageSize + 1;
        this.setState({ pagesize: pageSize, minpage: minpage, current: currentPage },
            function () {
                this.RequestInfoTags(minpage, pageSize, this.state.SEARCH, currentPage);
            });
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    handleOnSaveTag() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })
            if (this.state.errors.PRG_NAME != "" && this.state.errors.PRG_NAME != null) {
                this.setState({ loading: false })
                return false;
            }

            let req = JSON.stringify({
                PRG_NAME: this.state.PRG_NAME
            });

            apiClient.post('api/Tag/INSERT_TAG', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.RequestInfoTags(1, 10, "");
                    this.onSearch();
                    this.setState({ PRG_NAME: "" });
                    this.setState({ loading: false, visible: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnDeleteAllTag() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })
            let req = JSON.stringify({
                TAGS: this.state.Tagdeletelist,
            });

            apiClient.post('api/Tag/DELETE_ALL_TAG', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.RequestInfoTags(1, 10, "");
                    this.onSearch();
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnSelectRowDelete(id, action) {
        const rows = [...this.state.Tagdeletelist]
        if (action == true) {
            rows.push({ 'PRG_ID': id });
        } else {
            var index = rows.findIndex(x => x.PRG_ID === id);
            if (index > -1) {
                rows.splice(index, 1);
            }
        }
        this.setState({
            Tagdeletelist: rows,
            Disabled: rows.length == 0 ? true : false
        })

    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    value: state.SEARCH,
                }
            }
        });
        this.updateWindowDimensions();
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };


    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, totalpage, Tagdeletelist, PRG_NAME, visible, screenwidth } = this.state;
        const { title, member, search } = this.state;
        const routes = [
            {
                path: '/people/person/',
                breadcrumbName: 'People',
            },
            {
                path: 'Person',
                breadcrumbName: 'Tag',
            },

        ];
        var styleinput_tagName = {
            width: screenwidth,
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        return (
            <div>
                <PageHeader breadcrumb={{ routes }} >
                </PageHeader>

                <div className="content" >
                    <Card>
                        <Row gutter={8} type="flex" justify="space-between">
                            <Col xs={12}>
                                <Button key="submit" style={{ marginRight: 8 }}
                                    disabled={Tagdeletelist.length > 0 ? false : true}
                                    onClick={() => { this.warningModal(true, "Do you want to delete this tag?", "Deleted tag successfully."), this.setStateByProps("action", "delete") }}>
                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                   Delete
                                </Button>
                            </Col>

                            <Col xs={12}>
                                <Link to={'/people/tag/detail'} style={{ fontSize: 16, color: '#7b7b7b', float: "right" }} >
                                    <Button type="primary" style={{ width: "100%" }}>
                                        <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                                        New
                                        </Button>
                                </Link>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="end" className="row-nowrap" id="main">
                            <Col xs={24} >
                                <Input placeholder="Tag" style={styleinput_tagName} onChange={(e) => this.setStateByProps("SEARCH", e.target.value)} />

                                <Button icon="search" onClick={() => { this.RequestInfoTags(1, 10, this.state.SEARCH), this.onSearch() }}>
                                    Search
                                        </Button>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TableTag
                                    search={search}
                                    setStateByProps={this.setStateByProps}
                                    Tagdeletelist={Tagdeletelist}
                                    warningModal={this.warningModal}
                                    updateWindowDimensions={this.updateWindowDimensions}
                                />
                            </Col>
                        </Row>

                    </Card >
                </div >

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalTag visible={visible}
                    warningModal={this.warningModal}
                    handleOk={this.handleOk}
                    setStateByProps={this.setStateByProps}
                    PRG_NAME={this.state.PRG_NAME}
                />

            </div >
        );
    }
}


