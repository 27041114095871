/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col } from 'antd';


export default class TagDestination extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount() {

    }

    render() {
        const { icon, title, deskey, length } = this.props;

        return (
            <div>
                {
                    icon == "mobile" ?
                        <Tag
                            style={{ minWidth: 160, marginBottom: 16, backgroundColor: '#fff' }}
                        >
                            &nbsp;<span className="fa fa-mobile size14"></span> &nbsp;&nbsp;
                            {title}
                            <span className="fa fa-times-circle-o size14" style={{ cursor: 'pointer', float: 'right', marginTop: 3 }}
                                onClick={() => {
                                    this.props.warningModal(true, "Do you want to delete?", "Delete Success."),
                                        this.props.setStateByProps("action", "deletedes"),
                                        this.props.setStateByProps("deskey", deskey)
                                }}></span>

                            &nbsp;&nbsp;
                        </Tag>
                        : icon == "email" ?
                            <Tag
                                style={{ minWidth: 160, marginBottom: 16, backgroundColor: '#fff' }}
                            >
                                &nbsp;<span className="fa fa-envelope-o size14"></span> &nbsp;&nbsp;
                            {title}
                                <span className="fa fa-times-circle-o size14" style={{ cursor: 'pointer', float: 'right', marginTop: 3 }}
                                    onClick={() => {
                                        this.props.warningModal(true, "Do you want to delete?", "Delete Success."),
                                            this.props.setStateByProps("action", "deletedes"),
                                            this.props.setStateByProps("deskey", deskey)
                                    }}></span>
                                &nbsp;&nbsp;
                            </Tag>
                            : ""
                }
            </div>

        );
    }
}


