import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col } from 'antd';


export default class TagSender extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { title, RemoveSender } = this.props;

        return (
            <Tag style={{ marginBottom: 16, backgroundColor: '#fff' }} >
                {title}
                <span className="fa fa-times-circle-o size14" style={{ cursor: 'pointer', float: 'right', marginTop: 3 }}
                    onClick={() => RemoveSender(title)}></span> &nbsp;&nbsp;
            </Tag>

        );
    }
}


