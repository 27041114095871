/* eslint-disable */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Tooltip, Popover, Menu } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, getAccId, getId, getUsername, CheckTokenExp } from '../../../config';
import NumericLabel from 'react-pretty-numbers';
import ModalServerError from '../Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'YYYY-MM-DD';
const optionnumber = {
    justification: 'L',
    locales: 'en-US',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    shortFormat: false,
    shortFormatPrecision: 1,
    title: true
};

export default class TableBroadcast extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            search: {},
            completecolor: "#1bbc9b",
            inProgresscolor: "#edc510",
            draftcolor: "#95a5a5",
            cancelcolor: "#e44b3b",
            uploading: "#edc510",
            pagesize: 10,
            pageactive: 1,
            startrow: 1,
            endrow: 10,
            currentpage: 1,
            columns: [
                {
                    title: 'Broadcast Information',
                    dataIndex: 'CAMP_ID',
                    key: 'CAMP_ID',
                    width: 260,
                    render: (text, item) => (
                        <div>
                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <span style={{ marginRight: 5, fontSize: 20, fontWeight: 'bold', color: '#7b7b7b' }} title={item.CAMP_NAME}>
                                        {item.CAMP_NAME ? item.CAMP_NAME.length > 25 ? item.CAMP_NAME.substring(0, 25) + "..." : item.CAMP_NAME : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <span style={{ marginRight: 5, fontSize: 20 }}>
                                        created
                                    </span>
                                    {moment(item.CAMP_CREATE_DATE).format('DD/MM/YYYY HH:mm')} &nbsp;&nbsp;&nbsp;
                                    <span style={{ marginRight: 5, fontSize: 20 }}>
                                        by
                                    </span>
                                    {item.CREATE_BY}
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <span style={{ marginRight: 5, fontSize: 20 }}>
                                        scheduled
                                     </span>
                                    
                                     {item.CAMP_SCHEDULE_DATE && moment(item.CAMP_SCHEDULE_DATE).format('YYYY')!='0001'  ? moment(item.CAMP_SCHEDULE_DATE).format('DD/MM/YYYY HH:mm') : "Immediated"}
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: 'Metrics',
                    dataIndex: 'CAMP_ID',
                    key: '1',
                    width: 140,
                    render: (text, item) => (
                        <div>

                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <img src={require('../../../img/vendors/icon/valid_recipients_icon.png')} alt="icon" style={{ marginLeft: 4, width: 16 }} />
                                    &nbsp;&nbsp;
                                    <span style={{ marginRight: 8, fontSize: 20 }}>
                                        <NumericLabel params={optionnumber}>{parseInt(item.validRecipients)}</NumericLabel>
                                    </span>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <img src={require('../../../img/vendors/icon/total_messages_sent_icon.png')} alt="icon" style={{ width: 20 }} />
                                    &nbsp;&nbsp;
                                    <span style={{ marginRight: 8, fontSize: 20 }}>
                                        <NumericLabel params={optionnumber}>{parseInt(item.totalMessagesSent)}</NumericLabel>
                                    </span>
                                </Col>
                            </Row>

                            <Row gutter={16} style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <img src={require('../../../img/vendors/icon/cost_icon.png')} alt="icon" style={{ marginLeft: 4, width: 14 }} />
                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{ marginRight: 8, fontSize: 20 }}>
                                        <NumericLabel params={optionnumber}>{parseInt(item.costCredits)}</NumericLabel>
                                    </span>
                                    {item.costCredits > 1 ? "CREDITS" : "CREDIT"}
                                </Col>
                            </Row>

                        </div>
                    )
                },
                {
                    title: 'Content',
                    dataIndex: 'Content',
                    key: 'Content',
                    width: 200,
                    render: (text, item) => (
                        <div>
                            <Row gutter={16} type="flex" style={{ lineHeight: "1" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <Tooltip placement="top" title={item.Content} overlayClassName="ant-tooltip-inner-content">
                                        <span style={{ marginRight: 8, fontSize: 20 }} title={item.CAMP_CONTENT}>
                                            {item.CAMP_CONTENT ? item.CAMP_CONTENT.length > 100 ? item.CAMP_CONTENT.substring(0, 100) + "..." : item.CAMP_CONTENT : ""}
                                        </span>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 100,
                    render: (tags, item) => (
                        <div>
                            <Tag className={
                                item.DRAFT == true ? this.statusCampaignColor(0) :
                                    tags ? this.statusCampaignColor(tags) : this.statusCampaignColor(1)
                            }>
                                {item.DRAFT == true ? this.statusCampaignByid(0) :
                                    tags ? this.statusCampaignByid(tags) : this.statusCampaignByid(1)}
                            </Tag>
                        </div>
                    )
                },
                {
                    title: 'Analysis',
                    dataIndex: 'deliveryRate',
                    key: 'deliveryRate',
                    width: 110,
                    render: (text, item) => (
                        <div>
                            <div style={{ marginRight: 8, fontSize: 35, color: '#95a5a5', lineHeight: "1.2" }}>
                                {(parseFloat(text).toFixed(2) + '%')}
                            </div>
                            <div style={{ marginRight: 8, fontSize: 20, color: '#95a5a5', marginTop: -16 }}>
                                Delivered
                            </div>
                        </div>
                    )
                },
                {
                    dataIndex: 'CAMP_ID',
                    key: '2',
                    width: 40,
                    align: 'center',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu
                                    mode="vertical"
                                >
                                    <Menu.Item key="0" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        {
                                            item.DRAFT ?
                                                <Link to={'/communication/' + (item.DRAFT == true ? "edit?id=" + key + "&type=" + ((item.CAMP_SEND_TYPE == 2 || item.CAMP_SEND_TYPE == 6)? "broadcast" : "broadcasttemplate") : "detail?id=" + key)} style={{ textDecoration: 'none' }} >
                                                    <span style={{ cursor: 'pointer' }}><span className="fa fa-edit ants-gray size14" ></span>&nbsp;&nbsp;  Edit</span>
                                                </Link> :
                                                <span style={{ cursor: 'not-allowed', color: '#b5b5b5' }}><span className="fa fa-edit ants-gray size14" ></span>&nbsp;&nbsp;  Edit</span>
                                        }
                                    </Menu.Item>
                                    <Menu.Item key="1" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <Link to={{ search: '?duplicate=communication?id=' + key }} style={{ textDecoration: 'none' }}
                                            onClick={() => {
                                                this.props.warningModal(true, "Do you want to duplicate this campaign?", "Duplicated campaign successfully."),
                                                    this.props.setStateByProps("action", "copy"), this.props.setStateByProps("campaignid", key)

                                            }}
                                        >
                                            <span style={{ cursor: 'pointer' }}><span className="fa fa-files-o ants-gray size14" ></span>&nbsp;&nbsp;  Duplicate</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="2" style={{ borderBottom: '1px solid #7b7b7b' }}
                                        onClick={() => {
                                            if (item.DRAFT == true) {
                                                this.props.warningModal(true, "Do you want to delete this campaign?", "Delete campaign successfully.");
                                                this.props.setStateByProps("action", "delete"), this.props.setStateByProps("campaignid", key);
                                            }
                                        }}>
                                        {
                                            item.DRAFT ?
                                                <span style={{ cursor: 'pointer' }}><span className="fa fa-trash-o ants-gray size14" ></span>&nbsp;&nbsp;  Delete</span>
                                                :
                                                <span style={{ cursor: 'not-allowed', color: '#b5b5b5' }}><span className="fa fa-trash-o ants-gray size14"></span>&nbsp;&nbsp;  Delete</span>
                                        }
                                    </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>


                        </div>
                    ),
                },
            ],
            clickId: null,
            camp_list_new: [],
            action: "",
            listStatus: [],
            pageIndex: 1,
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);
        //this.reloadStatus = this.reloadStatus.bind(this);
        //this.loadList = this.loadList.bind(this);

        this.getStatusCampaign = this.getStatusCampaign.bind(this);
        this.statusCampaignByid = this.statusCampaignByid.bind(this);
        this.statusCampaignColor = this.statusCampaignColor.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
        this.getStatusCampaign();
      
        //this.reloadStatus();
        //this.loadList();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            let req = JSON.stringify({
                "CampaignName": search.name,
                "StartDate": search.dateRange.length > 0 ? search.dateRange[0].format(dateFormat) : null,
                "EndDate": search.dateRange.length > 0 ? search.dateRange[1].format(dateFormat) : null,
                "page": this.state.pageactive,
                "UserId": search.users ? search.users.map((item) => item = item == getUsername() ? getId() : item) : "",
                "StatusId": search.status ? search.status : null,
                "pageIndex": this.state.pageIndex,
                "account": getAccId(),
            });
            apiClient.post('api/Campaign/List', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState(state => {
                        return {
                            data: json !== null ? json.data : [],
                            totalpage: json !== null ? json.totalpage : 0,
                            currentpage: state.startrow == 1 ? 1 : state.currentpage,
                            startrow: 1,
                        };
                    }, () => { this.props.updateWindowDimensions() });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    onChangePage(currentPage, pageSize) {

        this.setState({ loading: true });
        this.setState({ currentpage: currentPage });
        var endrow = currentPage * pageSize;
        var startrow = ((currentPage * pageSize) + 1) - pageSize;
        this.setState({
            pageIndex: currentPage,
            startrow: startrow,
            endrow: endrow,
            currentpage: this.state.pagesize == pageSize ? currentPage : 1, pagesize: pageSize
        }, () => this.getData(), () => this.loadList());
    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                window.location.href = 'communication/' + (item.DRAFT==true ? "edit?id=" + item.CAMP_ID +  (item.CAMP_SEND_TYPE == 4 ?  "&type=broadcasttemplate" :  "&type=broadcast" ) : "communication?id=" + item.CAMP_ID)
            }
            return {
                clickId: item ? item.ID : item,
            }
        })
    }

    getStatusCampaign() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            
            apiClient.get('api/Campaign/GetStatusList'
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ listStatus: data.data });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ listStatus: [] });
                    }
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ listStatus: [] });
                });
        } else {
            window.location.href = "logout";
        }

    }

    statusCampaignByid(value) {
        const { listStatus } = this.state;

        let res = listStatus.filter(i => i.ID == value);

        return res.length > 0 ? res[0].TEXT : "IN PROGRESS";
    }

    statusCampaignColor(value) {

        const list = [
            { ID: 0, COLCOR: "tag-draft" },
            { ID: 1, COLCOR: "tag-inProgres" },
            { ID: 4, COLCOR: "tag-schedu" },
            { ID: 3, COLCOR: "tag-complete" },
            { ID: 7, COLCOR: "tag-pending" },
            { ID: 8, COLCOR: "tag-cancel" },
            { ID: 9, COLCOR: "tag-error" },
            { ID: 10, COLCOR: "tag-waiting" }
        ];

        let res = list.filter(i => i.ID == value);

        return res[0].COLCOR;
    }

    render() {
        const { columns, loading, data, totalpage, modalVisible } = this.state;

        return (
            <>
                <ModalServerError modalVisible={modalVisible}/>
                <Table
                    loading={loading}
                    pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: false,
                        total: totalpage,
                        showTotal: (total, range) => <div>&nbsp;&nbsp; {range[0]}-{range[1]} of {totalpage} items </div>,
                        // onShowSizeChange: this.onChangePage,
                        // onChange: this.onChangePage,
                        // current: this.state.currentpage
                    }
                    }
                    style={{ cursor: 'pointer' }}
                    columns={columns}
                    dataSource={data}
                    className="table-ants"
                    tableLayout="fixed"
                    rowKey="CAMP_ID"
                    onRow={(item, index) => ({
                        onDoubleClick: () => { this.setClickId(item) }
                    })}
                />
            </>
        );
    }
}

