/* eslint-disable */
import React, { Component } from "react";
import { Select, Button, Modal, Row, Col } from "antd";

import { token, CheckTokenExp } from "../../../config";
import { apiClient } from "../../../api";
import { root } from "../../../config";
import ModalServerError from "../Modal/ModalServerError";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const { Option } = Select;

const styleChooses = {
  height: "31.5rem",
  textAlign: "center",
  backgroundColor: "#ffffff",
};

const styleChoosesMobile = {
  height: "31.5rem",
  left: "-1.5rem",
  backgroundColor: "#ffffff",
};

const styleChoose = {
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 1px 2px, rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 4px 8px, rgba(0, 0, 0, 0.2) 0px 8px 16px, rgba(0, 0, 0, 0.2) 0px 16px 32px, rgba(0, 0, 0, 0.2) 0px 32px 64px",
  height: "31.5rem",
};
const styleChoose2 = {
  boxShadow:
    "box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
};

const styleIsChoose = {
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
  height: "31.5rem",
};

export default class SelectModalConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      select: undefined,
      list: [],
      visible: false,
      windowWidth: 0,
      windowHeight: 0,
      choose: null,
      items: [],
      modalVisible: false,
    };

    this.getData = this.getData.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleDragStart = (e) => console.log(e);

  responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  items = [
    <div className="item" data-value="1">
      <div onClick={this.handleDragStart}>
        <iframe
          style={{ height: "29rem", width: "15rem" }}
          src="https://localhost:44300/sms/aa"
        ></iframe>

        <div>
          choose{" "}
          <a href="https://localhost:44300/sms/aa" target="_blank">
            preview
          </a>
        </div>
      </div>
    </div>,
  ];

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.getData("");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getData(value) {
    if (!CheckTokenExp()) {
      this.state.list.length > 0 ? "" : this.setState({ loading: true });
      apiClient
        .get("api/Campaign/GetConsent", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          if (data.status === 200 && data.data) {
            var tempData = [];
            data.data.forEach((element) => {
              tempData.push(
                <div className="item" data-value={element.ID}>
                  <div
                    onClick={this.handleDragStart(element.ID)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <iframe
                      style={{ height: "29rem", width: "15rem" }}
                      src="https://localhost:44300/sms/aa"
                    ></iframe>
                    
                  </div>
                  <div>
                      choose{" "}
                      <a href="https://localhost:44300/sms/aa" target="_blank">
                        preview
                      </a>
                    </div>
                </div>
              );
            });
            this.setState({ list: tempData });
          } else {
            this.setState({ modalVisible: true });
            this.setState({ list: [] });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ modalVisible: true });
          this.setState({ list: [] });
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  setVisible(flag) {
    this.setState({
      visible: !flag,
    });
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  setChoose(value) {
    this.setState({
      choose: value,
    });
  }

  render() {
    const { loading, list, choose, modalVisible } = this.state;
    const { select, setStateByProps, disabled } = this.props;
    return (
      // <Select

      //     showSearch

      //     loading={loading}
      //     name="select"
      //     onSearch={(value) => { this.getData(value) }}
      //     onChange={(value) => { setStateByProps("CAMP_CONSENT_ID", value) }}
      //     value={select || undefined}
      //     suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
      //     placeholder="E-Consent"
      //     filterOption={(input, option) =>
      //         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //     }
      //     disabled={disabled}
      // >
      //     {
      // list.map((item, index) =>
      //     <Option key={index} value={item.ID}>{item.TEXT}</Option>
      // )
      //     }
      // </Select>
      <>
        <Button onClick={() => this.setVisible(this.state.visible)}>
          Select Template <span className="fa fa-angle-right" />
        </Button>
        <Modal
          title="e-Consent Template"
          centered
          visible={this.state.visible}
          onOk={() => this.setVisible(this.state.visible)}
          onCancel={() => this.setVisible(this.state.visible)}
          width={1100}
        >
          <Row>
            {
              <AliceCarousel
                mouseTracking
                items={list}
                responsive={this.responsive}
                mouseDragEnabled={true}
                controlsStrategy="alternate"
              />
              // list.map((item, index) =>
              //         <Col xs={24} md={12} onClick={()=>{console.log(item.ID)}} style={this.state.windowWidth < 768 ? styleChoosesMobile : styleChooses}>
              //             {/* <div className="phone-preview-container-consent"
              //                 onClick={()=>this.setChoose(item.ID)}
              //                 style={choose == item.ID ? styleIsChoose : {}}
              //             >
              //                 <div className="phone-content"> */}
              //                 <div
              //                     onClick={()=>this.setChoose(item.ID)}
              //                     style={choose == item.ID ? styleIsChoose : {}}
              //                 >
              //                     <iframe style={{height:'31rem',width:'16rem'}} src="https://localhost:44300/sms/aa"></iframe>
              //                 </div>
              //                 {/* </div>
              //             </div> */}
              //         </Col>
              // )
            }
            {/* <AwesomeSlider style={{ height: "31rem" }}>
              {list.map((item, index) => (
                <Row style={
                    this.state.windowWidth < 768
                      ? styleChoosesMobile
                      : styleChooses
                  }>
                  <Col
                    xs={24}
                    md={12}
                    onClick={() => {
                      console.log(item.ID);
                    }}
                    
                  >
                    <div
                      onClick={() => this.setChoose(item.ID)}
                      style={choose == item.ID ? styleIsChoose : {}}
                    >
                      <iframe
                        style={{ height: "29rem", width: "15rem" }}
                        src="https://localhost:44300/sms/aa"
                      ></iframe>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    onClick={() => {
                      console.log(item.ID);
                    }}
                    
                  >
                    <div
                      onClick={() => this.setChoose(item.ID)}
                      style={choose == item.ID ? styleIsChoose : {}}
                    >
                      <iframe
                        style={{ height: "31rem", width: "16rem" }}
                        src="https://localhost:44300/sms/aa"
                      ></iframe>
                    </div>
                  </Col>
                </Row>
              ))}
            </AwesomeSlider> */}

            {/* <Row style={
                    this.state.windowWidth < 768
                      ? styleChoosesMobile
                      : styleChooses
                  }>
                  <Col
                    xs={24}
                    md={12}
                    onClick={() => {
                      console.log(1);
                    }}
                    
                  >
                    <div
                      onClick={() => this.setChoose(1)}
                      style={choose == 1 ? styleIsChoose : {}}
                    >
                      <iframe
                        style={{ height: "29rem", width: "15rem" }}
                        src="https://localhost:44300/sms/aa"
                      ></iframe>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    onClick={() => {
                      console.log(1);
                    }}
                    
                  >
                    <div
                      onClick={() => this.setChoose(2)}
                      style={choose == 2 ? styleIsChoose : {}}
                    >
                      <iframe
                        style={{ height: "31rem", width: "16rem" }}
                        src="https://localhost:44300/sms/aa"
                      ></iframe>
                    </div>
                  </Col>
                </Row> */}
          </Row>
        </Modal>
        <ModalServerError modalVisible={modalVisible}/>
      </>
    );
  }
}

// Col xs={24} md={12} onClick={()=>{console.log(item.ID)}} style={this.state.windowWidth < 768 ? styleChoosesMobile : styleChooses}>
//                                         {/* <div className="phone-preview-container-consent"
//                                             onClick={()=>this.setChoose(item.ID)}
//                                             style={choose == item.ID ? styleIsChoose : {}}
//                                         >
//                                             <div className="phone-content"> */}
//                                             <div
//                                                 onClick={()=>this.setChoose(item.ID)}
//                                                 style={choose == item.ID ? styleIsChoose : {}}
//                                             >
//                                                 <iframe style={{height:'31rem',width:'16rem'}} src="https://localhost:44300/sms/aa"></iframe>
//                                             </div>

//                                             {/* </div>
//                                         </div> */}
//                                     </Col>
