/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';

import SelectAppOtp from '../Select/SelectAppOtp';

const { TextArea } = Input;
export default class ModalTestOTP extends Component {

    constructor(props) {
        super(props);

        this.state = {
            appotp: undefined,
            Mobile_format: false,
            error_message: "",
            Prefix: "",

        };
        this.CleanPhoneNumber = this.CleanPhoneNumber.bind(this);
    }

    componentDidMount() {
        this.setState({ Mobile_format: false });
    }

    CleanPhoneNumber(phone) {
        if (!CheckTokenExp()) {
            this.setState({ Mobile_format: false });

            if (isNaN(phone)) {
                this.setState({ Mobile_format: true });
            }
            else if (phone.length < 10) {
                this.setState({ Mobile_format: true });
            }
            else {
                apiClient.get('OTP/CleanPhone/' + phone
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        if (json.phone !== "" && json.phone.length === 11) {
                            this.setState({ Mobile_format: false });
                        }
                        else {
                            this.setState({ Mobile_format: true });
                        }
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            }
        } else {
            window.location.href = "logout";
        }

    }

    render() {
        const { loading, visible, warningModal,cancelModal, setStateByProps, appotp, mobile, Prefix, result_message } = this.props;
        const { Mobile_format } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        const labelvalidate = { color: "red", float: 'left' };
        const labelError = { textAlign: 'center', fontSize: '30px', marginTop: '-20px', color: "red", width: '100%' };
        const labelSuccess = { textAlign: 'center', fontSize: '30px', marginTop: '-20px', color: "green", width: '100%' };
        return (
            <Modal
                width={450}
                title={<div>Test OTP <span className="fa fa-times-circle-o card-close" onClick={this.props.showModal}></span></div>}
                visible={visible}
                onOk={this.props.handleOk}
                onCancel={() => cancelModal(false)}
                loading={loading}
                footer={[
                    <Row gutter={16} type="flex" justify="end" style={{ marginBottom: 16, marginLeft: 39, marginRight: 39 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            {this.state.Mobile_format === true ?
                                <label style={labelvalidate}>Please input your mobile number!</label>
                                :
                                null
                            }
                            {this.state.credit_error === true ?
                                <label style={labelvalidate}>Credit not enough!</label>
                                :

                                null}
                            {this.state.error_message ?
                                <label style={labelvalidate}>{this.state.error_message}</label>
                                :
                                null}

                            {
                                result_message ?
                                    <label style={status === true ? labelSuccess : labelError}>{result_message}</label>
                                    :
                                    null
                            }

                            <Button type="primary" style={{ marginRight: 8, float: 'right' }}
                                disabled={appotp && mobile && !Mobile_format ? false : true}
                                loading={loading}
                                onClick={() => {
                                    warningModal(true, "Do you want to send this message?", "Send message successfully."),
                                        setStateByProps("action", "otpsend")

                                }}>
                                <span className="fa fa-paper-plane-o"></span> &nbsp;&nbsp;
                              Send
                        </Button>
                        </Col>
                    </Row>
                ]}
            >
                <div>
                    <Row gutter={16} type="flex" justify="end" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>App OTP Name</label>
                            <label className="red">*</label>
                            <SelectAppOtp select={appotp} Prefix={Prefix} setStateByProps={setStateByProps} />
                        </Col>
                    </Row>

                    <Row gutter={16} type="flex" justify="end" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}>Mobile</label>
                            <label className="red">*</label>
                            <Input placeholder="Mobile" value={mobile} onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                    setStateByProps("mobile", e.target.value);
                                    this.CleanPhoneNumber(e.target.value);
                                }

                            }} />
                        </Col>
                    </Row>


                </div>
            </Modal>
        );
    }
}


