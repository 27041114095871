/* eslint-disable */
import React, { Component } from 'react';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card,
    Tooltip, Progress, Table, Divider, Tag, Modal, Checkbox, Spin, Radio
} from 'antd';
import ModalAddUser from '../../../components/Ants/Modal/ModalAddUser';
import TableUsers from '../../../components/Ants/Table/TableUsers';

import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, validateEmail, getUrlVars, CheckTokenExp } from '../../../config';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import ModalError from "../../../components/Ants/Modal/ModalError";

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class SubAccountUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            users: [],
            search: {
                name: "",
                users: [],
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            screenwidth2: 0,
            MadalAddUservisible: false,
            id: null,
            addusername: "",
            addpassword: "",
            addstatusacc: 1,
            tocreate: false,
            creactsubeaccount: false,
            admin: false,
            createuser: false,
            owner: false,
            password: "",
            repassword: "",
            addrepassword: "",
            uemail: "",
            emailError: false,
            uemailError: false,
            errorModalVisible: false,
            messageError: ""
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.GetUserDetail = this.GetUserDetail.bind(this);
        this.accountUpdate = this.accountUpdate.bind(this);
        this.userCreate = this.userCreate.bind(this);
        this.userSave = this.userSave.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.errorModal = this.errorModal.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "usercreate") {
            this.setState({ action: "" });
            this.userCreate();
        }
        if (this.state.action == "saveuser") {
            this.setState({ action: "" });
            this.userSave();
        }
        this.setState({ successModalVisible: value });

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    users: state.users,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    GetUserDetail(id) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                id: id
            });

            apiClient.post('api/usersmanage/UserDetail', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({
                        addstatusacc: json.status,
                        addusername: json.username,
                        tocreate: json.permission,
                        uemail: json.email,
                        uemailError: validateEmail(json.email)
                    });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    accountUpdate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                name: this.state.accname,
                email: this.state.email,
                username: this.state.username,
                password: this.state.password,
                paytype: this.state.paytype,
                status: this.state.statusacc,
                creactsubeaccount: this.state.creactsubeaccount == 0 ? false : (this.state.creactsubeaccount == 1 ? true : this.state.creactsubeaccount),
            });

            apiClient.post('api/usersmanage/AccountUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    if (data.data) {
                        this.setState({ successModalVisible: value });
                    } else {
                        this.setState({
                            errorModalVisible: true,
                            messageError: "Update Account unsuccessful!",
                        });
                    }

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    userCreate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                accountId: getUrlVars()["id"],
                username: this.state.addusername,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission: this.state.tocreate == 0 ? false : (this.state.tocreate == 1 ? true : this.state.tocreate),
            });

            apiClient.post('api/usersmanage/UserCreate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();
                    this.handleOk();

                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "" });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "" });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    userSave() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                userid: this.state.id,
                password: this.state.addpassword,
                status: this.state.addstatusacc,
                permission: this.state.tocreate == 0 ? false : (this.state.tocreate == 1 ? true : this.state.tocreate),
                email: this.state.uemail
            });

            apiClient.post('api/usersmanage/UserUpdate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    if (data.data) {
                        this.setState({ successModalVisible: value });
                    } else {
                        this.setState({
                            errorModalVisible: true,
                            messageError: "Update user unsuccessful!",
                        });
                    }

                    this.onSearch();
                    this.handleOk();

                    this.setState({ addusername: "", addpassword: "", repassword: "", addstatusacc: 1, tocreate: false, usercreate: "" });

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    handleOk() {
        this.setState({
            MadalAddUservisible: false, usercreate: "", addrepassword: "", addpassword: "", uemail: ""
        });
    };

    errorModal(value) {
        this.setState({ errorModalVisible: value });
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem,
            action, dateRange, screenwidth, screenwidth2, accname, email, statusacc, username, paytype, MadalAddUservisible,
            id, addusername, addpassword, repassword, password, addstatusacc, tocreate, creactsubeaccount, admin, createuser,
            owner, addrepassword, uemail, emailError, uemailError, errorModalVisible, messageError } = this.state;
        const { accdetailId } = this.props;
        return (
            <div className="content" id="main">
                <Row gutter={8} className="row-nowrap" id="main">
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Input placeholder="Search by username" onChange={(e) => this.setStateByProps("name", e.target.value)} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <RangePicker
                            placeholder={["Start Date", "End Date"]}
                            defaultValue={dateRange}
                            ranges={{
                                Today: [moment().startOf('day'), moment().endOf('day')],
                                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')]
                            }}
                            onChange={(value) => this.setStateByProps("dateRange", value)}
                            value={dateRange}
                            format={dateFormat} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Button icon="search" onClick={this.onSearch}>
                            Search
                        </Button>
                    </Col>
                </Row>

                <Row gutter={8} type="flex" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TableUsers
                            loading={loading}
                            search={search}
                            templateItem={templateItem}
                            action={action}
                            warningModal={this.warningModal}
                            setStateByProps={this.setStateByProps}
                            id={accdetailId}
                            GetUserDetail={this.GetUserDetail}
                            owner={owner}
                            admin={admin}
                            createuser={createuser}
                            updateWindowDimensions={this.updateWindowDimensions}
                        />

                    </Col>
                </Row>


                <ModalAddUser
                    setStateByProps={this.setStateByProps}
                    visible={MadalAddUservisible}
                    handleOk={this.handleOk}
                    id={id}
                    username={addusername}
                    password={addpassword}
                    repassword={addrepassword}
                    statusacc={addstatusacc}
                    tocreate={tocreate}
                    loading={loading}
                    warningModal={this.warningModal}
                    owner={owner}
                    admin={admin}
                    email={uemail}
                    createuser={createuser}
                    emailError={uemailError}
                />
                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalError
                    loading={loading}
                    errorModalVisible={errorModalVisible}
                    errorModal={this.errorModal}
                    messageError={messageError}
                />

            </div>

        );
    }
}
