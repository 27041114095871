import React, { Component } from 'react';
import { Radio } from 'antd';
import { colorGreen, colorRed } from '../../../config';


export default class ButtonGroupStatusAcc extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        const { placeholder, radio, setStateByProps, style, statename, disabled } = this.props;

        const colorEn = radio == 1 ? colorGreen : "";
        const colorDis = radio == 0 ? colorRed : "";
        return (
            <Radio.Group style={style ? style : null} value={radio}
                onChange={(e) => setStateByProps(statename ? statename : "statusacc", e.target.value)}
                buttonStyle="solid" size="small">
                <Radio.Button value={0} style={{ backgroundColor: colorDis, borderColor: colorDis }} disabled={disabled} >Disabled</Radio.Button>
                <Radio.Button value={1} style={{ backgroundColor: colorEn, borderColor: colorEn }} disabled={disabled} >Enabled</Radio.Button>
            </Radio.Group>
        );
    }
}