import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input } from 'antd';
import { Link } from "react-router-dom";

export default class ModalCreateReport extends Component {

    constructor(props) {
        super(props);


        this.state = {

        };

    }


    render() {
        const { loading, inputModalVisible, inputModal, successModal, modalcountDown, message, messageSuccess, createreport } = this.props;
        //const { cancelModalVisible } = this.state;

        return (
            <div>
                <style>{`
              

                
                 `}</style>

                <Modal
                    zIndex={1021}
                    wrapClassName="popup-question"
                    closeIcon={false}
                    visible={inputModalVisible}
                    onOk={() => inputModal(true, message, messageSuccess)}
                    onCancel={() => inputModal(false, message, messageSuccess)}
                    width={550}
                    footer={[
                        <Button key="submit" type="gray" loading={loading} onClick={() => inputModal(false, message, messageSuccess)}>
                            CANCEL
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={() => { inputModal(false, message, messageSuccess); createreport(); }}>
                            CREATE
                        </Button>
                    ]}
                >
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: 24 }}>
                            <label style={{ color: '#000000', fontWeight: 'bold' }}>{message}</label>
                            <label style={{ color: '#000000' }}>It takes a few minutes to create a report. If you want to get a custom report or see all existing ones, visit the <Link to={'/report'} style={{ fontWeight:'bold'}}>Reports pages.</Link> </label>
                        </Col>
                    </Row>

                </Modal>
            </div>
        );
    }
}


