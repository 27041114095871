import React, { Component } from "react";
import {
  Card,
  CardBody,
  // Table
} from "reactstrap";
import moment from 'moment';
import 'moment-timezone';
import { Button, Icon, Radio, Row, Col, Spin, notification, Table } from "antd";
import { CheckUserLogedin, getAccId, smsLoginUrl } from "../../../../config";
import {
  formatCurrency
} from "../../../../config";
import queryString from "query-string";
import ButtonBase from "../../../../components/Button/ButtonBase";
import TranserctionService from "../../Service/transection";
import { TransectionStatus } from "../../../../models/transection";

moment.tz.setDefault("Asia/Bangkok");

class OrderSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errormessage: "",
      loading: true,
      message: "Loading your receipt",
    };
  }

  componentDidMount() {
    const rid = queryString.parse(this.props.location.search).rid
    const oid = queryString.parse(this.props.location.search).oid
    if (!CheckUserLogedin()) {
      this.props.history.push("/PackageList");
    } else if (rid !== undefined) {
      this.getTransectionDetail(rid, "receipt");
    } else if (oid !== undefined) {
      this.getTransectionDetail(oid, "order");
    }

    //clear session
    sessionStorage.clear();
  }

  async getTransectionDetail(id, type) {
    let response = null;
    if (type == "receipt") {
      response = await TranserctionService.getReceiptDetail(id)
    } else {
      response = await TranserctionService.getOrderDetail(id)
    }
    this.setState({
      loading: response.loading,
      message: response.message,
    });

    this.initTransectionAndOrderData(response.data);

    if (type == "receipt") {
      this.initReceiptData(response.data);
    }
  }

  initTransectionAndOrderData(transection) {
    this.setState({
      orderId: transection.order.id.toUpperCase(),
      invoice_no: transection.order.invoice_no,
      packagename: transection.order.package.PKG_NAME,
      packagedetail: transection.order.package.PKG_DETAIL,
      paymentmethod: transection.order.payment_method_display,
      paymentstatus: transection.order.payment_status_display,
      orderStatus: TranserctionService.mapTransectionStatus(transection.order.payment_status),
      orderdate: transection.order.create_date !== null ? moment(transection.order.create_date).format('DD/MM/YYYY HH:mm') : "-",
      packagecost: transection.order.package.PKG_COST_INFO.PKGC_PRICE,
      vat: transection.order.package.PKG_COST_INFO.PKGC_VAT,
      net: transection.order.package.PKG_COST_INFO.PKGC_NET_PRICE,
      fullname: transection.order.user_fullname,
      mobileno: transection.order.user_phone_no !== "" ? transection.order.user_phone_no : "-",
      email: transection.order.user_email,
    });
  }

  initReceiptData(receipt) {
    this.setState({
      taxinfoid: receipt.tax_invoice_id,
      contact_name: receipt.contact_name,
      contact_mobile: receipt.contact_mobile,
      receiptid: receipt.receipt.RE_NO.toUpperCase(),
      receiptdate: receipt.receipt_datetime_display !== null ? moment(receipt.receipt_datetime_display).format('DD/MM/YYYY HH:mm') : '-',
      discountprice: receipt.receipt.RE_DISCOUNT,
      rid: receipt.receipt.RE_NO,
    });
  }


  async downloadTaxInvoice() {
    const rid = this.state.rid;
    const taxinfoid = this.state.taxinfoid;
    const { loading, url } = await TranserctionService.downloadTaxInvoice(rid, taxinfoid);
    this.setState({
      loadingAll: loading,
    });

    var link = document.createElement("a");
    link.href = url;
    link.download = `${rid}.pdf`
    link.click();
  }

  render() {
    let {
      orderId,
      orderdate,
      invoice_no,
      packagename,
      packagedetail,
      receiptid = "-",
      receiptdate = "-",
      paymentmethod,
      paymentstatus,
      packagecost,
      vat,
      net,
      fullname,
      mobileno,
      email,
      loading,
      message,
      discountprice = 0,
      orderStatus,
      contact_name = "-",
      contact_mobile = "-",
    } = this.state;

    const columns = [
      {
        title: "No",
        dataIndex: "key",
        key: "key",
        align: "center",
        width: "40px",
        render: (text) => <span>1</span>,
      },
      {
        title: "Package",
        dataIndex: "",
        key: "",
        width: "300px",
        render: (text, record, index) => {
          return (
            <span>
              {`${packagename} - ${packagedetail}`}
            </span>
          );
        },
      },
      {
        title: "Unit",
        dataIndex: "address",
        key: "address",
        width: "130px",
        render: (text, record, index) => {
          return (
            <>
              <div>1 Unit</div>
              <div>Subtotal</div>
              <div>Discount</div>
              <div>Vat(7%)</div>
              <div style={{ fontWeight: "bold", color: "#000", fontSize: "22px" }}>NET AMOUNT</div>
            </>
          );
        },
      },
      {
        title: "Amount (Baht)",
        key: "tags",
        dataIndex: "tags",
        align: "right",
        width: "130px",
        render: (text, record, index) => {
          return (
            <>
              <div>{formatCurrency(parseFloat(packagecost).toFixed(2))} Baht</div>
              <div>{formatCurrency(parseFloat(packagecost).toFixed(2))} Baht</div>
              {discountprice ? (
                <div style={{ color: "red" }}>- {formatCurrency(parseFloat(discountprice).toFixed(2))} Baht</div>
              ) : (
                <div>{formatCurrency(parseFloat(discountprice).toFixed(2))} Baht</div>
              )}
              <div>{formatCurrency(parseFloat(vat).toFixed(2))} Baht</div>
              <div style={{ fontWeight: "bold", color: "#000", fontSize: "22px" }}>{formatCurrency(parseFloat(net).toFixed(2))} Baht</div>
            </>
          );
        },
      },
    ];

    const data = [this.state];

    return (
      <div
        className="app flex-row align-items-center orderdetailbg"
        style={{ display: "block" }}
      >
        <div className="payment-success" style={{ display: orderStatus === TransectionStatus.SUCCESS.name ? "flex" : "none" }}>
          <Icon
            type="check-circle"
            theme="filled"
            style={{ fontSize: "16px", color: "#fff", marginRight: "1rem" }}
          />
          <h4 style={{ marginBottom: "0px", color: "#fff", letterSpacing: "1px" }}>
            Purchase successful, Thank you for your payment
          </h4>
        </div>
        <div className="payment-fail" style={{ display: orderStatus !== TransectionStatus.SUCCESS.name ? "flex" : "none" }}>
          <Icon
            type="check-circle"
            theme="filled"
            style={{ fontSize: "16px", color: "#fff", marginRight: "1rem" }}
          />
          <h4 style={{ marginBottom: "0px", color: "#fff", letterSpacing: "1px" }}>
            Purchase Failed, Please try again
          </h4>
        </div>
        <div className="receipt-container">
          <div className="receipt-detail">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={11}></Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={13}>
                <h2 style={{ margin: "10px" }}>Order</h2>
                {loading ? (
                  <Card className="mx-4">
                    <CardBody className="p-4">
                      <Row className="">
                        <Col className="d-flex justify-content-center">
                          <div>{message}</div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col className="d-flex justify-content-center">
                          <Spin size="large" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Card className="card-left-payment">
                        <h4>Buyer</h4>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Account Name :&nbsp;
                          </span>
                          <span>{fullname}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Mobile :&nbsp;
                          </span>
                          <span>{mobileno}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Email :&nbsp;
                          </span>
                          <span>{email}</span>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Card className="card-right-payment">
                        <h4>Order Detail</h4>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {orderStatus === TransectionStatus.SUCCESS.name ? `Receipt NO.` : `Invoice No.`} :&nbsp;
                          </span>
                          <span>{orderStatus === TransectionStatus.SUCCESS.name ? receiptid : invoice_no}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {orderStatus === TransectionStatus.SUCCESS.name ? `Receipt Date` : `Order Date`} :&nbsp;
                          </span>
                          <span>{orderStatus === TransectionStatus.SUCCESS.name ? receiptdate : orderdate}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Payment Method :&nbsp;
                          </span>
                          <span>{paymentmethod}</span>
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Status :&nbsp;
                          </span>
                          <span>{orderStatus}</span>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Card className="card-center-payment">
                        <h4>Package Detail</h4>
                        <Table
                          className="tbPackageDetail"
                          columns={columns}
                          dataSource={data}
                          pagination={false}
                        />
                      </Card>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
          <div className="receipt-footer" style={{ display: orderStatus !== TransectionStatus.SUCCESS.name ? "none" : "block" }}>
            <ButtonBase
              onClick={(e) => this.downloadTaxInvoice()}
              style={{ marginRight: "8px" }}
            >
              <Icon type="file-pdf" />
              <span>Dowload Tax invoice</span>
            </ButtonBase>
          </div>
        </div>
      </div>
    );
  }

}

export default OrderSummary;
