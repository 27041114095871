/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input, Spin } from 'antd';
import { token } from '../../../config';

import SelectAppOtp from '../Select/SelectAppOtp';

const { TextArea } = Input;
export default class ModalVerifyCodeOTP extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.setStateByProps = this.setStateByProps.bind(this);
    }

    setStateByProps(parameters, value) {
        this.setState({ [parameters]: value });
    }


    render() {
        const { appotp } = this.state;
        const { loading, visible, warningModal, mobile,cancelModal, refcode, handleOk_verifyotp, setStateByProps, result_message, status, verifyotp_close, codeotp, Prefix } = this.props;
        const styleMarginBottom = { marginBottom: 8 };
        const labelError = { textAlign: 'center', fontSize: '30px', marginTop: '-20px', color: "red", width: '100%' };
        const labelSuccess = { textAlign: 'center', fontSize: '30px', marginTop: '-20px', color: "green", width: '100%' };
        return (
            <Modal
                width={450}
                title={<div>Test OTP <span className="fa fa-times-circle-o card-close" onClick={verifyotp_close}></span></div>}
                visible={visible}
                onOk={handleOk_verifyotp}
                onCancel={() => cancelModal(false)}
                loading={loading}
                footer={null}
            >
                <div>
                    <Spin spinning={refcode ? false : true}>
                        <Row gutter={16} type="flex" justify="end">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Moblie : {mobile}</label>
                            </Col>
                        </Row>

                        <Row gutter={16} type="flex" justify="end">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Code OTP</label>
                                <label className="red">*</label>
                                <br />
                                <label className=" control-label require" style={{ fontWeight: 700 }}>{Prefix ? <label style={{ fontWeight: '700' }}>{Prefix.length == 0 ? "" : Prefix + " - "}&nbsp;</label> : null}</label>
                                <Input placeholder="Code OTP" value={codeotp} style={{ width: Prefix ? '85%' : '100%' }} onChange={(e) => setStateByProps("codeotp", e.target.value)} />

                                <label className=" control-label require" style={{ fontWeight: 700 }}>Ref Code : {refcode}</label>
                            </Col>
                            {
                                result_message ?
                                    <label style={status === true ? labelSuccess : labelError}>{result_message}</label>
                                    :
                                    null
                            }
                        </Row>

                        <Row gutter={16} type="flex" justify="end" style={{ textAlign: 'right', marginBottom: 16 }} >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Button type="primary"
                                    loading={loading}
                                    disabled={mobile && refcode && codeotp ? false : true}
                                    onClick={() => { warningModal(true, "Do you want to verify OTP?", "Verify Success."), setStateByProps("action", "verifyotp") }}>
                                    <span className="fa fa-paper-plane-o"></span> &nbsp;&nbsp;
                              Verify
                        </Button>
                            </Col>
                        </Row>

                    </Spin>
                </div>
            </Modal >
        );
    }
}


