/* eslint-disable */
import React, { Component } from 'react';
import { Select, AutoComplete } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectTags extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select: undefined,
            list: [],
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        this.getData("");
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData(this.props.search);
        }
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "";

            let req = JSON.stringify({
                "SEARCH_TAG": search,
                "MINPAGE": 1,
                "PAGESIZE": 10
            });

            apiClient.post('api/Tag/TAG_LIST/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data.tag_list) {
                        this.setState({ list: data.data.tag_list });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, select, setStateByProps, mode, style } = this.props;

        const findbyname = list.find(e => e.PRG_NAME == select);
        const findbyid = list.find(e => e.PRG_ID == select);

        return (
            <>
                {mode == "insert" ?
                    <AutoComplete
                        style={style ? style : null}
                        dataSource={list.map(item => item.PRG_NAME ? item.PRG_NAME : "")}
                        onSearch={(value) => { setStateByProps("search", value) }}
                        onChange={(value) => { setStateByProps("tag", list.find(e => e.PRG_NAME == value) ? list.find(e => e.PRG_NAME == value).PRG_ID : value) }}
                        value={findbyid ? findbyid.PRG_NAME : select}
                        placeholder={placeholder ? placeholder : "Tag"}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                    :
                    <Select

                        showSearch
                        allowClear

                        style={style ? style : null}
                        loading={loading}
                        name="select"
                        onSearch={(value) => { this.getData(value) }}
                        onChange={(value) => { setStateByProps("tag", value) }}
                        value={select}
                        suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                        placeholder={placeholder ? placeholder : "Tag"}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            list.map((item,index) =>
                                <Option key={index} value={item.PRG_ID}>{item.PRG_NAME}</Option>
                            )
                        }
                    </Select>
                }
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


