import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
    PageHeader,
    Button,
    Descriptions,
    Select,
    Row,
    Col,
    DatePicker,
    Icon,
    Form,
    Input,
    Card,
    Tooltip,
    Progress,
    Table,
    Divider,
    Tag,
    Modal
} from "antd";

import { token, getUrlVars } from '../../../config';

import Card3 from "../../../components/Ants/Card/Card3";

import PageQuickSend from './QuickSend/';
import PageBroadcast from './Broadcast/';
import PageBroadcastByTemplate from './BroadcastByTemplate/';

const { Option } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { confirm } = Modal;

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.getTypeBroadcast = this.getTypeBroadcast.bind(this);
        this.clearType = this.clearType.bind(this);


        this.state = {
            broadcastType: "",
            pagename: "",
            page: "",
            CAMP_ID: 0,
            CAMPAIGN_NAME: "",
            content: "",
            content_old: "",
            sender: "",
            newTemplate: "",
            cb_shortedurl: false,
            to: "",
            fileList: [],
            TEMPLATE_ID: 0,
            schedule_commu_date: null,
            schedule_commu_time: null,
            draft_spinning: false,
            indexcontent: 0,
            SMS_FROM: [],
        };
    }

    componentDidMount() {
        var type = getUrlVars() ? getUrlVars()["type"] : "";
        this.setState({ broadcastType: type });
        this.getTypeBroadcast(type);

    }

    getTypeBroadcast(value) {
        var pagename = "";
        var page = "";
        switch (value) {
            case "quicksend":
                pagename = "Quick Send";
                page = <PageQuickSend clearType={this.clearType} />;
                break;
            case "broadcast":
                pagename = "Broadcast";
                page = <PageBroadcast clearType={this.clearType} />;
                break;
            case "broadcasttemplate":
                pagename = "Broadcast by template";
                page = <PageBroadcastByTemplate clearType={this.clearType} />;
                break;

        }

        this.setState({ broadcastType: value, pagename, page });

    }

    clearType() {
        this.getTypeBroadcast("");
        this.props.history.push('/communication/new')
    }



    render() {
        const { broadcastType, pagename, page } = this.state;
        const routes = [
            {
                path: 'communication',
                breadcrumbName: 'Communication',
            },
            {
                breadcrumbName: pagename + ' ' + (getUrlVars()["id"] ? 'edit' : 'new'),
            },
        ];
        return (
            <div>
                <PageHeader breadcrumb={{ routes }}>
                    <Row gutter={24} className="row-underheder">
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div style={{ pointerEvents: getUrlVars()["id"] ? "none" : "visible" }} >
                                <Link
                                    to={{ /*pathname: "/new",*/search: "?type=quicksend" }}
                                    onClick={() => this.getTypeBroadcast("quicksend")}
                                    style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }}
                                >
                                    <Card3
                                        title={"Quick Send"}
                                        style={"card3"}
                                         img={require('../../../img/vendors/icon/Quick-send.png')} active={broadcastType == "quicksend" ? "active" : ""}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div style={{ pointerEvents: getUrlVars()["id"] ? "none" : "visible" }} >
                                <Link
                                    to={{ /*pathname: "/new",*/search: "?type=broadcast" }}
                                    onClick={() => this.getTypeBroadcast("broadcast")}
                                    style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }}
                                >
                                    <Card3
                                        title={"Broadcast"}
                                        style={"card4"} img={require('../../../img/vendors/icon/Broadcast.png')} active={broadcastType == "broadcast" ? "active" : ""}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                            <div style={{ pointerEvents: getUrlVars()["id"] ? "none" : "visible" }} >
                                <Link
                                    to={{ /*pathname: "/new",*/search: "?type=broadcasttemplate" }}
                                    onClick={() => this.getTypeBroadcast("broadcasttemplate")}
                                    style={{ textDecoration: "none", paddingLeft: 10, paddingRight: 10 }}
                                >
                                    <Card3
                                        title={"Broadcast By Template"}
                                        style={"card5"} img={require('../../../img/vendors/icon/Broadcast-by-template.png')} active={broadcastType == "broadcasttemplate" ? "active" : ""}
                                    />
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        </Col>
                    </Row>

                </PageHeader>

                <div className="content">
                    {
                        pagename ?
                            <Card className="card-content" style={{ minHeight: 200 }}>
                                <span className="fa fa-times-circle-o card-close" onClick={() => this.clearType("")}></span>
                                {page}
                            </Card>
                            : ""
                    }
                </div>
            </div>
        );
    }
}
