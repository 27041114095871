import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, } from 'antd';

import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';

export default class TableOTPLog extends Component {

    constructor(props) {
        super(props);

        this.state = {

            columns: [
                {
                    title: 'App OTP Name',
                    dataIndex: 'APP_OTP',
                    key: 'APP_OTP'
                },
                {
                    title: 'OTP ID',
                    dataIndex: 'OTP_ID',
                    key: 'OTP_ID',
                },
                {
                    title: 'Mobile',
                    dataIndex: 'Mobile',
                    key: 'Mobile',
                },
                {
                    title: 'Ref Code',
                    dataIndex: 'Ref_Code',
                    key: 'Ref_Code',
                },
                {
                    title: 'OTP Code',
                    dataIndex: 'Code',
                    key: 'Code',
                },
                {
                    title: 'Date',
                    dataIndex: 'CreateDate',
                    key: 'CreateDate',
                },
                {
                    dataIndex: 'OTP_ID',
                    key: 'OTP_ID',
                    width: '8%',
                    render: (key, item) => (
                        <Link to={'/otp/log/detail?id=' + key} style={{ fontSize: 16, color: '#7b7b7b', paddingRight: 10 }} >
                            <span className="fa fa-eye size14" style={{ cursor: 'pointer' }}></span>
                        </Link>
                    ),
                },
            ],
            pagesize: 10,
            currentpage: 1,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
         this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            let req = JSON.stringify({
                "otpid": search.OTPID,
                "AppName": search.APPNAME,
                "Mobile": search.Mobile,
                "Ref_code": search.Ref_Code,
                "current": this.state.currentpage,
                "total": this.state.pagesize
            });
            apiClient.post('OTP/TEST/LIST', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        data: json !== null ? json : [],
                        totalpage: json !== null ? json[0].TOTAL_PAGE : 0,
                    });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        data: [],
                        totalpage: 0,
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        this.setState({ loading: true });
        this.setState({ currentpage: currentPage });
        var endrow = currentPage * pageSize;
        var startrow = ((currentPage * pageSize) + 1) - pageSize;
        this.setState({
            startrow: startrow,
            endrow: endrow,
            currentpage: this.state.pagesize == pageSize ? currentPage : 1, pagesize: pageSize
        });
        this.getData();
    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        return (
            <Table
                loading={loading}
                pagination={{
                    defaultPageSize: "10",
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                    total: totalpage,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                    onShowSizeChange: this.onChangePage,
                    onChange: this.onChangePage,
                    current: this.state.currentpage
                }
                }
                columns={columns}
                dataSource={data}
                className="table-ants"
            />
        );
    }
}


