import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Template Name',
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Sender Name',
                    dataIndex: 'sendername',
                    key: 'sendername',
                    width: 100,
                },
                {
                    title: 'Content',
                    dataIndex: 'content',
                    key: 'content',
                    width: 200,
                    render: text => (<div className="table-content" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    width: 130,
                    render: text => (text ? moment(text).format(dateFormat) : "")
                },
                {
                    title: 'Create By',
                    dataIndex: 'createby',
                    key: 'createby',
                    width: 130,
                },
                {
                    dataIndex: 'key',
                    key: 'key',
                    width: 50,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu onClick={(e) => this.handlemenu(e, item)}
                                    mode="vertical"
                                >
                                    <Menu.Item key="0" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <span className="fa fa-file-o ants-gray size14" ></span>&nbsp;&nbsp;  Download xlsx
                                        </Menu.Item>
                                    <Menu.Item key="1" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <span className="fa fa-files-o ants-gray size14" ></span>&nbsp;&nbsp;  Copy</Menu.Item>

                                    <Menu.Item key="2" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <Link to={"/communication/template/edit?id=" + key} style={{ textDecoration: 'none' }}>
                                            <span className="fa fa-pencil-square-o ants-gray size14" ></span>&nbsp;&nbsp;  Edit
                                             </Link>
                                    </Menu.Item>

                                    <Menu.Item key="3">
                                        <span className="fa fa-trash-o ants-gray size14" ></span>&nbsp;&nbsp;  Delete
                                        </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },
            ],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            enddata: 10,
            totalpage: 0,
            clickId: null,
        };

        this.handlemenu = this.handlemenu.bind(this);
        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);
    }

    handlemenu(e, value) {
        switch (e.key) {
            case "0":
                this.props.warningModal(true, "Do you want to download xlsx file?", "Please wait.");
                this.props.setStateByProps("action", "export");
                break;
            case "1":
                this.props.warningModal(true, "Do you want to copy this template?", "Copied template successfully.");
                this.props.setStateByProps("action", "duplicate");
                break;
            case "3":
                this.props.warningModal(true, "Do you want to delete this template?", "Deleted template successfully.");
                this.props.setStateByProps("action", "delete");
                break;
        }

        this.props.setStateByProps("templateItem", [value]);


    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    search: search.name,
                    users: search.users,
                    date: [search.dateRange[0] ? search.dateRange[0].format(dateFormat) : null, search.dateRange[1] ? search.dateRange[1].format(dateFormat) : null],
                    startdata: state.minpage,
                    enddata: state.pagesize,
                });

                apiClient.post('api/template/get', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState(state => {
                            return {
                                data: json ? json.data : [],
                                totalpage: json ? json.totalpage : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var maxpage = currentPage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize === 10 ? 1 : currentPage,
                pagesize: maxpage,
                minpage: minpage,
            };
        });
        this.getData();

    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                window.location.href = 'communication/template/edit?id=' + item.key
            }
            return {
                clickId: item ? item.key : item,
            }
        })
    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem } = this.props;
        const rowSelection = {
            columnWidth: 30,
            onChange: (selectedRowKeys, selectedRows) => {

                setStateByProps("templateItem", selectedRows);
            },
            getCheckboxProps: record => ({
                key: record.key,
            }),
        };
        return (
            <Table
                style={{ cursor: 'pointer' }}
                loading={loading}
                rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                    total: totalpage,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                    onShowSizeChange: this.onChangePage,
                    onChange: this.onChangePage,
                    current: this.state.currentpage
                }
                }
                onRow={(item, index) => ({
                    onDoubleClick: () => this.setClickId(item)
                })}
                columns={columns}
                dataSource={data}
                className="table-ants"
                footer={() => footer}
                tableLayout="fixed"
            />
        );
    }
}


