/* eslint-disable */
import React, { Component } from 'react';
import { Select } from 'antd';
import { token, deepEqual, CheckTokenExp} from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            accounts: [],
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.accounts !== nextProps.accounts) {
            this.setState({ accounts: nextProps.accounts });
            this.getData("", nextProps.accounts)
        }

        //if (this.props.select !== nextProps.select) {
        //    this.setState({ select: [] });
        //}
    }

    componentDidMount() {
        this.getData();
    }

    getData(value, accounts) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "";

            let req = JSON.stringify({
                accounts,
                search
            });
            apiClient.post('api/users/get/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ list: data.data });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, select, setStateByProps, accounts, style, mode } = this.props;

        return (
            <>
                <ModalServerError modalVisible={modalVisible}/>
                <Select
                    mode={mode == "default" ? "default" : "multiple" }
                    allowClear
                    showSearch
                    style={style ? style : null}
                    loading={loading}
                    name="select"
                    onSearch={(value) => { this.getData(value, accounts) }}
                    onChange={(value) => setStateByProps("users", value)}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "User"}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        list.map((item,index) =>
                            <Option key={index} value={item.ID}>{item.TEXT}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


