import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col } from 'antd';


export default class Card3 extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { title, style, img, active } = this.props;

        return (
            <Card className={style + " " + active} >
                <img src={img} />
                <div className={style + "-body"}>
                    <div className={style + "-body"}>
                        <h5>{title}</h5>
                    </div>
                </div>
            </Card>
        );
    }
}


