/* eslint-disable */
import React, { Component } from "react";
import { Select, Button, Modal, Row, Col } from "antd";

import { token, CheckTokenExp } from "../../../config";
import { apiClient } from "../../../api";
import { root } from "../../../config";

const { Option } = Select;

const styleChooses = {
    height: "32.5rem",
    textAlign: "center",
    backgroundColor: "#ffffff",
  };
  
  const styleChoosesMobile = {
    height: "32.5rem",
    left: "-1.5rem",
    backgroundColor: "#ffffff",
  };

export default class ButtonPreviewConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      select: undefined,
      list: [],
      visible: false,
      windowWidth: 0,
      windowHeight: 0,
      choose: null,
      items: [],
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  setVisible(flag) {
    this.setState({
      visible: !flag,
    });
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  render() {
    const { loading, list, choose } = this.state;
    const { select, setStateByProps, disabled ,CAMP_CONSENT_FLAG , CAMP_CONSENT_ID,OPTIONCONSENT } = this.props;

    const url = OPTIONCONSENT.find((element) => {
        return element.ID === CAMP_CONSENT_ID;
    })

    return (
      <>
        {/* <Button onClick={() => this.setVisible(this.state.visible)}>
          Select Template <span className="fa fa-angle-right" />
        </Button> */}

        <Button key="previewEConsent" disabled={!CAMP_CONSENT_FLAG || CAMP_CONSENT_ID == null} onClick={() => this.setVisible(this.state.visible)} style={{marginTop:'3.1rem'}}>
            <span className="fa fa-eye"></span> &nbsp;&nbsp;
            Preview e-Consent
        </Button>

        <Modal
          centered
          visible={this.state.visible}
          footer={false}
          onCancel={() => this.setVisible(this.state.visible)}
          className="modal-consent"
        >
            <div className="phone-preview-container-consent"
            >
                <div className="phone-content-header"/>
                <div className="phone-content">
                    <iframe className="iframe-preview" src={url == undefined ? "" : url.URL} scrolling="no" frameBorder={0} border="0" allowtransparency="true"></iframe>
                </div>
            </div>
        </Modal>
      </>
    );
  }
}

