import React, { Component } from 'react';

export class Logout extends Component {
    constructor(props) {
        super(props);

        this.ClearCookie = this.ClearCookie.bind(this);

    }


    componentDidMount() {
        //this.ClearCookie();
        setTimeout(() => {
            this.ClearCookie();
        }, 500);
    }

    ClearCookie() {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time
        now.setTime(expireTime);
        document.cookie = "__AntiXsrfUerkey=" + "" + ";expires=" + now.toGMTString() + ";path=/";
        this.props.history.push("/login");
        localStorage.clear();
    }

    render() {
        return (
            <div className="app flex-row align-items-center">

            </div>
        );
    }
}

export default Logout;
