import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber,
    Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Switch
} from 'antd';
import { apiClient } from '../../../../api';
import{root} from '../../../../config';
import { token, CheckTokenExp } from '../../../../config';

import SelectSender from '../../../../components/Ants/Select/SelectSender';
import SelectPinLenght from '../../../../components/Ants/Select/SelectPinLenght';

export default class Detail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            APP_ID: "",
            APP_NAME: "",
            Sender: "",
            Prefix: "",
            ExpireSec: "",
            Pin_length: "",
            OTP_ID: "",
            Ref_code: "",
            Code: "",
            Mobile: "",
            Send_Msg: "",
            CreateDate: "",
            UpdateDate: "",
            ExpireDate: "",
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        var params = {};
        if (this.props.location.search) {
            var parts = this.props.location.search.substring(1).split('&');
            for (var i = 0; i < parts.length; i++) {
                var nv = parts[i].split('=');
                if (!nv[0]) continue;
                params[nv[0]] = nv[1] || true;
            }
            this.getData(params.id);
        }
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                OTP_ID: value
            });

            apiClient.post('OTP/TEST/INFO', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        APP_ID: json.APP_ID,
                        APP_NAME: json.APP_NAME,
                        Sender: json.Sender,
                        Prefix: json.Prefix,
                        ExpireSec: json.ExpireSec,
                        Pin_length: json.Pin_length,
                        OTP_ID: json.OTP_ID,
                        Ref_code: json.Ref_code,
                        Code: json.Code,
                        Mobile: json.Mobile,
                        Send_Msg: json.Send_Msg,
                        CreateDate: json.CreateDate,
                        UpdateDate: json.UpdateDate,
                        ExpireDate: json.ExpireDate
                    });


                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        APP_ID: "",
                        APP_NAME: "",
                        Sender: "",
                        Prefix: "",
                        ExpireSec: "",
                        Pin_length: "",
                        OTP_ID: "",
                        Ref_code: "",
                        Code: "",
                        Mobile: "",
                        Send_Msg: "",
                        CreateDate: "",
                        UpdateDate: "",
                        ExpireDate: ""
                    });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    render() {

        const { APP_ID, APP_NAME, Sender, Prefix, ExpireSec, Pin_length, OTP_ID, Ref_code,
            Code, Mobile, Send_Msg, CreateDate, UpdateDate, ExpireDate } = this.state;

        const styleMarginBottom = { marginBottom: 16 };
        return (
            <div className="animated fadeIn">
                <form>
                    <PageHeader title={"OTP | Configuration | " + APP_NAME}>
                        <Row gutter={16} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>App OTP Name</label>
                                <Input placeholder="App OTP Name" value={APP_NAME} />
                            </Col>
                        </Row>
                    </PageHeader>

                    <div className="content">
                        <Card className="card-content" style={{ minHeight: 200 }} >
                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>App OTP
                                </label>
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Sender
                                </label>
                                    <label className="red">*</label>
                                    <Input value={Sender} />
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Pin Length
                                </label>
                                    <label className="red">*</label>
                                    <Input value={Pin_length} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <Row gutter={32} type="flex" justify="end">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <Checkbox checked={Prefix ? true : false}>Prefix</Checkbox>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ textAlign: 'right' }}>
                                            <label style={{ color: '#7b7b7b7b' }}>Minimum 3 digits and format 0-9 or A-Z </label>

                                        </Col>
                                    </Row>
                                    <Input placeholder="Prefix" value={Prefix} />
                                </Col>
                            </Row>


                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Expire Duration
                                </label>
                                    <label className="red">*</label>
                                    <label style={{ color: '#7b7b7b7b' }}>Minimum 60 second or maximum 600 Secounds</label>

                                    <Input placeholder="Expire Duration" value={ExpireSec} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>

                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>OTP
                                </label>
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>OTP ID
                                </label>
                                    <Input placeholder="Sender" value={OTP_ID} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Mobile
                                </label>
                                    <Input placeholder="Mobile" value={Mobile} />
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Ref Code
                                </label>
                                    <Input placeholder="Ref Code" value={Ref_code} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>OTP Code
                                </label>
                                    <Input placeholder="OTP Code" value={Code} />
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Create Date
                                </label>
                                    <Input placeholder="Create Date" value={CreateDate} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Update Date
                                </label>
                                    <Input placeholder="Update Date" value={UpdateDate} />
                                </Col>
                            </Row>

                            <Row gutter={32} style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                    <label className=" control-label require" style={{ fontWeight: 700 }}>Expire Date
                                </label>
                                    <Input placeholder="Expire Date" value={ExpireSec} />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={10}>

                                </Col>
                            </Row>

                            <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={20} style={{ textAlign: 'right' }}>
                                    <Link to={'/otp/log/'}>
                                        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                                            <span className="antsicon-pop-up-confirm stroke05 size14"></span> &nbsp;&nbsp;
                                            Ok
                                    </Button>
                                    </Link>

                                </Col>
                            </Row>

                        </Card>
                    </div>
                </form>
            </div>
        );
    }
}


