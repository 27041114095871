import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, } from 'antd';

export default class TableAnalyze extends Component {

    constructor(props) {
        super(props);

        this.state = {

            columns: [
                {
                    title: 'Sender Name',
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: 'Schedule',
                    dataIndex: 'schedule',
                    key: 'schedule',
                },
            ],
        };

    }

    render() {
        const { columns } = this.state;
        const { data } = this.props;
        return (
            <Table
                pagination={false}
                size="middle"
                columns={columns}
                dataSource={data}
                className="table-ants"
            />
        );
    }
}


