import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col } from 'antd';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateTimeFormat = 'DD/MM/YYYY HH:mm';

export default class CardIssueReport extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }


    render() {
        const { subject, date1, message, date2, support, loading } = this.props;
        const styleMarginBottom = { marginBottom: 16 };
        return (
            <Card style={{ minHeight: 200 }}>

                <Row gutter={16} loading={loading}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={16} style={styleMarginBottom} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}> Subject :</label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                {subject}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <label className=" control-label require" style={{ color: "#7b7b7b7b" }}> {date1 ? moment(date1).format(dateTimeFormat) : ""}</label>
                            </Col>
                        </Row>

                        <Row gutter={16} style={styleMarginBottom} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}> Message :</label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                 {message}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>

                            </Col>
                        </Row>

                        <Row gutter={16} style={styleMarginBottom} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}> Support :</label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                {support}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                <label className=" control-label require" style={{ color: "#7b7b7b7b" }}> {date2 ? moment(date2).format(dateTimeFormat) : ""}</label>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Card>
        );
    }
}


