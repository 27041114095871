import React, { Component } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

import defaultStyle from './Style/defaultStyle';
import defaultMentionStyle from './Style/defaultMentionStyle';

export default class MultipleTrigger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contents: "",
            data: [],
            clearstate: false,
            placeholder: "Enter your message or select from existing message templates."
        }

    }

    componentWillReceiveProps(nextProps) {
        const res = nextProps.data.map(data => {
            return { id: data };
        });

        this.setState({ data: res, contents: nextProps.contents });

        //if (nextProps.clearstate !== this.state.clearstate) {

        //    this.setState({ clearstate: false });
        //    nextProps.clearstate ? this.setState({ contents: "" }) : "";
        //}
    }

    handleChange(value, useonchange) {
        if (useonchange == true) {
            this.setState({
                contents: value.target.value.substring(0, 1600)
            }, function () { this.props.onchange(this.state.contents.substring(0, 1600)) })
        }
    };

    render() {
        const { contents, placeholder } = this.state;
        return (
            <div className="multiple-triggers">
                <MentionsInput
                    className="comments-textarea"
                    value={contents}
                    onChange={(value) => this.handleChange(value, this.props.useonchange)}
                    onMouseUp={this.props.onmouseup}
                    onKeyUp={this.props.onmouseup}
                    onKeyPress={this.props.onmouseup}
                    style={defaultStyle}
                    placeholder={this.props.placeholder ? this.props.placeholder : placeholder}
                >
                    <Mention
                        markup="{__id__}"
                        trigger="@"
                        data={this.state.data}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                            index,
                            focused
                        ) => (
                                <div className={`user ${focused ? 'focused' : ''}`}>
                                    {highlightedDisplay} 
                                </div>
                            )}
                        style={defaultMentionStyle}
                    />

                </MentionsInput>
            </div>
        );
    }
}