import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  PageHeader,
  Select,
  Card,
  Row,
  Col,
  Divider,
  Spin,
  Button,
  notification,
} from "antd";
import Individual from "./Individual";
import Enterprise from "./Enterprise";
import { apiClient, apiSender } from "../../../api.js";
import {
  getAccId,
  getUsername,
  CheckTokenExp,
  getEmail,
  getMobile,
  getPackage,
  getCompany,
} from "../../../config";

const { Option } = Select;

export default function RequestSender(props) {
  let history = useHistory();
  const [countSender, setCountSender] = useState(0);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(1); //1=individual, 2=enterprise
 
  const [data, setData] = useState({
    RSD_FIRST_NAME: "",
    RSD_LAST_NAME: "",
    RSD_EMAIL: getEmail() || "",
    RSD_CONTACT_NAME: "",
    RSD_CONTACT_NUMBER: getMobile() || "",
    RSD_POSITION: "",
    RSD_WEBSITE: "",
    RSD_IDEN_DOC: [],
    senderNamelength: 0,
    senderNameCount: 11,
    RSD_LIST_SENDER_NAME: "",
    RSD_LIST_REASON_USE: [],
    RSD_LIST_REASON_USE_COMMENT: "",
    messageLength: 0,
    messageCount: 100,
    RSD_LIST_EX_TEXT: "",
    RSD_LIST_IS_WHITELIST: false,
    error_reasonUseComment: false,
    sig_img_indi: [],
    sig_img_enterP: [],
    RSD_CO_SEAL: [],
    RSD_LIST_USER_FLOW: null,
    senderlist: null,
    RSD_LIST_REQUEST: 1,
    sig_type: "1", //signature type upload
    sender_id: "",
  });

  const [errorText, setErrorText] = useState(false);

  const routes = [
    {
      path: "RequestSender",
      breadcrumbName: "Sender Name Registration",
    },
  ];

  const handleChangeType = (value) => {
    console.log(`selected ${value}`);
    setType(value);
  };

  const changeFile = (file, name) => {
    console.log("test change file", file, name);
    setData({
      ...data,
      [name]: file,
    });
  };

  const handleChangeInput = (e, name) => {
    if (name === "RSD_LIST_SENDER_NAME") {
      CountMessageNew(
        e.target.value,
        name,
        "senderNamelength",
        "senderNameCount"
      );
    } else if (name === "RSD_LIST_EX_TEXT") {
      CountMessageNew(e.target.value, name, "messageLength", "messageCount");
    } else if (name === "RSD_LIST_REASON_USE_COMMENT") {
      console.log("dd", e.target.value);
      setData({
        ...data,
        [name]: e.target.value,
      });
      if (/[^a-zA-Z\s]/.test(e.target.value)) {
        //thai
        setErrorText(true);
      } else {
        //eng
        setErrorText(false);
      }
    } else {
      setData({
        ...data,
        [name]: e.target.value,
      });
    }
  };

  const CountMessageNew = (message, name, textLength, textCount) => {
    let len = message.length;
    if (len <= data[textCount]) {
      setData({
        ...data,
        [name]: message,
        [textLength]: len,
      });
    }
  };

  const onChangeCheckBox = (e, name) => {
    console.log(`checked = ${e.target.checked}`);
    if (name === "RSD_LIST_IS_WHITELIST") {
      setData({
        ...data,
        [name]: e.target.checked,
        RSD_LIST_REQUEST: "2",
      });
    } else {
      setData({
        ...data,
        [name]: e.target.checked,
      });
    }
  };

  const handelChangeCheckboxGroup = (value, name) => {
    console.log("checked = ", value);
    let len = value.length;
    setData({ ...data, [name]: value });
  };

  const ClearData = () => {
    setData({
      senderNamelength: 0,
      senderNameCount: 11,
      messageLength: 0,
      messageCount: 100,
    });
  };

  const getSenderListCount = () => {
    var get_package = JSON.parse(getPackage());
    let _countSender = get_package.sender;
    console.log("get package: ", get_package);
    apiClient
      .post(
        "api/sender/GetSenderListCountActive",
        JSON.stringify({ accLogin: getAccId(), packageSender: _countSender }),
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log("response: ", response.data);
        if (response.data.statusCode === "200") {
          setCountSender(response.data.totalCount);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        setCountSender(2);
        console.log("error: ", error);
        //openNotification("error", error);
      });
  };

  const handleClickNext = () => {
    console.log("onclick next", data);
    console.log(data);

    // var get_package = JSON.parse(getPackage());
    let _countSender = 1;
    console.log("checkCreateSender", _countSender, 2);
    if (_countSender > 2) {
    console.log("why")
      openNotification(
        "warning",
        "You already reach to maximum allowed to a Demo version. Please purchase package use this function"
      );
    } else {
      //check required feild
      if (
        data.RSD_FIRST_NAME === "" ||
        data.RSD_LAST_NAME === "" ||
        (data.RSD_EMAIL === "" && type === 1) ||
        data.RSD_CONTACT_NAME === "" ||
        (data.RSD_CONTACT_NUMBER === "" && type === 1) ||
        (data.RSD_POSITION === "" && type === 2) ||
        data.RSD_WEBSITE === "" ||
        data.RSD_LIST_SENDER_NAME === "" ||
        data.RSD_LIST_REASON_USE.length === 0 ||
        data.RSD_LIST_EX_TEXT === ""
      ) {
        //กรุณา กรอกข้อมูลให้ครบถ้วน
        openNotification(
          "warning",
          "Plase fill the information in require box"
        );
      } else if (
        data.RSD_IDEN_DOC.length === 0 ||
        (data.RSD_CO_SEAL.length === 0 && type === 2) ||
        (data.sig_img_indi.length === 0 && type === 1) ||
        (data.sig_img_enterP.length === 0 && type === 2)
      ) {
        //check file upload
        openNotification("warning", "Please upload file!");
      } else if (
        data.RSD_LIST_REASON_USE.length > 0 &&
        data.RSD_LIST_REASON_USE.indexOf("5") > -1 &&
        data.RSD_LIST_REASON_USE_COMMENT === ""
      ) {
        openNotification("warning", "Plase fill in reason use other");
      } else {
        onSaveData();
      }
    }
  };

  const openNotification = (type, message) => {
    notification[type]({
      className: `reqSenderNoti ${type}`,
      message: message ? message : "Required field",
      description: message,
      // duration: 0,
    });
  };

  const onSaveData = () => {
    if (!CheckTokenExp()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("iden_doc", data.RSD_IDEN_DOC[0]);

      if (type === 1) {
        //individual
        formData.append("sig_img", data.sig_img_indi[0]);
      } else {
        //enterprise
        formData.append("com_seal", data.RSD_CO_SEAL[0]);
        formData.append("sig_img", data.sig_img_enterP[0]);
      }

      console.log(formData.get("iden_doc"));
      console.log(formData.get("sig_img"));

      let datasender = JSON.stringify({
        id: data.sender_id,
        name: data.RSD_LIST_SENDER_NAME,
        reason_use: data.RSD_LIST_REASON_USE.toString(),
        reason_Other: data.RSD_LIST_REASON_USE_COMMENT,
        sender_ex: data.RSD_LIST_EX_TEXT,
        request_whitelist: data.RSD_LIST_IS_WHITELIST,
        request_sender: data.RSD_LIST_REQUEST,
      });

      // let datasenderMock = JSON.stringify({
      //   id: 1,
      //   name: "test",
      //   reason_use: "test",
      //   reason_Other: "test",
      //   sender_ex: "test",
      //   request_whitelist: 0,
      //   request_sender: 1,
      // });

      console.log(datasender);

      formData.append("account", getAccId());
      formData.append("username", getUsername());
      formData.append("request_type", type);
      formData.append("firstName", data.RSD_FIRST_NAME);
      formData.append("lastName", data.RSD_LAST_NAME);
      formData.append("email", data.RSD_EMAIL);
      formData.append("contact_name", data.RSD_CONTACT_NAME);
      formData.append("contact_number", data.RSD_CONTACT_NUMBER);
      formData.append("position", data.RSD_POSITION);
      formData.append("website", data.RSD_WEBSITE);
      formData.append("datasender_list", datasender);
      formData.append("sig_type", 1);

      apiClient
        .post("api/sender/save", formData, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((response) => {
          console.log("response: ", response.data);
          if (response.data.statusCode === "200") {
            openNotification("success", response.data.statusDesciption);
            history.push("/communication/sender/list");
          } else if (response.data.statusCode === "901") {
            openNotification("warning", response.data.statusDesciption);
          } else {
            openNotification("error", response.data.statusDesciption);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("error", error);
          setLoading(false);
        });
    } else {
      window.location.href = "logout";
    }
  };

  useEffect(() => {
    var reqSenderList = JSON.parse(sessionStorage.getItem("requestSenderList"));
    console.log("check reqWhiteList: ", reqSenderList);
    let senderId = "";
    for (let obj in reqSenderList) {
      console.log(obj, reqSenderList[obj]);
      if (obj === "sender_id") {
        senderId = reqSenderList[obj];
      }
      if (obj === "request-whiteList" && Number(reqSenderList[obj]) === 3) {
        setData({
          ...data,
          RSD_LIST_REQUEST: Number(reqSenderList[obj]),
          RSD_LIST_IS_WHITELIST: true,
          sender_id: senderId,
        });
      }
    }
    // getSenderListCount();
  }, []);

  useEffect(() => {
    var _getCompany = getCompany().trim().split(" ");
    console.log("_getCompany: ", _getCompany);
    if(_getCompany.length > 0){
      setData({
        ...data,
        RSD_FIRST_NAME: _getCompany[0],
        RSD_LAST_NAME: _getCompany.length >=2 ? _getCompany[1] : "",
      });
    }
  }, [])

  return (
    <div className="animated fadeIn">
      <PageHeader breadcrumb={{ routes }}>
        <Row gutter={8} type="flex" justify="end">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ textAlign: "right" }}>
              <Select
                defaultValue={type}
                style={{ width: 150 }}
                suffixIcon={<span className="fa fa-angle-down" />}
                onChange={handleChangeType}
              >
                <Option value={1}>Individual Form</Option>
                <Option value={2}>Enterprise Form</Option>
              </Select>
            </div>
          </Col>
        </Row>
      </PageHeader>
      <div className="content" style={{ minHeight: 200 }}>
        <Spin spinning={loading}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col span={20}>
              <Card
                className="card-content"
                style={{
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                }}
              >
                {type === 1 ? (
                  <Individual
                    data={data}
                    handleChangeInput={handleChangeInput}
                    handelChangeCheckboxGroup={handelChangeCheckboxGroup}
                    onChangeCheckBox={onChangeCheckBox}
                    errorText={errorText}
                    changeFile={changeFile}
                  />
                ) : (
                  <Enterprise
                    data={data}
                    handleChangeInput={handleChangeInput}
                    handelChangeCheckboxGroup={handelChangeCheckboxGroup}
                    onChangeCheckBox={onChangeCheckBox}
                    errorText={errorText}
                    changeFile={changeFile}
                  />
                )}

                <Divider />

                <div style={{ float: "right" }}>
                  <Button style={{ marginRight: "8px" }} onClick={ClearData}>
                    Clear Data
                  </Button>
                  <Button onClick={handleClickNext}>Next</Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
}
