import React, { Component } from 'react';


import { Route, Router, Switch } from 'react-router';
import 'antd/dist/antd.css';

// Styles
// Import Flag Icons Set
//import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';
// Temp fix for reactstrap
import './scss/core/_dropdown-menu-right.scss';

import {Helmet} from "react-helmet";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Login } from './views/Pages/Login/';
import { Logout } from './views/Pages/Logout/Logout';
import { Console } from './Console';
import Full from './containers/Full/';
import { Title } from "./config";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <>
    <Helmet>
                <link rel="shortcut icon" href={Title} />
              </Helmet>
      <Layout>
        <Switch>
      
          <Route exact path='/counter' component={Counter} />
          <Route exact path='/fetch-data' component={FetchData} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route path='/' component={Full} />

        </Switch>
      </Layout>
      </>
    );
  }
}
