/* eslint-disable */
import React, { Component } from 'react';
import { Select } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';

const { Option } = Select;

export default class SelectConsent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select: undefined,
            list: [],
        };

        // this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        // this.getData("");
    }

    // getData(value) {
    //     if (!CheckTokenExp()) {
    //         this.state.list.length > 0 ? "" : this.setState({ loading: true });
    //         apiClient.get('api/Campaign/GetConsent'
    //             , {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`,
    //                     'Content-type': 'application/json; charset=UTF-8'
    //                 }
    //             })
    //             .then(data => {
    //                 var json = data.data;
    //                 this.setState({ list: json });
    //                 this.setState({ loading: false });
    //             })
    //             .catch(error => {
    //                 this.setState({ loading: false });
    //             });
    //     } else {
    //         window.location.href = "logout";
    //     }
    // }

    render() {
        const { loading, list } = this.state;
        const { placeholder, select, setStateByProps,disabled,option } = this.props;
        return (
            <>
                <Select
                    showSearch
                    loading={loading}
                    name="select"
                    // onSearch={(value) => { this.getData(value) }}
                    onChange={(value) => { setStateByProps("CAMP_CONSENT_ID", value) }}
                    value={select == null ? undefined : select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "Select e-Consent"}
                    disabled={!disabled}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        option?.map((item, index) =>
                            <Option key={index} value={item.ID}>{item.TEXT}</Option>
                        )
                    }
                </Select>
            </>
        );
    }
}


