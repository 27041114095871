import React, {Component} from 'react';

export default class Communication extends Component {
  render() {
      return (
          <div className="animated fadeIn">
              Report Communication
          </div>
      );
  }
}


