
/* eslint-disable */
import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectDomain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select: null,
            list: [],
            modalVisible: false,
            defaultValue:null
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData("");
    }

    getData(value) {
        this.state.list.length > 0 ? "" : this.setState({ loading: true });
            apiClient.get('api/Campaign/GetDomainList'
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ list: data.data });
                        this.props.setStateByProps("lstDomain", data.data);
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                        this.props.setStateByProps("lstDomain", []);
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
    }

    render() {
        const { loading, list, modalVisible, defaultValue } = this.state;
        const { placeholder, mode, disablestatus, select, setStateByProps, by, style } = this.props;
        return (
            <>
                <Select
                    mode={mode ? mode : "default"}
                    //allowClear={mode ? true : false}
                    disabled={disablestatus == true ? true : false}
                    
                    style={style ? style : { width: 150 }}
                    loading={loading}
                    name="shorten_domain"
                    //onSearch={(value) => { this.getData(value) }}
                    onChange={(value) => { setStateByProps("selected_domain", value) }}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    //placeholder={placeholder ? placeholder : "Enter new sender name or select from options here."}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                >

                    {
                        list.map((item, index) =>
                            <Option key={item.ID} value={item.TEXT}>{item.TEXT}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


