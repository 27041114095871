/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Input, Checkbox } from 'antd';
import { Link } from "react-router-dom";

export default class CardTag extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { title, member, id, setStateByProps, handleOnSelectRowDelete, PRG_NAME } = this.props;

        return (
            <Card className="card-tag">
                {
                    title ?
                        <div>
                            <Checkbox key={id} style={{ display: 'block', marginTop: '-10px', marginLeft: '-10px' }}
                                onChange={(e) => handleOnSelectRowDelete(id, e.target.checked)}
                            />
                            < Row gutter={16} type="flex" justify="center" style={{ textAlign: "center", marginBottom: '-25px' }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Link to={'/people/tag/detail?id=' + id}
                                        style={{ fontSize: 16, color: '#000000', paddingRight: 10, textDecoration: 'none' }} >
                                        <h1>{title}</h1>
                                    </Link>
                                </Col>
                            </Row >

                            <Row gutter={16} type="flex" justify="center" style={{ textAlign: "center", color: '#7b7b7b' }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    Member : {member}
                                </Col>
                            </Row>

                            <span className="fa fa-trash-o delete-tag"
                                style={{ cursor: 'pointer', float: 'right', display: 'block', marginRight: '-10px', marginTop: 10 }}
                                onClick={() => { this.props.warningModal(true, "Do you want to delete this tag?", "Deleted tag successfully."), handleOnSelectRowDelete(id, true), setStateByProps("action", "delete") }}>
                            </span> &nbsp;&nbsp;
                        </div>
                        :
                        <div>
                            <Row gutter={16} type="flex" justify="center" style={{ textAlign: "center" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                                    <Input placeholder="Tag" value={PRG_NAME} onChange={(e) => setStateByProps("PRG_NAME", e.target.value)} />
                                </Col>
                            </Row>

                            <Row gutter={16} type="flex" justify="center" style={{ textAlign: "center", marginTop: 16 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    {
                                        PRG_NAME ?
                                            <span className="fa fa-plus-circle" style={{ fontSize: 50, color: '#7b7b7b', cursor: 'pointer' }}
                                                onClick={() => {
                                                    this.props.warningModal(true, "Do you want to create this tag?", "Created tag successfully."),
                                                        setStateByProps("action", "add")
                                                }}></span>
                                            :
                                            <span className="fa fa-plus-circle" style={{ fontSize: 50, color: '#7b7b7b', cursor: 'no-drop' }}
                                               ></span>
                                    }
                                    &nbsp;&nbsp;
                                </Col>
                            </Row>

                            <span className="delete-tag"
                                style={{ float: 'right', display: 'block', marginRight: '-10px', marginTop: 10 }}
                            >
                            </span> &nbsp;&nbsp;
                        </div>
                }
            </Card>
        );
    }
}


