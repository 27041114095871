import React, { Component } from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default class SelectParameters extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            select: [],
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.list !== this.state.list && nextProps.list) {
            this.setState({ list: nextProps.list });
        }
    }

    render() {
        const { loading, select, list } = this.state;
        const { placeholder, handleChangevariable } = this.props;

        return (
            <Select
                style={{ width: 150 }}
                loading={loading}
                name="select"
                onChange={(value) => { handleChangevariable(value) }}
                value={select}
                placeholder={placeholder ? placeholder : <div style={{ color: '#666666', fontWeight: 700 }}>Add Parameter</div>}
                suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    list.map((item,index) =>
                        <Option  key={index}  value={item}>{item}</Option>
                    )
                }
            </Select>
        );
    }
}


