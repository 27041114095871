/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Select,
  Row,
  Col,
  Form,
  Tooltip,
  Spin,
} from "antd";

import { apiClient } from "../../../api";
import { root } from "../../../config";
import {
  token,
  validateEmail,
  CheckTokenExp,
} from "../../../config";
import ModalSuccess from "../../../components/Ants/Modal/ModalSuccess";
import ModalWarning from "../../../components/Ants/Modal/ModalWarning";
import ModalWarningCustom from "../../../components/Ants/Modal/ModalWarningCustom";
import ModalError from "../../../components/Ants/Modal/ModalError";
import { tokenAuth } from "../../../components/Ants/TooltipText/Text";

const { Option } = Select;

export default class TokenAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningModalVisible: false,
      successModalVisible: false,
      loading: false,
      message: "",
      messageSuccess: "",
      screenwidth: 0,
      formLayout: "horizontal",
      configCreditId: 0,
      tokenAuth: 'asd551d5aw1d5a16d2aw6d1awwd5a1w5d12397-7fsa=',
      copied: false,
      amount: undefined,
      email: [],
      statusconfig: 0,
      emailError: true,
      warningModalOpen: false,
      errorModalVisible: false,
      messageError: "",
    };

    this.warningModal = this.warningModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.modalcountDown = this.modalcountDown.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setStateByProps = this.setStateByProps.bind(this);
    this.reissueToken = this.reissueToken.bind(this);
    this.configUpdate = this.configUpdate.bind(this);
    this.getData = this.getData.bind(this);
    this.warningModalCustom = this.warningModalCustom.bind(this);
    this.onSubmitWarning = this.onSubmitWarning.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard = () => {
    const text = this.state.tokenAuth;

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        this.setState({ copied: true });
        setTimeout(() => {
          this.setState({ copied: false });
        }, 2000);
      }).catch(err => {
        console.error("Failed to copy: ", err);
      });
    } else {
      // Fallback to execCommand for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.setState({ copied: true });
          setTimeout(() => {
            this.setState({ copied: false });
          }, 2000);
        } else {
          console.error("Fallback: Oops, unable to copy");
        }
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  }

  componentDidMount() {
    this.getData();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    setTimeout(() => {
      var root = document.getElementById("root");
      if (root) {
        var e = document.getElementById("main");
        if (e) {
          var scroll = root.offsetHeight > window.innerHeight;
          this.setState({
            screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124,
          });
        }
      }
    }, 500);
  }

  warningModal(value, message, messageSuccess) {
    this.setState({ warningModalVisible: value, message, messageSuccess });
  }

  successModal(value) {
    if (this.state.action == "configcredit") {
      if (this.state.configCreditId == 0) {
        this.setState({ action: "" });
        this.configCreate();
      } else {
        this.setState({ action: "" });
        this.configUpdate();
      }
    }

    this.setState({ successModalVisible: value });

    let modalcontent = document.querySelectorAll(
      ".ant-modal-contentant-modal-content-hide"
    );
    for (var i = 0; i < modalcontent.length; i++) {
      modalcontent[i].className = "ant-modal-content";
    }
  }

  modalcountDown() {
    let secondsToGo = 2;
    const timer = setInterval(() => {}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.setState({ successModalVisible: false });
    }, secondsToGo * 1000);
  }

  setStateByProps(parameters, value) {
    value ? value : "";

    if (parameters == "email") {
      this.setState({
        emailError: true,
      });
      if (value.length > 0) {
        var i;
        var error = true;
        for (i = 0; i < value.length; i++) {
          if (validateEmail(value[i])) {
            this.setState({ [parameters]: value });
          } else {
            error = false;
          }
        }
        if (!error) {
          this.setState({ emailError: false, [parameters]: value });
        }
      } else {
        this.setState({ [parameters]: value });
      }
    } else {
      this.setState({ [parameters]: value });
    }
  }

  getData() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });
      let req = JSON.stringify({

      });
      apiClient
        .post("api/token/getCurrentToken", req
        , {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          }
        })
        .then((data) => {
          this.setState({
            tokenAuth : data.data.token
          });

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({
            emailError: true,
          });
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  reissueToken() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({

      });

      apiClient
        .post("api/token/generateTokenForApi", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data
          if (json.status === 200) {
            this.getData();
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }

  configUpdate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({
        configId: this.state.configCreditId,
        credit: this.state.amount,
        email: this.state.email,
        flag: this.state.statusconfig === 1 ? true : false,
      });

      apiClient
        .post("api/users/update", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          if (json.Status === "error") {
            this.setState({
                errorModalVisible: true,
                messageError: "Save config credit unsuccessful!",
              });
          } else {
            this.setState({ successModalVisible: true });
            setTimeout(() => {
                this.setState({ successModalVisible: false });
            }, 3000);
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }

  warningModalCustom(value) {
    this.setState({ warningModalOpen: value });
  }

  onSubmitWarning() {
    if (this.state.action === "reissueToken") {
        this.setState({ action: "" });
        this.reissueToken();
    }
  }

  errorModal(value) {
    this.setState({ errorModalVisible: value });
  }

  render() {
    const {
      loading,
      message,
      messageSuccess,
      warningModalVisible,
      successModalVisible,
      formLayout,
      amount,
      email,
      emailError,
      statusconfig,
      warningModalOpen,
      errorModalVisible,
      messageError
    } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : null;
    return (
      <div className="content mt-4" id="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={24}>
              <Form.Item
                label={
                  <label>
                    Access Token <Tooltip placement="rightTop" title={tokenAuth}>
                                <span className="fa fa-info-circle ants-gray-low size14"></span>
                            </Tooltip>
                  </label>
                }
                {...formItemLayout}
              >
                <div className="d-flex" style={{ wordBreak: 'break-word', whiteSpace: 'normal'}}>
                  <p style={{ margin: 0 }}>{this.state.tokenAuth}</p>
                  {this.state.tokenAuth ? <Button className="ml-3 mr-2" onClick={this.copyToClipboard}>
                    {!this.state.copied && <span className="fa fa-copy mr-1"></span>}
                    {this.state.copied ? "Copied" : "Copy"}
                  </Button> : null}
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        warningModalOpen: true,
                        message: "Do you want to reissue this token?",
                        messageSuccess: "Reissue token successfully.",
                        action: "reissueToken",
                      });
                    }}
                  >
                    {this.state.tokenAuth ? "Regenerate Token" : "Generate Token"}
                  </Button>
                </div>
                
              </Form.Item>

            </Col>
          </Row>
        </Spin>

        <ModalWarning
          loading={loading}
          warningModalVisible={warningModalVisible}
          warningModal={this.warningModal}
          successModal={this.successModal}
          modalcountDown={this.modalcountDown}
          message={message}
          messageSuccess={messageSuccess}
        />

        <ModalSuccess
          loading={loading}
          successModalVisible={successModalVisible}
          successModal={this.successModal}
          messageSuccess={messageSuccess}
        />

        <ModalWarningCustom
          loading={loading}
          warningModalOpen={warningModalOpen}
          warningModal={this.warningModalCustom}
          onSubmit={this.onSubmitWarning}
          message={message}
        />

        <ModalError
          loading={loading}
          errorModalVisible={errorModalVisible}
          errorModal={this.errorModal}
          messageError={messageError}
        />
      </div>
    );
  }
}
