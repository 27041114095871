import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {Tag,Tooltip,Card,Skeleton,Checkbox,Button,Spin,Popover,Menu,Pagination,Radio,Icon} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import {token,capitalizeFLetter,deepEqual,CheckTokenExp} from '../../../config';

import ModalSuccess from '../Modal/ModalSuccess';
import ModalWarning from '../Modal/ModalWarning';
moment.tz.setDefault("Asia/Bangkok");

const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
class CardReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showbottom: false,
            reportLoading: '',
            loading: false,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            action: "",
            loadingcloud: false,   
            visible: false
        };
        this.geticonstatus = this.geticonstatus.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
    }

    geticonstatus() {
        let status = this.props.status;
        let icon = "";
        let color = "";
        switch (status) {

            case 0: color = "#FED531"; icon = "fa fa-stop-circle"; break;
            case 1: color = "#FED531"; icon = "fa fa-clock-o"; break;
            case 2: color = "#5bd2a6"; icon = "fa fa-check-circle"; break;
            case 4: color = "#1890ff"; icon = "fa fa-refresh"; break;
            case 5: color = "#d25c5b"; icon = "fa fa-times-circle"; break;
            case 9: color = "#d25c5b"; icon = "fa fa-times-circle"; break;
        }
        return (<i style={{color: color}} className={icon}></i>)
    }
    getname() {
        let status = this.props.status;
        let name = "";
        let color = "";
        switch (status) {
            case 0: color = "#FED531"; name = "PENDING"; break;
            case 1: color = "#FED531"; name = "IN PROGRESS"; break;
            case 2: color = "#5bd2a6"; name = "COMPLETED"; break;
            case 4: color = "#1890ff"; name = "RECURRING"; break;
            case 5: color = "#d25c5b"; name = "EXPIRED"; break;
            case 9: color = "#d25c5b"; name = "ERROR"; break;
        }
        return (<span style={{color: color}}>{name}</span>)
    }
    warningModal(a,b,c) {
        
        this.setState({warningModalVisible: false});
    }
    successModal(a) {

        switch (this.state.action) {
            case "copy": if (a) window.location.href = "report/create?id=" + window.btoa(unescape(encodeURIComponent(this.props.id)));
                break;
            case "delete": if (a) {
                this.onDeleteItem(this.props.id);
                this.setState({warningModalVisible: false,successModalVisible: true});
                this.props.onDeleteReport(this.props.id);
            }
                break;

        }
    }
    onDeleteItem(key) {
        if (!CheckTokenExp()) {
            this.setState({loading: true});

            let req = JSON.stringify({
                key: [key]
            });

            apiClient.post('api/report/delete',req
                ,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                })
                .catch(error => {
                    this.setState({loading: false});
                });
        } else {
            window.location.href = "logout";
        }

    }
    modalcountDown() {

    }
    handleVisibleChange = visible => {
        this.setState({visible});
    };
    downloadFile(item) {
        this.setState({loadingcloud: true}) 
   
        if (!CheckTokenExp()) {
            this.setState({ reportLoading: item });
            apiClient.get('api/file/getFilename/' + item
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                .then(data => {
                    var filename = data.data;

                    apiClient.get('api/file/download/' + item
                        , {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-type': 'application/json; charset=UTF-8'
                            },
                            responseType: 'blob'
                        })
                        .then(data => {
                            var json = data.data;

                            const url = window.URL.createObjectURL(new Blob([json]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = filename;
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            this.setState({ reportLoading: '' });
                            this.setState({ loadingcloud: false });
                        })
                        .catch(error => {
                            this.setState({ reportLoading: '' });
                            this.setState({ loadingcloud: false });
                        });
                })
                .catch(error => {
                    this.setState({ reportLoading: '' });
                    this.setState({ loadingcloud: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }
    render() {
        const {loading,warningModalVisible,message,} = this.state;
        return (<Card>
            <div className="Log-card">
                <div className="ant-row">
                    <div className="ant-col-sm-2 ant-col-sm-offset-0 ant-col-xs-4 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                            {

                                this.props.fileId ?     
                                    this.state.loadingcloud ?  
                                        <Icon type="loading" style={{marginLeft: "15px"}}/>      
                                        :
                                        <span className="fa fa-cloud-download" title={this.props.total_file+" file"+(this.props.total_file>1?"s":"")+this.props.total_size} style={{fontSize: 16,color: '#7b7b7b',cursor: 'pointer',marginLeft: "15px"}} onClick={() => this.downloadFile(this.props.fileId,this.props.name)}> &nbsp;&nbsp; </span>
                                        :
                                    <span className="fa fa-cloud-download" style={{fontSize: 16,color: '#b7b7b7',cursor: 'not-allowed',marginLeft: "15px"}}> &nbsp;&nbsp; </span>
                            }
                        </div>

                        
                    </div>
                    <div className="ant-col-sm-2 ant-col-sm-offset-0 ant-col-xs-20 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                          {this.props.width<576?  <div className="log-lable ant-row">Total File(Size)</div>:null }
                            <div className="log-value ant-row" > { this.props.total_file>0? this.props.total_file+"("+this.props.total_size+")":"-"}</div>
                        </div>
                    </div>
                    <div className="ant-col-sm-5 ant-col-sm-offset-0 ant-col-xs-20 ant-col-xs-offset-4  pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                          {this.props.width<576?  <div className="log-lable ant-row">Name</div>:null }
                            <div className="log-value ant-row" > {this.props.name}</div>
                        </div>
                    </div>
                    <div className="ant-col-sm-3 ant-col-sm-offset-0 ant-col-xs-20 ant-col-xs-offset-4 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                        {this.props.width<576?  <div className="log-lable ant-row">Type</div>:null }
                            <div className="log-value ant-row" > {this.props.type}</div>
                        </div>
                    </div>
                    <div className="ant-col-sm-3 ant-col-sm-offset-0 ant-col-xs-20 ant-col-xs-offset-4 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                        {this.props.width<576?  <div className="log-lable ant-row">Status</div>:null }
                            <div className="log-icon">
                                {this.geticonstatus()}
                            </div>
                            <div className="log-value" style={{color: "#9fb1ae"}} >
                                {this.getname()}
                            </div>
                        </div>
                    </div>
                    <div className="ant-col-sm-3 ant-col-sm-offset-0 ant-col-xs-20 ant-col-xs-offset-4 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                        {this.props.width<576?  <div className="log-lable ant-row">Schdule Date</div>:null }
                            <div className="log-value ant-row" > {this.props.schduleDate}</div>
                        </div>
                    </div>
                    <div className="ant-col-sm-3 ant-col-sm-offset-0 ant-col-xs-20 ant-col-xs-offset-4 pr-1 pl-1 overflow-hidden">
                        <div className="ant-row">
                        {this.props.width<576?  <div className="log-lable ant-row">Complete Date</div>:null }
                            <div className="log-value ant-row" > {this.props.completeDate}</div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showbottom ?

                        <div className="ant-row">
                            <div className="ant-col-24 Log-panal">
                                {
                                    eval(this.props.filter).map((item,index) => (
                                        <div key={index} className="ant-col-8 pr-1 pl-1 overflow-hidden">
                                            <div className="ant-row">
                                                <div className="log-lable ant-row">{item.rpt_display}</div>
                                                <div className="log-value ant-row" title={item.rpt_display_value} style={{display:"block",overflow:"hidden"}}>
                                                    {item.rpt_display_value}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    this.props.email?  <div  className="ant-col-8 pr-1 pl-1 overflow-hidden">
                                    <div className="ant-row">
                                        <div className="log-lable ant-row">RECIPENTS</div>
                                        <div className="log-value ant-row" >
                                            {this.props.email}
                                        </div>
                                    </div>
                                </div>:null
                                }
                                 {
                                    this.props.oneTime||this.props.recurrence?  <div  className="ant-col-8 pr-1 pl-1 overflow-hidden">
                                    <div className="ant-row">
                                        <div className="log-lable ant-row">{this.props.oneTime?"ONE-TIME":"RECURRENCE"}</div>
                                        <div className="log-value ant-row" >
                                            {this.props.oneTime?this.props.oneTime:this.props.recurrence}
                                        </div>
                                    </div>
                                </div>:null
                                }
                                {
                                    this.props.export?  <div  className="ant-col-8 pr-1 pl-1 overflow-hidden">
                                    <div className="ant-row">
                                        <div className="log-lable ant-row">FTP/sFTP SETTING</div>
                                        <div className="log-value ant-row" >
                                            YES
                                        </div>
                                    </div>
                                </div>:null
                                }
                               
                            </div>
                        </div>
                        : null}
                <div className="log-left-btn" >
                    {/* <Popover placement="bottomRight" visible={this.state.visible} onVisibleChange={this.handleVisibleChange} content={
                        <Menu
                            mode="vertical"
                        >
                            <Menu.Item key="1" style={{borderBottom: '1px solid #7b7b7b'}}
                                onClick={() => {
                                    this.setState({
                                        warningModalVisible: true,
                                        message: "Do you want to duplicate this Report?",
                                        action: "copy",
                                        visible: false,
                                    });
                                }}>
                                <span style={{cursor: 'pointer'}}><span className="fa fa-files-o ants-gray size14" ></span>&nbsp;&nbsp;  Duplicate</span>
                            </Menu.Item>
                            <Menu.Item key="2" style={{borderBottom: '1px solid #7b7b7b'}}
                                onClick={() => {
                                    this.setState({
                                        warningModalVisible: true,
                                        message: "Do you want to delete this Report?",
                                        messageSuccess: "Delete report successfully.",
                                        action: "delete",
                                        visible: false,
                                    });
                                }}>
                                <span style={{cursor: 'pointer'}}><span className="fa fa-trash-o ants-gray size14" ></span>&nbsp;&nbsp;  Delete</span>
                            </Menu.Item>
                        </Menu>
                    } trigger="click">
                        <div className="log-icon-btn" >
                            <i className="fa fa-ellipsis-v"></i>
                        </div>
                    </Popover>
                     */}
                    <div className="log-icon-btn" onClick={() => {
                                    this.setState({
                                        warningModalVisible: true,
                                        message: "Do you want to delete this Report?",
                                        messageSuccess: "Delete report successfully.",
                                        action: "delete",
                                        visible: false,
                                    });
                                }}>
                        <i className="fa fa-trash-o"></i>
                    </div>
                    <div className="log-icon-btn" onClick={() => {this.setState({showbottom: !this.state.showbottom})}}>
                        <i className={this.state.showbottom ? "fa fa-compress" : "fa fa-expand"}></i>
                    </div>
                </div>
            </div>
            <ModalWarning loading={loading}
                warningModalVisible={warningModalVisible}
                warningModal={this.warningModal}
                successModal={this.successModal}
                modalcountDown={this.modalcountDown}
                message={message}
            />

        </Card>

        )
    }

}
class BTNSort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            sortActive: false,
            sort: ""
        };

    }
    componentDidMount() {
        this.setState({name: this.props.name});
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.sort !== this.state.sort) {
            this.setState({sort: nextProps.sort});
        }
    }
    render() {
        const enable = {color: "#5F5F61"};
        const disable = {color: "#b9b9c8"};
        return (<div className="log-sort-btn" onClick={() => {this.props.onSort({name: this.state.name,sort: this.state.sort == "desc" ? "asc" : "desc"})}} >
            <div className="log-sort-up" >
                <i className="fa fa-caret-up" style={this.state.sort == "desc" ? disable : enable} ></i>
            </div>
            <div className="log-sort-down">
                <i className="fa fa-caret-down" style={this.state.sort == "asc" ? disable : enable} ></i>
            </div>
        </div>)
    }
}
class BTNShowGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            show: true
        };

    }
    componentDidMount() {
        this.setState({name: this.props.name,show:this.props.show});
        
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.state.show) {
            this.setState({show: nextProps.show});
        }
    }
    render() {

        return (<div className="ant-row Log-card" style={!this.state.show ? {backgroundColor: this.props.color,padding: "1px solid " + this.props.color,borderRadius: "4px",marginBottom: "2px"} : null}>
            <div className="ant-col-24" >

                <div className="log-icon">
                    <div className="log-sort-btn" onClick={() => {this.props.onClick(!this.state.show)}} >
                        <div className="log-sort-up" >
                            <i className={this.state.show ? "fa fa-chevron-circle-down" : "fa fa-chevron-circle-right"} style={!this.state.show ? {color: "#ffffff"} : {color: this.props.color}}></i>
                        </div>
                    </div>
                </div>
                <div className="log-value" >
                    <span style={!this.state.show ? {color: "#ffffff"} : {color: this.props.color}}>{this.props.name}</span>
                </div>
                <div className="log-value" style={this.state.show ? {color: "rgba(0, 0, 0, 0.45)"} : {color: "#ffffff"}} >
                    &nbsp;{this.props.length + " reports"}
                </div>
            </div>
        </div>)
    }
}
class ReportCardViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sort: "",
            pageStart: 0,
            pageEnd: 10,
            pageSize: 10,
            loading:false
        };
        this.onHandleSort = this.onHandleSort.bind(this);
        this.onShowSizeChange = this.onShowSizeChange.bind(this);

        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        let data =  this.props.data;
        this.setState({data: data});
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.state.data) {
            this.setState({data: nextProps.data});
        }
    }
    onShowSizeChange(current,pageSize) {
        this.setState({
            loading: true
        });
        this.setState({
            pageStart: (current - 1) * pageSize,
            pageEnd: ((current - 1) * pageSize) + pageSize,
            pageSize: pageSize,
            loading:false
        });
    }
    onChange(current) {
        this.setState({
            loading: true
        });
        this.setState({
            pageStart: (current - 1) * this.state.pageSize,
            pageEnd: ((current - 1) * this.state.pageSize) + this.state.pageSize,
            loading:false
        });
    }
    onHandleSort(e) {
        this.setState({sort: e.name + " " + e.sort});
    }
    render() {
        const {data,sort,loading} = this.state;
        const totalpage = data.length / 10;
        return (
            <Spin spinning={loading}>
                <div style={{marginBottom: "2rem"}}>
                    {

                        ((data.length > 0 & this.props.width>576) ?
                            (
                                <div className="Log-card-header">
                                    {this.props.name ?
                                        <div className="ant-row">
                                            <div className="ant-col-24">
                                                {this.props.name}
                                            </div>
                                        </div> : null
                                    }
                                    <div className="ant-row">
                                        <div className="ant-col-2">
                                            <div className="log-caption" style={{color:"#f9fafc"}}>
    .
                                            </div>
                                        </div>  
                                        <div className="ant-col-2">
                                            <div className="log-caption" >Total File(Size)
                                            </div>
                                        </div>
                                        <div className="ant-col-5">
                                            <div className="log-caption" >Name

                                            </div>
                                            <BTNSort name="name" sort={sort == "name asc" ? "asc" : (sort == "name desc" ? "desc" : "")} onSort={(e) => {this.onHandleSort(e);}} />
                                        </div>
                                        <div className="ant-col-3">
                                            <div className="log-caption" >Type</div>
                                            <BTNSort name="type" sort={sort == "type asc" ? "asc" : (sort == "type desc" ? "desc" : "")} onSort={(e) => {this.onHandleSort(e);}} />
                                        </div>
                                        <div className="ant-col-3">
                                            <div className="log-caption" >Status</div>
                                            <BTNSort name="status" sort={sort == "status asc" ? "asc" : (sort == "status desc" ? "desc" : "")} onSort={(e) => {this.onHandleSort(e);}} />
                                        </div>
                                        <div className="ant-col-3">
                                            <div className="log-caption" >Schedule Date</div>
                                            <BTNSort name="schedule_date" sort={sort == "schedule_date asc" ? "asc" : (sort == "schedule_date desc" ? "desc" : "")} onSort={(e) => {this.onHandleSort(e);}} />
                                        </div>
                                        <div className="ant-col-3">
                                            <div className="log-caption" >Complete Date</div>
                                            <BTNSort name="complete_date" sort={sort == "complete_date asc" ? "asc" : (sort == "complete_date desc" ? "desc" : "")} onSort={(e) => {this.onHandleSort(e);}} />
                                        </div>
                                        <div className="ant-col-3">
                                            <div className="log-caption" >

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <div></div>)}
                    {
                        data.sort((a,b) => {
                            let v;
                            switch (sort) {
                                case "name asc": v = a.name.toLowerCase() < b.name.toLowerCase()?1:-1; break;
                                case "name desc": v = b.name.toLowerCase() < a.name.toLowerCase()?1:-1; break;
                                case "type asc": v = a.type.toLowerCase() < b.type.toLowerCase()?1:-1; break;
                                case "type desc": v = b.type.toLowerCase() < a.type.toLowerCase()?1:-1; break;
                                case "status asc": v = a.status < b.status?1:-1; break;
                                case "status desc": v = b.status < a.status?1:-1; break;
                                case "schedule_date asc": v = new Date(a.schduleDate) < new Date(b.schduleDate)?1:-1; break;
                                case "schedule_date desc": v = new Date(b.schduleDate) < new Date(a.schduleDate)?1:-1; break;
                                case "complete_date asc": v = new Date(a.completeDate) < new Date(b.completeDate)?1:-1; break;
                                case "complete_date desc": v = new Date(b.completeDate) < new Date(a.completeDate)?1:-1; break;
                            }
                            return v;
                            // return new Date(b.donedate).getTime() - new Date(a.donedate).getTime() ;
                        })
                        .slice(this.props.page ? this.state.pageStart : 0,
                            this.props.page ? this.state.pageEnd : this.props.showing).map(item => (

                                <CardReport width={this.props.width}  key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    type={item.type}
                                    status={item.status}
                                    schduleDate={item.schduleDate}
                                    completeDate={item.completeDate}
                                    fileId={item.fileId}
                                    filter={item.filter}
                                    email={item.email}
                                    recurrenceInfo={item.recurrenceInfo}
                                    export={item.export}
                                    onDeleteReport={(e) => {this.props.onDeleteReport(e)}}
                                    oneTime={item.oneTime}
                                    recurrence={item.recurrence}
                                    total_file={item.total_file}
                                    total_size={item.total_size}
                                />

                            ))
                    }
                    {
                        this.props.page && totalpage > 1 ?
                            <Pagination
                                showSizeChanger
                                onChange={this.onChange}
                                onShowSizeChange={this.onShowSizeChange}
                                defaultCurrent={1}
                                total={data.length}
                            />
                            : null
                    }
                </div>
            </Spin>
        )
    }
}
export default class TableCardReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pagesize: 20,
            startrow: 1,
            endrow: 20,
            currentpage: 1,
            data: [],
            pending_data: [],
            inProgress_data: [],
            complete_data: [],
            error_data: [],
            recurring_data: [],
            expire_data: [],
            pending_show: true,
            inProgress_show: true,
            complete_show: true,
            error_show: true,
            recurring_show: true,
            expire_show: true,
            totalpage: 0,
            showing: 0,
            sort: "",
            message: "",
            showgroup: false,successModalVisible: false
        };

        this.getData = this.getData.bind(this);
        this.trackScrolling = this.trackScrolling.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.search,prevProps.search)) {
            this.setState({data: [],showing: 0,startrow: 1,endrow: 20,totalpage: 0,sort: ""},() => {this.getData()});
        }
    }


    getData() {
        if (!CheckTokenExp()) {
            const {search} = this.props;
            this.setState({loading: true});
            let req = JSON.stringify({
                account: search.account,
                users: search.users,
                search: search.name,
                startdata: 1,
                enddata: 10,
                template: search.template,
                status: search.statusReport  ? search.statusReport.toString() :(search.statusReport===0? "0": "") ,
                startdate: search.startdate,
                enddate: search.enddate,
            });

            apiClient.post('api/report/get/',req
                ,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        data: json.data,
                        showing: json.data.length <= 50 ? json.data.length : 50,
                        pending_data: json.data.filter(f => f.status == 0),
                        inProgress_data: json.data.filter(f => f.status == 1),
                        complete_data: json.data.filter(f => f.status == 2),
                        error_data: json.data.filter(f => f.status == 9),
                        expire_data: json.data.filter(f => f.status == 5),
                        recurring_data: json.data.filter(f => f.status == 4)
                    }
                        ,() => {this.props.updateWindowDimensions()});

                    this.setState({totalpage: json.data.length,startrow: json.data.length + 1,endrow: json.data.length + 20});
                    this.setState({
                        pending_show: true,
                        inProgress_show: true,
                        complete_show: true,
                        error_show: true,
                        recurring_show: true,
                        expire_show: true,
                    });
                    this.setState({loading: false});
                })
                .catch(error => {
                    this.setState({
                        data: [],
                        pending_data: [],
                        inProgress_data: [],
                        complete_data: [],
                        error_data: [],
                        recurring_data: [],
                        expire_data: [], 
                    });
                    this.setState({loading: false});
                });
        } else {
            window.location.href = "logout";
        }

    }

    isBottom(el) {
        this.setState({message: el.getBoundingClientRect().bottom + ":" + window.innerHeight});
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    trackScrolling() {
        const wrappedElement = document.getElementById('loadding');
        if (wrappedElement) {
            if (this.isBottom(wrappedElement)) {
                let s = this.state.showing;
                if((s+50) >= this.state.totalpage){
                    s = this.state.totalpage;
                }else{
                    s = s + 50;
                }
                this.setState({showing: s})
            }
        }
    };
    getStatusInfo(status) {
        let name = "";
        let color = "";
        let icon = "";
        switch (status) {
            case 0: color = "#FED531"; icon = "fa fa-stop-circle"; name = "PENDING"; break;
            case 1: color = "#FED531"; icon = "fa fa-clock-o"; name = "IN PROGRESS"; break;
            case 2: color = "#5bd2a6"; icon = "fa fa-check-circle"; name = "COMPLETED"; break;
            case 4: color = "#1890ff"; icon = "fa fa-refresh"; name = "RECURRING"; break;
            case 5: color = "#d25c5b"; icon = "fa fa-times-circle"; name = "EXPIRED"; break;
            case 9: color = "#d25c5b"; icon = "fa fa-times-circle"; name = "ERROR"; break;
        }
        return ({name: name,color: color,icon: icon})
    }
    successModal(a) {
        this.setState({successModalVisible: false});
    }
    deleteReport(e) {
        let data = this.state.data;
        this.setState({successModalVisible: true});
        var index = data.findIndex(function (o) {
            return o.id === e;
        })
        if (index !== -1) data.splice(index,1);
        this.setState({
            data: data,
            pending_data: data.filter(f => f.status == 0),
            inProgress_data: data.filter(f => f.status == 1),
            complete_data: data.filter(f => f.status == 2),
            error_data: data.filter(f => f.status == 9),
            expire_data: data.filter(f => f.status == 5),
            recurring_data: data.filter(f => f.status == 4)
        });
        setTimeout(() => {
            this.setState({successModalVisible: false});
        },2000);
    }
    viewData(showgroup) {
        if (showgroup) {
            return <div>

                {this.state.recurring_data.length > 0 ?
                    <BTNShowGroup show={this.state.recurring_show}
                        name={this.getStatusInfo(4).name}
                        color={this.getStatusInfo(4).color}
                        length={this.state.recurring_data.length} onClick={(e) => {this.setState({recurring_show: e})}} /> :
                    null}
                {this.state.recurring_show ?
                    <ReportCardViewer width={this.props.width}  page data={this.state.recurring_data} /> : null}

                {this.state.pending_data.length > 0 ?
                    <BTNShowGroup show={this.state.pending_show}
                        name={this.getStatusInfo(0).name}
                        color={this.getStatusInfo(0).color}
                        length={this.state.pending_data.length} onClick={(e) => {this.setState({pending_show: e})}} /> :
                    null}
                {this.state.pending_show ? <ReportCardViewer width={this.props.width}  page data={this.state.pending_data} onDeleteReport={e => this.deleteReport(e)} /> : null}

                {this.state.inProgress_data.length > 0 ?
                    <BTNShowGroup show={this.state.inProgress_show}
                        name={this.getStatusInfo(1).name}
                        color={this.getStatusInfo(1).color}
                        length={this.state.inProgress_data.length} onClick={(e) => {this.setState({inProgress_show: e})}} /> :
                    null}
                {this.state.inProgress_show ?
                    <ReportCardViewer width={this.props.width}  page data={this.state.inProgress_data} onDeleteReport={e => this.deleteReport(e)} /> : null}

                {this.state.complete_data.length > 0 ?
                    <BTNShowGroup show={this.state.complete_show}
                        name={this.getStatusInfo(2).name}
                        color={this.getStatusInfo(2).color}
                        length={this.state.complete_data.length} onClick={(e) => {this.setState({complete_show: e})}} /> :
                    null}
                {this.state.complete_show ? <ReportCardViewer width={this.props.width}  page data={this.state.complete_data} onDeleteReport={e => this.deleteReport(e)} /> : null}

                {this.state.expire_data.length > 0 ?
                    <BTNShowGroup show={this.state.expire_show}
                        name={this.getStatusInfo(5).name}
                        color={this.getStatusInfo(5).color}
                        length={this.state.expire_data.length} onClick={(e) => {this.setState({expire_show: e})}} /> :
                    null}
                {this.state.expire_show ? <ReportCardViewer width={this.props.width} page data={this.state.expire_data} onDeleteReport={e => this.deleteReport(e)} /> : null}

                {this.state.error_data.length > 0 ?
                    <BTNShowGroup show={this.state.error_show}
                        name={this.getStatusInfo(9).name}
                        color={this.getStatusInfo(9).color}
                        length={this.state.error_data.length} onClick={(e) => {this.setState({error_show: e})}} /> :
                    null}
                {this.state.error_show ? <ReportCardViewer width={this.props.width}  page data={this.state.error_data} onDeleteReport={e => this.deleteReport(e)} /> : null}

                
            </div>
        }
        else {
            return <div>
                {<ReportCardViewer width={this.props.width}  data={this.state.data} showing={this.state.showing} onDeleteReport={e => this.deleteReport(e)} />}
            </div>
        }
    }
    render() {


        const {loading,totalpage,data,showing,showgroup,successModalVisible,pending_data,inProgress_data,recurring_data,error_data,expire_data,complete_data} = this.state;
        const Nodata = <div>
            <div className="Log-card-footer">
                <div className="ant-row">
                    <div className="ant-col-24">
                        <div className="ant-empty ant-empty-normal"><div className="ant-empty-image"><svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg></div><p className="ant-empty-description">No Data</p></div>
                    </div>
                </div>
            </div>
        </div >
        if (showing < totalpage && !loading && !showgroup) {

            document.addEventListener('scroll',this.trackScrolling);
        }
        else {
            document.removeEventListener('scroll',this.trackScrolling);
        }
        if (data.length == 0 && !loading) {
            return Nodata;
        }
        else {
            return (

                <div>


                    {
                        (showing > 0) ? (
                            <div className="Log-card-header">
                                <div className="ant-row">
                                    <div className="ant-col-12">
                                        {!this.state.showgroup ? <div className="showing">Showing {showing} / {totalpage}  </div> : <div className="showing">Showing {
                                            ((inProgress_data.length > 0 ? 1 : 0) +
                                                (pending_data.length > 0 ? 1 : 0) +
                                                (complete_data.length > 0 ? 1 : 0) +
                                                (recurring_data.length > 0 ? 1 : 0) +
                                                (error_data.length > 0 ? 1 : 0) +
                                                (expire_data.length > 0 ? 1 : 0))
                                        } group</div>}
                                    </div>
                                    <div className="ant-col-12  text-right">

                                        <Radio.Group value={this.state.showgroup} onChange={(e) => {this.setState({showgroup: e.target.value});}}>
                                            <Radio.Button value={false}><i className="fa fa-list" style={{fontSize: "14px"}}></i> {this.props.width > 575 ? " List viewer" : null}</Radio.Button>
                                            <Radio.Button value={true}><i className="fa fa-clone" style={{fontSize: "14px"}} ></i>{this.props.width > 575 ? " Group by status" : null}</Radio.Button>
                                        </Radio.Group>
                                    </div>

                                </div>
                            </div>

                        )

                            : <div></div>}
                    {
                        this.viewData(showgroup,data)
                    }
                    {
                        loading ?
                            (<Card >
                                <div className="ant-col-2">

                                </div>
                                <div className="ant-col-5">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-4">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-5">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-4">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-4">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                            </Card>) : (<div></div>)
                    }
                    {
                        (showing < totalpage && !loading && !showgroup) ?
                            (<div id="loadding" className="Log-card-footer" onClick={() => {
                                let s = showing;
                                s = s + 50;
                                this.setState({showing: s})
                            }}>
                                <div className="ant-row">
                                    <div className="ant-col-24">
                                        <div className="loading-dot"><span>.</span><span>.</span><span>.</span> </div>
                                    </div>
                                </div>
                            </div>) : (<div></div>)
                    }
                    <div >&nbsp;</div>

                    <ModalSuccess loading={loading}
                        successModalVisible={successModalVisible}
                        successModal={this.successModal}
                        messageSuccess={"Delete report successfully."}
                    />
                </div>
            );
        }

    }
}


