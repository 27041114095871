import React, { Component } from 'react';
import { Select } from 'antd';
import { apiClient } from '../../../api';
import { token, CheckTokenExp } from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectStatus extends Component {
    state = {
        loading: false,
        list: [], // Full list of statuses
        selectedStatuses: {}, // Map of status ID to status name
        selectedValues: [], // Names of selected statuses
        modalVisible: false,
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            apiClient.get('api/Status', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                if (data.status === 200 && data.data) {
                    const statusMap = data.data.reduce((acc, item) => {
                        acc[item.ID] = item.TEXT;
                        return acc;
                    }, {});
                    this.setState({ list: data.data, selectedStatuses: statusMap });
                    this.props.setStateByProps("statuslist", data.data);
                } else {
                    this.setState({ modalVisible: true, list: [] });
                    this.props.setStateByProps("statuslist", []);
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ modalVisible: true, list: [], loading: false });
            });
        } else {
            window.location.href = "logout";
        }
    }

    onChange = (value) => {
        const { selectedStatuses } = this.state;
        // Map selected IDs to names
        const selectedStatusNames = value.map(id => selectedStatuses[id] || id); 
        this.setState({ selectedValues: selectedStatusNames });
        this.props.setStateByProps("status", selectedStatusNames); // Send names of selected statuses
    };

    // Restore the list with deselected options
    getFilteredList = () => {
        const { list, selectedValues } = this.state;

        // Filter the list to show only non-selected options
        const selectedStatusNames = selectedValues.map(name => {
            const id = Object.keys(this.state.selectedStatuses).find(key => this.state.selectedStatuses[key] === name);
            return id;
        });

        const filteredList = list.filter(item => !selectedStatusNames.includes(item.ID.toString()));

        return filteredList;
    };

    render() {
        const { loading, selectedValues, modalVisible } = this.state;
        const { placeholder, select, mode, disablestatus, selectall, style } = this.props;

        return (
            <>
                <Select
                    mode={mode || "multiple"} // Use mode as "multiple" for selecting multiple options
                    allowClear
                    disabled={disablestatus === true}
                    style={style}
                    loading={loading}
                    name="select"
                    onSearch={(value) => { this.getData(value) }}
                    onChange={this.onChange}
                    value={selectedValues} // Set value based on selected values state
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder || "Status"}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {selectall && <Option value="all">SELECT ALL</Option>}
                    {this.getFilteredList().map((item) => (
                        <Option key={item.ID} value={item.ID.toString()}>{item.TEXT}</Option>
                    ))}
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}
