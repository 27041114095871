import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal} from 'antd';


export default class ModalErrorWarning extends Component {

    constructor(props) {
        super(props);

        this.state = {
           
        };

    }

    render() {
        const { loading, errorWarningModalVisible, errorWarningModal, messageError} = this.props;
        return (
            <div>
                <style>{`
               
                 `}</style>

                <Modal
                    zIndex={1021}
                    wrapClassName="customModal popup-question"
                    closeIcon={false}
                    visible={errorWarningModalVisible}
                    onOk={() => errorWarningModal(false)}
                    onCancel={() => errorWarningModal(false)}
                    footer={null}
                    width={360}
            >
                <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                            <Icon type="info-circle" style={{color: "#ffff", fontSize: "60px" }} />
                            {/* <img src={require("../../../img/vendors/icon/question.png")} width="65px" /> */}
                    </Col>
                </Row>

                <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <h4>ERROR.</h4>
                            <label>{messageError}</label>
                    </Col>
                </Row>


                </Modal>
                </div>
        );
    }
}


