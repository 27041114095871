import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal } from 'antd';

export default class ModalBroadCastWarning extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { loading, isVisible, message, onCancel, onOk } = this.props;

        return (
            <div>
                <Modal
                    zIndex={1021}
                    wrapClassName="popup-question"
                    closeIcon={false}
                    visible={isVisible}
                    width={360}
                    footer={[
                        <Button
                            key="gray"
                            type="gray"
                            onClick={() => { onCancel(); }}>
                            Cancel
                        </Button>,
                        <Button
                            style={{ marginRight: 8, float: 'right' }}
                            key="primary"
                            type="primary"
                            loading={loading}
                            onClick={() => { onOk(); }}>
                            OK
                        </Button>
                    ]}
                >
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                            <img src="../img/vendors/icon/question.png" width="65px" />
                        </Col>
                    </Row>

                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <label style={{ color: '#000000' }}>{message}</label>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}


