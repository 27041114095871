/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input, Select, Spin } from 'antd';
import { token } from '../../../config';

const { TextArea } = Input;
export default class ModalTag extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            pagesizeDes: 20,
            PRG_NAME: "",
        };

    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps) {
        if (this.props.PRG_NAME !== prevProps.PRG_NAME) {
            this.setState({ PRG_NAME: this.props.PRG_NAME });
        }
    }

    render() {
        const { visible, setStateByProps, loading } = this.props;
        const styleMarginBottom = { marginBottom: 16 };
        return (
            <Modal
                width={700}
                title={<div>Tag <span className="fa fa-times-circle-o card-close" onClick={() => this.props.setStateByProps('visible', false)}></span></div>}
                visible={visible}
                onOk={this.props.handleOk}
                onCancel={false}
                footer={[
                    <Row gutter={16} type="flex" justify="end" style={styleMarginBottom} style={{ textAlign: 'right', marginRight: 31 }} >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Button type="primary" style={{ marginRight: 8 }} loading={loading}
                                disabled={this.props.PRG_NAME ? false : true}
                                onClick={() => { this.props.warningModal(true, "Do you want to add this tag?", "Add tag successfully."), this.props.setStateByProps('action', 'add') }}>
                                <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                Add
                        </Button>
                        </Col>
                    </Row>
                ]}
            >
                <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                        <label className=" control-label require" style={{ fontWeight: 700 }}> Name</label>
                        <label className="red">*</label>
                        <Input style={{ maxWidth: '100%' }} value={this.props.PRG_NAME} name="PRG_NAME" placeholder="Enter tag name"
                            onChange={(e) => {
                                this.props.setStateByProps("PRG_NAME", e.currentTarget.value)
                            }} />
                    </Col>
                </Row>

            </Modal>
        );
    }
}


