import React, { Component } from "react";
import { Button, Row, Col, Modal } from "antd";

export default class ModalWarningCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      loading,
      warningModalOpen,
      warningModal,
      message,
      onSubmit
    } = this.props;

    return (
      <div>
        <Modal
          zIndex={1021}
          wrapClassName="popup-question"
          closeIcon={false}
          visible={warningModalOpen}
          onOk={() => warningModal(true)}
          onCancel={() => warningModal(false)}
          width={360}
          footer={[
            <Button
              key="submit"
              type="gray"
              loading={loading}
              onClick={() => warningModal(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit1"
              type="primary"
              loading={loading}
              onClick={() => {
                warningModal(false);
                onSubmit();
              }}
            >
              OK
            </Button>,
          ]}
        >
          <Row gutter={8} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
              <img
                src={require("../../../img/vendors/icon/question.png")}
                width="65px"
              />
            </Col>
          </Row>
          <Row gutter={8} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
              <label style={{ color: "#000000" }}>{message}</label>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
