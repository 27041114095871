import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  Tooltip,
  Icon,
  Divider,
  Checkbox,
  Button,
  Upload,
} from "antd";
import UserFlow from "./assets/User Flow Example.png";
import Signature from "./Signature";

const { Dragger } = Upload;
const { TextArea } = Input;

export default function Individual(props) {
  const [visible, setVisible] = useState(false);

  const beforeUploadFile = (file, name) => {
    console.log(file);
    props.changeFile([file], name);
    return false;
  };

  const onRemove = (file, fileList, name) => {
    props.changeFile(null, name);
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <label className="control-label" style={{ fontSize: 25 }}>
            Request Information
          </label>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <label className="control-label">First Name <span style={{color: "red"}}>*</span></label>
              <Input
                placeholder={"Pre-filled Information"}
                value={props.data.RSD_FIRST_NAME}
                onChange={(e) => props.handleChangeInput(e, "RSD_FIRST_NAME")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <label className="control-label">Last Name <span style={{color: "red"}}>*</span></label>
              <Input
                placeholder={"Pre-filled Information"}
                value={props.data.RSD_LAST_NAME}
                onChange={(e) => props.handleChangeInput(e, "RSD_LAST_NAME")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <label className="control-label">Position <span style={{color: "red"}}>*</span></label>
              <Input
                placeholder={"Pre-filled Information"}
                value={props.data.RSD_POSITION}
                onChange={(e) => props.handleChangeInput(e, "RSD_POSITION")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <label className="control-label">Business Name <span style={{color: "red"}}>*</span></label>
              <Input
                placeholder={"Pre-filled Information"}
                value={props.data.RSD_CONTACT_NAME}
                onChange={(e) => props.handleChangeInput(e, "RSD_CONTACT_NAME")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">Company Website <span style={{color: "red"}}>*</span></label>
              <Input
                value={props.data.RSD_WEBSITE}
                onChange={(e) => props.handleChangeInput(e, "RSD_WEBSITE")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">Identification Documents <span style={{color: "red"}}>*</span></label>
              &nbsp; &nbsp;
              <Tooltip
                placement="rightTop"
                overlayClassName="requestSenderCustom"
                title={
                  <>
                    <div style={{ fontWeight: "bold", color: "#454545" }}>
                      Identifation Documents
                    </div>
                    <div style={{ color: "#707070", lineHeight: "18px" }}>
                      A copy of the person's Thai national I.D. card or other
                      official identity document, certified true with an
                      original signature
                    </div>
                  </>
                }
              >
                <span className="fa fa-info-circle ants-gray-low size14"></span>
              </Tooltip>
              <Dragger
                accept={".jpg,.png"}
                beforeUpload={(file) => beforeUploadFile(file, "RSD_IDEN_DOC")}
                onRemove={(file, fileList) =>
                  onRemove(file, fileList, "RSD_IDEN_DOC")
                }
                fileList={props.data.RSD_IDEN_DOC}
                {...props}
              >
                <p className="ant-upload-drag-icon icon-upload">
                  <Icon type="upload" style={{ color: "#000" }} />
                </p>
                <div className="ant-upload-text" style={{ lineHeight: "15px" }}>
                  <p>Drag and drop here (.jpg .png)</p>
                  <p>or</p>
                  <p style={{ color: "#097FF5" }}>Browse</p>
                </div>
              </Dragger>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">Company Seal Image <span style={{color: "red"}}>*</span></label>
              &nbsp; &nbsp;
              <Tooltip
                placement="rightTop"
                overlayClassName="requestSenderCustom"
                title={
                  <>
                    <div style={{ fontWeight: "bold", color: "#454545" }}>
                      Company Seal
                    </div>
                    <div style={{ color: "#707070", lineHeight: "18px" }}>
                      A way of confirming that a document is certified and
                      agreed upon by all parties involved in the decision
                      making. It is considered the official signature or mark of
                      the corporation. A company seal will include:
                      <br /> &nbsp; &nbsp;- The registered company name
                      <br /> &nbsp; &nbsp;- The year of incorporation
                      <br /> &nbsp; &nbsp;- The state in which it was legally
                      filed
                      <br /> &nbsp; &nbsp;- The company logo
                    </div>
                  </>
                }
              >
                <span className="fa fa-info-circle ants-gray-low size14"></span>
              </Tooltip>
              <Dragger
                accept={".jpg,.png"}
                beforeUpload={(file) => beforeUploadFile(file, "RSD_CO_SEAL")}
                onRemove={(file, fileList) =>
                  onRemove(file, fileList, "RSD_CO_SEAL")
                }
                fileList={props.data.RSD_CO_SEAL}
                {...props}
              >
                <p className="ant-upload-drag-icon icon-upload">
                  <Icon type="upload" style={{ color: "#000" }} />
                </p>
                <div className="ant-upload-text" style={{ lineHeight: "15px" }}>
                  <p>Drag and drop here (.jpg .png)</p>
                  <p>or</p>
                  <p style={{ color: "#097FF5" }}>Browse</p>
                </div>
              </Dragger>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <label className="control-label" style={{ fontSize: 25 }}>
            Sender Name Registration
          </label>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label
                className="control-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  Sender Name <span style={{color: "red"}}>*</span> &nbsp; &nbsp;
                  <Tooltip
                    placement="rightTop"
                    overlayClassName="requestSenderCustom"
                    title={
                      <>
                        <div style={{ fontWeight: "bold", color: "#454545" }}>
                          Instruction
                        </div>
                        <div style={{ color: "#707070", lineHeight: "18px" }}>
                          1. Sender Name must be English character or numbers{" "}
                          <br />
                          2. Special characters can only be ! “ # % * () + - . =
                          / ?
                        </div>
                        <div style={{ fontWeight: "bold", color: "#454545" }}>
                          Sender names policy
                        </div>
                        <div style={{ color: "#707070", lineHeight: "18px" }}>
                          Examples of such content that is not allowed include:
                          <br />
                          1. Sender Name of the Financial Institution or
                          Government Agency without a letter of consent or not
                          authorized by the owner of the name <br />
                          2. Sender Name of the product name or trademark
                          without a written consent from the product owner.
                          <br />
                          3. Sender Name that is related to sexual content such
                          as sexy, saew, se-yew, XchoSMS <br />
                          4. Sender Name that is related to gambling or loan
                          shark such as bet, slot, baccarat, casino, or with
                          letters + numbers such as VSCR888, MBslot99, Bonus,
                          i888.co
                        </div>
                      </>
                    }
                  >
                    <span className="fa fa-info-circle ants-gray-low size14"></span>
                  </Tooltip>
                </span>
                <span style={{ fontWeight: 500 }}>
                  Characters: {props.data.senderNamelength}/
                  {props.data.senderNameCount}
                </span>
              </label>
              <Input
                value={props.data.RSD_LIST_SENDER_NAME}
                onChange={(e) =>
                  props.handleChangeInput(
                    e,
                    "RSD_LIST_SENDER_NAME",
                    "senderNameCount"
                  )
                }
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">Reason of Use <span style={{color: "red"}}>*</span></label>
              <Checkbox.Group
                // defaultValue={
                //   props.data.RSD_LIST_REASON_USE
                //     ? [`${props.data.RSD_LIST_REASON_USE}`]
                //     : []
                // }
                value={
                  props.data.RSD_LIST_REASON_USE
                }
                onChange={(e) =>
                  props.handelChangeCheckboxGroup(e, "RSD_LIST_REASON_USE")
                }
              >
                <Row>
                  <Col xs={24} sm={12}>
                    <Checkbox value={"1"}>Memberships’ Notification</Checkbox>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Checkbox value={"2"}>Privileges</Checkbox>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Checkbox value={"3"}>
                      Important Activities and Details
                    </Checkbox>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Checkbox value={"4"}>Order Confirmation</Checkbox>
                  </Col>
                  <Col xs={6} sm={4}>
                    <Checkbox value={"5"}>Other</Checkbox>
                  </Col>
                  {props.data.RSD_LIST_REASON_USE?.indexOf("5") > -1 && (
                    <Col xs={18} sm={20}>
                      <Input
                        value={props.data.RSD_LIST_REASON_USE_COMMENT}
                        onChange={(e) =>
                          props.handleChangeInput(
                            e,
                            "RSD_LIST_REASON_USE_COMMENT"
                          )
                        }
                        placeholder={"Please specify"}
                      />
                      {props.errorText && (
                        <span style={{ color: "red" }}>
                          Please, input text in “English” only.
                        </span>
                      )}
                    </Col>
                  )}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label
                className="control-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Message Example <span style={{color: "red"}}>*</span></span>
                <span style={{ fontWeight: 500 }}>
                  Characters: {props.data.messageLength}/
                  {props.data.messageCount}
                </span>
              </label>
              <TextArea
                autoSize={{ minRows: 4 }}
                style={{
                  resize: "none",
                }}
                value={props.data.RSD_LIST_EX_TEXT}
                onChange={(e) => props.handleChangeInput(e, "RSD_LIST_EX_TEXT")}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">User Flow (Optional)</label>
              &nbsp; &nbsp;
              <Tooltip
                placement="rightTop"
                overlayClassName="requestSenderCustom"
                title={
                  <>
                    <div style={{ fontWeight: "bold", color: "#454545" }}>
                      User Flow Example
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img src={UserFlow} alt="" />
                    </div>
                  </>
                }
              >
                <span className="fa fa-info-circle ants-gray-low size14"></span>
              </Tooltip>
              <Dragger
                accept={".jpg,.png"}
                beforeUpload={(file) =>
                  beforeUploadFile(file, "RSD_LIST_USER_FLOW")
                }
                onRemove={(file, fileList) =>
                  onRemove(file, fileList, "RSD_LIST_USER_FLOW")
                }
                fileList={props.data.RSD_LIST_USER_FLOW}
                {...props}
              >
                <p className="ant-upload-drag-icon icon-upload">
                  <Icon
                    type="upload"
                    style={{ fontSize: "20px", color: "#000" }}
                  />
                </p>
                <div className="ant-upload-text" style={{ lineHeight: "15px" }}>
                  <p>Drag and drop here (.jpg .png)</p>
                  <p>or</p>
                  <p style={{ color: "#097FF5" }}>Browse</p>
                </div>
              </Dragger>
              <Checkbox
                checked={props.data.RSD_LIST_IS_WHITELIST}
                disabled={props.data.RSD_LIST_REQUEST === 3}
                onChange={(e) =>
                  props.onChangeCheckBox(e, "RSD_LIST_IS_WHITELIST")
                }
              >
                Request Whitelist&nbsp; &nbsp;
                <Tooltip
                  placement="rightTop"
                  overlayClassName="requestSenderCustom"
                  title={
                    <>
                      <div style={{ fontWeight: "bold", color: "#454545" }}>
                        Whitelist Conditions
                      </div>
                      <div style={{ color: "#707070", lineHeight: "18px" }}>
                        1. Messages from government and government agencies such
                        as disaster warning messages. <br />
                        2. Messages about financial transaction of financial
                        institutions such as messages used as passwords (OTP
                        Password), etc. <br />
                        3. Communication messages between the organization and
                        employees or between the orgainzation and customer
                        groups, such as messages telling employees to take a
                        break from work due to a riot. Message notification of
                        membership status, membership renewal or member benefits
                        in which customers or emplyees must consent or agree in
                        accepting news from the orgainzation.
                      </div>
                    </>
                  }
                >
                  <span className="fa fa-info-circle ants-gray-low size14"></span>
                </Tooltip>
              </Checkbox>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <label className="control-label">Signature <span style={{color: "red"}}>*</span></label>
              <div>
                <Button onClick={() => setVisible(true)}>Add Signature</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Signature visible={visible} fileList={props.data.sig_img_enterP} fileName={"sig_img_enterP"} changeFile={props.changeFile} setVisible={setVisible} />
    </>
  );
}
