/* eslint-disable */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber,
    Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Switch
} from 'antd';
import { token, CheckTokenExp,ReplaceUrlTaga } from '../../../../config';
import { apiClient } from '../../../../api';
import{root} from '../../../../config';
import SelectSender from '../../../../components/Ants/Select/SelectSender';
import SelectPinLenght from '../../../../components/Ants/Select/SelectPinLenght';
import ModalSuccess from '../../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../../components/Ants/Modal/ModalWarning';
import ModalServerError from '../../../../components/Ants/Modal/ModalServerError';

import MultipleTrigger from '../../../../components/Ants/Mentions/MultipleTrigger';

const { Option } = Select;

export default class OtpAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexcontent: 0,
            contentlength: 0,
            content: "",
            content_old: "",
            MessageCount: 1,
            CharacterCount: 160,
            variable: [],
            variabledef: ["firstName", "lastName", "address",
                "city", "country", "gender", "birthDate"],
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            id: undefined,
            APP_ID: undefined,
            APP_GUID: undefined,
            APPNAME: undefined,
            STATUS: true,
            sender: undefined,
            Prefix: "",
            pin_length: undefined,
            Expire: undefined,
            otp_max: undefined,
            action: "",
            error_message: "",
            notifyUrl: "",
            notifyType: "application/json",
            callBack: "",
            modalVisible: false,
        };

        this.indexcontent = this.indexcontent.bind(this);
        this.countMessage = this.countMessage.bind(this);
        this.getData = this.getData.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.saveData = this.saveData.bind(this);
        this.IsMatchingCode = this.IsMatchingCode.bind(this);

    }

    componentDidMount() {
        var params = {};
        if (this.props.location.search) {
            var parts = this.props.location.search.substring(1).split('&');
            for (var i = 0; i < parts.length; i++) {
                var nv = parts[i].split('=');
                if (!nv[0]) continue;
                params[nv[0]] = nv[1] || true;
            }
            this.setState({ id: params.id });
            params.id ? this.getData(params.id) : null;
            
        }
        ReplaceUrlTaga();
    }

    indexcontent(e) {
        var start = this.state.content.substring(0, e.target.selectionEnd);
        var c = (start.split("{").length - 1) * 2;
        this.setState({ indexcontent: e.target.selectionEnd + c });
    }

    countMessage(content) {
        content = content ? content : "";

        var countCodeOTP = (content.match(/{Code OTP}/g) || []).length;
        var countRefOTP = (content.match(/Ref Code}/g) || []).length;

        if (countCodeOTP > 1) {
            return false;
        }

        if (countRefOTP > 1) {
            return false;
        }

        //1 Message / Used with 5 remaining 165
        this.setState(state => {
            var contentlength = content == undefined ? 0 : content.length;
            var addParameter = (((state.Prefix ? state.Prefix.length : 0)) + (state.pin_length ? state.pin_length : 0));
            var code_otp = "{Code OTP}";
            var ref_code = "{Ref Code}";
            var check_otp = content.search(code_otp);
            var check_ref = content.search(ref_code);
            //contentlength = (contentlength + addParameter) - (check_otp == -1 ? 0 : code_otp.length) - (check_ref == -1 ? 0 : 5);
            contentlength = (contentlength + (check_otp === -1 ? 0 : addParameter)) - (check_otp == -1 ? 0 : code_otp.length) - (check_ref == -1 ? 0 : 1);
            var msgCount = 0;
            var messremaining = 160;
            if (contentlength) {
                if (!content.match(/^([a-zA-Z0-9\|!#$%&/()*^=?»«@£§€{}.-;'"<>_[\]\\,\-\+ \n\r])+$/i)) {
                    msgCount = contentlength <= 70 ? 1 : contentlength <= 134 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 67));
                    messremaining = contentlength <= 70 ? 70 - contentlength : contentlength <= 134 ? 134 - contentlength : (134 + (67 * (msgCount - 2))) - contentlength;
                } else {
                    msgCount = contentlength <= 160 ? 1 : contentlength <= 306 ? 2 : parseInt(Math.ceil(parseFloat(contentlength) / 153));
                    messremaining = contentlength <= 160 ? 160 - contentlength : contentlength <= 306 ? 306 - contentlength : (306 + (153 * (msgCount - 2))) - contentlength;
                }
            }

            this.setState({
                content: content,
                content_require: false,
            });
            this.setState({ MessageCount: msgCount });
            this.setState({ contentlength: contentlength });
            this.setState({ CharacterCount: messremaining });
        });
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            apiClient.get("OTP/INFO/" + value,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data.data;
                    this.setState({
                        APP_ID:  json.otcId,//"json.ID"
                        APP_GUID: json.otcId,
                        APPNAME: json.otcName,
                        STATUS: json.status,
                        sender: json.sender,
                        Prefix: json.prefix,
                        pin_length: json.pinLength,
                        Expire: json.expireTime,
                        otp_max: json.maxFail,
                        content: json.content,
                        notifyUrl: json.notifyURL,
                        notifyType: json.notifyType ? json.notifyType : undefined,
                        callBack: json.callbackData,
                    });

                    this.countMessage(json.content);
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    saveData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            // let req = JSON.stringify({
            //     ID: this.state.APP_ID,
            //     NAME: this.state.APPNAME,
            //     STATUS: this.state.STATUS,
            //     SENDER: this.state.sender,
            //     PREFIX: this.state.Prefix,
            //     PIN_LENGTH: this.state.pin_length,
            //     EXPIRE_DATE: this.state.Expire,
            //     OTP_MAX: this.state.otp_max,
            //     CONTENT: this.state.content,
            //     NOTIFY_URL: this.state.notifyUrl,
            //     NOTIFY_TYPE: this.state.notifyType,
            //     CALLBACK: this.state.callBack,
            // });

            let req = JSON.stringify({
                id: this.state.APP_ID,
                name: this.state.APPNAME,
                status: this.state.STATUS,
                sender: this.state.sender,
                prefix: this.state.Prefix,
                pinLength: this.state.pin_length,
                expireTime: this.state.Expire,
                maxFail: this.state.otp_max,
                content: this.state.content,
                notifyUrl: this.state.notifyUrl,
                notifyType: this.state.notifyType,
                callback: this.state.callBack,
            });

            apiClient.post("OTP/Save", req,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    if(json.message === "Success") {
                        this.setState({ successModalVisible: true });
                        this.modalcountDown();
                    } else {
                        this.setState({ error_message: json });
                        Modal.error({
                            title: json,
                            className: 'popup-error',
                            onOk() { },
                        });
                    }

                    this.setState({ loading: false });
                })
                .catch(error => {
                    var resp_error = error.response.data;
                    this.setState({ error_message: resp_error });

                    Modal.error({
                        title: resp_error,
                        className: 'popup-error',
                        onOk() { },
                    });

                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps(parameters, value) {
        if (parameters == "Prefix") {
            if (this.IsMatchingCode(value)) {
                this.setState({ [parameters]: value });
                this.countMessage(this.state.content);
            }
        }
        else {
            this.setState({ [parameters]: value });
        }
    }

    IsMatchingCode(str) {
        var result = true;
        if (str.length <= 3) {
            var rex = /^[a-zA-Z0-9]*$/g.test(str);
            result = rex ? true : false;
            return result;
        }
        else {
            return false;
        }

    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "save") {
            this.saveData();
            this.setState({ action: "" });
            value = false;
        }

        if (this.state.action == "discard") {
            this.setState({ action: "" });
            this.props.history.push('/otp/configuration/');
            value = false;

        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                    this.props.history.push('/otp/configuration/');
                }, secondsToGo * 1000);
            }
            this.setState({ error_message: "" });
        });
    }

    render() {
        const { loading, warningModalVisible, message, messageSuccess, successModalVisible, modalVisible } = this.state;
        const { APP_ID, APP_GUID, APPNAME, STATUS, sender, Prefix, pin_length, Expire, otp_max, content,
            error_message, notifyUrl, notifyType, callBack } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        const routes = [
            {
                path: '/otp/configuration/',
                breadcrumbName: 'OTP',
            },
            {
                breadcrumbName: 'Configuration',
            },
        ];
        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }} >

                </PageHeader>

                <div className="content">
                    <Card className="card-content" style={{ minHeight: 200 }} >

                        <Row gutter={16} style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>App OTP Name</label>
                                <label className="red">*</label>
                                <label className="red" style={{ marginLeft: 16 }}>{error_message}</label>
                                <Input placeholder="App OTP Name" value={APPNAME} onChange={(e) => this.setStateByProps("APPNAME", e.target.value)} />
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Sender
                                </label>
                                <label className="red">*</label>
                                <SelectSender select={sender} setStateByProps={this.setStateByProps} />
                            </Col>

                        </Row>

                        <Row gutter={16} style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Pin Length
                                </label>
                                <label className="red">*</label>
                                <SelectPinLenght select={pin_length} setStateByProps={this.setStateByProps} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Prefix</label>
                                <Input placeholder="Maximun 3 digit and format 0-9 or A-Z" value={Prefix} pattern="[a-zA-Z0-9.+,$-]+" onChange={(e) => this.setStateByProps("Prefix", e.target.value)} />
                            </Col>
                        </Row>


                        <Row gutter={16} style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Expire Duration
                                </label>
                                <label className="red">*</label>
                                <InputNumber min={60} max={600} placeholder="Minimum 60 second or maximum 600 Secounds" value={Expire} onChange={(e) => this.setStateByProps("Expire", e)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Maximum number of fail
                                </label>
                                <label className="red">*</label>

                                <InputNumber min={1} value={otp_max} placeholder="Maximum number of fail" onChange={(e) => this.setStateByProps("otp_max", e)} />
                            </Col>
                        </Row>

                        <Row gutter={16} style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Notify Url
                                </label>
                                <Input placeholder="Notify Url" value={notifyUrl} onChange={(e) => this.setStateByProps("notifyUrl", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Notify Type
                                </label>

                                <Select
                                    disabled={notifyUrl ? false : true}
                                    name="notifyType"
                                    onChange={(value) => { this.setStateByProps("notifyType", value) }}
                                    value={notifyType}
                                    placeholder="Select notify Type"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="application/json">application/json</Option>
                                    <Option value="application/xml">application/xml</Option>
                                </Select>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Callback Data
                                </label>
                                <Input disabled={notifyUrl ? false : true} placeholder="CallBack Data" value={callBack} onChange={(e) => this.setStateByProps("callBack", e.target.value)} />
                            </Col>
                        </Row>

                        <Row gutter={16} style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Content</label>
                                <label className="red" style={{ marginRight: 8 }}>* </label>
                                <label className="red"> Require Code OTP</label>
                                <div style={{ float: "right", }}>
                                    <label style={{ color: '#bfbfbf' }}>  {this.state.MessageCount} {this.state.MessageCount > 1 ? "Credits" : "Credit"}, {this.state.contentlength}/{this.state.CharacterCount} characters</label>
                                </div>

                                <MultipleTrigger
                                    placeholder={"Your OTP code is xxxx (Ref code)"}
                                    data={this.state.variable} contents={this.state.content}
                                    onmouseup={this.indexcontent}
                                    useonchange={true}
                                    onchange={(contents) => {
                                        this.setState({
                                            content_old: contents,
                                            indexcontent: this.state.indexcontent + 1
                                        }), this.countMessage(contents)
                                    }} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>&nbsp;&nbsp;&nbsp;
                                </label>

                                <Row gutter={32} type="flex" justify="end">

                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Button style={{ marginRight: 8 }} onClick={() => {

                                            var start = this.state.content.substring(0, this.state.indexcontent);
                                            var end = this.state.content.substring(this.state.indexcontent);

                                            let content = this.state.content;

                                            content.includes("{Code OTP}") ? null :
                                                this.setState({ ["content"]: start + "{Code OTP}" + end, indexcontent: content.length + 11 }, function () { this.countMessage(this.state.content) }.bind(this));


                                        }}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Code OTP
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Button style={{ marginRight: 8 }} onClick={() => {

                                            var start = this.state.content.substring(0, this.state.indexcontent);
                                            var end = this.state.content.substring(this.state.indexcontent);

                                            let content = this.state.content;

                                            content.includes("{Ref Code}") ? null :
                                                this.setState({ ["content"]: start + "{Ref Code}" + end, indexcontent: content.length + 11 }, function () { this.countMessage(this.state.content) }.bind(this));


                                        }}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Ref Code
                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <label className=" control-label require" style={{ fontWeight: 700, marginRight: 8 }}>Active OTP</label>
                                <Switch defaultChecked checked={STATUS} onChange={(e) => this.setStateByProps("STATUS", e)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10} style={{ textAlign: "right" }}>
                                <Button key="submit" style={{ marginRight: 8 }}
                                    onClick={() => { this.warningModal(true, "Do you want to discard change?", "Discarded change successfully."), this.setState({ action: "discard" }) }}>
                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                   Discard
                                </Button>
                                <Button type="primary"
                                    disabled={APPNAME && sender && pin_length && Expire && otp_max && content.trim() && (content.trim().includes("{Code OTP}")) ? false : true}
                                    onClick={() => { this.warningModal(true, "Do you want to save this app OTP?", "Saved app OTP successfully."), this.setState({ action: "save" }) }}>
                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalServerError modalVisible={modalVisible}/>

            </div>
        );
    }
}


