/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Popover, Menu } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, colorGreen, colorRed, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableUsers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    width: 200,
                    render: text => (moment(text, "D/M/YYYY H:m").format(dateFormatDateTime))
                },
                {
                    title: 'Create User',
                    dataIndex: 'permission',
                    key: 'permission',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Yes" : "No"}</Tag>)
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Enabled" : "Disabled"}</Tag>)
                    }
                },
                {
                    title: 'Generate Token',
                    dataIndex: 'genToken',
                    key: 'genToken',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Yes" : "No"}</Tag>)
                    }
                },
                {
                    title: 'Two-Factor',
                    dataIndex: 'twoFactor',
                    key: 'twoFactor',
                    width: 130,
                    render: text => {
                        let colors = text == true ? colorGreen : colorRed;
                        return (<Tag color={colors}>{text == true ? "Yes" : "No"}</Tag>)
                    }
                },
                {
                    dataIndex: 'key',
                    key: 'key',
                    width: 50,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu mode="vertical" >
                                    <Menu.Item key="0" onClick={() => {
                                        this.props.setStateByProps("MadalAddUservisible", true),
                                            this.props.setStateByProps("id", key),
                                            this.props.GetUserDetail(key)
                                    }}>
                                        <span className="fa fa-pencil-square-o ants-gray size14"></span>&nbsp;&nbsp;  {this.state.owner && (this.state.admin || this.state.createuser) ? "Edit" : "View"}
                                    </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },
            ],
            pagesize: 10,
            currentpage: 1,
            startrow: 1,
            endrow: 10,
            totalpage: 0,
            clickId: null,
            owner: false,
            admin: false,
            createuser: false,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);

    }


    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData();
        }
        if (this.props.owner !== prevProps.owner) {
            this.setState({ owner: this.props.owner });
        }
        if (this.props.admin !== prevProps.admin) {
            this.setState({ admin: this.props.admin });
        }
        if (this.props.createuser !== prevProps.createuser) {
            this.setState({ createuser: this.props.createuser });
        }
        if (this.props.id !== prevProps.id) {
            this.getData();
        }

    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    accountId: this.props.id,
                    name: search.name,
                    date: [search.dateRange[0] ? search.dateRange[0].format(dateFormat) : null, search.dateRange[1] ? search.dateRange[1].format(dateFormat) : null],
                    startrow: state.startrow,
                    endrow: state.endrow,
                });

                apiClient.post('api/usersmanage/UserList', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                data: json.length > 0 ? json : [],
                                totalpage: json.length > 0 ? json[0].totalpage : 0,
                                currentpage: state.startrow == 1 ? 1 : state.currentpage,
                                startrow: 1,
                                endrow: state.pagesize,
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            startrow: 1,
                            endrow: 10
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var endrow = currentPage * pageSize;
        var startrow = endrow - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize ? currentPage : 1,
                pagesize: pageSize,
                startrow: startrow,
                endrow: endrow,

            };
        });
        this.getData();

    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                this.props.setStateByProps("MadalAddUservisible", true);
                this.props.setStateByProps("id", item.key);
                this.props.GetUserDetail(item.key);
            }
            return {
                clickId: item ? item.key : item,
            }
        })
    }



    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps, footer, templateItem } = this.props;
        return (
            <Table
                style={{ cursor: 'pointer' }}
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                    total: totalpage,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${totalpage} items`,
                    onShowSizeChange: this.onChangePage,
                    onChange: this.onChangePage,
                    current: this.state.currentpage
                }
                }
                onRow={(item, index) => ({
                    onDoubleClick: () => this.setClickId(item)
                })}
                columns={columns}
                dataSource={data}
                className="table-ants"
                footer={() => footer}
                tableLayout="fixed"
            />
        );
    }
}
