import React, { Component } from 'react';


import { Route } from 'react-router';

import { Home } from './components/Home';


export class Console extends Component {
  render() {
    return (
      <ConsoleLayout>
        <Route exact path='/console' />
        <Route path='/console/Home' component={Home} />
      </ConsoleLayout>
    )

  }
}
export class ConsoleLayout extends Component {

  render() {
    return (
      <div className="row m-0">
        <div className="col-md-3 col-xl-2 db-sidebar nav-site ">
         
        </div>
        <main className="col-md-9 col-xl-10 pymd-3 db-content p-0">
           <div className="container-fulid">
          {this.props.children}
        </div>
        </main>
       
      </div>
    );
  }
}