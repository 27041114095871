export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard/',
            icon: 'antsicon-dashboard'
        },
        {
            name: 'Communication',
            url: '/communication/',
            icon: 'antsicon-message',
            children: [
             /*    {
                    name: 'Quick send',
                    url: '/communication/new?type=quicksend',
                }, {
                    name: 'Broadcast',
                    url: '/communication/new?type=broadcast',
                }, */
                {
                    name: 'Template',
                    url: '/communication/template/list/',
                },
                {
                    name: 'Sender',
                    url: '/communication/sender/list/',
                },
            ]
        },
        {
            name: 'People',
            url: '/people/',
            icon: 'antsicon-people',
            children: [
                {
                    name: 'Tag',
                    url: '/people/tag/',
                },
                {
                    name: 'Blacklist',
                    url: '/people/blacklist/',
                },
            ]
        },
        {
            name: 'Report',
            url: '/report/',
            icon: 'antsicon-report',
            children: [
                {
                    name: 'Logs',
                    url: '/report/logs/',
                }
            ]
        },
        {
            name: 'OTP',
            url: '/otp/configuration/',
            icon: 'antsicon-otp',
        },
        {
            name: 'Contact Us',
            url: '/contactus/',
            icon: 'antsicon-issue-report'
        }
    ]
};