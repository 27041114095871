/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber,
    Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal
} from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';

import TableConfigurationOTP from '../../../components/Ants/Table/TableConfigurationOTP';

import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import ModalTestOTP from '../../../components/Ants/Modal/ModalTestOTP';
import ModalVerifyCodeOTP from '../../../components/Ants/Modal/ModalVerifyCodeOTP';

export default class Configuration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            VerifyCodeOTP_visible: false,
            loading: false,
            loading_VerifyCodeOTP: false,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            AppName: "",
            ref_Code: "",
            currentpage: 1,
            pageSize: 10,
            itemOtp: [],
            search: {
                AppName: "",
                currentpage: 1,
                pageSize: 10,
                action: ""
            },
            action: "",
            codeotp: "",
            code_OTP_validate: false,
            result_message: "",
            status: false,
            mobile: "",
            screenwidth: 0,
            Prefix: ""
        };

        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.DeleteOtp = this.DeleteOtp.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleOk_verifyotp = this.handleOk_verifyotp.bind(this);
        this.verifyotp_close = this.verifyotp_close.bind(this);

    }

    componentDidMount() {
        this.onSearch();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 116 : e.offsetWidth - 116 })
                }
            }
        }, 500);
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk(e) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true, codeotp: "", result_message: "" });

            let req = JSON.stringify({
                otcid: this.state.appotp,
                mobile: this.state.mobile,

            });

            apiClient.post('OTP/requestOTP', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    var json = data.data;

                    // if (json.sms_status == 4) {
                    //     this.setState({ result_message: "Credit not enough!" });
                    // } else if (json.sms_status == 9) {
                    //     this.setState({ result_message: json.status.description });
                    // } else {
                    //     this.setState({
                    //         ref_Code: json.refCode,
                    //         OTP_ID: json.otpId
                    //     });

                    //     this.setState({ visible: false, VerifyCodeOTP_visible: true });
                    //     this.setState({ appotp: undefined, codeotp: "", result_message: "" });
                    // }

                    if(json.success.message === "Success") {
                        this.setState({
                            ref_Code: json.referenceCode,
                            OTP_ID: json.otpId,
                            Prefix: json.prefix
                        });

                        this.setState({ visible: false, VerifyCodeOTP_visible: true });
                        this.setState({ appotp: undefined, codeotp: "", result_message: "" });
                    } else {
                        this.setState({ result_message: json.success.message });
                    }

                    //this.showModal();
                    //this.setState({ loading_VerifyCodeOTP: false });
                    this.setState({ loading: false });
                    //this.setState({ visible: false, });
                })
                .catch(error => {

                    Modal.error({
                        title: "Error",
                        className: 'popup-error',
                        onOk() { },
                    });

                    this.setState({ loading: false });
                    this.setState({ visible: false, });
                });
        } else {
            window.location.href = "logout";
        }

    };

    handleCancel(e) {
        this.setState({
            visible: false,
        });
    };

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "deletelist") {
            this.DeleteOtp();

        }
        if (this.state.action == "otpsend") {
            value = false;
            this.handleOk();


            this.setState({ action: "" });
        }
        if (this.state.action == "verifyotp") {
            value = false;
            this.handleOk_verifyotp();
            this.setState({ action: "" });
        }


        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                }, secondsToGo * 1000);
            }
        });
    }

    setStateByProps(parameters, value) {
        this.setState({ [parameters]: value });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    AppName: state.AppName,
                    currentpage: state.currentpage,
                    pageSize: state.pageSize,
                    action: state.action
                }
            }
        });
        this.updateWindowDimensions();
    }

    DeleteOtp() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                otcId: this.state.itemOtp.map(m => m.otcId)
            });

            apiClient.post('OTP/Delete', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();
                    this.setState({ loading: false });
                    this.setState({ action: "" });
                })
                .catch(error => {
                    this.setState({ action: "" });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }


    handleOk_verifyotp() {
        if (!CheckTokenExp()) {
            this.setState({ loading_VerifyCodeOTP: true });

            if (!this.state.codeotp) {
                this.setState({ code_OTP_validate: true })
                this.setState({ loading_VerifyCodeOTP: false });
                return false;
            }

            let req = JSON.stringify({
                otpid: this.state.OTP_ID,
                otpcode: this.state.codeotp,
            });

            apiClient.post('OTP/verifyOTP', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    let result = "Success";

                    // if (json.error) {
                    //     result = json.error.message;
                    // } else {
                    //     result = json.isexprcode ? "OTP Code Expired" : json.iserrcnt ? "Over Limit Usage" : json.result ? "Success" : "Verify Fail";
                    // }
                    result = json.isExprCode ? "OTP Code Expired" : json.isErrorCount ? "Over Limit Usage" : json.result ? "Success" : "Verify Fail";

                    this.setState({ result_message: result, status: json.result, max_use: (parseInt(this.state.max_use) + 1) });

                    this.setState({ loading_VerifyCodeOTP: false });
                })
                .catch(error => {

                    this.setState({ loading_VerifyCodeOTP: false });
                    //this.setState({ VerifyCodeOTP_visible: false, });
                });
        } else {
            window.location.href = "logout";
        }

    }

    verifyotp_close() {
        this.setState({ VerifyCodeOTP_visible: false, otpid: "", otpcode: "", mobile: "", OTP_ID: "", appotp: undefined, codeotp: "", ref_Code: "", result_message: "" });
    }



    render() {
        const { visible, VerifyCodeOTP_visible, loading, loading_VerifyCodeOTP, message, messageSuccess, warningModalVisible,
            successModalVisible, search, mobile, appotp, itemOtp, ref_Code, screenwidth, result_message, status, codeotp, Prefix } = this.state;
        const routes = [
            {
                path: '/otp/configuration/',
                breadcrumbName: 'OTP',
            },
        ];
        var styleinput_name = {
            width: screenwidth,
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>

                </PageHeader>

                <div className="content" >
                    <Card style={{ minHeight: 200 }} >
                        <Row gutter={8} type="flex" justify="space-between" >
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Button key="submit" style={{ marginRight: 8 }}
                                    disabled={itemOtp.length > 0 ? false : true}
                                    onClick={() => { 
                                        this.setState({ action: "deletelist" }), 
                                        this.warningModal(true, "Do you want to delete this app OTP?", "Deleted app OTP successfully.")
                                     }}>
                                    <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                    Delete
                                </Button>
                            </Col>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                <Row gutter={8} type="flex" justify="end">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div style={{ float: "right" }}>
                                            <Link to={'/otp/configuration/otpadd'} style={{ fontSize: 16, color: '#7b7b7b' }} >
                                                <Button type="primary" style={{ marginRight: 8 }}>
                                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                                    New
                                                </Button>
                                            </Link>

                                            <Button onClick={this.showModal}>
                                                <span className="antsicon-otp size14"></span> &nbsp;&nbsp;
                                                Test OTP
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main" >
                            <Col xs={24}>
                                <Input placeholder="App OTP Name" onChange={(e) => this.setStateByProps("AppName", e.target.value)} style={styleinput_name} />

                                <Button icon="search" onClick={this.onSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TableConfigurationOTP search={search} setStateByProps={this.setStateByProps} warningModal={this.warningModal} updateWindowDimensions={this.updateWindowDimensions} />
                            </Col>
                        </Row>


                    </Card>
                </div>

                <ModalTestOTP loading={loading}
                    visible={visible}
                    handleCancel={this.handleCancel}
                    handleOk={this.handleOk}
                    warningModal={this.warningModal}
                    setStateByProps={this.setStateByProps}
                    showModal={this.handleCancel}
                    appotp={appotp}
                    mobile={mobile}
                    Prefix={Prefix}
                    result_message={result_message}
                />

                <ModalVerifyCodeOTP
                    visible={VerifyCodeOTP_visible}
                    loading={loading_VerifyCodeOTP}
                    warningModal={this.warningModal}
                    mobile={mobile}
                    refcode={ref_Code}
                    handleOk={this.handleOk}
                    setStateByProps={this.setStateByProps}
                    result_message={result_message}
                    status={status}
                    verifyotp_close={this.verifyotp_close}
                    codeotp={codeotp}
                    Prefix={Prefix}
                />


                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                    handleOk_verifyotp={this.handleOk_verifyotp}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div>
        );
    }
}


