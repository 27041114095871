import React, { useState, useEffect } from "react";
import {
  Card, Col, Row,
  // CardBody,
  // CardFooter,
  // Input,
  // InputGroup,
  // InputGroupAddon,
} from "reactstrap";
import { Slider, InputNumber } from "antd";
import { useHistory } from "react-router-dom";
// import Button1 from "../../../components/Button/Button1";
import { apiClient } from "../../../api";
import Flower from "../../../img/flower.svg"
import ClockWhite from "../../../img/clock(white).svg"
import LetterWhite from "../../../img/letter(white).svg"
import WriteWhite from "../../../img/write(white).svg"
import ClockGray from "../../../img/clock(gray).svg"
import LetterGray from "../../../img/letter(gray).svg"
import WriteGray from "../../../img/write(gray).svg"

import {
  token
} from "../../../config";

export default function PackageList() {
  let history = useHistory();

  const [model, setModel] = useState({
    inputValue: 499,
    val: 499 / 200, //หาร 200 เพราะว่า 1 ช่อง มีค่า 2000:10
  });

  const [data, setData] = useState([]);

  const marks = {
    0: "",
    10: "2,000",
    20: "2,500",
    30: "5,000",
    40: "10,000",
    50: "50,000",
    60: "100,000",
    70: "250,000",
    80: "500,000",
    90: "1,000,000",
    100: "",
  };

  const onClickToPage = (id, type) => {
    const encodedData = btoa(id); // encode a string
    history.push("RequestTaxInfo?pid=" + encodedData);
  };

  const onChangeInput = (value) => {
    let cal = 10;
    if (value > 0 && value <= 2000) {
      cal = value / 200;
    } else if (value > 2000 && value <= 2500) {
      cal = 10 + value / 250;
    } else if (value > 2500 && value <= 5000) {
      cal = 20 + value / 500;
    } else if (value > 5000 && value <= 10000) {
      cal = 30 + value / 4000;
    } else if (value > 10000 && value <= 50000) {
      cal = 40 + value / 5000;
    } else if (value > 50000 && value <= 100000) {
      cal = 50 + value / 15000;
    } else if (value > 100000 && value <= 250000) {
      cal = 60 + value / 25000;
    } else if (value > 250000 && value <= 500000) {
      cal = 70 + value / 50000;
    } else if (value > 500000 && value <= 1000000) {
      cal = 80 + value / 100000;
    } else if (value > 1000000 && value <= 2000000) {
      cal = 90 + value / 200000;
    }
    setModel({
      inputValue: value,
      val: cal,
    });
  };

  const onChangeSlider = (value) => {
    let cal = 10;
    let minVal = 499 / 200; //input min 499
    let maxVal = 80 + 999999 / 100000; //input max 999999
    if (value <= minVal) {
      value = minVal;
      cal = 499;
    } else if (value > minVal && value <= 10) {
      cal = value * 200;
    } else if (value > 10 && value <= 20) {
      if (value % 10 === 0) {
        cal = 2000 + 10 * 50;
      } else {
        cal = 2000 + (value % 10) * 50;
      }
    } else if (value > 20 && value <= 30) {
      if (value % 10 === 0) {
        cal = 2500 + 10 * 250;
      } else {
        cal = 2500 + (value % 10) * 250;
      }
    } else if (value > 30 && value <= 40) {
      if (value % 10 === 0) {
        cal = 5000 + 10 * 500;
      } else {
        cal = 5000 + (value % 10) * 500;
      }
    } else if (value > 40 && value <= 50) {
      if (value % 10 === 0) {
        cal = 10000 + 10 * 4000;
      } else {
        cal = 10000 + (value % 10) * 4000;
      }
    } else if (value > 50 && value <= 60) {
      if (value % 10 === 0) {
        cal = 50000 + 10 * 5000;
      } else {
        cal = 50000 + (value % 10) * 5000;
      }
    } else if (value > 60 && value <= 70) {
      if (value % 10 === 0) {
        cal = 100000 + 10 * 15000;
      } else {
        cal = 100000 + (value % 10) * 15000;
      }
    } else if (value > 70 && value <= 80) {
      if (value % 10 === 0) {
        cal = 250000 + 10 * 25000;
      } else {
        cal = 250000 + (value % 10) * 25000;
      }
    } else if (value > 80 && value < maxVal) {
      if (value % 10 === 0) {
        cal = 500000 + 10 * 50000;
      } else {
        cal = 500000 + (value % 10) * 50000;
      }
    } else {
      value = maxVal;
      cal = 999999;
    }
    // else if (value > 90 && value <= 100) {
    //   if (value % 10 === 0) {
    //     cal = 1000000 + 10 * 100000;
    //   } else {
    //     cal = 1000000 + (value % 10) * 100000;
    //   }
    // }
    setModel({
      inputValue: cal,
      val: value,
    });
  };

  const fetchData = async () => {
    //api
    let req = null;

    apiClient.post("api/ecom/getPackagedata", req, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        setData(response.data.PackageList);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatCurrency = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const ClearCookie = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time;
    now.setTime(expireTime);
    document.cookie =
      "__AntiXsrfUerkey=" + "" + ";expires=" + now.toGMTString() + ";path=/";
    // this.props.history.push("/PackageList");
    localStorage.clear();
  };

  return (
    <div className="app">
      <div className="package">
        <div>
          <p
            style={{
              textAlign: "center",
              margin: "20px 0px",
              fontSize: "3rem",
              fontWeight: "bold",
            }}
          >
            How many SMS do you plan to send?
          </p>
        </div>
        {/*  <div className="message-volume">
          <Slider
            style={{ width: "100%" }}
            tooltipVisible={false}
            marks={marks} //defaultValue={20}
            onChange={onChangeSlider}
            value={typeof model.val === "number" ? model.val : 0}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputNumber
              min={499}
              max={999999}
              style={{ marginLeft: 16 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={model.inputValue}
              onChange={onChangeInput}
            />
            <span
              style={{
                marginLeft: "10px",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              Message
            </span>
          </div>
        </div> */}
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data &&
            data.length > 0 &&
            data.map((obj, index) => {
              if (obj.PackageType === 2) {
                return (
                  <Card key={index} className="card-package">
                    <h4 className="card-title">Demo Trial</h4>
                    <p className="sub-title">Free</p>
                    <div className="card-content">
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {formatCurrency(obj.Package_Creditstart)} Messages
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {obj.PackagePrice_per_credit} THB/Message
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>No Limit</span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {obj.Package_Sender} Sender Name
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {formatCurrency(obj.Package_People)} Maximum people
                        </span>
                      </div>
                    </div>
                    <div style={{ margin: "0px 20px 20px" }}>
                      <Card
                        className={"button-black"}
                        onClick={() => {
                          ClearCookie();
                          let pid =
                            obj.PackageId + "|" + obj.Package_Creditstart;
                          onClickToPage(pid, obj.PackageType);
                        }}
                      >
                        <h5 style={{ color: "#fff", margin: "0px" }}>
                          Start Free Trial
                        </h5>
                      </Card>
                    </div>
                  </Card>
                );
              } else if (obj.PackageType === 1 && obj.Package_Recommend) {
                return (
                  <div key={index}>
                    <div className="recommend-top-card">
                      <h3 style={{ color: "white" }}>
                        Recommend
                      </h3>
                    </div>
                    <Card className="card-package-center-recommend">
                      {/* <div className="d-flex justify-content-center mt-4">
                        <img src={Flower} />
                      </div> */}
                      <h3 className="card-title-recommend">{obj.PackageName}</h3>
                      <div style={{ textAlign: "center", margin: "15px" }}>
                        {/* <div className="plan-title">
                            {obj.PackagePrice_per_credit}
                          </div> */}
                        <div style={{ fontSize: "25px" }}>
                          <span className="plan-title">
                            {obj.PackagePrice_per_credit}
                          </span>
                          <span style={{ color: '#ff5e00', fontSize: '40px' }}>THB/SMS</span>
                        </div>
                        <hr className="hr-card-recommend" />
                        <div style={{ fontSize: "30px" }}>
                          <div style={{ fontSize: "40px" }}>
                            Price
                          </div>
                          <span className="plan-sub-title-recommned">
                            {(obj.Packagetotal_price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                          ฿
                        </div>
                      </div>
                      <div className="card-content">
                        <Row>
                          <Col xl="6" style={{ borderRight: '1px solid white' }}>
                            <div className="text-desc-recommned">
                              <img className="icon-card" src={ClockWhite} />
                              <div style={{ display: "inline-block", fontSize: "26px", marginLeft: "10px" }}>
                                Account
                              </div>
                              <div style={{ display: "inline-block", fontSize: "26px", marginLeft: "35px" }}>
                                Credit period
                              </div>
                            </div>
                            <div className="text-desc-recommned">
                              <img className="icon-card" src={ClockWhite} />
                              <div style={{ display: "inline-block", fontSize: "26px", marginLeft: "10px" }}>
                                Detailed
                              </div>
                              <div style={{ display: "inline-block", fontSize: "26px", marginLeft: "35px" }}>
                                Report period
                              </div>
                            </div>
                            <div className="text-desc-recommned">
                              <img className="icon-card" src={LetterWhite} />
                              <div style={{ display: "inline-block", fontSize: "26px", marginLeft: "10px" }}>
                                SMS Qty
                              </div>
                            </div>
                            <div className="text-desc-recommned mt-3">
                              <img className="icon-card" src={LetterWhite} />
                              <span style={{ fontSize: "26px" }}>
                                Sender name
                              </span>
                            </div>
                          </Col>
                          <Col xl="6">
                            <div className="text-desc-recommned" style={{ marginTop: "0.7em" }}>
                              <span style={{ fontSize: "40px" }}>
                                {obj.Package_Packageexpire} Month
                              </span>
                            </div>
                            <div className="text-desc-recommned" style={{ marginTop: "0.6em" }}>
                              <span style={{ fontSize: "40px" }}>
                                {obj.Package_Reportexpire} Month
                              </span>
                            </div>
                            <div className="text-desc-recommned">
                              <span style={{ fontSize: "40px" }}>
                                {obj.Packagetotal_message}
                              </span>
                            </div>
                            <div className="text-desc-recommned">
                              <span style={{ fontSize: "40px" }}>
                                {obj.Package_Sender === 0 ? "Unlimited" : obj.Package_Sender}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        {/* <div className="text-desc">
                          <div className="box"><div className="inner-box"></div></div>
                          <span style={{ fontSize: "20px" }}>
                            {formatCurrency(obj.Packagetotal_message)} Messages
                          </span>
                        </div>
                        <div className="text-desc">
                          <div className="box"><div className="inner-box"></div></div>
                          <span style={{ fontSize: "20px" }}>
                            {obj.PackagePrice_per_credit} THB/Message
                          </span>
                        </div> */}
                        {/*  <div className="text-desc">
                          <div className="box"><div class="inner-box"></div></div>
                          <span style={{ fontSize: "22px" }}>No Limit</span>
                        </div>
                        <div className="text-desc">
                          <div className="box"><div class="inner-box"></div></div>
                          <span style={{ fontSize: "22px" }}>
                            {obj.Package_Sender} Sender Name
                          </span>
                        </div>
                        <div className="text-desc">
                          <div className="box"><div class="inner-box"></div></div>
                          <span style={{ fontSize: "22px" }}>
                            {formatCurrency(obj.Package_People)} Maximum people
                          </span>
                        </div> */}
                      </div>
                      <div style={{ margin: "0px 30px 20px" }}>
                        <Card
                          className={"button-white"}
                          onClick={() => {
                            let pid =
                              obj.PackageId + "|" + obj.Packagetotal_price;

                            console.log(pid);
                            onClickToPage(pid, obj.PackageType);
                          }}
                        >
                          <h2 style={{ color: "#ff5e00", margin: "0px" }}>Buy now</h2>
                        </Card>
                      </div>
                    </Card>
                  </div>
                );

              } else if (obj.PackageType === 1 && !obj.Package_Recommend) {


                return (
                  <Card key={index} className="card-package-center">
                    {/* <div className="d-flex justify-content-center mt-4">
                      <img src={Flower} />
                    </div> */}
                    <h3 className="card-title">{obj.PackageName}</h3>
                    <div style={{ textAlign: "center", margin: "15px" }}>
                      {/* <div className="plan-title">
                          {obj.PackagePrice_per_credit}
                        </div> */}
                      <div style={{ fontSize: "25px" }}>
                        <span className="plan-title">
                          {obj.PackagePrice_per_credit}
                        </span>
                        <span style={{ color: '#ff5e00', fontSize: '40px' }}>THB/SMS</span>
                      </div>
                      <hr className="hr-card" />
                      <div style={{ fontSize: "30px" }}>
                        <div style={{ fontSize: "40px", color: "#19164F" }}>
                          Price
                        </div>
                        <span className="plan-sub-title">
                          {(obj.Packagetotal_price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        ฿
                      </div>
                    </div>
                    <div className="card-content">
                      <Row>
                        <Col xl="6" style={{ borderRight: '1px solid #19164F' }}>
                          <div className="text-desc">
                            <img className="icon-card" src={ClockGray} />
                            <span style={{ fontSize: "20px" }}>
                              Credit period
                            </span>
                          </div>
                          <div className="text-desc">
                            <img className="icon-card" src={ClockGray} />
                            <span style={{ fontSize: "20px" }}>
                              Report period
                            </span>
                          </div>
                          <div className="text-desc">
                            <img className="icon-card" src={LetterGray} />
                            <span style={{ fontSize: "20px" }}>
                              SMS Qty
                            </span>
                          </div>
                          <div className="text-desc">
                            <img className="icon-card" src={WriteGray} />
                            <span style={{ fontSize: "20px" }}>
                              Sender name
                            </span>
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="text-desc">
                            <span style={{ fontSize: "20px" }}>
                              {obj.Package_Packageexpire} Month
                            </span>
                          </div>
                          <div className="text-desc">
                            <span style={{ fontSize: "20px" }}>
                              {obj.Package_Reportexpire} Month
                            </span>
                          </div>
                          <div className="text-desc">
                            <span style={{ fontSize: "20px" }}>
                              {obj.Packagetotal_message}
                            </span>
                          </div>
                          <div className="text-desc">
                            <span style={{ fontSize: "20px" }}>
                              {obj.Package_Sender === 0 ? "Unlimited" : obj.Package_Sender}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {/* <div className="text-desc">
                        <div className="box"><div className="inner-box"></div></div>
                        <span style={{ fontSize: "20px" }}>
                          {formatCurrency(obj.Packagetotal_message)} Messages
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"><div className="inner-box"></div></div>
                        <span style={{ fontSize: "20px" }}>
                          {obj.PackagePrice_per_credit} THB/Message
                        </span>
                      </div> */}
                      {/*  <div className="text-desc">
                        <div className="box"><div class="inner-box"></div></div>
                        <span style={{ fontSize: "22px" }}>No Limit</span>
                      </div>
                      <div className="text-desc">
                        <div className="box"><div class="inner-box"></div></div>
                        <span style={{ fontSize: "22px" }}>
                          {obj.Package_Sender} Sender Name
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"><div class="inner-box"></div></div>
                        <span style={{ fontSize: "22px" }}>
                          {formatCurrency(obj.Package_People)} Maximum people
                        </span>
                      </div> */}
                    </div>
                    <div style={{ margin: "0px 30px 20px" }}>
                      <Card
                        className={"button-orange"}
                        onClick={() => {
                          let pid = obj.PackageId + "|" + obj.Packagetotal_price;
                          onClickToPage(pid, obj.PackageType);
                        }}
                      >
                        <h2 style={{ color: "white", margin: "0px" }}>Buy now</h2>
                      </Card>
                    </div>
                  </Card>
                );

              } else {
                return (
                  <Card key={index} className="card-package">
                    <h4 className="card-title">Enterprise</h4>
                    <p className="sub-title">Custom</p>
                    <div className="card-content">
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {`>=`} {formatCurrency(obj.Package_Creditstart)}{" "}
                          Messages
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {obj.PackagePrice_per_credit} THB/Message
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>No Limit</span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {obj.Package_Sender === 0
                            ? "No Limit"
                            : obj.Package_Sender}{" "}
                          Sender Name
                        </span>
                      </div>
                      <div className="text-desc">
                        <div className="box"></div>
                        <span style={{ fontSize: "26px" }}>
                          {formatCurrency(obj.Package_People)} Maximum people
                        </span>
                      </div>
                    </div>
                    <div style={{ margin: "0px 20px 20px" }}>
                      <Card
                        className={"button-black"}
                        onClick={() => {
                          let pid =
                            obj.PackageId + "|" + obj.Package_Creditstart;
                          onClickToPage(pid, obj.PackageType);
                        }}
                      >
                        <h5 style={{ color: "#fff", margin: "0px" }}>
                          Contact Sales
                        </h5>
                      </Card>
                    </div>
                  </Card>
                );
              }
            })}
          {/* <Row>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card className="card-package">
                <h4 className="card-title">Demo Trial</h4>
                <p className="sub-title">Free</p>
                <div className="card-content">
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>5,000 Messages</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>0.65 THB/Message</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>Limit</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>1 Sender Name</span>
                  </div>
                </div>
                <div style={{ margin: "0px 20px 20px" }}>
                  <Card
                    className={"button-black"}
                    onClick={() =>
                      toOrderDetail("D60CA92B-94A1-4AE1-9BDC-407F6512FA2A")
                    }
                  >
                    <h5 style={{ color: "#fff", margin: "0px" }}>
                      Start Free Trial
                    </h5>
                  </Card>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card className="card-package-center">
                <h3 className="card-title">Plane Name</h3>
                <div style={{ textAlign: "center", margin: "30px" }}>
                  <div className="plan-title">0.65</div>
                  <div style={{ fontSize: "1.8rem" }}>THB / Message</div>
                  <div style={{ fontSize: "25px" }}>
                    Total Price <span className="plan-sub-title">2000000</span>{" "}
                    THB
                  </div>
                </div>
                <div className="card-content">
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>5,000 Messages</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>0.65 THB/Message</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>Limit</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>1 Sender Name</span>
                  </div>
                </div>
                <div style={{ margin: "0px 20px 20px" }}>
                  <Card
                    className={"button-orange"}
                    onClick={() =>
                      toOrderDetail("D60CA92B-94A1-4AE1-9BDC-407F6512FA2A")
                    }
                  >
                    <h5 style={{ color: "#fff", margin: "0px" }}>Buy</h5>
                  </Card>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card className="card-package">
                <h4 className="card-title">Enterprise</h4>
                <p className="sub-title">Custom</p>
                <div className="card-content">
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>5,000 Messages</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>0.65 THB/Message</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>Limit</span>
                  </div>
                  <div className="text-desc">
                    <div className="box"></div>
                    <span style={{ fontSize: "26px" }}>1 Sender Name</span>
                  </div>
                </div>
                <div style={{ margin: "0px 20px 20px" }}>
                  <Card
                    className={"button-black"}
                    onClick={() =>
                      toOrderDetail("D60CA92B-94A1-4AE1-9BDC-407F6512FA2A")
                    }
                  >
                    <h5 style={{ color: "#fff", margin: "0px" }}>
                      Start Free Trial
                    </h5>
                  </Card>
                </div>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </div>
  );
}
