import React, { Component } from 'react';
import { Select } from 'antd';

import { token } from '../../../config';

const { Option } = Select;

export default class SelectStatusReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [
                { id: 4, name: "RECURRING" },
                { id: 0, name: "PENDING" },
                { id: 1, name: "IN PROGRESS" },
                { id: 2, name: "COMPLETED" },
                { id: 5, name: "EXPIRED" },
                { id: 9, name: "ERROR" }],
            select: [],
        };

        //this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        //this.getData();
    }

    //getData() {
    //    this.state.list.length > 0 ? "" : this.setState({ loading: true });
    //    let req = JSON.stringify({

    //    });
    //    apiClient.post('api/report/Reportstatus', req
    //        , {
    //            headers: {
    //                'Authorization': `Bearer ${token}`,
    //                'Content-type': 'application/json; charset=UTF-8'
    //            }
    //        })
    //        .then(data => {
    //            var json = data.data;
    //            this.setState({ list: json });

    //            this.setState({ loading: false });
    //        })
    //        .catch(error => {
    //            this.setState({ loading: false });
    //        });
    //}

    render() {
        const { loading, list } = this.state;
        const { placeholder, select, setStateByProps, style } = this.props;
        return (
            <Select

                showSearch
                allowClear

                style={style ? style : null}
                loading={loading}
                value={select}
                name="select"
                suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                placeholder={placeholder ? placeholder : "Status"}
                onChange={(value) => setStateByProps("statusReport", value)}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    list.map((item) =>
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                }
            </Select>
        );
    }
}


