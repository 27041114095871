/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker,
    Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal, Tabs
} from 'antd';

//import ModalAddAccount from '../../../../components/Ants/Modal/ModalAddAccount';

import { token, validateEmail } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalSuccess from '../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import SubAccountDetail from '../SubAccountDetail/';
import SubAccountUsers from '../SubAccountUsers/';
import Sender from '../Sender/';
//import Account from '../Account';
//import SubAccounts from '../SubAccounts';
//import Users from '../Users';
//import CreditsMovement from '../CreditsMovement';

moment.tz.setDefault("Asia/Bangkok");

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class SubAccountDetailList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            search: {
                name: "",
                dateRange: [null, null]
            },
            page: "",
            templateItem: [],
            action: "",
            dateRange: [null, null],
            screenwidth: 0,
            MadalAddAccountvisible: false,
            accname: "",
            email: "",
            username: "",
            password: "",
            paytype: 1,
            addstatusacc: 1,
            creactsubeaccount: false,
            createaccount: false,
            type: 0,
            emailError: false,
            errorMessage: "",
            dsender: "",
            setsubtabs: "subaccount",
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.callbackView = this.callbackView.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 })
                }
            }
        }, 500);
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "createacc") {
            this.setState({ action: "" });
            this.accountCreate();
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "username") {
            if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
                this.setState({ [parameters]: value });
            }
        } else {
            this.setState({ [parameters]: value });

            if (parameters == "email") {
                this.setState({ emailError: validateEmail(value) });
            } else if (parameters == "uemail") {
                this.setState({ uemailError: validateEmail(value) });
            }
        }
        this.setState({ errorMessage: "" });
    }

    handleOk() {
        this.setState({
            MadalAddAccountvisible: false, usercreate: ""
        });
    };

    callbackView(key) {
        this.setState({
            setsubtabs: key,
        });
    }

    render() {
        const { loading, message, messageSuccess, warningModalVisible, successModalVisible, users, search, templateItem, action, dateRange, screenwidth,
            MadalAddAccountvisible, accname, email, username, password, paytype, addstatusacc, repassword, passwordcheck,
            creactsubeaccount, createaccount, type, emailError, errorMessage, dsender, owner, admin, createuser, setsubtabs } = this.state;
        const { accdetailId, discard, subaccount } = this.props;
        const routes = [
            {
                path: 'accountmanagement',
                breadcrumbName: 'Account Management',
            },
        ];
        var styleinput_name = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };
        var styleinput_date = {
            width: (screenwidth / 2),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 200
        };

        return (
            <div className="animated fadeIn">

                <div className="content">

                    <Tabs activeKey={setsubtabs} type="card" onTabClick={this.callbackView}>
                        <TabPane tab="SUB ACCOUNT" key="subaccount">
                            <SubAccountDetail accdetailId={accdetailId} discard={discard} />
                        </TabPane>
                        <TabPane tab="USERS" key="users">
                            <SubAccountUsers accdetailId={accdetailId} />
                        </TabPane>
                        <TabPane tab="SENDER" key="sender">
                            <Sender accdetailId={accdetailId} subaccount={subaccount} setsubtabs={setsubtabs} />
                        </TabPane>
                    </Tabs>

                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div>
        );
    }
}


