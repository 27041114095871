/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input,
    Card, Tooltip, Progress, Table, Divider, Tag, Modal, InputNumber, Spin, Radio
} from 'antd';
import SelectAccount from "../../components/Ants/Select/SelectAccountMacc";
import TableCreditMovementLog from '../../components/Ants/Table/TableCreditMovementLog';
import ModalSuccess from '../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../components/Ants/Modal/ModalWarning';
import { apiClient } from '../../api';
import { token, formatNumber, colorGreen, colorRed, getPaytype } from '../../config';
import moment from 'moment';
import 'moment-timezone';
import ModalServerError from '../../components/Ants/Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const { MonthPicker, RangePicker, WeekPicker, } = DatePicker;

export default class CreditMovement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screenwidth: 0,
            loading: false,
            search: {
                accname: "",
                dateRange: [null, null]
            },
            accountId: "",
            dateRange: [null, null],
            message: "",
            messageSuccess: "",
            warningModalVisible: false,
            successModalVisible: false,
            accname: "",
            amount: undefined,
            paytype: undefined,
            balance: undefined,
            errormsg: "",
            maxCredit: 0,
            mypaytype: 1,
            remark: "",
            actionCredit: "add",
            creditmove: this.props.creditmove,
            modalVisible: false,
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.getCreditMovementAccount = this.getCreditMovementAccount.bind(this);
        this.getcredit = this.getcredit.bind(this);
        this.addcredit = this.addcredit.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        this.getcredit();
        if (this.props.creditaccId !== undefined) {
            this.setState({ account: this.props.creditaccId, creditmove: this.props.creditmove, account_name: this.props.creditaccName })
            this.setState(state => {
                return {
                    search: {
                        accname: this.props.creditaccName,
                        dateRange: [null, null]
                    }
                }
            });
            this.getCreditMovementAccount(this.props.creditaccId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.creditmove !== prevProps.creditmove) {
            this.setState({ account: this.props.creditaccId, creditmove: this.props.creditmove, account_name: this.props.creditaccName })
            this.setState(state => {
                return {
                    search: {
                        accname: this.props.creditaccName,
                        dateRange: [null, null]
                    }
                }
            });
            this.getCreditMovementAccount(this.props.creditaccId);
        }
    }


    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124 });

                }
            }
        }, 500);
    }

    setStateByProps(parameters, value) {
        value ? value : "";

        if (parameters == "account") {
            this.setState({ paytype: undefined, balance: undefined });
            if (value != undefined) {
                this.setState(state => {
                    return {
                        search: {
                            accname: state.accountname,
                            dateRange: state.dateRange
                        }
                    }
                });
            }
            else {
                this.setState(state => {
                    return {
                        search: {
                            accname: "",
                            dateRange: [null, null]
                        }
                    }
                });
            }
            this.getCreditMovementAccount(value);

            this.setState({ [parameters]: value });
        } else if (parameters == "amount") {
            this.setState({ [parameters]: parseInt(value) });
        }
        else {
            this.setState({ [parameters]: value });
        }

    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "addcredit") {
            this.setState({ action: "" });
            this.addcredit();
            value = false;
        }

        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                }, secondsToGo * 1000);
            }
        });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    accname: state.accname,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    getCreditMovementAccount(id) {
        if (id) {
            this.setState({ loading: true });

            this.setState(state => {
                apiClient.get('api/CreditMovement/GetCreditMovementAccount/' + id
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState(state => {
                            return {
                                paytype: json ? json.paytype : 1,
                                balance: json ? json.balance : 0,
                                errormsg: json ? json.errormsg : "Data Not Found",
                            };
                        });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            paytype: 1,
                            balance: 0,
                            errormsg: "Data Not Found",
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            this.setState({ paytype: undefined, balance: undefined, });
        }

    }

    getcredit() {
        this.setState({ loading: true });
        let req = JSON.stringify({

        });

        apiClient.post('api/credit/get_credit_byuser', req
            , {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(data => {
                var json = data.data;
                var pay_type = getPaytype();
                this.setState({ maxCredit: json ? pay_type == "1" ? json.balance : pay_type == "2" ? 1000000000 : 0 : 0 });
                this.setState({ mypaytype: json ? pay_type : 0 });
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ modalVisible: true });
                this.setState({ maxCredit: 0, mypaytype: 0 });
                this.setState({ loading: false });
            });
    }

    addcredit() {
        this.setState({ loading: true });

        this.setState(state => {
            let req = JSON.stringify({
                accountId: state.account,
                amount: state.actionCredit == "add" ? state.amount : (state.amount) * -1,
                remark: state.remark
            });

            apiClient.post('api/creditmovement/AddCreditMovement', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ loading: false });

                    this.setState({ account: undefined, amount: undefined, paytype: undefined, balance: undefined, remark: "", actionCredit: "add" });

                    this.onSearch();
                    this.getcredit();
                    this.props.onGetcreditbalance();

                    if (!json) {
                        Modal.error({
                            className: 'popup-error',
                            title: 'Error',
                            onOk: () => Modal.destroyAll(),
                            content: 'Invalid credit amount specified',
                            icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                            zIndex: 1300
                        });
                    } else {
                        this.successModal(true);
                        this.modalcountDown();
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });

                    this.getcredit();

                });
        });
    }



    render() {
        const { loading, search, message, messageSuccess, warningModalVisible, successModalVisible, screenwidth,
            dateRange, account, amount, accname, paytype, balance, maxCredit, mypaytype, remark, actionCredit, account_name, errormsg, modalVisible } = this.state;
        const routes = [
            {
                path: 'creditMovement',
                breadcrumbName: 'Credits Movement',
            },
        ];
        var styleinput = {
            width: (screenwidth / 3) - 62,
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput2 = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 180
        };
        var styleinput3 = {
            width: 140,
            marginRight: 8,
            marginBottom: 8,
            minWidth: 140
        };
        const colorAdd = actionCredit == "add" ? colorGreen : "";
        const colorRemove = actionCredit == "remove" ? colorRed : "";
        return (
            <div className="animated fadeIn" >

                <div className="content">
                    <Card>
                        <Spin spinning={loading}>
                            <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                <Col xs={24} >
                                    <div style={{ fontWeight: 700 }}>
                                        <label className="control-label require" style={styleinput}>Account Name</label>

                                        <label className="control-label require" style={styleinput3}>Action</label>

                                        {
                                            mypaytype == 1 ?
                                                <label className="control-label require" style={styleinput}> Amount of Credits d<label className="red">*</label> (Credits balance {maxCredit ? formatNumber(maxCredit) : 0})</label>
                                                :
                                                <label className="control-label require" style={styleinput}>Amount of Credits s<label className="red">*</label> </label>
                                        }

                                        <label className="control-label require" style={styleinput}>Description</label>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                <Col xs={24} >
                                    <SelectAccount showSearch={true} mode={"-"} select={account} selectname={account_name} setStateByProps={this.setStateByProps} style={styleinput} />

                                    <Radio.Group onChange={(e) => this.setStateByProps("actionCredit", e.target.value)}
                                        value={actionCredit} style={styleinput3} buttonStyle="solid">
                                        <Radio.Button style={{ backgroundColor: colorRemove, borderColor: colorRemove }} value="remove">Remove</Radio.Button>
                                        <Radio.Button style={{ backgroundColor: colorAdd, borderColor: colorAdd }} value="add">Add</Radio.Button>
                                    </Radio.Group>

                                    <InputNumber min={0}
                                        max={actionCredit == "add" ? maxCredit : actionCredit == "remove" ? paytype == 2 ? 0 : balance : 0} style={styleinput}
                                        placeholder="Amount Credits" value={amount}
                                        onChange={(e) => this.setStateByProps("amount", e)}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    />


                                    <Input name="remark" placeholder="Description" value={remark} style={styleinput}
                                        onChange={(e) => {
                                            this.setStateByProps("remark", e.currentTarget.value)
                                        }} />

                                    <Button type="primary" style={{ marginRight: 8 }}
                                        disabled={account && amount && actionCredit ? false : true}
                                        onClick={() => { this.warningModal(true, "Do you want to add credits to this account?", "Add credits successfully."), this.setStateByProps('action', 'addcredit') }}>
                                        <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                        Save
                                    </Button>

                                </Col>
                            </Row>


                            {
                                paytype ?
                                    <Row gutter={8} className="row-nowrap" >
                                        <Col xs={10} >
                                            {
                                                paytype == 2 ?
                                                    errormsg !== "" ?
                                                        <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                                            <Col xs={10} >
                                                                <label className="control-label require" style={{ color: 'red' }}> Data Not Found </label>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                                            <Col xs={10} >
                                                                <label className="control-label require"> Payment type : Postpaid </label>
                                                            </Col>
                                                        </Row>
                                                    :
                                                    errormsg !== "" ?
                                                        <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                                            <Col xs={10} >
                                                                <label className="control-label require" style={{ color: 'red' }}> Data Not Found </label>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row gutter={8} type="flex" justify="start" className="row-nowrap" id="main">
                                                            <Col xs={10} >
                                                                <label className="control-label require"> Balance : {balance ? formatNumber(balance) : 0} CREDITS</label>
                                                            </Col>
                                                            <Col xs={10} >
                                                                <label className="control-label require"> Payment type : {paytype == 1 ? "Prepaid" : "Postpaid"} </label>
                                                            </Col>
                                                        </Row>
                                            }
                                        </Col>

                                    </Row>
                                    : null
                            }
                        </Spin>
                    </Card>

                    <Card>
                        <Row gutter={8} className="row-nowrap" id="main">
                            <h3>Transaction List</h3>
                        </Row>
                        <Row gutter={8} className="row-nowrap" id="main">
                            <Col xs={24} style={{ textAlign: "end" }}>
                                <RangePicker
                                    placeholder={["Start Date", "End Date"]}
                                    style={styleinput2}
                                    defaultValue={dateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                        'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                        'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')]
                                    }}
                                    onChange={(value) => this.setStateByProps("dateRange", value)}
                                    value={dateRange}
                                    format={dateFormat} />

                                <Input placeholder="Search by account or transection" style={styleinput2} value={accname} onChange={(e) => this.setStateByProps("accname", e.target.value)} />


                                <Button icon="search" onClick={this.onSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>

                        <Row gutter={8} type="flex" justify="center">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <TableCreditMovementLog
                                    loading={loading}
                                    search={search}
                                    warningModal={this.warningModal}
                                    setStateByProps={this.setStateByProps}
                                    updateWindowDimensions={this.updateWindowDimensions}
                                />

                            </Col>
                        </Row>



                    </Card>

                    <ModalWarning loading={loading}
                        warningModalVisible={warningModalVisible}
                        warningModal={this.warningModal}
                        successModal={this.successModal}
                        modalcountDown={this.modalcountDown}
                        message={message}
                        messageSuccess={messageSuccess}
                    />

                    <ModalSuccess loading={loading}
                        successModalVisible={successModalVisible}
                        successModal={this.successModal}
                        messageSuccess={messageSuccess}
                    />




                </div>

                <ModalServerError modalVisible={modalVisible} />
            </div>
        );
    }
}