/* eslint-disable */
import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import {
  PageHeader,
  Button,
  Descriptions,
  Select,
  Row,
  Col,
  DatePicker,
  Icon,
  Form,
  Input,
  Card,
  Tooltip,
  Progress,
  Table,
  Divider,
  Tag,
  Modal,
  Checkbox,
  Spin,
} from "antd";

import { apiClient } from "../../../api";
import { root, getGenToken, getTwoFactor } from "../../../config";
import { token, validateEmail, CheckTokenExp } from "../../../config";

import TableAccounts from "../../../components/Ants/Table/TableAccounts";
import ModalAddAccount from "../../../components/Ants/Modal/ModalAddAccount";
import SubAccountDetailList from "../SubAccountDetailList/";

import ModalSuccess from "../../../components/Ants/Modal/ModalSuccess";
import ModalWarning from "../../../components/Ants/Modal/ModalWarning";

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = "DD/MM/YYYY";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

export default class SubAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningModalVisible: false,
      successModalVisible: false,
      loading: false,
      message: "",
      messageSuccess: "",
      name: "",
      search: {
        name: "",
        dateRange: [null, null],
      },
      page: "",
      templateItem: [],
      action: "",
      dateRange: [null, null],
      screenwidth: 0,
      MadalAddAccountvisible: false,
      accname: "",
      email: "",
      username: "",
      password: "",
      paytype: 1,
      addstatusacc: 1,
      creactsubeaccount: true,
      createaccount: false,
      generateToken: true,
      twoFactor: true,
      type: 0,
      emailError: false,
      errorMessage: "",
      dsender: undefined,
      accdetailId: "",
      listview: this.props.listview,
      detailview: this.props.detailview,
      subaccount: this.props.subaccount,
      senderlist: [],
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.warningModal = this.warningModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.modalcountDown = this.modalcountDown.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setStateByProps = this.setStateByProps.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.accountCreate = this.accountCreate.bind(this);
    this.setViewChangePage = this.setViewChangePage.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.listview !== prevProps.listview) {
      this.setState({
        subaccount: this.props.subaccount,
        listview: this.props.listview,
        detailview: this.props.detailview,
      });
    }
  }

  updateWindowDimensions() {
    setTimeout(() => {
      var root = document.getElementById("root");
      if (root) {
        var e = document.getElementById("main");
        if (e) {
          var scroll = root.offsetHeight > window.innerHeight;
          this.setState({
            screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124,
          });
        }
      }
    }, 500);
  }

  warningModal(value, message, messageSuccess) {
    this.setState({ warningModalVisible: value, message, messageSuccess });
  }

  successModal(value) {
    if (this.state.action == "createacc") {
      this.setState({ action: "" });
      this.accountCreate();
    }

    this.setState({ successModalVisible: value });
  }

  modalcountDown() {
    let secondsToGo = 2;
    const timer = setInterval(() => {}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.setState({ successModalVisible: false });
    }, secondsToGo * 1000);
  }

  onSearch() {
    this.setState((state) => {
      return {
        search: {
          name: state.name,
          dateRange: state.dateRange,
        },
      };
    });
    this.updateWindowDimensions();
  }

  setStateByProps(parameters, value) {
    value ? value : "";

    if (parameters == "username") {
      if (value.match(/^[A-Za-z0-9 /./+/*/@/\\_-]+$/i) || value == "") {
        this.setState({ [parameters]: value });
      }
    } else {
      this.setState({ [parameters]: value });

      if (parameters == "email") {
        this.setState({ emailError: validateEmail(value) });
      } else if (parameters == "uemail") {
        this.setState({ uemailError: validateEmail(value) });
      }
    }
    this.setState({ errorMessage: "" });
  }

  handleCreateAccount() {
    this.setState({
      MadalAddAccountvisible: true,
      usercreate: "",
      accname: "",
      email: "",
      username: "",
      password: "",
      repassword: "",
      paytype: 1,
      addstatusacc: 1,
      creactsubeaccount: true,
      generateToken: getGenToken() === "True" ? true : false,
      twoFactor: getTwoFactor() === "True" ? true : false,
      type: 0,
      emailError: false,
      errorMessage: "",
      dsender: undefined,
      clearsender: true,
    });
  }

  handleOk() {
    this.setState({
      MadalAddAccountvisible: false,
      usercreate: "",
    });
  }

  accountCreate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({
        name: this.state.accname,
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
        paytype: this.state.paytype,
        status: this.state.addstatusacc,
        creactsubeaccount:
          this.state.creactsubeaccount == 0
            ? false
            : this.state.creactsubeaccount == 1
            ? true
            : this.state.creactsubeaccount,
        generateToken:
          this.state.generateToken == 0
            ? false
            : this.state.generateToken == 1
            ? true
            : this.state.generateToken,
          twoFactor:
          this.state.twoFactor == 0
            ? false
            : this.state.twoFactor == 1
            ? true
            : this.state.twoFactor,
        sender: this.state.dsender,
        senderlist: this.state.senderlist,
      });

      apiClient
        .post("api/usersmanage/AccountCreate", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;

          if (json.resultCode == "000") {
            this.onSearch();
            this.handleOk();

            this.setState({
              accname: "",
              email: "",
              username: "",
              password: "",
              repassword: "",
              paytype: 1,
              addstatusacc: 1,
              creactsubeaccount: false,
              generateToken: false,
              twoFactor: false,
            });
          } else {
            this.setState({
              errorMessage: json.message,
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({
            accname: "",
            email: "",
            username: "",
            password: "",
            repassword: "",
            paytype: 1,
            addstatusacc: 1,
            creactsubeaccount: false,
            generateToken: false,
            twoFactor: false,
          });
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  setViewChangePage(id) {
    this.setState({
      listview: "none",
      detailview: "block",
      accdetailId: id,
    });
  }

  render() {
    const {
      loading,
      message,
      messageSuccess,
      warningModalVisible,
      successModalVisible,
      users,
      search,
      templateItem,
      action,
      dateRange,
      screenwidth,
      MadalAddAccountvisible,
      accname,
      email,
      username,
      password,
      paytype,
      addstatusacc,
      repassword,
      passwordcheck,
      creactsubeaccount,
      generateToken,
      twoFactor,
      emailError,
      errorMessage,
      dsender,
      visible,
      listview,
      detailview,
      accdetailId,
      senderlist,
      subaccount,
      clearsender,
    } = this.state;
    const { createaccount, type, setCreditAccId, discard } = this.props;
    const routes = [
      {
        path: "accountmanagement",
        breadcrumbName: "Account Management",
      },
    ];
    var styleinput_name = {
      width: screenwidth / 2,
      marginRight: 8,
      marginBottom: 8,
      minWidth: 200,
    };
    var styleinput_date = {
      width: screenwidth / 2,
      marginRight: 8,
      marginBottom: 8,
      minWidth: 200,
    };
    return (
      <div className="content" id="main">
        <div style={{ display: listview }}>
          <Row gutter={8} className="row-nowrap" id="main">
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Input
                placeholder="Account name"
                onChange={(e) => this.setStateByProps("name", e.target.value)}
                style={{ marginBottom: 10 }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <RangePicker
                placeholder={["Start Date", "End Date"]}
                defaultValue={dateRange}
                ranges={{
                  Today: [moment().startOf("day"), moment().endOf("day")],
                  "Last 7 Days": [moment().subtract(6, "d"), moment()],
                  "Last Week": [
                    moment().subtract(1, "weeks").startOf("week"),
                    moment().subtract(1, "weeks").endOf("week"),
                  ],
                  "Last 15 Days": [moment().subtract(14, "d"), moment()],
                  "Last 30 Days": [moment().subtract(29, "d"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={(value) => this.setStateByProps("dateRange", value)}
                style={{ marginBottom: 10 }}
                value={dateRange}
                format={dateFormat}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Button
                style={{ marginBottom: 10 }}
                icon="search"
                onClick={this.onSearch}
              >
                Search
              </Button>
            </Col>
            {createaccount ? (
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Row type="flex" justify="end">
                  <Button type="primary" onClick={this.handleCreateAccount}>
                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                    Create Account
                  </Button>
                </Row>
              </Col>
            ) : null}
          </Row>

          <Row gutter={8} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TableAccounts
                subaccount={subaccount}
                search={search}
                templateItem={templateItem}
                action={action}
                warningModal={this.warningModal}
                setStateByProps={this.setStateByProps}
                updateWindowDimensions={this.updateWindowDimensions}
                setViewChangePage={(e) => this.setViewChangePage(e)}
                setCreditAccId={setCreditAccId}
              />
            </Col>
          </Row>
        </div>

        <div style={{ display: detailview }}>
          <SubAccountDetailList
            accdetailId={accdetailId}
            discard={discard}
            subaccount={subaccount}
          />
        </div>

        <ModalAddAccount
          loading={loading}
          setStateByProps={this.setStateByProps}
          visible={MadalAddAccountvisible}
          handleOk={this.handleOk}
          accname={accname}
          email={email}
          username={username}
          password={password}
          paytype={paytype}
          statusacc={addstatusacc}
          repassword={repassword}
          warningModal={this.warningModal}
          passwordcheck={passwordcheck}
          creactsubeaccount={creactsubeaccount}
          generateToken={generateToken}
          twoFactor={twoFactor}
          type={type}
          dsender={dsender}
          emailError={emailError}
          errorMessage={errorMessage}
          senderarr={senderlist}
          clearsender={clearsender}
        />

        <ModalWarning
          loading={loading}
          warningModalVisible={warningModalVisible}
          warningModal={this.warningModal}
          successModal={this.successModal}
          modalcountDown={this.modalcountDown}
          message={message}
          messageSuccess={messageSuccess}
        />

        <ModalSuccess
          loading={loading}
          successModalVisible={successModalVisible}
          successModal={this.successModal}
          messageSuccess={messageSuccess}
        />
      </div>
    );
  }
}
