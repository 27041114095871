import { apiClient } from "../../../api"
import { token, CheckTokenExp, } from "../../../config";
import { TransectionStatus } from "../../../models/transection";

const TranserctionService = {

    async downloadTaxInvoice(rid, taxinfoid) {
        let url = "";
        let loading = true;

        if (CheckTokenExp()) {
            window.location.href = "/logout";
        }

        let req = JSON.stringify({
            tiid: taxinfoid,
        });

        await apiClient
            .post("api/ecom/downloadTaxInvoice", req, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
                responseType: "arraybuffer",
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/octet-stream",
                });
                url = URL.createObjectURL(blob);
            })
            .catch((error) => {
                console.table(error);
            })
            .finally((data) => {
                loading = false;
            });
        return { url, loading }
    },

    async getReceiptDetail(rid) {
        const result = {
            loading: true,
            message: "",
            data: null
        }
        let req = JSON.stringify({
            rid: rid,
        });

        await apiClient
            .post("api/ecom/getreceiptdetail", req, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                if (response.data.status == 999) {
                    result.message = response.data.message + " " + response.data.description;
                    result.loading = true;
                } else {
                    result.loading = false;
                    result.data = response.data;
                }
            })
            .catch((error) => {
                result.message = `${error.message} ${error.description === undefined ? "" : error.description}`;
            });

        return result;
    },

    async getOrderDetail(oid) {
        const result = {
            loading: true,
            message: "",
            data: null
        }
        let req = JSON.stringify({
            oid: oid,
        });

        await apiClient
            .post("api/ecom/getorderdetail", req, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                if (response.data.status == 999) {
                    result.message = response.data.message + " " + response.data.description;
                    result.loading = true;
                } else {
                    result.loading = false;
                    result.data = response.data;
                }
            })
            .catch((error) => {
                result.message = `${error.message} ${error.description === undefined ? "" : error.description}`;
            });

        return result;
    },

    async createOrFindOrder(pid) {
        const result = {
            loading: true,
            message: null,
            data: null
        }

        let req = JSON.stringify({
            pid: pid
        });

        await apiClient
            .post("api/ecom/createorderwithpackage", req, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                result.data = response.data;
            })
            .catch((error) => {
                result.message = error.response.data
            });
        return result;
    },

    async createCharge(req) {
        const result = {
            loading: true,
            message: null,
            data: null
        }

        await apiClient
            .post("api/ecom/createchargefromsource", req, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
            .then((response) => {
                result.data = response.data;
            })
            .catch((error) => {
                result.message = error.response.data
            });
        return result;
    },

    mapTransectionStatus(status) {
        let result = "";
        switch (status) {
            case TransectionStatus.PENDING.code:
                result = TransectionStatus.PENDING.name;
                break;
            case TransectionStatus.SUCCESS.code:
                result = TransectionStatus.SUCCESS.name;
                break;
            case TransectionStatus.FAIL.code:
                result = TransectionStatus.FAIL.name;
                break;
            case TransectionStatus.EXPIRED.code:
                result = TransectionStatus.EXPIRED.name;
                break;
            case TransectionStatus.REVERSED.code:
                result = TransectionStatus.REVERSED.name;
                break;
            default:
                break;
        }
        return result;
    }
}

export default TranserctionService;