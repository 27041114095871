import React, { Component, useEffect, useState } from 'react';
import { Container, Card, CardBody, CardFooter, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Button, Icon, Radio, Spin, Row, Col, notification } from 'antd';
import { CheckUserLogedin, getAccId } from '../../../../config';
import { apiClient, urlClientInfo } from '../../../../api';
import { token, set_cookie, getExpiredDate } from '../../../../config';
import queryString from 'query-string';
import { TransectionErrorType } from "../../../../models/transection";

class OrderValidate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errormessage: "",
            loading: true,
            statusmessage: "Checking your order.",
            statusdescription: null,
            statusred: false,
            redirectUrl: "",
            showCreateOrderButton: false,
            showRefreshButton: false,
            loading: true,
            name: null,
            mobile: null,
            branch: null,
            idcard: null,
            address: null,
            province: null,
            district: null,
            subdistrict: null,
            post: null,
            savedefault: true,
            new_taxvoice: true,
            taxvoice_id: null,
            infolist: null,
            discountcode: null,
            tmpvalue: null,
            expireOrderDate: null,
            timeRemaining: { minutes: 0, seconds: 0 },
        }

        this.countdownInterval = null;
        this.checkOrderStatus = this.checkOrderStatus.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.setUrl = this.setUrl.bind(this);
    }

    componentDidMount() {
        const oid = queryString.parse(this.props.location.search).oid;
        if (!CheckUserLogedin() || oid === undefined) {
            this.props.history.push("/404");
        } else {
            this.setState({ infolist: sessionStorage.getItem("taxinfo") });
            this.setState({ discountlist: sessionStorage.getItem("discount") }, () => { console.table(this.state.discountlist) })
            this.getClientInfo();
        }
    }

    componentWillUnmount() {
        clearInterval(this.countdownInterval);
    }

    startCountdown() {
        if (this.state.expireOrderDate) {
            const targetTime = new Date(this.state.expireOrderDate);
            this.countdownInterval = setInterval(() => {
                const now = new Date();
                const timeDifference = targetTime - now;

                if (timeDifference > 0) {
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
                    this.setState({ timeRemaining: { minutes, seconds } });
                } else {
                    clearInterval(this.countdownInterval);
                    this.setState({ timeRemaining: { minutes: 0, seconds: 0 } });
                    window.location.reload();
                }
            }, 1000);
        }
    }

    setUrl(url, time = 3000) {
        setTimeout(() => {
            this.props.history.push(url);
        }, time);
    }

    setMessage(message, description = null, isred = false) {
        this.setState({
            statusmessage: String(message).replace("_", " "),
            statusdescription: description,
            statusred: isred
        });
    }

    getClientInfo() {
        urlClientInfo.get(''
            , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                this.setState({
                    clientInfo: {
                        ipString: response.data.ipString,
                        userAgent: response.data.userAgent
                    }
                }, () => {
                    this.checkOrderStatus();
                });
            })
            .catch(error => {
                this.setState({ clientInfo: "" });
            });
    }

    checkOrderStatus() {
        const oid = queryString.parse(this.props.location.search).oid
        let req = {
            "oid": oid,
            "client": this.state.clientInfo,
            "TaxInfo": JSON.parse(this.state.infolist),
        };

        if (this.state.discountlist) {
            req.discount = JSON.parse(this.state.discountlist);
        }

        apiClient.post('api/ecom/validateOrder', JSON.stringify(req),
            { headers: { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => {
                this.handleEventOrderStatus(response.data);
            })
            .catch(error => {
                this.setMessage(error.message, "Please try again later. Or contact support with order id : " + queryString.parse(this.props.location.search).oid, true);
                this.setState({
                    showRefreshButton: true
                });
            })
            .finally(f => {
                this.setState({
                    loading: false,
                });
            });
    }

    handleEventOrderStatus(response) {
        switch (response.status) {
            case TransectionErrorType.ERROR:
                this.setMessage(response.message, response.description, true);
                this.setState({
                    redirectUrl: response.redirectTarget,
                    showCreateOrderButton: true
                });
                break;
            case TransectionErrorType.CREATE_RECEIPT:
            case TransectionErrorType.ADD_CREDIT_LOG:
            case TransectionErrorType.INNTERNAL_ERROR:
            case TransectionErrorType.NOT_FOUND_ORDER:
                this.setMessage(response.message, response.description, true);
                break;
            case TransectionErrorType.UPDATE_STATUS_ORDER_FAIL:
                this.setMessage(response.message, response.description);
                this.setState({
                    showRefreshButton: true
                });
                break;
            case TransectionErrorType.OMISE_CHARGE_NOT_SUCCESS:
                this.setMessage(response.message, response.description);
                this.setState({
                    redirectUrl: response.redirectTarget,
                    showCreateOrderButton: true
                });
                break;
            case TransectionErrorType.ORDER_PENDING:
                this.setMessage(response.message, response.description);
                this.setState({
                    showRefreshButton: true,
                    tmpvalue: response.tmpvalue,
                    expireOrderDate: response.order_expire_date
                });
                this.startCountdown();
                break;
            case TransectionErrorType.ORDER_FAILED:
            case TransectionErrorType.ORDER_REVERSED:
            case TransectionErrorType.ORDER_EXPIRED:
                this.setMessage(response.message, response.description);
                this.setState({
                    redirectUrl: response.redirectTarget,
                    showCreateOrderButton: true
                });
                break;
            case TransectionErrorType.SUCCESS:
                this.setMessage(response.message, response.description);
                this.setUrl(response.redirectTarget);
                break;
            default:
                this.setMessage(response.message, response.description);
                break;
        }
    }

    render() {
        let { statusmessage, statusdescription, statusred, showCreateOrderButton, showRefreshButton, redirectUrl, loading, tmpvalue, expireOrderDate, timeRemaining } = this.state;

        return (
            <div className="app flex-row align-items-center orderdetailbg">
                <Col xs={0} lg={8} xl={12}>&nbsp;</Col>
                <Col xs={24} lg={16} xl={12}>
                    <Card className="mx-4">
                        <CardBody className="p-4">
                            <Row className="">
                                <Row className="text-center">
                                    <img style={{ width: "auto", maxWidth: "400px" }} src={tmpvalue} width="400px" />
                                </Row>
                                <Col className={statusred ? "justify-content-center order-status-error" : "justify-content-center"}>
                                    <Row className="text-center" style={{ display: tmpvalue !== null ? 'block' : 'none' }}>
                                        <Col xs={24} style={{ fontSize: "30px" }}>
                                            {
                                                timeRemaining.minutes > 0 || timeRemaining.seconds > 0
                                                    ? `Please pay within ${timeRemaining.minutes.toString().padStart(2, '0')}:${timeRemaining.seconds.toString().padStart(2, '0')}`
                                                    : `Processing...`
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="text-center"><Col xs={24}>{statusmessage}</Col></Row>
                                    {(statusdescription != "" && statusdescription != null)
                                        ? <Row><Col xs={24} className="text-center">{statusdescription}</Col></Row>
                                        : null
                                    }
                                    {(showCreateOrderButton) ?
                                        <Row className="mt-2">
                                            <Col xs={24} className="text-center">
                                                <Button type="dash" style={{}} onClick={() => this.setUrl(redirectUrl, 0)}>Create new order</Button>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                    {(showRefreshButton) ?
                                        <Row className="mt-2">
                                            <Col xs={24} className="text-center">
                                                <Button type="dash" style={{}} onClick={() => window.location.reload()}>Check your order again</Button>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                </Col>
                            </Row>
                            {loading ?
                                <Row className="mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <Spin size="large" />
                                    </Col>
                                </Row>
                                : null
                            }
                        </CardBody>
                    </Card>
                </Col >
            </div >
        );
    }
}

export default OrderValidate;
