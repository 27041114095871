import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row ,Col} from 'antd';


export default class Card1 extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { title, detail } = this.props;

        return (
            <Card className="card1" >
                <div className="card1-head">
                    <h1>{title}</h1>
                </div>
                <div className="card1-label">
                    <label className="ants-gray-low">{detail}</label>
                    </div>
            </Card>
        );
    }
}


