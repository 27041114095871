/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Button, Drawer, Upload, Modal } from 'antd';
import { token, getFileExtension, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import _ from 'lodash';

const maxsize_file = 10485760;
const maxNameLength = 90;
//const filemax = 10;

export default class ButtonUpload extends Component {

    constructor(props) {
        super(props);

        this.onbeforeUpload = this.onbeforeUpload.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.getColumnsFile = this.getColumnsFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);

        this.state = {
            fileList: [],
            fileloading: false,
            variable: [],
            to: [],
            file_size: 0,
            filemax: 10,
            errorMsg: []
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.to !== this.state.to) {
            this.setState({ to: nextProps.to });
        }
        if (nextProps.fileList !== this.state.fileList) {
            this.setState({ fileList: nextProps.fileList });

            if (nextProps.fileList.length > 0) {
                this.setState({ variable: [], file_size: 0 });
                this.props.setStateByProps("variable", []);

                for (var i = 0; i < nextProps.fileList.length; i++) {
                    var file = nextProps.fileList[i];
                    this.getColumnsFile(file);
                }
            }
        }

        if (nextProps.filemax !== this.state.filemax) {
            this.setState({ filemax: nextProps.filemax });
        }

    }

    componentDidMount() {
        //if (this.props.filemax) {
        //    this.props.filemax = filemax;
        //}
        this.setState({result:true});
    }

    onbeforeUpload(file) {
        

        this.setState(state => {
            if (state.fileList.map(item => item.name).includes(file.name)) {

                const msg = 'File is a duplicate.\n';
                return {...state , errorMsg: _.uniq([...state.errorMsg, msg])}
                
                // Modal.error({
                //     className: 'popup-error',
                //     title: 'Error',
                //     onOk: () => Modal.destroyAll(),
                //     content: 'This file is a duplicate.',
                //     icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                //     zIndex: 1300
                // });
            } else {
                

                if (getFileExtension(file.name) == "xlsx") {
                    const size = state.fileList.length > 0 ? state.fileList.map((item) => item.size).reduce((size, item) => size + item) + file.size : file.size;
                    const fileName = file.name.split(".")[0];
                    let isMaxLength = false;

                    for (let ofile of state.fileList) {
                        
                        if(ofile.name.split(".")[0] > maxNameLength){
                            isMaxLength = true
                        }
                    }

                    this.setState(state => {
                        this.props.setStateByProps("file_size", size);
                        return {
                            file_size: size
                        }
                    });

                    if (file.size > maxsize_file || size > maxsize_file) {

                        const msg = 'File size limit 10 MB.\n';                           
                        return {...state , errorMsg: _.uniq([...state.errorMsg, msg])}

                        

                        // Modal.error({
                        //     className: 'popup-error',
                        //     title: 'Error',
                        //     onOk: () => Modal.destroyAll(),
                        //     content: 'File size limit 10 MB',
                        //     icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                        //     zIndex: 1300
                        // });


                    }
                    else if (state.fileList.length > this.state.filemax - 1) {

                        const msg = `Upload maximum ${this.state.filemax} item.\n`;
                        return {...state , errorMsg: _.uniq([...state.errorMsg, msg])}

                        // Modal.error({
                        //     className: 'popup-error',
                        //     title: 'Error',
                        //     onOk: () => Modal.destroyAll(),
                        //     content: `Upload maximum ${this.state.filemax} item.`,
                        //     icon: < Icon type="close-circle" style={{ fontSize: 36 }} />,
                        //     zIndex: 1300
                        // });

     
                    } 
                    else if (fileName.length > maxNameLength || isMaxLength) {
                        
                        const msg = 'File name size limit 90 characters.\n';
                        return {...state , errorMsg: _.uniq([...state.errorMsg, msg])}

                        
                        // Modal.error({
                        //     className: 'popup-error',
                        //     title: 'Error',
                        //     onOk: () => Modal.destroyAll(),
                        //     content: 'File name size limit 90 characters.',
                        //     icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                        //     zIndex: 1300
                        // });

     
                    }                   
                    else {
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        this.getColumnsFile(file);

                        var value = [...state.to, file.name].filter((item) => !item.includes("person_") == true).filter((item) => !item.includes("tag_") == true);

                        this.props.setStateByProps("fileList", [...state.fileList, file]);
                        this.props.setStateByProps("to", value);
                        //this.props.setStateByProps("file_size", state.file_size + file.size);

                        return {
                            fileList: [...state.fileList, file],
                            to: [...state.to, file.name],
                            file_size: state.file_size + file.size,
                        };
                    }
                } else {

                    const msg = 'File type error\n';
                    return {...state , errorMsg: _.uniq([...state.errorMsg, msg])}
    
                    

                    // Modal.error({
                    //     className: 'popup-error',
                    //     title: 'Error',
                    //     onOk: () => Modal.destroyAll(),
                    //     content: 'File type error',
                    //     icon: <Icon type="close-circle" style={{ fontSize: 36 }} />,
                    //     zIndex: 1300
                    // });
                }
            }
        });
    };

    handleChange(info) {
        if (info.file.status !== 'uploading') {
            this.setState({ fileloading: true });
        }

        if (info.file.status === 'done') {
            this.setState({ fileloading: false });
        } else if (info.file.status === 'error') {
            this.setState({ fileloading: false });
        }
    };

    clearErrorMsg = e => {
        this.setState({ errorMsg: [] });
    };

    handleRemove(file) {
        this.setState({ errorMsg: [] });
        this.setState({ file_size: 0 });
        this.props.setStateByProps("file_size", 0);

        this.setState(state => {
            const index = state.fileList.map((item) => item.uid).indexOf(file);
            const newFileList = state.fileList.slice();
            var value = state.to.filter((item) => !item.includes(".xlsx") == true);
            newFileList.splice(index, 1);
            state.variable = [];
            for (var i = 0; i < newFileList.length; i++) {
                try {
                    var fileList = newFileList[i];
                    this.getColumnsFile(fileList);
                    this.props.setStateByProps("file_size", state.file_size + fileList.size);
                    this.setState({ file_size: state.file_size + fileList.size });

                }
                catch (err) {

                }
            }

            newFileList.length < 1 ?
                this.props.setStateByProps("variable", ["firstName", "lastName", "address", "city", "country", "gender", "birthDate"]) : [];

            this.props.setStateByProps("fileList", newFileList);
            this.props.setStateByProps("to", [...newFileList.map((item) => item.name), ...value]);


            return {
                fileList: newFileList,
                to: [...newFileList.map((item) => item.name), ...value],

            };
        });
    };


    getColumnsFile(fileList) {
        if (!CheckTokenExp()) {
            const formData = new FormData();

            formData.append("files[]", fileList);

            apiClient.post('api/GetColumns/Multi', formData
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    let variable = [...new Set(this.state.variable)];
                    //this.setState({ variable: [] });

                    const arr = json.columns.filter((item) => !variable.includes(item));
                    var value = this.state.to.filter((item) => !item.includes("tag_") == true).filter((item) => !item.includes("person_") == true);
                    this.setState(state => {
                        this.props.setStateByProps("variable", [...state.variable, ...arr]);
                        this.props.setStateByProps("to", value);
                        //this.props.setStateByProps("file_size", state.file_size + json.size);
                        return {
                            variable: [...state.variable, ...arr],
                            to: value,
                            file_size: json.size
                        }
                    });
                })
                .catch(error => {

                });
        } else {
             window.location.href = "logout";
        }
        
    }

    downloadFile(item, name) {
        if (!CheckTokenExp()) {
            apiClient.get('api/file/download/' + item
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = name;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }


    render() {
        const { uploadfile_visible, uploadfile_onClose, uploadfile_showDrawer, GetdataDraft, filemax, labeltype } = this.props;
        const { fileloading, fileList, errorMsg } = this.state;

        const props = {
            name: 'file',
            multiple: true,
            accept: ".xlsx",
            showUploadList: false,
            onChange: this.handleChange,
            beforeUpload: this.onbeforeUpload,
            customRequest: ()=>false,
        };

        return (
            <div>
                <Drawer
                    title={<h5>Import file</h5>}
                    placement="right"
                    zIndex="1020"
                    closable={true}
                    onClose={uploadfile_onClose}
                    visible={uploadfile_visible}
                    width={380}
                >
                    <Upload {...props}>
                        <Button type="primary" style={{ marginRight: 8 }} onClick={this.clearErrorMsg}>
                            <span className="fa fa-folder-open-o"></span> &nbsp;&nbsp;
                           Select file
                        </Button>

                    </Upload>

                    <label className=" control-label require">{`File type * xlsx maximum upload file size total 10 MB or ${filemax} ${filemax > 1 ? "items" : "item"}`}</label>
                    <label className=" control-label text-danger" style={{ whiteSpace: 'pre-line'}}>{errorMsg}</label>
                
                    <hr />

                    {
                        fileList ?
                            fileList.map((file,index) => (
                                <div key={index}>
                                    {
                                        fileList[fileList.length - 1].uid == file.uid ?
                                            fileloading ? <Icon type="loading" /> : <Icon type="paper-clip" /> : <Icon type="paper-clip" />
                                    }
                                    <span style={{ color: '#7b7b7b', cursor: 'pointer' }} onClick={() => this.downloadFile(file.url, file.name)}> {file.name}{" "} </span>
                                    <a onClick={() => this.handleRemove(file.uid)}><Icon type="close" style={{ color: "#d2aaaa" }} /></a>

                                </div>
                            )) : ""
                    }
                </Drawer>

                <Button style={{ marginRight: 8 }} onClick={uploadfile_showDrawer}>
                    <span className="fa fa-cloud-upload"></span> &nbsp;&nbsp;
                    Import
                        </Button>

            </div>

        );
    }
}

