import React from "react";
import { Button } from "antd";

export default function ButtonBase(props) {
  return (
    <>
      <Button {...props} className="buttonBase">
        {props.children}
      </Button>
    </>
  );
}
