import React, { Component } from 'react';
import { Select } from 'antd';
import { token } from '../../../config';

const { Option } = Select;

export default class SelectPayType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            select: [],

        };
    }

    componentDidMount() {

    }



    render() {
        const { loading, list } = this.state;
        const { placeholder, select, setStateByProps, style, disabled } = this.props;
        return (
            <Select
                disabled={disabled}
                style={style ? style : null}
                loading={loading}
                name="select"
                onSearch={(value) => { this.getData(value) }}
                onChange={(value) => setStateByProps("paytype", value)}
                value={select}
                suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                placeholder={placeholder ? placeholder : "Payment Type"}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <option value={1}>Prepaid</option>
                <option value={2}>Postpaid</option>
            </Select>
        );
    }
}


