import React, { Component } from 'react';
import { Radio } from 'antd';
import { colorGreen, colorRed } from '../../../config';


export default class ButtonGroupSubAcc extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        const { placeholder, radio, setStateByProps, style, tocreate, disabled } = this.props;

        const colorOn = radio == 1 ? colorGreen : "";
        const colorOff = radio == 0 ? colorRed : "";
        return (
            <Radio.Group style={style ? style : null} value={radio}
                onChange={(e) => setStateByProps(tocreate ? tocreate : "tocreate", e.target.value)}
                buttonStyle="solid" size="small">
                <Radio.Button value={0} style={{ backgroundColor: colorOff, borderColor: colorOff }} disabled={disabled} >OFF</Radio.Button>
                <Radio.Button value={1} style={{ backgroundColor: colorOn, borderColor: colorOn }} disabled={disabled} >ON</Radio.Button>
            </Radio.Group>
        );
    }
}