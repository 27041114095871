/* eslint-disable */
import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Avatar } from 'antd';

import { token } from '../../../config';

const { Option, OptGroup } = Select;

export default class InputTo extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleChangemoretagandperson = this.handleChangemoretagandperson.bind(this);

        this.state = {
            to: [],
            fileList: [],
            file_size: 0,
            PeopleLists: [],
            TagsLists: [],
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.to !== this.state.to) {
            this.setState({ to: nextProps.to });
        }
        if (nextProps.fileList !== this.state.fileList) {
            this.setState({ fileList: nextProps.fileList });
        }
        if (nextProps.file_size !== this.state.file_size) {
            this.setState({ file_size: nextProps.file_size });
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props.PeopleLists !== prevProps.PeopleLists) {
            this.setState({ PeopleLists: this.props.PeopleLists });
        }
        if (this.props.TagsLists !== prevProps.TagsLists) {
            this.setState({ TagsLists: this.props.TagsLists });
        }
    }

    onChange(value) {
    }

    onSearch(val) {
    }

    handleChangemoretagandperson(e, option) {
        // let length = e.length > 0 ? e.reduce((x, y) => x + y).length : 0;
        // if (length > 1000) {
        let length = e.toString().length;
        if (length > this.props.maxLength) {
            this.props.setStateByProps("errorLength","Maximum limit 1,000 charactors");
        } else {
            var value_to = this.state.to.length > 0 ? this.state.to.length : 0;
            var value_e = e.length > 0 ? e.length : 0;
            if (value_e >= value_to) {
                if (e[e.length - 1].includes("tag_") || e[e.length - 1].includes("person_")) {
                    var value = e.filter((item) => !item.includes(".xlsx") == true);
                    this.setState({
                        to: value,
                        fileList: [],
                        variable: []
                    });
                    this.props.setStateByProps("to", value);
                    this.props.setStateByProps("fileList", []);
                    this.props.setStateByProps("variable", ["firstName", "lastName", "address",
                        "city", "country", "gender", "birthDate"]);
                }
                else {
                    e[e.length - 1].includes(".xlsx") ? "" : this.setState({ to: e });
                    this.props.setStateByProps("to", e);
                }
            } else {
                this.setState({ to: e });
                this.props.setStateByProps("to", e);
            }
        }
    }

    render() {
        const { to, PeopleLists, TagsLists, } = this.state;
        const { loading, GetTagList, GetPeopleList } = this.props;
        return (
            <Select
                loading={loading}
                value={to}
                mode="tags"
                placeholder="Enter new numbers."
                tokenSeparators={[',', ' ']}
                onChange={this.handleChangemoretagandperson}
                style={{ width: '100%', borderColor: this.state.to_require == true ? 'red' : '#d9d9d9' }}
                filterOption={(input, option) =>
                    () => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                dropdownRender={menu => (
                    <div></div>
                )}
            >
            </Select>
        );
    }
}


