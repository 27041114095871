import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col } from 'antd';

const { Option } = Select;

export default class SelectPinLenght extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sender: undefined,
        };

    }

    render() {
        const { loading, list } = this.state;
        const { placeholder, select, setStateByProps } = this.props;
        return (
            <Select
                showSearch
                allowClear
                value={select}
                placeholder={placeholder ? placeholder : "Pin Lenght"}
                suffixIcon={<span className="fa fa-angle-down" />}
                onChange={(value) => { setStateByProps("pin_length", value) }}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Option value={4}>4 digit</Option>
                <Option value={5}>5 digit</Option>
                <Option value={6}>6 digit</Option>
                <Option value={7}>7 digit</Option>
                <Option value={8}>8 digit</Option>
                <Option value={9}>9 digit</Option>
                <Option value={10}>10 digit</Option>
            </Select>
        );
    }
}


