import React, { Component } from 'react';
import { Button, notification } from 'antd';
import { omisekey, sellername, currency } from '../../config';
class OmiseTrueMoney extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnLoginloading: false
        }

        this.callOmise = this.callOmise.bind(this);
    }

    componentDidMount() {
        const OmiseCard = window.OmiseCard;

        OmiseCard.configure({
            publicKey: omisekey,
            frameLabel: sellername,
        });

        this.setState({
            OmiseCreditCard: OmiseCard
        })
    }

    callOmise(e) {
        e.preventDefault();
        this.props.setLoading(true);
        this.props.setMessage("Waiting for your truemoney info...", null);
        /*this.props.setAllowToPayStatus(false);*/

        window.OmiseCard.open({
            amount: this.props.packagecost * 100,
            currency: currency,
            defaultPaymentMethod: "truemoney",
            onCreateTokenSuccess: (nonce) => {
                console.table(nonce);
                if (nonce.startsWith("src_")) {
                    if (this.props.AutoCheckout) {
                        this.props.ProcessPayment(nonce, "truemoney_jumpapp");
                    }
                } else {
                    notification.open({
                        message: 'Failed to create payment',
                        description: "Cannot connect to Payment operator. Please try again later.",
                        top: 60,
                        className: "rti_form r",
                    });
                };
            },
            onFormClosed: () => {
                this.props.setLoading(false);
            }
        });
    }

    beforeSubmit(e) {
        console.table(this.state);
        e.preventDefault();
    }

    render() {
        let { btnLoginloading } = this.state;

        return (

            <form id="CheckOutWithTrueMoney" onSubmit={this.beforeSubmit}>
            <Button
              style={{ backgroundColor: "#EA7326", color: "#fff" }}
              loading={btnLoginloading}
              onClick={this.callOmise}
            >
              Payment
            </Button>
          </form>


           /*  <form id="CheckOutWithTrueMoney" onSubmit={this.beforeSubmit}>
                <Button type="dash" style={{}} loading={btnLoginloading} onClick={this.callOmise}>Pay with Truemoney</Button>
            </form> */
        )
    }
}

export default OmiseTrueMoney;