/* eslint-disable */
import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {
    Form,Input,DatePicker,TimePicker,Select,Cascader,InputNumber,Icon,Row,Col,
    Button,Tooltip,Checkbox,Spin,PageHeader,Card,Menu,Dropdown,Modal,  
} from 'antd';
import { apiClient } from '../../api';
import {token,CheckTokenExp} from '../../config';
import moment from 'moment';
import 'moment-timezone';
import SelectReportType from '../../components/Ants/Select/SelectReportType';
import SelectStatusReport from '../../components/Ants/Select/SelectStatusReport';
import TableCardReport from '../../components/Ants/Table/TableCardReport';
import ModalSuccess from '../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../components/Ants/Modal/ModalWarning';

moment.tz.setDefault("Asia/Bangkok");

const {Option} = Select;
const {MonthPicker,RangePicker,WeekPicker} = DatePicker;
const dateFormat = 'DD/MM/YYYY';

export default class ReportNew extends Component {
    constructor(props) {
        super(props);

        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.disabledDate = this.disabledDate.bind(this);

        this.state = {
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            account: [],
            users: [],
            name: "",
            startdata: 1,
            enddata: 10,
            template: 0,
            reportType: undefined,
            statusReport: undefined,
            dateRange: [null,null],
            key: [],
            search: {
                account: [],
                name: "",
                startdata: 1,
                enddata: 10,
                template: 0,
                users: [],
                statusReport: "",
                startdate: "",
                enddate: "",
                search:true
            },
            screenwidth: 0,
            width:0,
        };
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize",this.updateWindowDimensions.bind(this));
        this.onSearch = this.onSearch.bind(this);
        this.onSearch();
    }

    componentWillUnmount() {
        window.removeEventListener("resize",this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({screenwidth: scroll ? e.offsetWidth - 140 : e.offsetWidth - 140})
                }
            }
        },500);
        this.setState({width:window.innerWidth});
    }

    onSearch() {
        let s = this.state.search.search;
        this.setState(state => {
            return {
                search: {
                    account: state.account,
                    users: state.users,
                    name: state.name,
                    startdata: state.startdata,
                    enddata: state.enddata,
                    template: state.reportType ? state.reportType : 0,
                    statusReport: state.statusReport,
                    startdate: state.dateRange[0] ? moment(state.dateRange[0],dateFormat).format(dateFormat) : null,
                    enddate: state.dateRange[1] ? moment(state.dateRange[1],dateFormat).format(dateFormat) : null,
                    search:!s
                }
            }
        });
        this.updateWindowDimensions();
    }

    setStateByProps(parameters,value) {
        value ? value : "";
        this.setState({[parameters]: value});
    }

    warningModal(value,message,messageSuccess) {
        this.setState({warningModalVisible: value,message,messageSuccess});

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    successModal(value) {
        this.onDeleteItem(this.state.key);
        this.setState({successModalVisible: value});

        let modalcontent = document.querySelectorAll('.ant-modal-contentant-modal-content-hide');
        for (var i = 0; i < modalcontent.length; i++) {
            modalcontent[i].className = "ant-modal-content";
        }
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        },1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({successModalVisible: false});
            window.location = '/report/';
        },secondsToGo * 1000);


    }

    onDeleteItem(key) {
        if (!CheckTokenExp()) {
            this.setState({loading: true});

            let req = JSON.stringify({
                key: key
            });

            apiClient.post('api/report/delete',req
                ,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({startdata: 1,enddata: 50});
                    this.onSearch();
                    this.setState({loading: false});
                })
                .catch(error => {
                    this.setState({loading: false});
                });
        } else {
            window.location.href = "logout";
        }

    }

    disabledDate(current) {
        return current && current < moment(new Date()).add(-1,'year');
    }

    render() {
        const {message,messageSuccess,warningModalVisible,successModalVisible,loading,} = this.state;
        const {search,account,users,reportType,statusReport,dateRange,key,screenwidth,width} = this.state;
        const routes = [
            {
                path: '/Report/',
                breadcrumbName: 'Report',
            },

        ];

        var input = {
            width: "100%",
            marginRight: 8,
            marginBottom: 8,
            minWidth: 120
        };

        return (
            <div className="animated fadeIn ">
                <PageHeader breadcrumb={{routes}}>

                </PageHeader>


                <div className="content">
                    <Card >
                        <div className="ant-row">
                            <div className="ant-col-md-3 ant-col-md-offset-21 text-right">

                                <div className="control-input">
                                    <Link to={"/report/create"}>
                                        <Button type="primary" onClick={this.showModal}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Create
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="ant-row">
                            <div className="ant-col-md-5">
                                <div className="control-label require">
                                    Name
                                </div>
                                <div className="control-input">
                                    <Input placeholder=" Name" style={input} onChange={(e) => this.setStateByProps("name",e.target.value)} />
                                </div>
                            </div>
                            <div className="ant-col-md-5">
                                <div className="control-label require">
                                    Type
                                </div>
                                <div className="control-input">
                                    <SelectReportType select={reportType} setStateByProps={this.setStateByProps} style={input} />
                                </div>
                            </div>
                            <div className="ant-col-md-5">
                                <div className="control-label require">
                                    Status
                                </div>
                                <div className="control-input">
                                    <SelectStatusReport select={statusReport} setStateByProps={this.setStateByProps} style={input} />

                                </div>
                            </div>
                            <div className="ant-col-md-6">
                                <div className="control-label require">
                                    Date Range
                                </div>
                                <div className="control-input">
                                    <RangePicker
                                        placeholder={["Start Date","End Date"]}
                                        style={input}
                                        disabledDate={this.disabledDate}
                                        ranges={{
                                            Today: [moment().startOf('day'),moment().endOf('day')],
                                            'Last 7 Days': [moment().subtract(6,'d'),moment()],
                                            'Last Week': [moment().subtract(1,'weeks').startOf('week'),moment().subtract(1,'weeks').endOf('week')],
                                            'Last 15 Days': [moment().subtract(14,'d'),moment()],
                                            'Last 30 Days': [moment().subtract(29,'d'),moment()],
                                            'This Month': [moment().startOf('month'),moment().endOf('month')]
                                        }}
                                        onChange={(value) => this.setStateByProps("dateRange",value)}
                                        value={dateRange}
                                        format={dateFormat} />
                                </div>
                            </div>
                            <div className="ant-col-md-3 text-right">
                                <div className="control-label require" style={{color: "#ffffff"}}>
                                    .
                                </div>
                                <div className="control-input">
                                    <Button icon="search" onClick={this.onSearch} >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <TableCardReport width={width} search={search} setStateByProps={this.setStateByProps} updateWindowDimensions={this.updateWindowDimensions} />
                </div>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

            </div>
        );
    }
}


