/* eslint-disable */
import React, { Component } from 'react';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col,
    Button, Tooltip, Checkbox, Spin, PageHeader, Card, Tag, Modal
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { token, getUrlVars, validateEmail, CheckTokenExp, ReplaceUrlTaga } from '../../../../config';
import TagDestination from '../../../../components/Ants/Tag/TagDestination';
import TagPersonTag from '../../../../components/Ants/Tag/TagPersonTag';
import SelectTags from '../../../../components/Ants/Select/SelectTags';
import ModalSuccess from '../../../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../../../components/Ants/Modal/ModalWarning';
import { apiClient } from '../../../../api';
import{root} from '../../../../config';

moment.tz.setDefault("Asia/Bangkok");

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { TextArea } = Input;
const confirm = Modal.confirm;
const dateFormat = 'DD/MM/YYYY';

export default class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PER_ID: null,
            PER_ACC: 0,
            PER_FIRSTNAME: null,
            PER_LASTNAME: null,
            PER_GENDER: 0,
            PER_CITY: null,
            PER_ADDRESS: null,
            PER_COUNTRY: null,
            PER_DOB: null,
            DES_ID: 0,
            DES_VALUE: null,
            DES_CREATE_DATE: null,
            DES_UPDATE_DATE: null,
            Check_des: null,
            Create_Des: true,
            Destinationslist: [],
            Taglist: [],
            Errorslist: [],
            //errors: [],
            loading: false,
            form: "",
            disable_tag: true,
            warningModalVisible: false,
            successModalVisible: false,
            loading: false,
            message: "",
            messageSuccess: "",
            name: "",
            dest: "",
            tag: "",
            search: "",
            tag: undefined,
            deskey: 0,
            errordup: "",
            errors: "",
            tagerror: "",
            tagid: null,
            primary: "",
            errorsPrimary: ""
        };

        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
        this.RequestInfoPerson = this.RequestInfoPerson.bind(this);
        this.handleCheckDestinationsRepeat = this.handleCheckDestinationsRepeat.bind(this);
        this.handleOnDeleteDestinations = this.handleOnDeleteDestinations.bind(this);
        this.handleValidateDes = this.handleValidateDes.bind(this);
        this.handleValidateTag = this.handleValidateTag.bind(this);
        this.handleOnDeleteTag = this.handleOnDeleteTag.bind(this);
        //this.handleOnSavePerson = this.handleOnSavePerson.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleCheckDestinationsRepeatPrimary = this.handleCheckDestinationsRepeatPrimary.bind(this);
        this.handleOnSaveDestinations = this.handleOnSaveDestinations.bind(this);

    }

    componentDidMount() {
        this.RequestInfoPerson();
        ReplaceUrlTaga();
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "deletedes") {
            this.handleOnDeleteDestinations();
            this.setState({ action: "" });
            this.setState({ successModalVisible: value });
        }
        if (this.state.action == "deletetag") {
            this.handleOnDeleteTag();
            this.setState({ action: "" });
            this.setState({ successModalVisible: value });
        }

        if (this.state.action == "discard") {
            this.setState({ action: "" });
            value = false;
            this.props.history.push('/people/person/')
            this.setState({ successModalVisible: value });
        }

        if (this.state.action == "save") {
            if (this.state.primary.length >= 9 || this.state.primary.length >= 11) {
                this.setState({ action: "saveall" });
                this.handleOnSavePersonDestinations();
            } else {
                value = false
            }
            this.setState({ successModalVisible: value });
        }

        if (this.state.action == "vali_des") {
            this.handleValidateDes();
            this.setState({ action: "" });
        }
        if (this.state.action == "vali_tag") {
            this.handleValidateTag();
            this.setState({ action: "" });
        }

        //this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        this.setState(state => {
            if (state.successModalVisible) {
                setTimeout(() => {
                    this.setState({ successModalVisible: false });
                }, secondsToGo * 1000);
            }
        });
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    RequestInfoPerson() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })
            if (getUrlVars()["id"]) {

                apiClient.get('api/Person/PERSON/' + getUrlVars()["id"]
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState({
                            PER_ID: json.PER_ID,
                            PER_FIRSTNAME: json.PER_FIRSTNAME,
                            PER_LASTNAME: json.PER_LASTNAME,
                            PER_GENDER: json.PER_GENDER,
                            PER_CITY: json.PER_CITY,
                            PER_ADDRESS: json.PER_ADDRESS,
                            PER_COUNTRY: json.PER_COUNTRY,
                            PER_DOB: json.PER_DOB,
                            Destinationslist: json.PERSONDESTINATION.filter((item) => item.DES_PRIMARY == null),
                            DES_ID: json.PERSONDESTINATION.filter((item) => item.DES_VALUE == null)[0].DES_ID,
                            primary: json.PERSONDESTINATION.filter((item) => item.DES_VALUE == null)[0].DES_PRIMARY,
                            Taglist: json.PERSONGROUPASSIGN,
                            disable_tag: json.PERSONDESTINATION.length > 0 ? false : true,
                            //errors: "",
                            //errorsPrimary: "",
                        }, function () { this.state.Destinationslist.length > 0 ? this.setState({ Create_Des: false }) : this.setState({ Create_Des: true }) });
                        setTimeout(() => {
                            this.setState({ loading: false });
                        }, 10);
                    })
                    .catch(error => {
                        this.setState({
                            PER_ID: null,
                            PER_FIRSTNAME: null,
                            PER_LASTNAME: null,
                            PER_GENDER: 0,
                            PER_CITY: null,
                            PER_ADDRESS: null,
                            PER_COUNTRY: null,
                            PER_DOB: null,
                            Destinationslist: [],
                            Taglist: [],
                            Create_Des: true,
                            DES_VALUE: null,
                            errors: "",
                            loading: false,
                            disable_tag: true,
                            errorsPrimary: "",
                        })
                        this.setState({ loading: false });
                    });
            } else {
                this.setState({
                    PER_ID: null,
                    PER_FIRSTNAME: null,
                    PER_LASTNAME: null,
                    PER_GENDER: 0,
                    PER_CITY: null,
                    PER_ADDRESS: null,
                    PER_COUNTRY: null,
                    PER_DOB: null,
                    Destinationslist: [],
                    Taglist: [],
                    Create_Des: true,
                    DES_VALUE: null,
                    errors: "",
                    loading: false,
                    disable_tag: true,
                    errorsPrimary: "",
                })
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    handleCheckDestinationsRepeat(e) {
        if (!CheckTokenExp()) {
            this.setState({ errors: "", iconLoading: true, form: "validating" })

            let req = JSON.stringify({
                "DES_VALUE": this.state.DES_VALUE,
            });

             apiClient.post('api/Person/CHECK_DESTINATION', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;


                    this.setState({ errordup: json, loading: false });
                    this.warningModal(true, "Do you want to add this destination?", "Added destination successfully.")
                    this.setStateByProps("action", "vali_des");
                })
                .catch(req => {

                    //let error = req.response.data.replace(/"/g, "");
                    //let errors = {};
                    //let form = "";
                    //if (error != "Data already exists") {
                    //    errors = error;
                    //} else {
                    //    errors = "";
                    //}
                    //this.setState({ errors: errors, iconLoading: false })
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleCheckDestinationsRepeatPrimary(e) {
        if (!CheckTokenExp()) {
            this.setState({ errorsPrimary: "" })
            if (e.length >= 7) {
                this.setState({ errors: "", iconLoading: true, form: "validating" })

                this.setState(state => {
                    let req = JSON.stringify({
                        "DES_PRIMARY": e,
                        "DES_PER": getUrlVars()["id"]
                    });

                    apiClient.post('api/Person/CHECK_DESTINATIONPRIMARY', req
                        , {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-type': 'application/json; charset=UTF-8'
                            }
                        })
                        .then(data => {
                            var json = data.data;

                            this.setState({ errorsPrimary: json })

                            this.setState({ loading: false });
                        })
                        .catch(req => {

                            let error = req.response.data.replace(/"/g, "");
                            let errors = {};
                            let form = "";
                            if (error != "Data already exists") {
                                errors = error;
                            } else {
                                errors = "";
                            }
                            this.setState({ errorsPrimary: errors })

                            this.setState({ loading: false });
                        });
                });
            } else {
                this.setState({ errorsPrimary: "Invalid number" })
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnSavePersonDestinations() {
        if (!CheckTokenExp()) {
            this.setState({ errors: "", tagerror: "", DES_VALUE: null, tag: "" });
            if (!this.state.errors) {
                let req = JSON.stringify({
                    PER_FIRSTNAME: this.state.PER_FIRSTNAME == "" ? null : this.state.PER_FIRSTNAME,
                    PER_LASTNAME: this.state.PER_LASTNAME == "" ? null : this.state.PER_LASTNAME,
                    PER_ID: this.state.PER_ID?this.state.PER_ID:0,
                    DES_ID: this.state.DES_ID,
                    DES_VALUE: null,
                    DES_PRIMARY: this.state.primary,
                    PER_GENDER: this.state.PER_GENDER > 0 ? this.state.PER_GENDER : null,
                    PER_CITY: this.state.PER_CITY == "" ? null : this.state.PER_CITY,
                    PER_ADDRESS: this.state.PER_ADDRESS == "" ? null : this.state.PER_ADDRESS,
                    PER_COUNTRY: this.state.PER_COUNTRY == "" ? null : this.state.PER_COUNTRY,
                    PER_DOB: this.state.PER_DOB != null ? moment(this.state.PER_DOB, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
                });

                apiClient.post('api/Person/PERSONDESTINATIONS_SAVE', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        let obj = {};
                        const rows = this.state.Destinationslist;

                        if (!json.DES_PRIMARY) {
                            obj["DES_ID"] = json.DES_ID;
                            obj["DES_PER"] = json.DES_PER;
                            obj["DES_VALUE"] = json.DES_VALUE;
                            obj["DES_CREATE_DATE"] = json.DES_CREATE_DATE;
                            obj["DES_UPDATE_DATE"] = json.DES_UPDATE_DATE;
                            obj["DES_IS_DELETED"] = json.DES_IS_DELETED;

                            rows.push(obj);
                        }


                        function compare(a, b) {
                            if (a.DES_ID > b.DES_ID)
                                return -1;
                            if (a.DES_ID < b.DES_ID)
                                return 1;
                            return 0;
                        }

                        this.setState({
                            PER_ID: json.DES_PER,
                            primary: json.DES_PRIMARY,
                            Destinationslist: rows.sort(compare),
                            disable_tag: rows.length > 0 ? false : true,
                        });

                        if (this.state.action == "saveall") {
                            this.props.history.push({ search: '?id=' + json.DES_PER })
                            //this.props.history.push('/people/person/')
                        } else {
                            this.props.history.push({
                                pathname: '/people/person/detail',
                                search: '?id=' + json.DES_PER
                            })
                        }

                        this.setStateByProps("DES_VALUE", "");
                        this.setStateByProps("tag", "");

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ form: "error" })
                        //message.error(text);

                        this.setState({ loading: false });
                    });
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnSaveDestinations() {
        if (!CheckTokenExp()) {
            this.setState({ successModalVisible: true, errors: "" });
            this.modalcountDown();
            if (!this.state.errors) {
                let req = JSON.stringify({
                    DES_ID: this.state.DES_ID,
                    DES_PER: this.state.PER_ID,
                    DES_VALUE: this.state.DES_VALUE,
                    DES_PRIMARY: this.state.primary,
                });

                apiClient.post('api/Person/DESTINATION_SAVE', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        let obj = {};
                        const rows = this.state.Destinationslist;

                        if (!json.DES_PRIMARY) {
                            obj["DES_ID"] = json.DES_ID;
                            obj["DES_PER"] = json.DES_PER;
                            obj["DES_VALUE"] = json.DES_VALUE;
                            obj["DES_CREATE_DATE"] = json.DES_CREATE_DATE;
                            obj["DES_UPDATE_DATE"] = json.DES_UPDATE_DATE;
                            obj["DES_IS_DELETED"] = json.DES_IS_DELETED;

                            rows.push(obj);
                        }


                        function compare(a, b) {
                            if (a.DES_ID > b.DES_ID)
                                return -1;
                            if (a.DES_ID < b.DES_ID)
                                return 1;
                            return 0;
                        }

                        this.setState({
                            PER_ID: json.DES_PER,
                            Destinationslist: rows.sort(compare),
                            disable_tag: rows.length > 0 ? false : true,
                        });

                        if (this.state.action == "saveall") {
                            this.props.history.push({ search: '?id=' + json.DES_PER })
                            //this.props.history.push('/people/person/')
                        } else {
                            this.props.history.push({
                                pathname: '/people/person/detail',
                                search: '?id=' + json.DES_PER
                            })
                        }

                        this.setStateByProps("DES_VALUE", "");

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ form: "error" })
                        message.error(text);

                        this.setState({ loading: false });
                    });
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnDeleteDestinations() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })

            apiClient.get('api/Person/DESTINATION_DELETE/' + this.state.deskey + '/' + getUrlVars()["id"]
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    this.RequestInfoPerson();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ form: "error" })
                    message.error(text);

                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleValidateDes() {
        this.setState(state => {
            let errors = "";
            let form = "";

            if (state.DES_VALUE == "" || state.DES_VALUE == null) {
                errors = "Required field";
            }
            else if (state.errordup) {
                errors = state.errordup;
            }
            else {
                if (isNaN(state.DES_VALUE)) {
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (!pattern.test(state.DES_VALUE)) {
                        errors = "Field is not email or number";
                    } else {
                        if (!state.errors) {
                            this.handleOnSaveDestinations();
                        }
                        errors = "";
                    }
                } else {
                    if (state.DES_VALUE.length >= 9 && state.DES_VALUE.length <= 11) {
                        if (!state.errors) {
                            this.handleOnSaveDestinations();
                        } else {
                            errors = state.errors;
                        }
                    } else {
                        errors = "Invalid number";
                    }
                }
            }

            return {
                errors: errors,
                form: form
            }
            
        });
    }

    handleValidateTag() {
        var checkduplicate = this.state.Taglist.find((e) => e.PRS_PRG == this.state.tagid);
        if (!checkduplicate) {
            this.handleOnSaveTag();
        } else {
            this.setState({ tagerror: "Duplicate Tag" });
        }
    }

    handleOnDeleteTag() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true })

            apiClient.get('api/Person/DELETE_TAG/' + this.state.tagkey + '/' + getUrlVars()["id"]
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {

                    this.RequestInfoPerson();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    handleOnSaveTag() {
        if (!CheckTokenExp()) {
            this.setState({ successModalVisible: true });
            this.modalcountDown();
            this.setState({ loading: true, tagerror: "" })

            this.setState(state => {
                let req = JSON.stringify({
                    PRS_PER: getUrlVars()["id"],
                    PRS_PRG: typeof state.tag == "string" ? state.tagid : state.tag
                });

                apiClient.post('api/Person/INSERT_TAG/', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState({ tag: null, tagid: null });

                        this.RequestInfoPerson();

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({ loading: false });
                    });
            }
            );
        } else {
            window.location.href = "logout";
        }
        
    }

    handleAddTag() {
        if (!CheckTokenExp()) {
            if (typeof this.state.tag == "string") {
                this.setState({ loading: true })
                if (this.state.errors.PRG_NAME != "" && this.state.errors.PRG_NAME != null) {
                    this.setState({ loading: false })
                    return false;
                }

                let req = JSON.stringify({
                    PRG_NAME: this.state.tag
                });

                apiClient.post('api/Tag/INSERT_TAG', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState({ tagid: json.PRG_ID, search: "" });
                        this.setState({ loading: false });
                        this.warningModal(true, "Do you want to add this tag?", "Added tag successfully.")
                        this.setStateByProps("action", "vali_tag");
                    })
                    .catch(error => {
                        this.setState({ search: "" });
                        this.setState({ loading: false });
                    });
            } else {
                setTimeout(() => {
                    this.setState({ search: "", tagid: this.state.tag });
                    this.warningModal(true, "Do you want to add this tag?", "Added tag successfully.")
                    this.setStateByProps("action", "vali_tag");
                }, 10)
            }
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const styleMarginBottom = { marginBottom: 16 };
        const { PER_GENDER, errors, loading, warningModalVisible, message, messageSuccess, DES_VALUE,
            successModalVisible, tag, tagerror, Destinationslist, PER_FIRSTNAME, PER_LASTNAME, search, primary, errorsPrimary } = this.state;
        const routes = [
            {
                path: '/people/person/',
                breadcrumbName: 'People',
            },
            {
                path: 'Person',
                breadcrumbName: 'Person' + (getUrlVars()["id"] ? ' edit' : ' new'),
            },

        ];
        return (
            <div className="animated fadeIn">
                <PageHeader breadcrumb={{ routes }}>
                </PageHeader>

                <div className="content">

                    <Row gutter={8} type="flex" justify="space-between">
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <Card style={{ minHeight: 650, maxHeight: 700, padding: 16 }} >

                                <Row gutter={8} style={styleMarginBottom}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> Primary Destination</label>
                                        <label className="red">*</label>
                                        <Input placeholder="Primary Destination" value={primary}
                                            onChange={(e) => {
                                                this.handleCheckDestinationsRepeatPrimary(e.currentTarget.value), this.setStateByProps("primary", e.currentTarget.value)
                                            }} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <label className=" control-label require" style={{ fontWeight: 700 }}> &nbsp;&nbsp;&nbsp;&nbsp; </label>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                {
                                                    errorsPrimary && primary ?
                                                        <label className="error" style={{ fontWeight: 700, color: 'red' }}>{errorsPrimary}</label> :
                                                        <label className="unerror" style={{ fontWeight: 700, color: 'red' }}>&nbsp;&nbsp;</label>
                                                }
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                                <Row gutter={8} style={styleMarginBottom}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> First Name</label>
                                        <Input placeholder="First Name" value={PER_FIRSTNAME} onChange={(e) => this.setStateByProps("PER_FIRSTNAME", e.currentTarget.value)} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> Last Name</label>
                                        <Input placeholder="Last Name" value={PER_LASTNAME} onChange={(e) => this.setStateByProps("PER_LASTNAME", e.currentTarget.value)} />
                                    </Col>
                                </Row>

                                <Row gutter={8} style={styleMarginBottom}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> Gender</label>
                                        <Select style={{ width: '100%' }} value={PER_GENDER} name="PER_GENDER"
                                            onChange={function (value) {
                                                this.setState({ PER_GENDER: value })
                                            }.bind(this)}
                                            placeholder="Gender" >
                                            <Option value={0}>Gender</Option>
                                            <Option value={1}>Male</Option>
                                            <Option value={2}>Female</Option>
                                        </Select>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                            <label className=" control-label require" style={{ fontWeight: 700 }}> Birthday</label>
                                            <DatePicker style={{ width: '100%' }} value={this.state.PER_DOB != null ? moment(this.state.PER_DOB, dateFormat) : this.state.PER_DOB} name="PER_DOB" format={dateFormat}
                                                onChange={function (value) { this.setState({ PER_DOB: value }) }.bind(this)} />
                                        </Col>
                                    </Col>
                                </Row>

                                <Row gutter={8} style={styleMarginBottom}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> Address</label>
                                        <TextArea style={{ width: '100%' }} value={this.state.PER_ADDRESS} name="PER_ADDRESS"
                                            autoSize={{ minRows: 4, maxRows: 6 }} placeholder="Address"
                                            onChange={function (e) { this.setState({ [e.currentTarget.name]: e.currentTarget.value }) }.bind(this)} />
                                    </Col>
                                </Row>

                                <Row gutter={8} style={styleMarginBottom}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> Country</label>
                                        <Input style={{ width: '100%' }} value={this.state.PER_COUNTRY} name="PER_COUNTRY" placeholder="Country"
                                            onChange={function (e) { this.setState({ [e.currentTarget.name]: e.currentTarget.value }) }.bind(this)} />
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <label className=" control-label require" style={{ fontWeight: 700 }}> City</label>
                                        <Input style={{ width: '100%' }} value={this.state.PER_CITY} name="PER_CITY" placeholder="City"
                                            onChange={function (e) { this.setState({ [e.currentTarget.name]: e.currentTarget.value }) }.bind(this)} />
                                    </Col>
                                </Row>

                                <Row gutter={8} type="flex" justify="end" style={{ textAlign: 'right', marginTop: 28 }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <Button key="submit" style={{ marginRight: 8 }} onClick={() => {
                                            this.warningModal(true, "Do you want to discard change?", "Discarded change successfully.");
                                            this.setStateByProps("action", "discard");
                                        }}>
                                            <span className="fa fa-trash-o"></span> &nbsp;&nbsp;
                                                   Discard
                                                </Button>


                                        <Button type="primary" style={{ marginRight: 8 }}
                                            disabled={!errorsPrimary && primary ? false : true}
                                            onClick={() => {
                                                this.warningModal(true, "Do you want to save this person?", "Saved person successfully.");
                                                this.setStateByProps("action", "save");
                                            }}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Save
                                                </Button>

                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={10}>

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Card style={{ height: 321, overflow: "auto" }} >
                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <label className=" control-label require" style={{ fontWeight: 700 }}> Destination</label>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} >
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <Input placeholder="" value={this.state.DES_VALUE} name="DES_VALUE"
                                                    onChange={(e) => this.setStateByProps("DES_VALUE", e.target.value)} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <Button type="primary"
                                                    disabled={getUrlVars()["id"] && DES_VALUE ? false : true}
                                                    style={{ marginRight: 8 }}
                                                    onClick={() => this.handleCheckDestinationsRepeat()}>
                                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Add
                                                </Button>
                                            </Col>
                                        </Row>

                                        {
                                            errors ?
                                                <label className="error" style={{ fontWeight: 700, color: 'red' }}>{errors}</label> :
                                                <label className="unerror" style={{ fontWeight: 700, color: 'red' }}>&nbsp;&nbsp;</label>
                                        }

                                        <Row gutter={16} style={styleMarginBottom}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                {
                                                    Destinationslist.map((item, index) =>
                                                        validateEmail(item.DES_VALUE) ?
                                                            <TagDestination icon={"email"} title={item.DES_VALUE} deskey={item.DES_ID}
                                                                warningModal={this.warningModal}
                                                                setStateByProps={this.setStateByProps} length={Destinationslist.length} />
                                                            :
                                                            <TagDestination icon={"mobile"} title={item.DES_VALUE} deskey={item.DES_ID}
                                                                warningModal={this.warningModal}
                                                                setStateByProps={this.setStateByProps} length={Destinationslist.length} />
                                                    )

                                                }

                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={8} type="flex" justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Card style={{ height: 321, overflow: "auto" }} >
                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <label className=" control-label require" style={{ fontWeight: 700 }}> Tags</label>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <SelectTags select={tag} setStateByProps={this.setStateByProps} placeholder={"Select"} mode={"insert"} search={search} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <Button type="primary" style={{ marginRight: 8 }}
                                                    disabled={getUrlVars()["id"] && tag ? false : true}
                                                    onClick={() => { this.setState({ tagerror: "" }), this.handleAddTag() }}
                                                >
                                                    <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                            Add
                                                </Button>
                                            </Col>
                                        </Row>

                                        <label className={tagerror ? "error" : "unerror"} style={{ fontWeight: 700, color: 'red' }}>{tagerror}</label>

                                        <Row gutter={16} style={styleMarginBottom}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                {
                                                    this.state.Taglist.map((item, index) =>
                                                        item.PRG_NAME ?
                                                            <TagPersonTag
                                                                title={item.PRG_NAME}
                                                                tagkey={item.PRS_ID}
                                                                warningModal={this.warningModal}
                                                                setStateByProps={this.setStateByProps}
                                                            />
                                                            : null
                                                    )
                                                }

                                            </Col>
                                        </Row>



                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </div>


                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />


            </div >
        );
    }
}

