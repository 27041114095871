import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Progress, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import { Avatar } from 'antd';
import { node } from 'prop-types';
import NumericLabel from 'react-pretty-numbers';

const optionnumber = {
    justification: 'L',
    locales: 'en-US',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    cssClass: ['red'],
    shortFormat: true,
    shortFormatMinValue: 10000000,
    shortFormatPrecision: 1,
    title: true
};

class Aside extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
        };

        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.handleClickTransactionHis = this.handleClickTransactionHis.bind(this);
    }

    handleClickAccount(){
        this.props.visiblePopover();
        this.props.history.push("/accountmanagement");
    };

    handleClickTransactionHis(){
        this.props.visiblePopover();
        this.props.history.push("/transactionhistory");
    };

    render() {
        let { profile, ecom} = this.props;

        return (
            // <aside>
            <div>
                <div className="m-0 py-2">
                    <div className="avatar float-left">
                        <Avatar className="avatar-ants" size="large">
                            <b>{profile.name.substring(0, 2)}</b>
                        </Avatar>
                    </div>
                    <div style={{ marginTop: -5, marginBottom: -10 }}>{profile.comp}</div>
                    <small className="mr-3 ants-gray-low"></small>
                </div>

                <hr className="mx-3 my-0" />

                {
                    //<div className="m-0 py-2">
                    //    <div className="profile-icon float-left">
                    //        <span className="antsicon-credit-balance ants-orange" style={{ fontSize: 16, display: 'block', marginTop: 7 }}></span>
                    //    </div>
                    //    <div style={{ marginBottom: -10 }}>Balance</div>
                    //    <small className="ants-gray-low">  {profile.type == 0 ? "-" : <NumericLabel params={optionnumber}>{parseInt(profile.credits)}</NumericLabel>} {profile.type == 0 ? "" : "CREDITS"}</small>
                    //</div>

                    //<hr className="mx-3 my-0" />
                }

                {
                    //profile.createaccount ?
                    //    <div className="m-0 py-1">
                    //        <Link to="/accountmanagement" style={{ textDecoration: 'none' }} >
                    //            <div className="profile-icon float-left">
                    //                &nbsp;&nbsp;
                    //    </div>
                    //    Account Management
                    //</Link>
                    //    </div>
                    //    : null
                }

                {
                    profile.admin || profile.createuser || profile.createaccount || profile.userGenToken ?
                        <div className="m-0 py-1" style={{cursor: "pointer"}} onClick={this.handleClickAccount}>
                            <div className="profile-icon float-left"></div>
                            &nbsp;&nbsp;Account Management
                        </div>
                        :
                        null
                }
                {this.props.ecom ? <div className="m-0 py-1">
                    <div className="m-0 py-1" style={{cursor: "pointer"}} onClick={this.handleClickTransactionHis}>
                        <div className="profile-icon float-left"></div>
                        &nbsp;&nbsp;Transaction History
                    </div>
                </div> : null}
                

                {profile.admin || profile.createuser || profile.createaccount || profile.userGenToken ? <hr className="mx-3 my-0" /> : null}


                <div className="m-0 py-1">
                    <Link to="/logout" style={{ textDecoration: 'none' }} >
                        <div className="profile-icon float-left">
                            <span className="antsicon-logout ants-orange" style={{ fontSize: 16 }}></span>
                        </div>
                        logout
                    </Link>
                    <small className="text-muted  m-0 py-3">&nbsp;</small>
                </div>

            {/* </aside> */}
            </div>
        )
    }
}

export default Aside;
