import React, {Component} from "react";
import {Icon, Button, Row, Col, Modal} from "antd";

export default class ModalServerError extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onLogout =   this.onLogout.bind(this);
  }

  onLogout() {
    window.location.href = "/logout"
  }

  render() {
    const {modalVisible} = this.props;
    return (
      <>
        <Modal
          zIndex={1021}
          wrapClassName="popup-error-log"
          closeIcon={false}
          visible={modalVisible}
          maskClosable={false}
          onOk={() => this.onLogout()}
          width={360}
          footer={[
            <Button key="submit1" type="primary" onClick={this.onLogout}>
              OK
            </Button>,
          ]}
        >
          <Row gutter={8} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
              <Icon type="info-circle" style={{color: "#ffff"}} />
            </Col>
          </Row>
          <Row gutter={8} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
              <label style={{color: "#ffff"}}>
                ขออภัยในความไม่สะดวก&nbsp;เนื่องจากมีการใช้งานในระบบเป็นจำนวนมาก&nbsp;
                กรุณารอสักครู่ และเข้าสู่ระบบอีกครั้ง
              </label>
              <label style={{color: "#ffff"}}>
                We apologize for any inconvenience this may have caused.&nbsp;
                Please wait a few minutes and try again to log-in.
              </label>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}
