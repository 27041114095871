/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PageHeader,
  Button,
  Descriptions,
  Select,
  Row,
  Col,
  DatePicker,
  Icon,
  Form,
  Input,
  Card,
  Tooltip,
  Progress,
  Table,
  Divider,
  Tag,
  Modal,
  Checkbox,
  Spin,
  Radio,
  From,
  InputNumber,
} from "antd";

import { apiClient } from "../../../api";
import { root } from "../../../config";
import {
  token,
  validateEmail,
  colorGreen,
  colorRed,
  CheckTokenExp,
} from "../../../config";
import ModalSuccess from "../../../components/Ants/Modal/ModalSuccess";
import ModalWarning from "../../../components/Ants/Modal/ModalWarning";
import ModalWarningCustom from "../../../components/Ants/Modal/ModalWarningCustom";
import ModalError from "../../../components/Ants/Modal/ModalError";

const { Option } = Select;

export default class ConfigCreditAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningModalVisible: false,
      successModalVisible: false,
      loading: false,
      message: "",
      messageSuccess: "",
      screenwidth: 0,
      formLayout: "horizontal",
      configCreditId: 0,
      amount: undefined,
      email: [],
      statusconfig: 0,
      emailError: true,
      warningModalOpen: false,
      errorModalVisible: false,
      messageError: "",
    };

    this.warningModal = this.warningModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.modalcountDown = this.modalcountDown.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setStateByProps = this.setStateByProps.bind(this);
    this.configCreate = this.configCreate.bind(this);
    this.configUpdate = this.configUpdate.bind(this);
    this.getData = this.getData.bind(this);
    this.warningModalCustom = this.warningModalCustom.bind(this);
    this.onSubmitWarning = this.onSubmitWarning.bind(this);
    this.errorModal = this.errorModal.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    setTimeout(() => {
      var root = document.getElementById("root");
      if (root) {
        var e = document.getElementById("main");
        if (e) {
          var scroll = root.offsetHeight > window.innerHeight;
          this.setState({
            screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124,
          });
        }
      }
    }, 500);
  }

  warningModal(value, message, messageSuccess) {
    this.setState({ warningModalVisible: value, message, messageSuccess });
  }

  successModal(value) {
    if (this.state.action == "configcredit") {
      if (this.state.configCreditId == 0) {
        this.setState({ action: "" });
        this.configCreate();
      } else {
        this.setState({ action: "" });
        this.configUpdate();
      }
    }

    this.setState({ successModalVisible: value });

    let modalcontent = document.querySelectorAll(
      ".ant-modal-contentant-modal-content-hide"
    );
    for (var i = 0; i < modalcontent.length; i++) {
      modalcontent[i].className = "ant-modal-content";
    }
  }

  modalcountDown() {
    let secondsToGo = 2;
    const timer = setInterval(() => {}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.setState({ successModalVisible: false });
    }, secondsToGo * 1000);
  }

  setStateByProps(parameters, value) {
    value ? value : "";

    if (parameters == "email") {
      this.setState({
        emailError: true,
      });
      if (value.length > 0) {
        var i;
        var error = true;
        for (i = 0; i < value.length; i++) {
          if (validateEmail(value[i])) {
            this.setState({ [parameters]: value });
          } else {
            error = false;
          }
        }
        if (!error) {
          this.setState({ emailError: false, [parameters]: value });
        }
      } else {
        this.setState({ [parameters]: value });
      }
    } else {
      this.setState({ [parameters]: value });
    }
  }

  getData() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      apiClient
        .get("api/users/get", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;

          this.setState({
            configCreditId: json.configId,
            amount: json.credit,
            email: json.email,
            statusconfig: json.flag,
            emailError: true,
          });

          this.setState({ loading: false });
        })
        .catch((error) => {
          
          this.setState({
            configCreditId: 0,
            amount: "",
            email: [],
            statusconfig: 0,
            emailError: true,
          });
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  configCreate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({
        credit: this.state.amount,
        email: this.state.email,
        flag: this.state.statusconfig === 1 ? true : false,
      });

      apiClient
        .post("api/users/add", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          if (json.Status === "error") {
            this.setState({
                errorModalVisible: true,
                messageError: "Save config credit unsuccessful!",
            });
          } else {
            this.setState({
              configCreditId: json,
              successModalVisible: true
            });
            setTimeout(() => {
                this.setState({ successModalVisible: false });
            }, 3000);
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }

  configUpdate() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({
        configId: this.state.configCreditId,
        credit: this.state.amount,
        email: this.state.email,
        flag: this.state.statusconfig === 1 ? true : false,
      });

      apiClient
        .post("api/users/update", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          if (json.Status === "error") {
            this.setState({
                errorModalVisible: true,
                messageError: "Save config credit unsuccessful!",
              });
          } else {
            this.setState({ successModalVisible: true });
            setTimeout(() => {
                this.setState({ successModalVisible: false });
            }, 3000);
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }

  warningModalCustom(value) {
    this.setState({ warningModalOpen: value });
  }

  onSubmitWarning() {
    if (this.state.action === "configcredit") {
      if (this.state.configCreditId === 0) {
        this.setState({ action: "" });
        this.configCreate();
      } else {
        this.setState({ action: "" });
        this.configUpdate();
      }
    }
  }

  errorModal(value) {
    this.setState({ errorModalVisible: value });
  }

  render() {
    const {
      loading,
      message,
      messageSuccess,
      warningModalVisible,
      successModalVisible,
      formLayout,
      amount,
      email,
      emailError,
      statusconfig,
      warningModalOpen,
      errorModalVisible,
      messageError
    } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : null;
    const colorOn = statusconfig == 0 ? colorGreen : "";
    const colorOff = statusconfig == 1 ? colorRed : "";
    return (
      <div className="content" id="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={16}>
              <Form.Item
                label={
                  <label>
                    Credit <span style={{ color: "red" }}>*</span>
                  </label>
                }
                {...formItemLayout}
              >
                <InputNumber
                  min={0}
                  placeholder="Amount Credits"
                  value={amount}
                  onChange={(e) => this.setStateByProps("amount", e)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <label>
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                }
                {...formItemLayout}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => this.setStateByProps("email", e)}
                ></Select>
                <label className="red" style={{ fontWeight: 600 }}>
                  {!emailError && email != "" ? "Invalid email format" : ""}
                </label>
              </Form.Item>
              <Form.Item {...buttonItemLayout}>
                <Radio.Group
                  value={statusconfig}
                  onChange={(e) =>
                    this.setStateByProps("statusconfig", e.target.value)
                  }
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio.Button
                    value={1}
                    style={{ backgroundColor: colorOff, borderColor: colorOff }}
                  >
                    OFF
                  </Radio.Button>
                  <Radio.Button
                    value={0}
                    style={{ backgroundColor: colorOn, borderColor: colorOn }}
                  >
                    ON
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item {...buttonItemLayout}>
                <Button
                  type="primary"
                  disabled={
                    amount != undefined &&
                    amount != "" &&
                    amount != null &&
                    emailError &&
                    email != ""
                      ? false
                      : true
                  }
                  onClick={() => {
                    this.setState({
                      warningModalOpen: true,
                      message: "Do you want to save this config credit?",
                      messageSuccess: "Save config credit successfully.",
                      action: "configcredit",
                    });
                    // this.warningModal(true, "Do you want to save this config credit?", "Save config credit successfully."),
                    // this.setStateByProps("action", "configcredit")
                  }}
                >
                  <span className="fa fa-plus-circle"></span> &nbsp;&nbsp; Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Spin>

        <ModalWarning
          loading={loading}
          warningModalVisible={warningModalVisible}
          warningModal={this.warningModal}
          successModal={this.successModal}
          modalcountDown={this.modalcountDown}
          message={message}
          messageSuccess={messageSuccess}
        />

        <ModalSuccess
          loading={loading}
          successModalVisible={successModalVisible}
          successModal={this.successModal}
          messageSuccess={messageSuccess}
        />

        <ModalWarningCustom
          loading={loading}
          warningModalOpen={warningModalOpen}
          warningModal={this.warningModalCustom}
          onSubmit={this.onSubmitWarning}
          message={message}
        />

        <ModalError
          loading={loading}
          errorModalVisible={errorModalVisible}
          errorModal={this.errorModal}
          messageError={messageError}
        />
      </div>
    );
  }
}
