/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col } from 'antd';


export default class TagPersonTag extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { title, tagkey } = this.props;

        return (
            <div>
                <Tag style={{ marginBottom: 16, backgroundColor: '#fff' }} >
                    {title}
                    <span className="fa fa-times-circle-o size14" style={{ cursor: 'pointer', float: 'right', marginTop: 3 }}
                        onClick={() => {
                            this.props.warningModal(true, "Do you want to delete tag?", "Delete Success."),
                                this.props.setStateByProps("action", "deletetag"),
                                this.props.setStateByProps("tagkey", tagkey)
                        }}></span> &nbsp;&nbsp;
                </Tag>
            </div>

        );
    }
}


