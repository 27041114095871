/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input } from 'antd';

import { token, getUrlVars } from '../../../config';

import TablePersonList from '../Table/TablePersonList';

export default class ModalTagAddMember extends Component {

    constructor(props) {
        super(props);


        this.state = {
            name: "",
            dest: "",
            search: {
                name: "",
                dest: "",
            },
        };

        this.onSearch = this.onSearch.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);
    }

    componentDidMount() {
        this.onSearch();
    }

    componentDidUpdate(prevProps) {
        //if (this.props.name !== prevProps.name) {
        //    this.onSearch();
        //}
        //if (this.props.dest !== prevProps.dest) {
        //    this.onSearch();
        //}
        if (this.props.loading !== prevProps.loading) {
            this.onSearch();
        }

        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible == false) {
                this.setState({
                    name: "",
                    dest: "",
                    search: {
                        name: "",
                        dest: "",
                    },
                })
                this.onSearch();
            }
        }
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    dest: state.dest,
                    loading: state.loading,
                }
            }
        });
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }

    render() {
        const { visible, Insertlist, loading } = this.props;
        const { search, name, dest, } = this.state;
        return (
            <Modal
                title={<div>Add Member <span className="fa fa-times-circle-o card-close" onClick={this.props.handleOk}></span></div>}
                visible={visible}
                onOk={this.props.handleOk}
                onCancel={false}
                width={900}
                footer={false}
            >
                <Row gutter={16}>

                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Input placeholder="Name" value={name} onChange={(e) => this.setStateByProps("name", e.target.value)} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Input placeholder="Destination" value={dest} onChange={(e) => this.setStateByProps("dest", e.target.value)} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                        <Button icon="search" style={{ marginRight: 8 }} onClick={this.onSearch}>
                            Search
                         </Button>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={6} style={{ textAlign: 'right' }}>
                        <Button type="primary" style={{ marginRight: 8 }}
                            disabled={Insertlist.length > 0 ? false : true}
                            onClick={() => {
                                this.props.warningModal(true, "Do you want to add member to this tag?", "Added member successfully."),
                                    this.props.setStateByProps("action", "addperson")
                            }}>
                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                           Add
                         </Button>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <TablePersonList
                            loading={loading}
                            search={search}
                            warningModal={this.props.warningModal}
                            setStateByProps={this.props.setStateByProps} />
                    </Col>
                </Row>
            </Modal>
        );
    }
}


