import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row ,Col} from 'antd';


export default class Card2 extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {
        const { img, title, detail } = this.props;

        return (
            <Card className="card2">
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <img src={img} />
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={0} xl={1}>
                    </Col>
                    <Col xs={18} sm={18} md={18} lg={18} xl={17}>
                        <h1>{title}</h1>
                        <label>{detail}</label>
                    </Col>
                </Row>
            </Card>
        );
    }
}


