import React, { Component } from 'react';
import { Tag, Tooltip, Card, Skeleton, Modal, Button, Row, Col } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import ModalError from "../../../components/Ants/Modal/ModalError";
import ModalWarning_Loginfo from "../../../components/Ants/Modal/ModalWarning_Loginfo";
import { token, capitalizeFLetter, deepEqual, CheckTokenExp } from '../../../config';

moment.tz.setDefault("Asia/Bangkok");


const dateTimeFormat = 'DD MMM YYYY, HH:mm:ss';
class CardLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showbottom: false
        };
        this.geticonstatus = this.geticonstatus.bind(this);
    }

    geticonstatus() {
        let status = this.props.status_name;
        let icon = "";
        let color = "";
        switch (status) {
            case "DELIVERED": color = "#5bd2a6"; icon = "fa fa-check-circle"; break;
            case "UNDELIVERED": color = "#d25c5b"; icon = "fa fa-times-circle"; break;
            case "PENDING": color = "#FED531"; icon = "fa fa-check-circle"; break;
            case "COMPLETED": color = "#FED531"; icon = "fa fa-check-circle"; break;
            case "EXPIRED": color = "#FED531"; icon = "fa fa-exclamation-circle"; break;
            case "REJECTED": color = "#d25c5b"; icon = "fa fa-times-circle"; break;
        }
        return (<i style={{ color: color }} className={icon}></i>)
    }
    render() {
        return (<Card>
            <div className="Log-card">
                <div className="ant-row">
                    <div className="ant-col-7">
                        <div className="ant-row">
                            <div className="log-value ant-row" style={{ color: "#9fb1ae" }}> {(moment(this.props.senddate).format(dateTimeFormat))}</div>
                            <div className="log-caption ant-row" > {this.props.sender}</div>
                            <div className="log-lable ant-row">Account</div>
                            <div className="log-value ant-row" > {this.props.acc_name}</div>
                            {this.state.showbottom ? <div className="log-lable ant-row">User</div> : <div></div>}
                            {this.state.showbottom ? <div className="log-value ant-row" > {this.props.username}</div> : <div></div>}
                        </div>
                    </div>
                    <div className="ant-col-7">
                        <div className="ant-row">
                            <div className="log-value ant-row" style={{ color: "#9fb1ae" }}> {this.props.donedate && this.props.donedate != "" ? (moment(this.props.donedate).format(dateTimeFormat)) : "-"}</div>
                            <div className="log-caption ant-row" > {this.props.mobile}</div>
                            <div className="log-lable ant-row">Comunication</div>
                            <div className="log-value ant-row" > {this.props.campaign && this.props.campaign != "" ? this.props.campaign : "-"}</div>
                            {this.state.showbottom ? <div className="log-lable ant-row">Message ID</div> : <div></div>}
                            {this.state.showbottom ? <div className="log-value ant-row" > {this.props.messageid ? this.props.messageid : this.props.smsId}</div> : <div></div>}
                        </div>
                    </div>
                    <div className="ant-col-10">
                        <div className="ant-row">
                            <div className="log-icon">
                                {this.geticonstatus()}
                            </div>
                            <div className="log-value" style={{ color: "#9fb1ae" }} >
                                {this.props.status_name}
                            </div>
                            <div className="log-value ant-row" >Reason: {this.props.status_desc}</div>
                            <div className="log-value ant-row" style={{ height: "40px" }} > {this.props.message}</div>
                            {this.state.showbottom ? <div className="log-value ant-row"  >Number of message: {this.props.credit}</div> : <div></div>}
                            {this.state.showbottom ? <div className="log-value ant-row" >SMS Type: {this.props.sms_type}</div> : <div></div>}
                        </div>
                    </div>
                </div>
                <div className="log-left-btn" >
                    <div className="log-icon-btn" onClick={() => { this.setState({ showbottom: !this.state.showbottom }) }}>
                        <i className={this.state.showbottom ? "fa fa-compress" : "fa fa-expand"}></i>
                    </div>
                </div>
            </div>
        </Card>

        )
    }

}

class BTNSort extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            sortActive: false,
            sort: ""
        };

    }
    componentDidMount() {
        this.setState({ name: this.props.name });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.sort !== this.state.sort) {
            this.setState({ sort: nextProps.sort });
        }
    }
    render() {
        const enable = { color: "#5F5F61" };
        const disable = { color: "#b9b9c8" };
        return (<div className="log-sort-btn" onClick={() => { this.props.onSort({ name: this.state.name, sort: this.state.sort == "desc" ? "asc" : "desc" }) }} >
            <div className="log-sort-up" >
                <i className="fa fa-caret-up" style={this.state.sort == "desc" ? disable : enable} ></i>
            </div>
            <div className="log-sort-down">
                <i className="fa fa-caret-down" style={this.state.sort == "asc" ? disable : enable} ></i>
            </div>
        </div>)
    }
}
export default class TableCardLog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pagesize: 20,
            startrow: 1,
            endrow: 20,
            currentpage: 1,
            log: [],
            totalpage: 0,
            showing: 0,
            sort: "",
            message: "",
            messageError: "",
            warningModalVisible: false,
            messageSuccess: "",
            history:"",
            loading:false
        };

        this.getData = this.getData.bind(this);
        this.onHandleSort = this.onHandleSort.bind(this);
        this.trackScrolling = this.trackScrolling.bind(this);
        this.closemodal =   this.closemodal.bind(this);
        this.warningModal =   this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.search, prevProps.search)) {
            this.setState({ log: [], showing: 0, startrow: 1, endrow: 20, totalpage: 0, sort: "" }, () => { this.getData() });
        }
    }

    getData() {
     
        if (!CheckTokenExp()) {
            const { search } = this.props;
            if (search.dateRange[0]) {
                this.setState({ loading: true });

                let req = JSON.stringify({
                    startrow: this.state.startrow,
                    endrow: this.state.endrow,
                    campaign: search.campaign,
                    status: search.status,
                    mobile: search.mobile,
                    sender: search.sender,
                    sms_type: search.smstype,
                    user: search.users,
                    account: search.account,
                    date: search.dateRange,
                    sort: this.state.sort
                });


                apiClient.post('api/loginfo/get/', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        console.log(data);
                        var temp_log = this.state.log;
                        temp_log.push.apply(temp_log, json.data);
                        // console.log("json what ", json.totalpage)
                        // console.log("temp_log what ", temp_log.length)
                        this.setState({ 
                            data: json.data, 
                            log: temp_log 
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ 
                            totalpage: json.totalpage, 
                            showing: temp_log.length <= 50 ? temp_log.length : 50,
                            startrow: temp_log.length + 1, 
                            endrow: temp_log.length + 20 
                        });

                        this.setState({ loading: false });
                    })
                    .catch(error => {

                        this.warningModal(true, "An error has occurred while searching Log. Please create Report instead.",search.history); 
                        this.setState({ loading: false });

                    });
            }
        } else {
            window.location.href = "logout";
        }
    }


    onHandleSort(e) {
        this.setState({ sort: e.name + " " + e.sort }, () => {
            if (this.state.showing == this.state.totalpage) {
                let log = this.state.log;
                log.sort((a, b) => {
                    let v;

                    switch (this.state.sort) {
                        case "sms_send_date asc": v = new Date(a.senddate).getTime() - new Date(b.senddate).getTime(); break;
                        case "sms_send_date desc": v = new Date(b.senddate).getTime() - new Date(a.senddate).getTime(); break;
                        case "sms_done_date asc": v = new Date(a.donedate).getTime() - new Date(b.donedate).getTime(); break;
                        case "sms_done_date desc": v = new Date(b.donedate).getTime() - new Date(a.donedate).getTime(); break;
                    }
                    return v;
                    // return new Date(b.donedate).getTime() - new Date(a.donedate).getTime() ;
                });
                this.setState({ log: log });
            }
            else {
                this.setState({ log: [], showing: 0, startrow: 1, endrow: 20, totalpage: 0 }, () => { this.getData(); });
            }
        });
    }
    isBottom(el) {
        console.log("csroll height", window.innerHeight)
        this.setState({ message: el.getBoundingClientRect().bottom + ":" + window.innerHeight });
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    trackScrolling() {
        const wrappedElement = document.getElementById('loadding');
        if (wrappedElement) {
            if (this.isBottom(wrappedElement)) {
                let s = this.state.showing;
                if((s+50) >= this.state.totalpage){
                    s = this.state.totalpage;
                }else{
                    s = s + 50;
                }
                this.setState({showing: s})
            }
        }
        // if (this.isBottom(wrappedElement)) {
        //     this.getData();
        // }
    };




    warningModal(value, messages, history) {      
        this.setState({ message : messages });
        this.setState({ warningModalVisible: value });
        this.setState({ history: history});


    }

    closemodal(){
        this.setState({ warningModalVisible: false });
    }


    successModal(){
        this.state.history.push('/report/createnew');

    }

    render() {


        const { loading, totalpage, log, showing, sort, message, errorModalVisible, messageSuccess, warningModalVisible } = this.state;
        const Nodata = <div>
            <div className="Log-card-footer">
                <div className="ant-row">
                    <div className="ant-col-24">
                        <div className="ant-empty ant-empty-normal"><div className="ant-empty-image"><svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#D9D9D9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path></g></g></svg></div><p className="ant-empty-description">No Data</p></div>
                    </div>
                </div>
                <ModalWarning_Loginfo loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    closemodal= {this.closemodal}
                    message={message}
                    messageSuccess={messageSuccess}
                    modalcountDown={() => { }}
                /> 

            </div>
        </div >
        if (showing < totalpage && !loading) {
            // console.log("log 1111111111", showing, totalpage, loading)
            document.addEventListener('scroll', this.trackScrolling);
        }
        else {
            // console.log("log 2222222222", showing, totalpage, loading)
            document.removeEventListener('scroll', this.trackScrolling);
        }
        if (log.length == 0 && !loading) {
            return Nodata;
        }
        else {
            return (

                <div>


                    {
                        (showing > 0) ? (
                            <div className="Log-card-header">
                                <div className="ant-row">
                                    <div className="ant-col-8">
                                        <div className="showing">Showing {showing} / {totalpage} </div>
                                    </div>
                                </div>
                            </div>

                        )

                            : <div></div>}

                    {
                        (showing > 0) ?
                            (
                                <div className="Log-card-header">
                                    <div className="ant-row">
                                        <div className="ant-col-7">
                                            <div className="log-caption" style={{ paddingLeft: "12px" }}>From

                                            </div>
                                            <BTNSort name="sms_send_date" sort={sort == "sms_send_date asc" ? "asc" : (sort == "sms_send_date desc" ? "desc" : "")} onSort={(e) => { this.onHandleSort(e); }} />
                                        </div>
                                        <div className="ant-col-7">
                                            <div className="log-caption" style={{ paddingLeft: "12px" }}>To</div>
                                            <BTNSort name="sms_done_date" sort={sort == "sms_done_date asc" ? "asc" : (sort == "sms_done_date desc" ? "desc" : "")} onSort={(e) => { this.onHandleSort(e); }} />
                                        </div>
                                        <div className="ant-col-10">
                                            <div className="log-caption">Message</div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <div></div>}
                    {
                        log.slice(0,showing).map(item => (
                            <CardLog key={item.rownum}
                                senddate={item.senddate}
                                sender={item.sender}
                                acc_name={item.acc_name}
                                username={item.username}
                                donedate={item.Dr_Date}
                                mobile={item.mobile}
                                campaign={item.campaign}
                                messageid={item.messageid}
                                status_name={item.status_name}
                                status_desc={item.status_desc}
                                message={item.message}
                                credit={item.credit}
                                sms_type={item.sms_type}
                                smsId={item.Sms_ID}
                            />
                        ))
                    }
                    {
                        loading ?
                            (<Card >
                                <div className="ant-col-7">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-7">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                                <div className="ant-col-10">
                                    <Skeleton loading={true} active>
                                    </Skeleton>
                                </div>
                            </Card>) : (<div></div>)
                    }
                    {
                        (showing < totalpage && !loading) ?
                            (<div id="loadding" className="Log-card-footer" onClick={() => {
                                let s = showing;
                                s = s + 50;
                                this.setState({showing: s})
                                // this.getData()}
                            }}>
                                <div className="ant-row">
                                    <div className="ant-col-24">
                                        <div className="loading-dot"><span>.</span><span>.</span><span>.</span> </div>
                                    </div>
                                </div>
                            </div>) : (<div></div>)
                    }
                    <div >&nbsp;</div>

                   <ModalWarning_Loginfo loading={loading}
                        warningModalVisible={warningModalVisible}
                        warningModal={this.warningModal}
                        successModal={this.successModal}
                        closemodal= {this.closemodal}
                        message={message}
                        messageSuccess={messageSuccess}
                        modalcountDown={() => { }}
                    /> 

                </div>

            );
        }

    }
}


