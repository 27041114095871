import React, { Component } from 'react';
import { Bar, Line, Doughnut } from 'react-chartjs-2';

import { token, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const dateFormat = 'DD/MM/YYYY';

function convertHex(hex, opacity) {
    hex = hex.replace('#', '');
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    var result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    return result;
};

const brandInfo = '#1bbc9b';
const brandDanger = '#95a5a5';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateRange: [],
            loading: false,
            chartlabel: [],
            chartdata: [],
            chartdatafail: [],
            sms_status_list: [],
            smscount: 0,
            modalVisible: false,
        };

        this.getData_canvas = this.getData_canvas.bind(this);

    }

    componentDidUpdate(prevProps) {
        //if (this.props.dateRange !== prevProps.dateRange) {
        //    this.getData_canvas();
        //}
        if (this.props.search !== prevProps.search) {
            this.getData_canvas();
        }
    }

    componentDidMount() {
        this.getData_canvas();
    }

    getData_canvas() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            let req = JSON.stringify({
                StartDate: search.StartDate ? search.StartDate.format(dateFormat) : moment(moment().subtract(6, 'd'), dateFormat).format(dateFormat),
                EndDate: search.EndDate ? search.EndDate.format(dateFormat) : moment(new Date(), dateFormat).format(dateFormat),
                accounts: search.account.length > 0 ? search.account : null
            });

            apiClient.post('api/Dashboard/get', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        chartlabel: json.label,
                        chartdata: json.values,
                        chartdatafail: json.failAmount,
                        smscount: json.maxChart
                    });
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({
                        chartlabel: [],
                        chartdata: [],
                        chartdatafail: [],
                        smscount: 0
                    });
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    render() {
        const { smscount, chartlabel, chartdata, chartdatafail, modalVisible } = this.state;

        const mainChart = {
            labels: chartlabel,
            datasets: [
                {
                    label: 'SMS Success',
                    backgroundColor: convertHex(brandInfo, 10),
                    borderColor: brandInfo,
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: chartdata
                },
                {
                    label: 'SMS Fail',
                    backgroundColor: 'transparent',
                    borderColor: brandDanger,
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: chartdatafail
                }
            ]
        };

        const mainChartOpts = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var dataset = data.datasets[tooltipItem.datasetIndex];
                        var currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                        return currentValue;
                    }
                }
            }, //end tooltips
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false,
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(smscount / 5),
                        max: this.state.smscount < 20 ? 20 : this.state.smscount,
                        userCallback: function (value, index, values) {
                            value = value.toString();
                            value = value.split(/(?=(?:...)*$)/);
                            value = value.join(',');
                            return value;
                        }
                    }
                }]
            },
            elements: {
                point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                }
            }
        };


        return (
            <>
                <Line data={mainChart} options={mainChartOpts} height={300} />
                <ModalServerError modalVisible={modalVisible} />
            </>
        );
    }
}

