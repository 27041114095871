import React, { Component } from 'react';
import { Tooltip } from 'antd';

function toFixed(num, pre) {
    num *= Math.pow(10, pre);
    num = (Math.round(num, pre) + (((num - Math.round(num, pre)) >= 0.5) ? 1 : 0)) / Math.pow(10, pre);
    return num.toFixed(pre);
}

export default class index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const parent = this.props;

        let bars = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
            if (item.value > 0) {

                return (
                    <Tooltip title={item.value.toFixed(2) + '%'} key={i}>
                        <div className="bar" style={{ 'backgroundColor': item.color, 'width': item.value + '%' }} >
                        </div>
                    </Tooltip>
                )
            }
        }, this);

        let percent = 0;
        let sum = parent.readings && parent.readings.length && parent.readings.map(function (item, i) {
            if (item.value > 0) {
                percent = percent + item.value;
            }
        }, this);


        return (
            <div className="multicolor-bar">
                <div className="title"> 
                    {percent == '' ? '0' : percent.toFixed(2)}%
                </div>
                <div className="bars">
                    {bars == '' ? '' : bars}
                </div>
            </div>
        );
    }
}



