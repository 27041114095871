import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Spin } from 'antd';


export default class ModalWarning_Loginfo extends Component {

    constructor(props) {
        super(props);


        this.state = {

        };

    }


    render() {
        const { loading, warningModalVisible, warningModal, successModal, modalcountDown, message, messageSuccess,closemodal } = this.props;
        return (
            <div>

                <style>{`
              

                
                 `}</style>
                <Modal

                    zIndex={1021}
                    wrapClassName="popup-error-log"
                    closeIcon={false}
                    visible={warningModalVisible}
                    onOk={() => warningModal(true, message, messageSuccess)}
                    onCancel={() => warningModal(false, message, messageSuccess)}
                    width={360}
                    footer={[
                        <Button key="submit" type="gray" loading={loading} onClick={() => closemodal()}>
                            Cancel
                        </Button>,
                      
                        <Button key="submit1" type="primary" loading={loading} onClick={() => successModal()}>
                            CREAT REPORT
                        </Button>
                    ]}
                >
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                            <Icon type="close-circle" style={{ color: '#ffff' }} />
                        </Col>
                    </Row>
                    <Row gutter={8} type="flex" justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                            <label style={{ color: '#ffff' }}>{message}</label>
                        </Col>
                    </Row>
                </Modal>
            </div >

        );
    }
}


