import {message} from 'antd';
import React, { Component } from 'react';
export class Hello extends Component{
    constructor(props){
        super(props)
        this.state ={
            Message:"Hello Team",
            num:0
        }
        this.setnum = this.setnum.bind(this);
    }
    componentDidMount() {
    }
    setnum(){
        let n = this.state.num;
      this.setState({num:n+1});
    }
    render(){
        const {Message,num}=this.state;
        let M = this.state.Message;
        return(
            <div style={num==1?{fontSize:"20px"}:{fontSize:"40px"}} > 
            
            <a onClick={this.setnum}>click</a>
            <input type="text" 
             onKeyDown={(e)=>{this.setState({Message:e.target.value})}} />
            {num}
            {this.state.Message}
            </div>
        )
    }
    
}

export default Hello;