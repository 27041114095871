/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { token, validateEmail, CheckTokenExp } from '../../../config';
import { Table, Divider, Tag, Icon, Button, Row, Col, Tooltip, Popover, Menu } from 'antd';
import { textedit, textdelete } from '../../../components/Ants/TooltipText/Text';
import { apiClient } from '../../../api';
import{root} from '../../../config';

export default class TablePerson extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [

                {
                    title: 'Name',
                    dataIndex: 'PER_NAME',
                    key: 'PER_NAME',
                    width: 300,
                    render: text => (text ? <div className="table-title" title={text}>{text}</div> : "No name")
                },
                {
                    title: 'Destination',
                    dataIndex: 'DES_VALUE_LIST',
                    width: 300,
                    render: (text, item) => (
                        <div>
                            {
                                item.DES_PRIMARY ? item.DES_PRIMARY : ""
                            }
                            {
                                item.DES_VALUE_LIST.length > 0 ?
                                    <Tooltip placement="bottom"
                                        title={
                                            item.DES_VALUE_LIST.map((value,index) => {
                                                return (
                                                    <p key={index} style={{ marginBottom: '1px' }}>{value}</p>
                                                )
                                            })
                                        }>
                                        <label style={{ color: '#36b4a1' }}>{"+" + item.DES_COUNT + " more"}</label>
                                    </Tooltip>
                                    : null
                            }

                        </div>
                    )
                },
                {
                    title: 'Tag',
                    dataIndex: 'PRG_VALUE_LIST',
                    width: 300,
                    render: (value, item) => (
                        <div className="table-content" title={value.map((text, i) => text)}>
                            {
                                value.map((text, i) =>
                                    <span key={i}>
                                        {text} {value.length == i + 1 ? "" : " , "}
                                    </span>
                                )
                            }
                        </div>
                    )
                },
                {
                    title: 'Channel',
                    dataIndex: 'channel',
                    key: 'channel',
                    width: 100,
                    align: 'center',
                    render: (text, item) => {
                        { var phone = false; var email = false; }
                        return (
                                <div>
                                    {
                                        item.CHANNEL_PRIMARY == "2" ? email = true : phone = true
                                    }
                                    {
                                        item.CHANNEL_LIST.map((value, index) => {
                                            if (value == "2") {
                                                if (!email) {
                                                    email = true;
                                                }
                                            } else {
                                                if (!phone) {
                                                    phone = true
                                                }
                                            } 
                                        }
                                        )
                                    }
                                    {
                                        phone ?
                                            <span className="fa fa-mobile" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                    }
                                    {
                                        email ?
                                            <span className="fa fa-envelope-o" style={{ paddingRight: 10, fontSize: 16 }} /> : ""
                                    }
                                </div>
                            )
                    }
                },
                {
                    title: 'Birthday',
                    dataIndex: 'PER_DOB',
                    key: 'PER_DOB',
                    width: 100,
                },
                {
                    dataIndex: 'key',
                    key: 'key',
                    width: 50,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu mode="vertical" >
                                    <Menu.Item key="0" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <Link to={'/people/person/detail?id=' + item.PER_ID} style={{ textDecoration: 'none' }} >
                                            <span className="fa fa-pencil-square-o ants-gray size14" ></span>&nbsp;&nbsp;  Edit
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="1" style={{ borderBottom: '1px solid #7b7b7b' }} onClick={() => {
                                        this.props.warningModal(true, "Do you want to delete this person? ", "Deleted person successfully."),
                                            this.props.setStateByProps("action", "delete"),
                                            this.props.setStateByProps("key", [{ PER_ID: item.PER_ID }])
                                    }}>
                                        <span className="fa fa-trash-o ants-gray size14" ></span>&nbsp;&nbsp;  Delete
                                        </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },
            ],
            data: [],
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            maxpage: 10,
            enddata: 10,
            totalpage: 0,
            key: 0,
            clickId: null,
        };


        this.onChangePage = this.onChangePage.bind(this);
        this.getData = this.getData.bind(this);
        this.setClickId = this.setClickId.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getData(1);
        }
    }

    componentDidMount() {
        this.getData(1);
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState(state => {

                let req = JSON.stringify({
                    "START_DATE": null,
                    "END_DATE": null,
                    "SEARCH_PEOPLE": search.name,
                    "DEST": search.dest,
                    "TAG": search.tag,
                    "MINPAGE": state.minpage,
                    "PAGESIZE": state.maxpage
                });

                apiClient.post('api/Person/PERSON_LIST', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setState(state => {
                            var json = data.data;
                            return {
                                data: json ? json.person_list : [],
                                totalpage: json ? json.totalpage : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        var currentpage = this.state.pagesize == pageSize ? currentPage : 1;
        var maxpage = currentpage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState({
            loading: true,
            currentpage: currentpage,
            pagesize: pageSize,
            minpage: minpage,
            maxpage: maxpage
        });
        this.getData();
    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                window.location.href = 'people/person/detail?id=' + item.PER_ID
            }
            return {
                clickId: item ? item.PER_ID : item,
            }
        })

    }

    render() {
        const { columns, data, loading } = this.state;
        const rowSelection = {
            columnWidth: 30,
            onChange: (selectedRowKeys, selectedRows) => {
                this.props.setStateByProps("key", selectedRows)
            },
            getCheckboxProps: record => ({
                key: record.key,
            }),
        };
        return (
            <div>
                <Table
                    style={{ cursor: 'pointer' }}
                    loading={loading}
                    rowKey={key => key.PER_ID}
                    pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        total: this.state.totalpage,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${this.state.totalpage} items`,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }}
                    onRow={(item, index) => ({
                        onDoubleClick: () => this.setClickId(item)
                    })}
                    rowSelection={rowSelection}
                    dataSource={data}
                    columns={columns}
                    tableLayout="fixed"
                    className="table-ants"
                />

            </div>

        );
    }
}


