/* eslint-disable */
import React, { Component } from 'react';
import { Select } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select: undefined,
            list: [],
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData("");
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";
            const encodedString = new Buffer(search).toString('base64');
            apiClient.get('api/Campaign/Template/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ list: data.data });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }


    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, select, setStateByProps } = this.props;
        return (
            <>
                <Select
                    showSearch
                    loading={loading}
                    name="select"
                    onSearch={(value) => { this.getData(value) }}
                    onChange={(value) => { setStateByProps("Template", value) }}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "Select Template"}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        list.map((item, index) =>
                            <Option key={index} value={item.ID}>{item.TEXT}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


