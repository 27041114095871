/* eslint-disable */
import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Input, Select, Spin, Checkbox } from 'antd';
import { apiClient } from '../../../api';
import{getTwoFactor, root} from '../../../config';
import { token, CheckTokenExp, getGenToken } from '../../../config';
import ButtonGroupStatusAcc from '../../../components/Ants/Button/ButtonGroupStatusAcc';
import ButtonGroupSubAcc from '../../../components/Ants/Button/ButtonGroupSubAcc';

const { TextArea } = Input;
export default class ModalAddUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            usercheck: false,
            errormessage: "",
        };
        this.CheckUserDuplicate = this.CheckUserDuplicate.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.forgotpwd = this.forgotpwd.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.passwordFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,}$/
    }

    componentDidMount() {


    }


    CheckUserDuplicate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            let req = JSON.stringify({
                username: this.props.username
            });

            apiClient.post('api/usersmanage/CheckUserDuplicate', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.setState({ usercheck: json });

                    if (!json && this.state.password === this.state.repassword) {
                        this.props.warningModal(true, "Do you want to add user?", "Add user successfully.");
                        this.props.setStateByProps('action', 'usercreate');
                    }

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    saveUser() {
        this.props.warningModal(true, "Do you want to save user?", "Save user successfully.");
        this.props.setStateByProps('action', 'saveuser');
    }

    forgotpwd() {
        // this.props.warningModal(true, "Would you like to send an email to change your password?", "Save user successfully.");
        this.props.setStateByProps('action', 'fgp');
        this.props.setStateByProps('warningModalOpen', true);
        this.props.setStateByProps('message', 'Would you like to send an email to change your password?');
        this.props.setStateByProps('messageSuccess', 'Save user successfully.');
    }

    handleOk() {
        this.setState({ usercheck: false })
        this.props.setStateByProps("usercreate", "");
        this.props.setStateByProps("addrepassword", "");
        this.props.setStateByProps("addpassword", "");
        this.props.setStateByProps("uemail", "");
        this.props.setStateByProps("MadalAddUservisible", false);
    }

    render() {
        const { visible, setStateByProps, loading, id, username, password, repassword,
            statusacc, tocreate, owner, admin, createuser, generateToken, twoFactor, email, emailError } = this.props;
        const { usercheck } = this.state;
        const styleMarginBottom = { marginBottom: 16 };
        return (

            <Modal
                width={700}
                title={<div>{id ? "Edit" : "Create"} user <span className="fa fa-times-circle-o card-close" onClick={this.handleOk}></span></div>}
                visible={visible}
                onOk={this.handleOk}
                onCancel={() => cancelModal(false)}
                loading={loading}
                footer={[
                    <Row gutter={16} type="flex" justify="space-between" style={{ marginBottom: 16, marginLeft: 36, marginRight: 36 }}  >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'right' }} >
                            <label className="red" style={{ marginLeft: 8, display: (!this.passwordFilter.test(password) && password === repassword) ? 'none' : 'initial' }}>{
                                password ?
                                    this.passwordFilter.test(password) ?
                                        password === repassword ? ""
                                            : "Passwords do not match"
                                        : <>
                                            <div>
                                                - The password must be at least 6 characters long
                                            </div>
                                            <div>
                                                - Uppercase and Lowercase is required
                                            </div>
                                            <div>
                                                - Number is required
                                            </div>
                                            <div>
                                                - Special character is required
                                            </div>
                                        </>
                                    : ""
                            }</label>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                            <Button style={{ marginRight: 8, display: owner && (admin || createuser) && id ? 'block' : 'none' }}
                                disabled={username && email && emailError ? false : true}
                                onClick={this.forgotpwd}
                                loading={loading}
                            >
                                <span className="fa fa-asterisk"></span> &nbsp;&nbsp;
                                        Forgot Password
                                    </Button>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                            {
                                owner && (admin || createuser) ?
                                    id ?
                                        <Button type="primary" loading={loading}
                                            disabled={username && email && emailError && this.passwordFilter.test(password) && password === repassword ? false : true}
                                            onClick={this.saveUser}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                        Save
                                    </Button>
                                        : <Button type="primary" loading={loading}
                                            disabled={username && email && emailError && this.passwordFilter.test(password) && password === repassword ? false : true}
                                            onClick={this.CheckUserDuplicate}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                        Save
                                    </Button>
                                    :
                                    <Button loading={loading}
                                        onClick={this.handleOk}>
                                        <span className="fa fa-times-circle"></span> &nbsp;&nbsp;
                                        Close
                                    </Button>
                            }
                        </Col >
                    </Row >
                ]}
            >
                <Spin spinning={loading}>
                    <Row gutter={16} type="flex" justify="start" style={styleMarginBottom}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Username</label>
                            <label className="red">*</label>
                            <label className="red" style={{ marginLeft: 8 }}>{usercheck ? "User duplicate" : ""}</label>
                            {
                                id ?
                                <br />
                                :
                                null
                            }
                            {
                                id ?
                                <label className=" control-label ant-fontsize-acc" style={{ fontWeight: 600 }}>{username}</label>
                                :
                                <Input placeholder="Username" value={username} onChange={(e) => { id ? null : setStateByProps("addusername", e.target.value), this.setState({ usercheck: "" }) }} maxLength={30} />
                            }
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Email</label>
                            <label className="red" style={{ fontWeight: 600 }}>*  &nbsp;{!emailError && email != "" ? "Invalid email format" : ""}</label>
                            <Input placeholder="Email" value={email} onChange={(e) => setStateByProps("uemail", e.target.value)} maxLength={100} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Password</label>
                            <label className="red">*</label>
                            <Input.Password placeholder="Password" value={password} onChange={(e) => setStateByProps("addpassword", e.target.value)} maxLength={30} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Re-type password</label>
                            <label className="red">* </label>
                            <Input.Password placeholder="Re-type Password" value={repassword} onChange={(e) => { setStateByProps("addrepassword", e.target.value) }} maxLength={30} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={styleMarginBottom}>
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Status</label>
                            <label className="red">*</label>
                            <br />
                            <ButtonGroupStatusAcc statename={"addstatusacc"} radio={statusacc} setStateByProps={setStateByProps} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} >
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Create Users</label>
                            <br />
                            <ButtonGroupSubAcc tocreate={"tocreate"} radio={tocreate ? 1 : 0} setStateByProps={setStateByProps} />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} >
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Generate Token</label>
                            <br />
                            <ButtonGroupSubAcc tocreate={"generateToken"} radio={generateToken ? 1 : 0} setStateByProps={setStateByProps} disabled={getGenToken() === "False" ? true : false}/>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={12} >
                            <label className=" control-label require" style={{ fontWeight: 700 }}> Two-Factor</label>
                            <br />
                            <ButtonGroupSubAcc tocreate={"twoFactor"} radio={twoFactor ? 1 : 0} setStateByProps={setStateByProps} disabled={getTwoFactor() === "False" ? true : false}/>
                        </Col>
                    </Row>
                </Spin>
            </Modal>

        );
    }
}
