import React, { Component } from 'react';
import {
    PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form,
    Input, Card, Tooltip, Progress, Table, Divider, Tag, Modal, Upload, Drawer, Spin, Collapse, Breadcrumb
} from 'antd';
import moment from 'moment';
import 'moment-timezone';
import NumericLabel from 'react-pretty-numbers';
import { apiClient } from '../../../api';
import { token, getUrlVars, formatCurrency, CheckTokenExp } from '../../../config';
import ModalWarning from '../../../components/Ants/Modal/ModalWarning';
import { List } from 'antd';
import { TransectionStatus } from '../../../models/transection';

moment.tz.setDefault("Asia/Bangkok");

export default class TransactionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingAll: true,
            currentpage: 1,
            datestart: null,
            dateend: null,
            itemperpage: 10,
            totalitem: null,
            transactionDatas: [],
        };

        this.getTransactionData = this.getTransactionData.bind(this);
    }

    componentDidMount() {
        if (CheckTokenExp()) {
            window.location.href = "#/logout";
        } else {
            this.getTransactionData(1);
        }
    }

    getTransactionData(page = 1) {

        if (CheckTokenExp()) {
            window.location.href = "#/logout";
        }

        this.setState({
            currentpage: page,
            loadingAll: true
        });

        let req = JSON.stringify({
            "currentpage": page,
            "itemperpage": this.state.itemperpage
        });

        apiClient.post('api/ecom/gettransactionhistory', req
            , {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })
            .then(response => {
                if (response.data.status == 999) {
                    return;
                }
                this.setState({
                    transactionDatas: response.data.transactions,
                    totalitem: response.data.count
                });
            })
            .catch(error => {
                console.table(error);
            })
            .finally(data => {
                this.setState({
                    loadingAll: false
                })
            });
    }

    getColorOfPaymentStatus(payment_status) {
        let color = { color: "red" }
        switch (payment_status) {
            case TransectionStatus.SUCCESS.code:
                color = { color: "green" };
                break;
            case TransectionStatus.PENDING.code:
                color = { color: "#ffd761" };
                break;
            default:
                break;
        }
        return color;
    }

    getUrlOfPaymentStatus(transection) {
        let url = `/OrderValidate?oid=${transection.order.id}` // pending
        switch (transection.order.payment_status) {
            case TransectionStatus.SUCCESS.code:
            case TransectionStatus.REVERSED.code:
            case TransectionStatus.FAIL.code:
            case TransectionStatus.EXPIRED.code:
                url = `/ordersummary${transection.receipt.RE_ID !== "" ? `?rid=${transection.receipt.RE_ID}` : `?oid=${transection.order.id}`}`;
                break;
            default:
                break;
        }
        return url;
    }

    render() {

        let { loadingAll, transactionDatas, itemperpage, totalitem } = this.state;

        const columns = [
            {
                title: 'User',
                dataIndex: 'order.create_by',
                key: 'order.create_by',
            },
            {
                title: 'Receipt ref',
                dataIndex: 'receipt.RE_NO',
                key: 'receipt.RE_NO',
            },
            {
                title: 'Order Date',
                dataIndex: 'order.create_date',
                key: 'order.create_date',
                render: (text, row) => {
                    return (
                        <div>
                            {moment(row.order.create_date).format('DD/MM/YYYY HH:mm')}
                        </div>
                    );
                }
            },
            {
                title: 'Package',
                dataIndex: 'package_detail_display',
                key: 'package_detail_display',
                render: (text, row) => {
                    return (
                        <div>
                            {row.receipt.RE_PACKAGE}
                            <br />
                            {row.receipt.RE_PACKAGE_DETAIL}
                        </div>
                    );
                }
            },
            {
                title: 'Payment Detail',
                dataIndex: 'payment_detail_display',
                key: 'payment_detail_display',
                render: (text, row) => {
                    return (
                        <div>
                            <span style={this.getColorOfPaymentStatus(row.order.payment_status)} > {row.receipt_payment_status_display}</span>
                            <br />
                            {row.receipt_payment_method_display}
                        </div >
                    );
                }
            },
            {
                title: 'Net amount',
                dataIndex: 'order.net_payment',
                key: 'order.net_payment',
                render: (text, row) => {
                    return (
                        <div>฿ {formatCurrency(parseFloat(row.order.net_payment).toFixed(2))}</div>
                    );
                }
            },
            {
                title: 'Action',
                dataIndex: 'receipt.RE_ID',
                key: 'receipt.RE_ID',
                render: (text, row) => {
                    return (
                        <div>
                            {<a href={this.getUrlOfPaymentStatus(row)}>More Detail</a>}
                        </div>);
                }
            },
        ];

        return (
            <Spin spinning={loadingAll}>
                <div style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
                    <div>
                        <h2>Transaction history</h2>
                    </div>
                    <div>
                        <Table
                            columns={columns}
                            dataSource={transactionDatas}
                            pagination={{
                                onChange: page => {
                                    console.table(page);
                                    this.getTransactionData(page);
                                },
                                pageSize: itemperpage,
                                total: totalitem
                            }}
                        />
                    </div>
                </div>
            </Spin>
        );
    }
}




