/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { Table, Divider, Tag, Icon, Button, Row, Col, Modal, Input } from 'antd';
import { apiClient } from '../../../api';
import { root } from '../../../config';
import { token, deepEqual, CheckTokenExp } from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const { TextArea } = Input;
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';

export default class TableContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'Subject',
                    dataIndex: 'subject',
                    key: 'subject',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Message',
                    dataIndex: 'question',
                    key: 'question',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Create Date',
                    dataIndex: 'date_question',
                    key: 'date_question',
                    width: 150,
                    render: text => (moment(text).format(dateFormatDateTime))
                },
                {
                    title: 'Reply',
                    dataIndex: 'answer',
                    key: 'answer',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Reply Date',
                    dataIndex: 'date_answer',
                    key: 'date_answer',
                    width: 150,
                    render: text => (text ? moment(text).format(dateFormatDateTime) : "")
                },
                {
                    title: 'File',
                    dataIndex: 'image',
                    key: 'image',
                    width: 80,
                    render: (text, item) => (
                        text ? <a onClick={() => this.downloadFile(text)}>Download</a> : ""
                    )
                },
            ],
            data: [],
            minpage: 1,
            pagesize: 10,
            totalpage: 0,
            currentpage: 1,
        };

        this.onChangePage = this.onChangePage.bind(this);
        this.getData = this.getData.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setStateByProps = this.setStateByProps.bind(this);



    }

    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.search, prevProps.search)) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            const { search } = this.props;

            this.setState({ loading: true });

            this.setState(state => {
                let req = JSON.stringify({
                    search: search.value,
                    startdata: state.minpage,
                    enddata: state.pagesize,
                });

                apiClient.post('api/issuereport/get', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;

                        this.setStateByProps();

                        this.setState(state => {
                            return {
                                data: json.data,
                                totalpage: json.totalpage,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        });
                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            })
        } else {
            window.location.href = "logout";
        }

    }

    setStateByProps() {
        this.props.setStateByProps("saveData", "");
    }

    onChangePage(currentPage, pageSize) {
        var maxpage = currentPage * pageSize;
        var minpage = maxpage - pageSize + 1;

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize === 10 ? 1 : currentPage,
                pagesize: maxpage,
                minpage: minpage,
            };
        });
        this.getData(); 

    }

    downloadFile(item, name) {
        if (!CheckTokenExp()) {
            apiClient.get('api/file/getFilename/' + item
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                .then(data => {
                    var filename = data.data;

                    apiClient.get('api/file/download/' + item
                        , {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-type': 'application/json; charset=UTF-8'
                            },
                            responseType: 'blob'
                        })
                        .then(data => {
                            var json = data.data;

                            const url = window.URL.createObjectURL(new Blob([json]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = filename;
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        })
                        .catch(error => {

                        });
                })
                .catch(error => {

                });
        } else {
            window.location.href = "logout";
        }

    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps } = this.props;
        return (
            <div>

                <Table
                    loading={loading}
                    pagination={{
                        current: this.state.currentpage,
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: false,
                        total: totalpage,
                        showTotal: (total, range) => <div>&nbsp;&nbsp; {range[0]}-{range[1]} of {totalpage} items </div>,
                        onShowSizeChange: (currentPage, pageSize) => this.onChangePage(currentPage, pageSize),
                        onChange: (currentPage, pageSize) => this.onChangePage(currentPage, pageSize)
                    }}
                    rowKey="id"
                    style={{ cursor: 'pointer' }}
                    columns={columns}
                    dataSource={data}
                    className="table-ants"
                    tableLayout="fixed"
                />




            </div>

        );
    }
}


