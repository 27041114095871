import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col, Avatar, Modal } from 'antd';

import { token } from '../../../config';

const { Option, OptGroup } = Select;

export default class InputTo extends Component {

    constructor(props) {
        super(props);

        this.handleChangemoretagandperson = this.handleChangemoretagandperson.bind(this);

        this.state = {
            to: [],
            fileList: [],
            file_size: 0,
            PeopleLists: [],
            TagsLists: [],
            count: 0,
        };

    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props.PeopleLists !== prevProps.PeopleLists) {
            this.setState({ PeopleLists: this.props.PeopleLists });
        }
        if (this.props.TagsLists !== prevProps.TagsLists) {
            this.setState({ TagsLists: this.props.TagsLists });
        }

        if (prevProps.to !== this.props.to) {
            this.setState({ count: this.state.count + 1 });
            

            this.setState({ to: this.props.to });
        }
        if (prevProps.fileList !== this.props.fileList) {
            this.setState({ fileList: this.props.fileList });
        }
        if (prevProps.file_size !== this.props.file_size) {
            this.setState({ file_size: this.props.file_size });
        }
    }



    handleChangemoretagandperson(e, option) {
        //e.length = ความยาว array , e.reduce((x, y) => x + y).length = ความยาวตัวอักษรทั้งหมดในช่อง to
        // let length = e.length > 0 ? e.reduce((x, y) => x + y).length : 0;
        let length = e.toString().length;
        if (length > this.props.maxLength) {
            this.props.setStateByProps("errorLength","Maximum limit 1,000 charactors");
        } else {
            var value_to = this.state.to.length > 0 ? this.state.to.length : 0;
            var value_e = e.length > 0 ? e.length : 0;

            //ความยาวของ to มากกว่า stat ก่อนหน้านี้
            if (value_e >= value_to) {
                if (e[e.length - 1].includes("tag_") || e[e.length - 1].includes("person_")) {
                    var value = e.filter((item) => !item.includes(".xlsx") == true);
                    this.setState({
                        to: value,
                        fileList: [],
                        variable: []
                    });
                    this.props.setStateByProps("to", value);
                    this.props.setStateByProps("fileList", []);
                    this.props.setStateByProps("variable", ["firstName", "lastName", "address", "city", "country", "gender", "birthDate"]);
                }
                else {
                    if (!e[e.length - 1].includes(".xlsx")) {
                        this.setState({ to: e });
                    }
                     
                    this.props.setStateByProps("to", e);
                }
            } else { //ความยาวของ to ลดลง
                this.setState(state => {
                    const fileList = state.fileList.filter((item) => e.includes(item.name));
                    const size = fileList.length > 0 ? fileList.map((item) => item.size).reduce((size, item) => size + item) : 0;
                    const newFileList = fileList;

                    if (newFileList.length == 0) {
                        this.props.setStateByProps("variable", ["firstName", "lastName", "address", "city", "country", "gender", "birthDate"]);
                    } else {
                        var value = e.filter((item) => !item.includes("person_") == true).filter((item) => !item.includes("tag_") == true);
                        this.props.setStateByProps("to", value);
                    }

                    this.props.setStateByProps("fileList", newFileList);
                    this.props.setStateByProps("file_size", size);

                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({ to: e });
                this.props.setStateByProps("to", e);
            }
        }
    }

    render() {
        const { to, PeopleLists, TagsLists, } = this.state;
        const { loading, GetTagList, GetPeopleList } = this.props;
        return (
            <Select
                loading={loading}
                value={to}
                mode="tags"
                placeholder="Enter new numbers or select from your people and tag here"
                tokenSeparators={[',', ' ']}
                onChange={this.handleChangemoretagandperson}
                onSearch={(value) => { GetTagList(value); GetPeopleList(value); }}
                style={{ width: '100%', borderColor: this.state.to_require == true ? 'red' : '#d9d9d9' }}
                filterOption={(input, option) =>
                    () => input != undefined ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : null
                }
            >
                <OptGroup label="People">
                    {
                        this.state.PeopleLists.map((data, index) => (
                            <Option key={index} value={"person_" + data?.ID.toString()} name="peolpe"><Avatar className="ant-avatar-to" style={{ backgroundColor: "#b71c1c", verticalAlign: 'middle', width: 20, height: 20 }} size="small">
                                {data?.TEXT.substring(0, 1)}
                            </Avatar>&nbsp;&nbsp;{data.TEXT}</Option>
                        ))
                    }
                </OptGroup>
                <OptGroup label="Tag">
                    {
                        this.state.TagsLists.map((data, index) => (
                            <Option key={index} value={"tag_" + data?.ID.toString()} name="tag"><Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle', width: 20, height: 20 }} icon="tag" size="small">
                            </Avatar>&nbsp;&nbsp;{data?.TEXT}</Option>

                        ))
                }
                </OptGroup>
            </Select>
        );
    }
}


