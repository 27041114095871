/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Spin } from 'antd';
import { token, formatNumber, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';
import { apiClient } from '../../../api';
import{root} from '../../../config';

moment.tz.setDefault("Asia/Bangkok");

const dateTimeFormat = 'YYYYMMDDHHmmss';

export default class TableRecipients extends Component {

    constructor(props) {
        super(props);

        this.downloadFile = this.downloadFile.bind(this);

        this.state = {
            loading: false,
            columns: [
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, item) =>
                        <div style={{
                            cursor: text != 'Unique' ? item.recipients > 0 ? 'pointer' : 'context-menu' : 'context-menu'
                        }}>
                            {
                                text == "Unique" ? <span className="fa fa-star-o or" style={{ color: '#0ad1a3' }}> &nbsp;&nbsp;</span> :
                                    text == "Duplicate" ? <span className="fa fa-files-o" style={{ color: '#2db5ee' }}> &nbsp;&nbsp;</span> :
                                        text == "Blacklist" ? <span className="fa fa-ban" style={{ color: '#808184' }}> &nbsp;&nbsp;</span> :
                                            text == "Invalid" ? <span className="fa fa-times-circle-o" style={{ color: '#ff717a' }}> &nbsp;&nbsp;</span> :
                                                ""

                            }{" "}
                            {text}
                        </div>
                },
                {
                    title: 'Recipients',
                    dataIndex: 'recipients',
                    key: 'recipients',
                    render: (text, item) =>
                        <div style={{ cursor: item.status != 'Unique' ? item.recipients > 0 ? 'pointer' : 'context-menu' : 'context-menu' }}

                        >
                            {
                                item.status == "Unique" ? <span style={{ color: '#0ad1a3' }}> {formatNumber(text)}</span> :
                                    item.status == "Duplicate" ? text == 0 ? formatNumber(text) : <span style={{ color: '#2db5ee' }}> {formatNumber(text)}</span> :
                                        item.status == "Blacklist" ? text == 0 ? formatNumber(text) : <span style={{ color: '#808184' }}> {formatNumber(text)}</span> :
                                            item.status == "Invalid" ? text == 0 ? formatNumber(text) : <span style={{ color: '#ff717a' }}> {formatNumber(text)}</span> :
                                                ""
                            }
                        </div>
                },
            ],
        };

    }

    downloadFile(id, status) {
        if (!CheckTokenExp()) {
            let req = JSON.stringify({
                id: id,
                status: status
            });

            apiClient
                .post('api/file/recipients/', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                    responseType: 'blob',
                })
                .then(data => {
                    var json = data.data;
                    const url = window.URL.createObjectURL(new Blob([json]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = "campaign_" + id + "_" + status + "_" + moment().format(dateTimeFormat) + ".xlsx";
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { columns, loading } = this.state;
        const { data } = this.props;
        return (
            <div>
                <Spin spinning={loading}>
                    <Table
                        pagination={false}
                        size="middle"
                        columns={columns}
                        dataSource={data}
                        className="table-ants time-table-row-select"
                        rowClassName={(item) =>
                            item.status != 'Unique' ? item.recipients > 0 ? 'hoverno' : '' : ''
                        }
                        showHeader={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (item) => { record.recipients > 0 ? record.status == 'Unique' ? null : this.downloadFile(record.id, record.status) : null }
                            };
                        }}
                    />
                </Spin>
            </div>
        );
    }
}


