
/* eslint-disable */
import React, { Component } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber, Icon, Row, Col } from 'antd';
import { token, CheckTokenExp } from '../../../config';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectSender extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            select: undefined,
            list: [],
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData("");
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            var search = value ? value : "null";

            const encodedString = new Buffer(search).toString('base64');
            apiClient.get('api/Campaign/Sender/' + encodedString
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ list: data.data });
                        this.props.setStateByProps("senderlist", data.data);
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                        this.props.setStateByProps("senderlist", []);
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, mode, disablestatus, select, setStateByProps, by, style } = this.props;
        return (
            <>
                <Select
                    mode={mode ? mode : "default"}
                    allowClear={mode ? true : false}
                    showSearch
                    disabled={disablestatus == true ? true : false}

                    style={style ? style : null}
                    loading={loading}
                    name="sender"
                    onSearch={(value) => { this.getData(value) }}
                    onChange={(value) => { setStateByProps("sender", value) }}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "Enter new sender name or select from options here."}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    {by == "id" ?
                        list.map((item, index) =>
                            <Option key={index} value={item.ID}>{item.TEXT}</Option>
                        )
                        :
                        list.map((item, index) =>
                            <Option key={index} value={item.TEXT}>{item.TEXT}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


