import React, { Component } from "react";
import { Button, notification } from "antd";
import { omisekey, sellername, currency } from "../../config";
class OmiseCreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnLoginloading: false,
    };

    this.callOmise = this.callOmise.bind(this);
  }

  componentDidMount() {
    const OmiseCard = window.OmiseCard;

    OmiseCard.configure({
      publicKey: omisekey,
      frameLabel: sellername,
    });

    this.setState({
      OmiseCreditCard: OmiseCard,
    });

    /*  this.callOmise() */
  }

  callOmise(e) {
    e.preventDefault();

    this.props.setLoading(true);
    this.props.setMessage("Waiting for your card info...", null);
    /*this.props.setAllowToPayStatus(false);*/
    console.log("test callOmise credit card");
    console.log(this.props.data);
    let inputData = this.props.data;
    let message = "";
    if(this.props.showtaxinvoice){
        if (inputData.mobile !== "") {
        const regex = new RegExp("^[0][0-9]{9}$");
        console.log("check mobile", regex.test(inputData.mobile));
        if (!regex.test(inputData.mobile)) {
            message = "เบอร์โทรศัพท์กรอกไม่ถูกต้อง";
            this.props.CheckBtnPayment(message);
        }
        }
        if (inputData.idcard !== "") {
        const regex = new RegExp("[0-9]{13}$");
        console.log("idcard", inputData.idcard);
        console.log("check idcard", regex.test(inputData.idcard));
        if (!regex.test(inputData.idcard)) {
            message = "id card กรอกไม่ถูกต้อง";
            this.props.CheckBtnPayment(message);
        }
        }
        if (inputData.typetax === 1) {
        if (
            inputData.name === "" ||
            inputData.mobile === "" ||
            inputData.idcard === ""
        ) {
            message = "กรุณากรอกข้อมูลให้ครบถ้วน";
            this.props.CheckBtnPayment(message);
        }
        } else if (inputData.typetax === 2) {
        if (
            inputData.name === "" ||
            inputData.branch === "" ||
            inputData.mobile === "" ||
            inputData.idcard === "" ||
            inputData.address === "" ||
            inputData.province === "" ||
            inputData.district === "" ||
            inputData.subdistrict === "" ||
            inputData.post === ""
        ) {
            message = "กรุณากรอกข้อมูลให้ครบถ้วน";
            this.props.CheckBtnPayment(message);
        }
        }
    }

    if (message === "") {
      window.OmiseCard.open({
        amount: this.props.packagecost * 100,
        currency: currency,
        defaultPaymentMethod: "credit_card",
        onCreateTokenSuccess: (nonce) => {
          console.table(nonce);
          if (nonce.startsWith("tokn_")) {
            if (this.props.AutoCheckout) {
              this.props.ProcessPayment(nonce);
            }
          } else {
            notification.open({
              message: "Failed to charge your card",
              description:
                "Cannot connect to Payment operator. Please try again later.",
              top: 60,
              className: "rti_form r",
            });
          }
        },
        onFormClosed: () => {
          this.props.setLoading(false);
        },
      });
    }
  }

  beforeSubmit(e) {
    console.table(this.state);
    e.preventDefault();
  }

  render() {
    let { btnLoginloading } = this.state;

    return (
      <form id="CheckOutWithCreditCard" onSubmit={this.beforeSubmit}>
        <Button
          style={{ backgroundColor: "#EA7326", color: "#fff" }}
          loading={btnLoginloading}
          onClick={this.callOmise}
        >
          Payment
        </Button>
      </form>
    );
  }
}

export default OmiseCreditCard;
