/* eslint-disable */
import React, { Component } from 'react';
import { Select } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectCampaign extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            select: [],
            accounts: [],
            users: [],
            sender: [],
            report_Template: 0,
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);

    }

    componentDidMount() {
        this.getData("", this.state.accounts, this.state.users, this.state.sender, this.state.report_Template) 
    }

   

    componentWillReceiveProps(nextProps) {

        if (this.state.accounts !== nextProps.accounts) {
            this.setState({ accounts: nextProps.accounts}, () => {
                this.getData("", this.state.accounts, this.state.users, this.state.sender, this.state.report_Template)});
       
        }

        if (this.state.users !== nextProps.users) {
            this.setState({ users: nextProps.users }, () => {  
                this.getData("", this.state.accounts, this.state.users, this.state.sender, this.state.report_Template) });
               
           
        }


        if (this.state.sender !== nextProps.sender) {
            this.setState({ sender: nextProps.sender }, () => { 
                this.getData("", this.state.accounts, this.state.users, this.state.sender, this.state.report_Template)});
          
        }

        if (this.state.report_Template !== nextProps.report_Template) {
            this.setState({ report_Template: nextProps.report_Template }, () => {
                this.getData("", this.state.accounts, this.state.users, this.state.sender, this.state.report_Template) });
            
        }
        
    }


    getData(value, accounts, users, sender, report_Template) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            let req = JSON.stringify({
                name: value,
                accountid: accounts,
                userid: users,
                sendername: sender,
                report_type: report_Template
            });

            apiClient.post('api/Report/CampaignList_createreport', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.status === 200 && data.data) {
                        this.setState({ list: data.data });
                    } else {
                        this.setState({ list: [] });
                        this.setState({ modalVisible: true });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }


    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, select,mode ,disablestatus, setStateByProps, style, accounts, users, sender, report_Template } = this.props;
        return (
            <>
                <Select
                    mode={mode == "default" ? "default" : "multiple"}
                    allowClear
                    showSearch
                    disabled={disablestatus == true ? true : false}

                    style={style ? style : null}
                    loading={loading}
                    name="select"
                    onSearch={(value) => { this.getData(value, accounts, users, sender, report_Template) }}
                    onChange={(value) => setStateByProps("campaign", value)}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "Campaign"}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        list.map((item, index) =>
                            <Option key={index} value={item.key}>{item.name == null ? "Unknown" : item.name}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}

