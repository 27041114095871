export const toText = 'Number or select from people and tag';
export const fromText = "Choose the sender's name to display to the destination you selected.";
export const templateText = 'Choose the template created';
export const templateCreateText = 'Desired template name';
export const senderText = 'Choose sender name The name you want to use cannot be found.Contact system administrator';
export const broadcastNameText = 'Desired broadcast name';
export const textforceStop = 'The system will stop sending all remaining message this campaign.';
export const textedit = 'Edit';
export const textdelete = 'Delete';
export const tokenAuth = 'Use this token with header for call api'
