/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Button, Descriptions, Select, Row, Col, DatePicker, Icon, Form, Input, Card, Tooltip, Progress, Table, Divider, Tag, Spin } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { token, getId, getUsername, CheckTokenExp,ReplaceUrlTaga } from '../../config';
import SelectStatusCampaign from '../../components/Ants/Select/SelectStatusCampaign';
import SelectUser from '../../components/Ants/Select/SelectUser';
import TableBroadcast from '../../components/Ants/Table/TableBroadcast.js';
import ModalSuccess from '../../components/Ants/Modal/ModalSuccess';
import ModalWarning from '../../components/Ants/Modal/ModalWarning';
import Axios from 'axios';
import ModalServerError from '../../components/Ants/Modal/ModalServerError';

moment.tz.setDefault("Asia/Bangkok");

const { Option } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateRange: [],
            name: "",
            users: [getUsername()],
            status: undefined,
            loading: false,
            message: "",
            messageSuccess: "",
            search: {
                name: "",
                status: undefined ,
                users: [getId()],
                dateRange: []
            },
            screenwidth: 0,
            modalVisible: false,
        };

        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.warningModal = this.warningModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.modalcountDown = this.modalcountDown.bind(this);
        this.handleDeleteCampaign = this.handleDeleteCampaign.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
        //this.onSearch();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        setTimeout(() => {
            var root = document.getElementById("root");
            if (root) {
                var e = document.getElementById("main");
                if (e) {
                    var scroll = root.offsetHeight > window.innerHeight;
                    this.setState({ screenwidth: scroll ? e.offsetWidth - 148 : e.offsetWidth - 148 })
                }
            }
        }, 500);
    }

    setStateByProps(parameters, value) {
        value ? value : "";
        this.setState({ [parameters]: value });
    }


    onSearch() {
        this.setState(state => {
            return {
                search: {
                    name: state.name,
                    status: state.status,
                    users: state.users,
                    dateRange: state.dateRange
                }
            }
        });
        this.updateWindowDimensions();
    }

    warningModal(value, message, messageSuccess) {
        this.setState({ warningModalVisible: value, message, messageSuccess });
    }

    successModal(value) {
        if (this.state.action == "copy") {
            this.handleDuplicate();
            this.setState({ action: "" });
        }
        if (this.state.action == "delete") {
            this.handleDeleteCampaign(this.state.campaignid);
            this.setState({ action: "" });
        }
        this.setState({ successModalVisible: value });
    }

    modalcountDown() {
        let secondsToGo = 2;
        const timer = setInterval(() => {
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({ successModalVisible: false });
        }, secondsToGo * 1000);


    }

    handleDuplicate() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            Axios.create({url:"/"}).get('api/Campaign/DuplicateCapaign/' + this.state.campaignid
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;

                    this.onSearch();

                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }

    }

    handleDeleteCampaign(CampId) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            Axios.create({url:"/"}).get('api/Campaign/DeleteCampaign/' + CampId
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.onSearch();
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
    }

    render() {
        const { dateRange, search, users, status, name, loading, warningModalVisible, successModalVisible, message, messageSuccess, screenwidth, modalVisible } = this.state;
        const routes = [
            {
                path: 'communication' ,
                breadcrumbName: 'Communication' ,
            },
        ];

        var styleinput_name = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 160
        };
        var styleinput_status = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 160
        };
        var styleinput_user = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 160
        };
        var styleinput_date = {
            width: (screenwidth / 4),
            marginRight: 8,
            marginBottom: 8,
            minWidth: 160
        };

        return (
            <div>
                <Spin spinning={loading}>
                    <PageHeader breadcrumb={{ routes }}>
                        <Row gutter={8} type="flex" justify="end">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{ textAlign: "right" }}>
                                    <Link to={'/communication/new?type=quicksend'} >
                                        <Button type="primary" style={{ marginRight: 8 }}>
                                            <span className="fa fa-plus-circle"></span> &nbsp;&nbsp;
                                        New
                                    </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </PageHeader>

                    <div className="content" >
                        <Card style={{ minHeight: 200 }}>
                            <Row gutter={8} className="row-nowrap" id="main">
                                <Col xs={24}>
                                    <Input placeholder="Broadcast name" value={name} style={styleinput_name} onChange={(e) => this.setStateByProps("name", e.target.value)} />
                                    
                                    <SelectStatusCampaign setStateByProps={this.setStateByProps} select={status} style={styleinput_status} />

                                    <SelectUser setStateByProps={this.setStateByProps} select={users} style={styleinput_user}  />

                                    <RangePicker
                                        placeholder={["Start Date", "End Date"]}
                                        style={styleinput_date}
                                        defaultValue={dateRange}
                                        ranges={{
                                            Today: [moment().startOf('day'), moment().endOf('day')],
                                            'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                                            'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                                            'Last 15 Days': [moment().subtract(14, 'd'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'd'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')]
                                        }}
                                        onChange={(value) => this.setStateByProps("dateRange", value)}
                                        value={dateRange}
                                        format={dateFormat} />

                                    <Button icon="search" onClick={this.onSearch}>
                                        Search
                           </Button>
                                </Col>

                            </Row>

                            <TableBroadcast search={search}
                                warningModal={this.warningModal}
                                setStateByProps={this.setStateByProps}
                                updateWindowDimensions={this.updateWindowDimensions}
                            />
                        </Card>
                    </div>
                </Spin>

                <ModalWarning loading={loading}
                    warningModalVisible={warningModalVisible}
                    warningModal={this.warningModal}
                    successModal={this.successModal}
                    modalcountDown={this.modalcountDown}
                    message={message}
                    messageSuccess={messageSuccess}
                />

                <ModalSuccess loading={loading}
                    successModalVisible={successModalVisible}
                    successModal={this.successModal}
                    messageSuccess={messageSuccess}
                />

                <ModalServerError modalVisible={modalVisible} />

            </div>
        );
    }
}


