import React, { Component } from 'react';
import { Button, Icon, Radio, notification } from 'antd';
import { omisekey, sellername, currency } from '../../config';
import { TaxType } from '../../models/transection';
class Omiseprompay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnLoginloading: false
        }

        this.callOmise = this.callOmise.bind(this);
    }

    componentDidMount() {
        const OmiseCard = window.OmiseCard;

        OmiseCard.configure({
            publicKey: omisekey,
            frameLabel: sellername,
        });

        this.setState({
            OmiseCreditCard: OmiseCard
        })
    }

    callOmise(e) {
        e.preventDefault();

        if (!this.props.data.mobile || !this.props.data.name || !this.props.data.idcard) {
            this.props.handleErrorMessage("Please fill up this form.")
            return
        }
        if (this.props.data.mobile.length !== 10) {
            this.props.handleErrorMessage("Mobile number must be 10 digits.")
            return
        }
        if (this.props.data.idcard.length < 10 || this.props.data.idcard.length > 13) {
            this.props.handleErrorMessage("ID card must be 10-13 digits.")
            return
        }
        if (this.props.data.typetax == TaxType.CORPORATE) {
            if (!this.props.data.address ||
                !this.props.data.branch ||
                !this.props.data.district ||
                !this.props.data.province ||
                !this.props.data.post ||
                !this.props.data.subdistrict) {

                this.props.handleErrorMessage("Please fill up this form.")
                return
            }
        }

        this.loadOmisePromptpay(this.props);
    }

    loadOmisePromptpay(data) {
        data.setLoading(true);
        data.setMessage("Waiting for your promptpay info...", null);

        window.OmiseCard.open({
            amount: data.packagecost * 100,
            currency: currency,
            defaultPaymentMethod: "promptpay",
            onCreateTokenSuccess: (nonce) => {
                if (nonce.startsWith("src_")) {
                    if (data.AutoCheckout) {
                        data.ProcessPayment(nonce, "promptpay");
                    }
                } else {
                    notification.open({
                        message: 'Failed to create payment',
                        description: "Cannot connect to Payment operator. Please try again later.",
                        top: 60,
                        className: "rti_form r",
                    });
                };
            },
            onFormClosed: () => {
                data.setLoading(false);
            }
        });
    }

    beforeSubmit(e) {
        e.preventDefault();
    }

    render() {
        let { btnLoginloading } = this.state;

        return (
            <form id="CheckOutWithpromptpay" onSubmit={this.beforeSubmit}>
                <span style={{ color: "red" }}>{this.props.data.errormessage}</span>
                <Button
                    style={{ backgroundColor: "#EA7326", color: "#fff" }}
                    loading={btnLoginloading}
                    //   disabled={!this.props.data.mobile || !this.props.data.name || !this.props.data.idcard}
                    onClick={this.callOmise}
                >
                    Payment
                </Button>
            </form>
            /*  <form id="CheckOutWithpromptpay" onSubmit={this.beforeSubmit}>
                 <Button style={{}} loading={btnLoginloading} onClick={this.callOmise}>Pay with promptpay</Button>
             </form> */
        )
    }
}

export default Omiseprompay;