/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  PageHeader,
  Button,
  Descriptions,
  Select,
  Row,
  Col,
  DatePicker,
  Icon,
  Form,
  Input,
  Card,
  Tooltip,
  Progress,
  Table,
  Divider,
  Tag,
  Modal,
  Checkbox,
  Spin,
  Radio,
  From,
  InputNumber,
} from "antd";

import { apiClient } from "../../../api";
import { root } from "../../../config";
import {
  token,
  validateEmail,
  colorGreen,
  colorRed,
  CheckTokenExp,
} from "../../../config";
import ModalSuccess from "../../../components/Ants/Modal/ModalSuccess";
import ModalWarning from "../../../components/Ants/Modal/ModalWarning";
import ModalWarningCustom from "../../../components/Ants/Modal/ModalWarningCustom";
import ModalError from "../../../components/Ants/Modal/ModalError";
import SelectSender from "../../../components/Ants/Select/SelectSender";


const { Option } = Select;

export default class LimitSend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningModalVisible: false,
      successModalVisible: false,
      loading: false,
      message: "",
      messageSuccess: "",
      screenwidth: 0,
      formLayout: "horizontal",
      configCreditId: 0,
      total: 1,
      email: [],
      statusconfig: 1,
      emailError: true,
      warningModalOpen: false,
      errorModalVisible: false,
      messageError: "",
      senderError: "",
      sender: [],
      list: [],
      type: 1,
    };

    this.warningModal = this.warningModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.modalcountDown = this.modalcountDown.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.warningModalCustom = this.warningModalCustom.bind(this);
    this.onSubmitWarning = this.onSubmitWarning.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.setStateByProps = this.setStateByProps.bind(this);
    this.getDropdown_limit_send_type = this.getDropdown_limit_send_type.bind(this);
    this.Get_limit_send_user = this.Get_limit_send_user.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.getDropdown_limit_send_type();
    this.Get_limit_send_user();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    setTimeout(() => {
      var root = document.getElementById("root");
      if (root) {
        var e = document.getElementById("main");
        if (e) {
          var scroll = root.offsetHeight > window.innerHeight;
          this.setState({
            screenwidth: scroll ? e.offsetWidth - 124 : e.offsetWidth - 124,
          });
        }
      }
    }, 500);
  }

  warningModal(value, message, messageSuccess) {
    this.setState({ warningModalVisible: value, message, messageSuccess });
  }

  successModal(value) {
    if (this.state.action == "configcredit") {
      if (this.state.configCreditId == 0) {
        this.setState({ action: "" });
        this.configCreate();
      } else {
        this.setState({ action: "" });
        this.configUpdate();
      }
    }

    this.setState({ successModalVisible: value });

    let modalcontent = document.querySelectorAll(
      ".ant-modal-contentant-modal-content-hide"
    );
    for (var i = 0; i < modalcontent.length; i++) {
      modalcontent[i].className = "ant-modal-content";
    }
  }

  modalcountDown() {
    let secondsToGo = 2;
    const timer = setInterval(() => { }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      this.setState({ successModalVisible: false });
    }, secondsToGo * 1000);
  }

  getDropdown_limit_send_type() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      apiClient
        .get("api/usersmanage/limit_send_type", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {

          const listOption = data.data.map((list) => {
            return {
              value: list.ID,
              label: list.TEXT
            }
          })

          this.setState({ list: listOption });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }
  warningModalCustom(value) {
    this.setState({ warningModalOpen: value });
  }

  onSubmitWarning() {

    this.savelimit();


  }

  errorModal(value) {
    this.setState({ errorModalVisible: value });
  }



  setStateByProps(parameters, value) {
    value ? value : "";
    if (parameters == "email") {
      this.setState({
        emailError: true,
      });
      if (value.length > 0) {
        var i;
        var error = true;
        for (i = 0; i < value.length; i++) {
          if (validateEmail(value[i])) {
            this.setState({ [parameters]: value });
          } else {
            error = false;
          }
        }
        if (!error) {
          this.setState({ emailError: false, [parameters]: value });
        }
      } else {
        this.setState({ [parameters]: value });
      }
    } else {
      this.setState({ [parameters]: value });
    }
  }


  savelimit() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      let req = JSON.stringify({
        limitflag: this.state.statusconfig === 0 ? true : false,
        type: this.state.type,
        total: this.state.total,
        sender: this.state.sender,
      });

      apiClient
        .post("api/usersmanage/Save_limit_send", req, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          var json = data.data;
          if (json.Status === "error") {
            this.setState({
              errorModalVisible: true,
              messageError: "Save config Limit send unsuccessful!",
            });
          } else {
            this.setState({ successModalVisible: true });
            setTimeout(() => {
              this.setState({ successModalVisible: false });
            }, 3000);
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ errorModalVisible: true, messageError: error });
        });
    } else {
      window.location.href = "logout";
    }
  }


  Get_limit_send_user() {
    if (!CheckTokenExp()) {
      this.setState({ loading: true });

      apiClient
        .get("api/usersmanage/Get_limit_send_user", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((data) => {
          this.setState({ statusconfig: data.data.limitflag ? 0 : 1 });
          this.setState({ type: data.data.type });
          this.setState({ total: data.data.total });
          this.setState({ sender: data.data.sender.length == 0 || (data.data.sender.length == 1 && data.data.sender[0] === '') ? [] : data.data.sender });
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } else {
      window.location.href = "logout";
    }
  }

  render() {
    const {
      loading,
      message,
      messageSuccess,
      warningModalVisible,
      successModalVisible,
      formLayout,
      total,
      email,
      emailError,
      statusconfig,
      warningModalOpen,
      errorModalVisible,
      messageError, sender, list, type
    } = this.state;

    const {
    } = this.props;

    const formItemLayout =
      formLayout === "horizontal"
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
          wrapperCol: { span: 14, offset: 4 },
        }
        : null;
    const colorOn = statusconfig == 0 ? colorGreen : "";
    const colorOff = statusconfig == 1 ? colorRed : "";
    return (
      <div className="content" id="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={16}>
              <Form.Item label={
                <label>
                  Limit send <label className="red">*</label>
                </label>
              }
                {...formItemLayout}>

                <Radio.Group
                  value={statusconfig}
                  onChange={(e) => {
                    this.setState({ statusconfig: e.target.value })
                  }
                  }
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio.Button
                    value={1}
                    style={{ backgroundColor: colorOff, borderColor: colorOff }}
                  >
                    OFF
                  </Radio.Button>
                  <Radio.Button
                    value={0}
                    style={{ backgroundColor: colorOn, borderColor: colorOn }}
                  >
                    ON
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={
                  <label>
                    Type <label className="red">*</label>
                  </label>
                }
                {...formItemLayout}
              >
                <Select select={type} onChange={(value) => { this.setState({ type: value }) }} style={{ width: "100%" }} value={type}>
                  {
                    list.map((item) =>
                      <Option value={item.value}>{item.label}</Option>
                    )
                  }
                </Select>


              </Form.Item>

              <Form.Item
                label={
                  <label>
                    Total Limit <label className="red">*</label>
                  </label>
                }
                {...formItemLayout}
              >
                <InputNumber
                  min={1}
                  defaultValue={1}
                  value={total}
                  onChange={(e) => { this.setState({ total: e }) }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              </Form.Item>
              {type == 2 ? <Form.Item label={
                <label>
                  Sender <label className="red">*</label>
                </label>
              }{...formItemLayout}>

                <SelectSender
                  style={{ width: "100%" }}
                  placeholder={"Select Sender"}
                  mode={"multiple"}
                  select={sender}
                  /* campaign={campaign} accounts={account} report_Template={report_Template} users={users}
                  disablestatus={report_Template == 5 || report_Template == 4 ? true : false} */
                  setStateByProps={this.setStateByProps} />

              </Form.Item> : null}

              <Form.Item {...buttonItemLayout}>
                <Button
                  type="primary"
                  disabled={
                    total == "" || total == null ||
                    (type == 2 && sender.length == 0)
                  }
                  onClick={() => {
                    this.setState({
                      warningModalOpen: true,
                      message: "Do you want to save this config credit?",
                      messageSuccess: "Save config credit successfully.",
                      action: "configcredit",
                    });
                    // this.warningModal(true, "Do you want to save this config credit?", "Save config credit successfully."),
                    // this.setStateByProps("action", "configcredit")
                  }}
                >
                  <span className="fa fa-plus-circle"></span> &nbsp;&nbsp; Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Spin>

        <ModalWarning
          loading={loading}
          warningModalVisible={warningModalVisible}
          warningModal={this.warningModal}
          successModal={this.successModal}
          modalcountDown={this.modalcountDown}
          message={message}
          messageSuccess={messageSuccess}
        />

        <ModalSuccess
          loading={loading}
          successModalVisible={successModalVisible}
          successModal={this.successModal}
          messageSuccess={messageSuccess}
        />

        <ModalWarningCustom
          loading={loading}
          warningModalOpen={warningModalOpen}
          warningModal={this.warningModalCustom}
          onSubmit={this.onSubmitWarning}
          message={message}
        />

        <ModalError
          loading={loading}
          errorModalVisible={errorModalVisible}
          errorModal={this.errorModal}
          messageError={messageError}
        />
      </div>
    );
  }
}
