import React from "react";
import { Modal, Radio, Upload, Icon } from "antd";

const { Dragger } = Upload;

export default function Signature(props) {
  const handleOk = () => {
    props.setVisible(false);
  };

  const handleCancel = () => {
    props.setVisible(false);
  };

  const beforeUploadFile = (file) => {
    console.log(file);
    props.changeFile([file], props.fileName);
    return false;
  };

  const onRemove = (file, fileList) => {
    props.changeFile(null, props.fileName);
  };

  return (
    <>
      <Modal
        title="Add Signature"
        visible={props.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={"signature"}
        maskClosable={false}
        centered={true}
      >
        <div style={{ textAlign: "center", marginBottom: "16px" }}>
          <Radio.Group defaultValue="upload">
            <Radio.Button value="3" disabled>
              Draw
            </Radio.Button>
            <Radio.Button value="2" disabled>
              Type
            </Radio.Button>
            <Radio.Button value="1">Upload</Radio.Button>
          </Radio.Group>
        </div>
        <Dragger accept={".jpg,.png"} beforeUpload={beforeUploadFile} onRemove={onRemove} fileList={props.fileList} {...props}>
          <p className="ant-upload-drag-icon icon-upload">
            <Icon type="upload" style={{ fontSize: "20px", color: "#000" }} />
          </p>
          <div className="ant-upload-text" style={{ lineHeight: "15px" }}>
            <p>Drag and drop here (.jpg .png)</p>
            <p>or</p>
            <p style={{ color: "#097FF5" }}>Browse</p>
          </div>
        </Dragger>
      </Modal>
    </>
  );
}
