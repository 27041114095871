import React, { Component } from 'react';
import {
    Form, Input, DatePicker, TimePicker, Select, Cascader, InputNumber,
    Icon, Row, Col, Button, Tooltip, Checkbox, Spin, PageHeader, Card, Menu, Dropdown, Modal, Switch
} from 'antd';

import TableOTPLog from '../../../components/Ants/Table/TableOTPLog';

export default class OtpLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            APPNAME: "",
            OTPID: "",
            Mobile: [],
            Ref_Code: "",
            search: {
                APPNAME: "",
                OTPID: "",
                Mobile: [],
                Ref_Code: ""
            },
        };

        this.setStateByProps = this.setStateByProps.bind(this);
        this.onSearch = this.onSearch.bind(this);

    }
    componentDidMount() {

    }

    setStateByProps(parameters, value) {
        this.setState({ [parameters]: value });
    }

    onSearch() {
        this.setState(state => {
            return {
                search: {
                    APPNAME: state.APPNAME,
                    OTPID: state.OTPID,
                    Mobile: state.Mobile,
                    Ref_Code: state.Ref_Code
                }
            }
        });
    }

    render() {
        const { search } = this.state;
        return (
            <div className="animated fadeIn">
                <form>
                    <PageHeader title="OTP | Configuration : New">
                        <Row gutter={16} type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={7}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>App OTP Name</label>
                                <Input placeholder="App OTP Name" onChange={(e) => this.setStateByProps("APPNAME", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>OTP ID</label>
                                <Input placeholder="OTP ID" onChange={(e) => this.setStateByProps("OTPID", e.target.value)} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Mobile</label>
                                <Select
                                    value={this.state.Mobile}
                                    defaultValue={this.state.Mobile}
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    onChange={(e) => this.setStateByProps("Mobile", e)}
                                    placeholder="Mobile"
                                    tokenSeparators={[',', ' ']}
                                    open={false}
                                >
                                </Select>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                                <label className=" control-label require" style={{ fontWeight: 700 }}>Ref Code</label>
                                <Input placeholder="Ref Code" onChange={(e) => this.setStateByProps("Ref_Code", e.target.value)} />
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <label className=" control-label require" style={{ fontWeight: 700, marginTop: 8 }}><br /><br /></label>
                                <Button icon="search" htmlType="submit" style={{ marginRight: 8 }} onClick={this.onSearch}>
                                    Search
                         </Button>
                            </Col>

                        </Row>
                    </PageHeader>

                    <div className="content">
                        <Card style={{ minHeight: 200 }} >
                            <Row gutter={32}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TableOTPLog search={search} />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </form>
            </div>
        );
    }
}


