/* eslint-disable */
import React, { Component } from 'react';
import { Select } from 'antd';
import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, CheckTokenExp } from '../../../config';
import ModalServerError from '../Modal/ModalServerError';

const { Option } = Select;

export default class SelectAppOtp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            list: [],
            select: [],
            modalVisible: false,
        };

        this.getData = this.getData.bind(this);
        this.getDataOTPInfo = this.getDataOTPInfo.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData(value) {
        if (!CheckTokenExp()) {
            this.state.list.length > 0 ? "" : this.setState({ loading: true });
            
            apiClient.get('OTP/TEST/DDL_CONFIG', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    if (data.data.message === "Success") {
                        this.setState({ list: data.data.data });
                    } else {
                        this.setState({ modalVisible: true });
                        this.setState({ list: [] });
                    }
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.setState({ list: [] });
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    getDataOTPInfo(value) {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });

            apiClient.get("OTP/INFO/" + value,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.props.setStateByProps("Prefix", json.PREFIX)
                    // this.countMessage(json.CONTENT);
                    this.setState({ loading: false });
                })
                .catch(error => {
                    this.setState({ modalVisible: true });
                    this.props.setStateByProps("Prefix", "")
                    this.setState({ loading: false });
                });
        } else {
            window.location.href = "logout";
        }
        
    }

    render() {
        const { loading, list, modalVisible } = this.state;
        const { placeholder, select, setStateByProps } = this.props;

        return (
            <>
                <Select
                    showSearch
                    loading={loading}
                    name="select"
                    onSearch={(value) => { this.getData(value) }}
                    onChange={(value) => { setStateByProps("appotp", value), this.getDataOTPInfo(value) }}
                    value={select}
                    suffixIcon={loading ? "" : <span className="fa fa-angle-down" />}
                    placeholder={placeholder ? placeholder : "App OTP Name"}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        list.map((item) =>
                            <Option value={item.otcId}>{item.otcName}</Option>
                        )
                    }
                </Select>
                <ModalServerError modalVisible={modalVisible}/>
            </>
        );
    }
}


