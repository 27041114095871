import React, { Component } from 'react';
import { Table, Divider, Tag, Icon, Button, Card, Row, Col, Modal, Spin } from 'antd';

export default class ModalWarning extends Component {

    constructor(props) {
        super(props);


        this.state = {

        };

    }


    render() {
        const { loading, warningModalVisible, warningModal, successModal, modalcountDown, message, messageSuccess } = this.props;
        //const { cancelModalVisible } = this.state;

        return (
            <div>

                <style>{`
              

                
                 `}</style>
                <Modal

                zIndex={1021}
                wrapClassName="popup-question"
                closeIcon={false}
                visible={warningModalVisible}
                onOk={() => warningModal(true, message, messageSuccess)}
                onCancel={() => warningModal(false, message, messageSuccess)}
                width={360}
                footer={[
                <Button key="submit" type ="gray" loading={loading} onClick={() => warningModal(false, message, messageSuccess)}>
                Cancel
                </Button>,
                <Button key="submit1" type ="primary" loading={loading} onClick={() => { warningModal(false, message, messageSuccess); successModal(true); modalcountDown(); }}>
                OK
                </Button>
            ]}
                >
                <Row gutter={8} type ="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-center">
                <img src={require("../../../img/vendors/icon/question.png")} width="65px" />
                </Col>
                </Row>
                <Row gutter={8} type ="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="img-label">
                <label style={{ color: '#000000' }}>{message}</label>
                </Col>
                </Row>
                </Modal>
            </div >
                
        );
    }
}


